<template>
	<div class="overlay-container" @click="clickContainer">
    <div class="n-container">
      <div class="container_ui">
        <h3>Tip {{userName}}</h3>
        <div class="profile-image" v-bind:style="{ backgroundImage: 'url(' + profileImageUrl + ')' }"></div>
        <div class="container-input-amount-eth">
          <input type="number" name="" placeholder="1.0" v-model="amount" step="0.01" min="0">
          <p v-if="chainName === 'ethereum'">ETH</p>
          <p v-else-if="chainName === 'polygon'">Matic</p>
          <p v-else></p>
        </div>
        <div class="container_buttons">
          <button class="n-btn-weak" @click="dismiss">Cancel</button>
          <button class="n-btn-primary" @click="transferETH">TIP</button>
        </div>
        <p class="weak-text">{{ $t('OVERLAY_TRANSFER_TOKEN.TIP_NOTE')}}</p>
      </div>
    </div>
	</div>
</template>

<style scoped>
.container_ui {
  margin: auto;
  padding: 24px;
  border-radius: 12px;
  background-color: #fff;
  /*Center of Screen*/
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.container-input-amount-eth {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
}
.container-input-amount-eth input {
  height: 30px;
  border-radius: 8px;
  border: 1px solid #1DA1F2;
}
.container-input-amount-eth input, p {
  font-size: 20px;
  font-weight: 600;
}
.container_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
}
.profile-image {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 12px auto;
  border-radius: 50px;
  border: none;
  background-color: #eee;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'OverlayTransferToken',
  data() {
    return {
      amount: 0.01
    }
  },
  props: {
    userName: {
      type: String
    },
    profileImageUrl: {
      type: String,
      default: ""
    },
    chainName: {
      type: String,
      default: ""
    }
  },
  emits: ["dismiss-overlay-transfer-token", "transfer-eth"],
  methods: {
    dismiss: function() {
      this.$emit("dismiss-overlay-transfer-token");
      // reset transfer amount to default
      this.amount = 0.003;
    },
    transferETH: function() {
      this.$emit("transfer-eth", this.amount);
    }
  }
});
</script>