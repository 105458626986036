<template>
  <MainHeader
    :title="title"
    :walletAddress="walletAddress"
    :chainName="chainName"
    :membershipId="membershipId"
    :isAdmin="isAdmin"
  />
  <div class="n-container container_">
    <div class="n-container">
      <h2>{{ $t('SELL_VIEW.SELL_ITEM') }}</h2>
      <div class="caution">
        <h4 class="error-text-small">{{ $t('SELL_VIEW.PLEASE_NOTE') }}⛱</h4>
        <p class="weak-text">
          {{ $t('SELL_VIEW.SELL_GUIDE') }}
        </p>
      </div>
      
      <!-- Page 1 -->
      <Page1Component
        v-if="currentPage === 1"
        :itemName="itemName"
        :itemDescription="itemDescription"
        :imageInputs="imageInputs"
        :isInvalidName="isInvalidName"
        :isInvalidDescription="isInvalidDescription"
        :isInvalidImages="isInvalidImages"
        @delete-image="deleteImage"
        @update:itemName="itemName = $event"
        @update:itemDescription="itemDescription = $event"
        @image-change="onImageChange"
        @save-and-proceed="saveAndProceed"
        @go-back="goBack"
      />

      <!-- Page 2 -->
      <Page2Component
        v-else-if="currentPage === 2"
        :item-name="itemName"
        v-model:currency="currency"
        v-model:price="price"
        v-model:stock="stock"
        :isInvalidCurrency="isInvalidCurrency"
        :isInvalidPrice="isInvalidPrice"
        :isInvalidStock="isInvalidStock"
        @validate-input="validateInput"
        @infinite-stock="infiniteStock"
        @proceed-to-publish="proceedToPublish"
        @go-back="goBack"
        @go-back-to-page-1="currentPage = 1"
      />

      <!-- Page 3 -->
      <Page3Component
        v-else
        :item="item"
        :stock="stock"
        :currentPage="currentPage"
        @go-back-to-page1="currentPage = 1"
        @publish-product="publishProduct"
        @go-back="goBack"
      />

      <!-- ページネーションコントロール -->
      <div
        class="pagenation-container"
        v-show="currentPage === 1 || currentPage === 2"
      >
        <button
          class="pagenation"
          :class="{ active: currentPage === 1 }"
          @click="saveAndProceed"
        ></button>
        <button
          class="pagenation"
          :class="{ active: currentPage === 2 }"
          @click="proceedToPublish"
        ></button>
      </div>
    </div>
  </div>

  <!-- Overlay spinner -->
  <OverlaySpinner v-show="showSpinner"></OverlaySpinner>
</template>

<style scoped>
form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
form div {
  margin-bottom: 0;
  display: flex;
}

.container_ {
  margin-top: 140px;
  background-color: #fff;
  border: 2px solid #000;
}

.caution {
  padding: 16px;
  border-radius: 8px;
  background-color: beige;
}
::v-deep .label- {
  font-size: 16px;
  color: #888;
  font-weight: bold;
}
::v-deep .buttons {
  display: flex;
  flex-direction: column;
  width: 240px;
  margin: auto;
  gap: 12px;
}

::v-deep .wrap-form {
  padding: 16px;
  margin-top: 24px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

::v-deep .label- {
  font-size: 14px;
  font-weight: 600;
  color: #2a2a2a;
  margin-right: 24px;
}

#image-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.text-field {
  width: 81%;
  margin-left: 0px !important;
}
.pagenation {
  width: 12px;
  height: 12px;
  border-radius: 50%; /* Make the button round */
  border: none; /* Remove default button border */
  background-color: #ccc; /* Default background color */
}
.pagenation-container {
  margin-top: 24px;
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: middle;
}
.pagenation.active {
  background-color: #000; /* Active page button color */
}
.currency-stock-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media all and (max-width: 480px) {
  .text-field-select{
    margin:0px;
  }
  .text-field-step2-stock {
    width: 62%;
  }
  .text-field-step2 {
    width: 90%;
  }
  .text-field {
    width: 90%;
  }
  .label- {
    text-align: left;
    margin-bottom: 1px;
    margin-left: 14px;
  }
  .wrap-form {
    padding: 10px;
  }
  .wrap-container-image {
    margin-left: 10px;
  }
}
@media all and (min-width: 1380px) {
  .wrap-container-image {
    width: 90%;
  }
}
</style>

<script>
import { defineComponent, ref, watch } from "vue";
import { initializeApp } from "firebase/app";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import MainHeader from "@/components/MainHeader.vue";
import OverlaySpinner from '@/views/OverlaySpinner.vue';
import shared from "@/shared";
import axios from "axios";
import Swal from 'sweetalert2';
import { onBeforeRouteLeave } from "vue-router";
import Page1Component from "@/components/Page1Component.vue";
import Page2Component from "@/components/Page2Component.vue";
import Page3Component from "@/components/Page3Component.vue";
import i18n from "@/language";


export default defineComponent({
  name: "SellView",
  components: {
    MainHeader,
    OverlaySpinner,
    Page1Component,
    Page2Component,
    Page3Component
  },
  data() {
    return {
      walletAddress: null,
      membershipId: null,
      title: "- - -",
      isAdmin: false,
      chainName: null,
      imageCount: 0,
      maxImages: 6,
      imageInputs: [
        {
          id: "image-input-0",
          placeholderId: "image-placeholder-0",
          className: "image-upload",
          src: require("@/assets/img/icon-plus.png"),
          isDefault: true 
        },
      ],    
      currentPage: 1,
      itemName: null,
      itemDescription: null,
      imageUrls: [],
      currency: null,
      price: null,
      item: {},
      isInvalidName: null,
      isInvalidDescription: null,
      isInvalidImages: null,
      isInvalidCurrency: null,
      isInvalidPrice: null,
      isInvalidStock: null,
      showSpinner: false
    };
  },
  setup(){
    /* Init firebase */
    initializeApp(shared.firebaseConfig());

    // stockを整数値のみに制限
    const stock = ref(null);
    watch(stock, (newValue, oldValue) => {
      console.log(`Stock value changed from ${oldValue} to ${newValue}`);
      if (newValue === '' || newValue === null) return;
      if (isNaN(newValue)) return;
      
      if (newValue >= 1) {
        let flooredValue = Math.floor(newValue);
        if (flooredValue !== newValue) {
          stock.value = flooredValue;
        }
      } else {
           stock.value = null;
      }
    });

    // 編集中、セーブせずにページを離れようとしたらアラートを表示
    const didPublishProduct = ref(false);
    onBeforeRouteLeave(() => {
      if (didPublishProduct.value) {
        return;
      }
      const answer = window.confirm(
        i18n.global.t('CONFIRM.LEAVING_PAGE')
      );
      // cancel the navigation and stay on the same page
      if (!answer) return false;
    });

    return {
      didPublishProduct, 
      stock,
    };
  },
  mounted() {
    this.connectWallet = shared.connectWallet.bind(this);
    this.handleErrorAndGoTop = shared.handleErrorAndGoTop.bind(this);
    this.getChainParamFromChainId = shared.getChainParamFromChainId.bind(this);

    if (this.$route.query.membershipId) {
      this.membershipId = this.$route.query.membershipId;
    } else {
      this.handleErrorAndGoTop(
        "Sorry, something went wrong",
        "Please login again",
        3000
      );
      return;
    }

    //
    this.initialize();
  },
  unmounted() {
    this.connectWallet = null;
    shared.connectWallet.bind(null);
    this.handleErrorAndGoTop = null;
    shared.handleErrorAndGoTop.bind(null);
    this.getChainParamFromChainId = null;
    shared.getChainParamFromChainId.bind(null);
  },
  inject: {
    API_ENDPOINT_MEMBERSHIP: {
      from: "API_ENDPOINT_MEMBERSHIP",
    },
    API_BASE_URL: {
      from: "API_BASE_URL",
    },
    API_ENDPOINT_CREATE_PRODUCT: {
      from: "API_ENDPOINT_CREATE_PRODUCT"
    },
    API_ENDPOINT_GET_STRIPE_ACCOUNT: {
      from: 'API_ENDPOINT_GET_STRIPE_ACCOUNT'
    }
  },
  methods: {
    initialize: function () {
      const _this = this;
      this.connectWallet((err, event, walletData) => {
        if (err) {
          console.error(err);
          _this.handleErrorAndGoTop(
           _this.$t('POPUP.TITLE_COMMON_ERROR'),
           _this.$t('POPUP.PLEASE_LOGIN_AGAIN'),
            3000
          );
          return;
        }
        let walletObj = JSON.parse(walletData);
        let chainParam = _this.getChainParamFromChainId(walletObj.chainId);
        if (!walletObj.walletAddress || !walletObj.chainId || !chainParam) {
          console.error("wallet is disconnected?", event, walletData);
          _this.handleErrorAndGoTop(
            _this.$t('POPUP.TITLE_COMMON_ERROR'),
            _this.$t('POPUP.PLEASE_LOGIN_AGAIN'),
            3000
          );
          return;
        }
        _this.chainName = chainParam;
        _this.walletAddress = walletObj.walletAddress;

        // GET membership info
        let apiUrl = _this.API_BASE_URL + _this.API_ENDPOINT_MEMBERSHIP;
        axios
          .get(apiUrl, {
            params: {
              membershipId: _this.membershipId,
              walletAddress: _this.walletAddress,
            },
          })
          .then((result) => {
            let dataObj = JSON.parse(JSON.stringify(result.data));
            _this.isAdmin = dataObj.is_admin;
            _this.title = dataObj.name;
          })
          .catch((err) => {
            console.error(err);
            _this.handleErrorAndGoTop(
              _this.$t('POPUP.TITLE_COMMON_ERROR'),
              _this.$t('POPUP.PLEASE_LOGIN_AGAIN'),
              3000
            );
          });
      });
    },
    validateInput() {
      const priceString = String(this.price);
      const re = /^-?\d*(\.\d{0,2})?$/;
      if (!re.test(priceString)) {
        this.price = parseFloat(priceString.substring(0, priceString.length - 1));
      }
    },
    onChangeItemName: function(event) {
      this.itemName = event.target.value;
    },
     onChangeItemDescription: function(event) {
      this.itemDescription = event.target.value;
    },
    onImageChange(e, imageInput) {
      if (!e.target.files || e.target.files.length === 0) {
        alert("Something went wrong");
        return;
      }

      const file = e.target.files[0];
      const maxSizeInMB = 2;
      if (file.size > maxSizeInMB * 1024 * 1024) {
        alert("File size exceeds the limit of 2MB.");
        e.target.value = null;
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        imageInput.src = event.target.result;
        imageInput.isDefault = false;
        imageInput.file = file;

        // 新しい画像がアップロードされ、まだ最大画像数に達していない場合は新しいアップロードボタンを追加します。
        if (this.imageInputs.length < this.maxImages) {
          const lastImageInput = this.imageInputs[this.imageInputs.length - 1];
          if (lastImageInput.src !== require("@/assets/img/icon-plus.png")) {
            this.addImageUploadButtonAfter(lastImageInput);
          }
        }
      };
      reader.readAsDataURL(e.target.files[0]);

      // inputタグのvalue属性をクリアします。
      e.target.value = null;
    },
    updateImageInputs(updatedImageInputs) {
      console.log("updateImageInputs", updatedImageInputs);
      // 子コンポーネントから受け取ったデータで imageInputs を更新
      this.imageInputs = updatedImageInputs;
    },
    addImageUploadButtonAfter(imageInput) {
      const newImageInputId = `image-input-${this.imageInputs.length}`;
      const newImagePlaceholderId = `image-placeholder-${this.imageInputs.length}`;

      const newImageInput = {
        id: newImageInputId,
        placeholderId: newImagePlaceholderId,
        className: "image-upload",
        src: require("@/assets/img/icon-plus.png"),
        isDefault: true
      };

      const imageInputIndex = this.imageInputs.findIndex(
        (input) => input.id === imageInput.id
      );
      this.imageInputs.splice(imageInputIndex + 1, 0, newImageInput);
    },
    deleteImage(imageInput) {
      // imageInputsが1つしかない場合は削除せずに画像をクリアする
      if (this.imageInputs.length === 1) {
        imageInput.src = require("@/assets/img/icon-plus.png");
        imageInput.isDefault = true;
        return;
      }

      imageInput.src = require("@/assets/img/icon-plus.png"); //画像を削除する代わりにsrcプロパティをプラスアイコンにリセットします。

      // imageInputを削除する
      const indexToRemove = this.imageInputs.findIndex(input => input.id === imageInput.id);
      if (indexToRemove !== -1) {
        this.imageInputs.splice(indexToRemove, 1);
      }

      // プラスアイコンが表示されているimageInputが2つ以上存在する場合、最後のimageInputを削除します。
      const plusIconInputs = this.imageInputs.filter(
        (input) => input.src === require("@/assets/img/icon-plus.png")
      );
      if (plusIconInputs.length > 1) {
        const lastImageInput = this.imageInputs[this.imageInputs.length - 1];
        const imageInputIndex = this.imageInputs.findIndex(
          (input) => input.id === lastImageInput.id
        );
        if (imageInputIndex > -1) {
          this.imageInputs.splice(imageInputIndex, 1);
        }
      }

      // imageInputsをソートし、プラスボタンが最後に表示されるようにします。
      this.imageInputs.sort((a, b) => {
        if (a.src === require("@/assets/img/icon-plus.png")) {
          return 1;
        }
        if (b.src === require("@/assets/img/icon-plus.png")) {
          return -1;
        }
        return 0;
      });
    },
    saveAndProceed() {
      if (this.itemName !== null && this.itemName !== "") {
        this.isInvalidName = false;
      } else {
        this.isInvalidName = true;
      }

      if (this.itemDescription !== null && this.itemDescription !== "") {
        this.isInvalidDescription = false;
      } else {
        this.isInvalidDescription = true;
      }

      const filterAndPushImageUrls = (sourceArray) => {
        const imageUrls = [];
        for (const item of sourceArray) {
          if (!item.isDefault) { // isDefaultプロパティをチェック
            imageUrls.push(item.src);
          }
        }
        return imageUrls;
      };
      this.imageUrls = filterAndPushImageUrls(this.imageInputs);
      if (this.imageUrls.length > 0) {
        this.isInvalidImages = false;
      } else {
        this.isInvalidImages = true;
      }

      if (!this.isInvalidName && !this.isInvalidDescription && !this.isInvalidImages) {
        this.currentPage = 2;
      }
    },
    proceedToPublish() {
      this.isInvalidCurrency = (!this.currency);
      this.isInvalidPrice = (!this.price);
      this.isInvalidStock = (!this.stock);
      if (this.isInvalidCurrency || this.isInvalidPrice || this.isInvalidStock) {
        return;
      }

      let unitAmount = this.price * 100;
      this.item = {
        name: this.itemName,
        description: this.itemDescription,
        isPublished: false,
        price: unitAmount,
        currency: this.currency,
        stock: this.stock,
        imageUrls: this.imageUrls,
      };
      this.currentPage = 3
    },
    async publishProduct() {
      this.showSpinner = true;

      try {
        const downloadURLs = await this.uploadImagesAndGetURLs();
        await this.sendProductData(downloadURLs);
        
        this.didPublishProduct = true;
        this.showSuccessDialog();
      } catch (error) {
        console.error('Error:', error);
        this.showErrorDialog();
        this.showSpinner = false;
      }
    },
    async uploadImagesAndGetURLs() {
      const storage = getStorage();
      const timestamp = Date.now();
      const replacedString = this.itemName.replace(/ /g, "_") + "_" + String(timestamp);
      const uploadTasks = [];
      
      for (let imageInput of this.imageInputs) {
        if (!imageInput.file) continue;
        const fileRef = storageRef(storage, `product_images/${this.membershipId}/${replacedString}/` + imageInput.file.name);
        uploadTasks.push(uploadBytes(fileRef, imageInput.file));
      }
      
      const snapshots = await Promise.all(uploadTasks);
      
      return Promise.all(snapshots.map(snapshot => getDownloadURL(snapshot.ref)));
    },
    async sendProductData(downloadURLs) {
      if (this.currency === "USD" || this.currency === "EUR") {
        this.price *= 100;  // Convert to cents
      }

      // this.stock = this.stock || -1;  // Set to -1 if stock is not provided (Infinite stock)

      console.log("this.stock", this.stock);

      const result_ = await this.getStripeAccountId();
      if (!result_?.data?.account_id) {
        this.showErrorDialog();
      } else {  
        const apiUrl = this.API_BASE_URL + this.API_ENDPOINT_CREATE_PRODUCT;
        const postData = {
          membershipId: this.membershipId,
          name: this.itemName,
          type: "good",
          description: this.itemDescription,
          price: this.price,
          currency: this.currency,
          inventory: this.stock,
          images: downloadURLs,
          accountId: result_?.data?.account_id
        };
        const result = await axios.post(apiUrl, postData);
        console.log("result", result);
      }
      
      this.showSpinner = false;
    },
    async getStripeAccountId() {
      try {
        console.log("getting stripe account id", this.walletAddress);
        let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_GET_STRIPE_ACCOUNT;
        const result = axios.get(apiUrl, { params: {
          membershipId: this.membershipId,
          walletAddress: this.walletAddress}
        });

        return result;

      } catch(error) {
        console.error("failed to get stripe account id");
        return;
      }
    },
    infiniteStock() {
      this.stock = null;
    },
    goBack() {
      window.history.back();
    },
    showSuccessDialog() {
      Swal.fire({
        position: 'top-end', // ダイアログを右上に配置
        icon: null, // アイコンを非表示にする
        title: 'Success',
        html: this.$t('POPUP.CONTENT_NEW_POST_SELL'),
        showConfirmButton: true,
        timer: 3000, // 3秒後に自動的に閉じる
        customClass: {
          title: 'black-text',
          content: 'black-text'
        }
      }).then(() => {
        let newPath = `/timeline?membershipId=${this.membershipId}#shop`;
        this.$router.push(newPath);
      });
    }, 
    showErrorDialog() {
      Swal.fire({
        position: 'top-end', // ダイアログを右上に配置
        icon: null, // アイコンを非表示にする
        title: 'Oops...',
        text: 'Something went wrong! Please try again later.',
        showConfirmButton: false,
        timer: 3000, // 3秒後に自動的に閉じる
        customClass: {
          title: 'black-text',
          content: 'black-text'
        }
      });
    }
  },
});
</script>