<template>
  <div class="news-cell-container" v-bind:style="{ backgroundColor: backgroundColor }">
    <div class="left-column">
      <div class="upper-section">
        <div class="profile-image" v-bind:style="{ backgroundImage: 'url(' + iconImageUrl + ')' }"></div>
        <div class="message-container">
          <p class="main-text"> {{ text }} </p>
        </div>
      </div>
      <div class="bottom-section"> 
        <p class="weak-text"> {{ ago }} </p>
      </div>
    </div>
    <div class="right-column">
      <button @click="clickNewsEvent"></button>
    </div>
  </div>
</template>

<style scoped>
.news-cell-container {
  display: flex;
  max-width: 60%;
  margin: auto;
  padding: 12px;
  border-bottom: 1px solid #ddd;
  overflow: hidden;
}
.left-column {
  width: 80%;
}
.upper-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 12px;
}
.bottom-section {
  
}
.bottom-section p {
  text-align: left;
}
.right-column {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
}
.right-column button {
  width: 50px;
  height: 50px;
  border: none;
  background-color: transparent;
  background-image: url("@/assets/img/icon-right-arrow.png");
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}
.profile-image {
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 25px;
  background-color: #eee;
}
.message-container {
  width: calc(100% - 80px);
}
.message-container p {
  text-align: left;
}
.main-text {
  font-weight: 500;
  font-color: #000;
}
.sub-text {
  font-size: 14px;
  font-color: #aaa;
}
@media all and (min-width: 1024px) and (max-width: 1280px) { }
@media all and (min-width: 768px) and (max-width: 1024px) { }
@media all and (min-width: 480px) and (max-width: 768px) { }
@media all and (max-width: 480px) { 
  .news-cell-container {
    max-width: 100%;
  }
}
</style>

<script>
import { defineComponent } from 'vue';
import moment from 'moment';

export default defineComponent({
  name: 'NewsCell',
  data() {
    return {
      ago: "",
      backgroundColor: "transparent",
    }
  },
  mounted() {
    var date = new Date(this.timestamp);
    let agoDate = moment(date, 'ddd MMM DD YYYY HH:mm:ss GMT Z').fromNow();
    this.ago = agoDate;
    if (!this.isRead) {
      this.backgroundColor = "#CCEAFD";
    } else {
      this.backgroundColor = "transparent";
    }
  },
  props: {
    iconImageUrl: {
      type: String,
      default: ""
    },
    isRead: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ""
    },
    timestamp: {
      type: Number,
      default: -1
    },
    externalUrl: {
      type: String,
      default: ""
    }
  },
  emits: ["click-news-event"],
  methods: {
    clickNewsEvent: function() {
      this.$emit("click-news-event", this.externalUrl);
    }
  }
});
</script>