import Swal from "sweetalert2";
import i18n from "@/language";
import router from "@/router";
export default {
  supportNetwork() {
    Swal.fire({
      title: i18n.global.t("TOP_VIEW.POPUP.TITLE_USE_POLUGON_ETHER"), // "Currently we support Ethereum & Polygon Chain",
      text: i18n.global.t("TOP_VIEW.POPUP.CONTENT_USE_POLUGON_ETHER"), //"Please switch to supported chain",
      type: "warn",
      showCancelButton: false,
      confirmButtonText: "OK",
    });
  },
  requestTimeoutMetamask() {
    const errorMessage = i18n.global.t("TOP_VIEW.POPUP.TITLE_CONNECT_METAMASK"); // "Connect on MetaMask";
    const errorHtml =
      "<div>" +
      i18n.global.t("TOP_VIEW.POPUP.PLEASE_CHECK_METAMASK") +
      "</div> <div><b>" +
      i18n.global.t("TOP_VIEW.POPUP.PLEASE_RELOAD_PAGE") +
      "</b></div>";
    //  "<div>Please check MetaMask.</div> <div><b>If nothing happens for a while, maybe something went wrong. Please reload the page.</b></div>";
    Swal.fire({
      title: errorMessage,
      html: errorHtml,
      type: "info",
      showCancelButton: true,
      confirmButtonText: i18n.global.t("TOP_VIEW.POPUP.BTN_RELOAD_PAGE"), //"Reload page",
      cancelButtonText: i18n.global.t("TOP_VIEW.POPUP.BTN_STAY_PAGE"), // "Stay on page",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        router.go();
      }
    });
  },
  userRejectMetamask() {
    const errorMessage = i18n.global.t(
      "TOP_VIEW.POPUP.TITLE_NEED_APPROVE_METAMASK"
    ); // "You need to approve request on MetaMask";
    const errorHtml = i18n.global.t(
      "TOP_VIEW.POPUP.CONTENT_NEED_APPROVE_METAMASK"
    );
    // "Please click 'Connect Wallet' button and approve the request.";
    Swal.fire({
      title: errorMessage,
      html: errorHtml,
      type: "info",
      showCancelButton: false,
      confirmButtonText: "OK",
    });
  },
  otherErrorMetamask() {
    const errorMessage = i18n.global.t("TOP_VIEW.POPUP.TITLE_DO_HAS_METAMASK"); // "Do you have Metamask?";
    const errorHtml = i18n.global.t("TOP_VIEW.POPUP.CONTENT_DO_HAS_METAMASK");
    // "<div style='text-align: left;'><div><b>If you have MetaMask installed:</b> Please check it.</div><br/><div><b>If not:</b> Please use the browser with MetaMask support.<br/> <div style='background-color: #eee; font-size: 16px; margin-top: 12px;'> <ul style='list-style-type: disc;'><li>PC/Mac →Use <a href='https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn' target='_blank'>MetaMask Extension</a></li><li>Mobile(iOS) →Download <a href='https://apps.apple.com/app/metamask-blockchain-wallet/id1438144202' target='_blank'>MetaMask App</a></li> <li>Mobile(Android) →Download <a href='https://play.google.com/store/apps/details?id=io.metamask&gl=US' target='_blank'>MetaMask App</a></li></ul></div></div></div>";
    Swal.fire({
      title: errorMessage,
      html: errorHtml,
      type: "info",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonClass: 'display-block',
    });
  },
  somethingWrong1006() {
    Swal.fire({
        title: i18n.global.t("TOP_VIEW.POPUP.TITLE_SOMETHING_WRONG_1006"), // "Sorry, something went wrong - error code: 1006",
        text: i18n.global.t("TOP_VIEW.POPUP.CONTENT_SOMETHING_WRONG_1006"), // "Please try again.",
        type: "warn",
        showCancelButton: false,
        confirmButtonText: i18n.global.t("TOP_VIEW.POPUP.BTN_RELOAD_PAGE"), //"Reload Page",
      }).then((result) => {
        if (result.value) {
          router.go();
        }
      });
  },
  registerMembershipErr403() {
    const imagePopUp = require("@/assets/img/info-graphic.png");
    const htmlPopUp =
      '<img src="' +
      imagePopUp + i18n.global.t("TOP_VIEW.POPUP.CONTENT_REGISTER_MEMBERSHIP_403")
     // '" style="width:60px;height:60px;"><div> If the NFT is created with some NFT marketplaces like OpenSea or Rarible, those NFT cannot create membership page. It is because they have the same contract address and not being able to identify as unique NFT collection. </div>';
     Swal.fire({
      title: i18n.global.t("TOP_VIEW.POPUP.TITLE_REGISTER_MEMBERSHIP_403"), //'Sorry, unsupported NFT'
      html: htmlPopUp,
      showCancelButton: false,
      confirmButtonText: "OK",
      timer: 15000,
    });
  },
  registerMembershipErr405() {
    Swal.fire({
        title:  i18n.global.t("TOP_VIEW.POPUP.TITLE_REGISTER_MEMBERSHIP_405"), // "Sorry, something went wrong",
        text: i18n.global.t("TOP_VIEW.POPUP.CONTENT_REGISTER_MEMBERSHIP_405"), // "This membership page is currently going through some technical issues. Please come back later.",
        showCancelButton: false,
        confirmButtonText: "OK",
        timer: 7000,
      });
  },
  otherError() {
    Swal.fire({
        title: i18n.global.t("POPUP.TITLE_OTHER_ERROR"), //"Sorry, something went wrong",
        text: i18n.global.t("POPUP.CONTENT_OTHER_ERROR"), //"Please try again",
        showCancelButton: false,
        confirmButtonText: "OK",
        timer: 3500,
      });
  },
  warningDiffNetwork() {
    Swal.fire({
      title: i18n.global.t("POPUP.TITLE_SUPPORT_NETWORK"), //'Currently we support Ethereum and Polygon',
      type: i18n.global.t("POPUP.CONTENT_SUPPORT_NETWORK"), //'Please switch to Ethereum and Polygon chain',
      showCancelButton: false,
      confirmButtonColor: '#000',
      timer: 5000
    });
  }
};
