<!-- Page2Component.vue -->
<template>
  <div class="wrap-form">
    <h3>{{ itemName }}</h3>

    <form @submit.prevent="onSubmit">
      <div class="currency-stock-container">
        <div class="row">
          <div
            class="col-12 col-lg-4 d-flex justify-content-start justify-content-lg-end"
          >
            <label class="label-" for="currency">{{ $t('SELL_VIEW.CURRENCY') }}</label>
          </div>
          <div class="col-12 col-lg-8 d-flex justify-content-center justify-content-lg-start">
            <select
                class="text-field-step2 text-field-select"
                id="currency"
                :value="currency"
                @input="$emit('update:currency', $event.target.value)"
              >
              <option value="" disabled selected>{{ $t('SELL_VIEW.CURRENCY_PLACEHOLDER') }}</option>
              <option value="USD">USD</option>
              <option value="EUR">EURO</option>
              <option value="JPY">JPY</option>
            </select>
            <p class="error-text-small" v-if="isInvalidCurrency">{{ $t('SELL_VIEW.CURRENCY_INVALID') }}</p>
          </div>
        </div>
        
        <div class="row">
          <div
            class="col-lg-4 d-flex justify-content-start justify-content-lg-end"
          >
            <label class="label-" for="price">{{ $t('SELL_VIEW.PRICE_INCLUDE_TAX') }}</label>
          </div>
          <div class="col-12 col-lg-8 d-flex justify-content-center justify-content-lg-start">
            <input 
              class="text-field-step2"
              id="price"
              type="number" 
              :value="price"
              @input="updatePrice"
              step="1"
            />
          </div>
        </div>
        <p class="error-text-small" v-if="isInvalidPrice">{{ $t('SELL_VIEW.PRICE_INCLUDE_TAX_INVALID') }}</p>

        <div class="row">
          <div
            class="col-lg-4 d-flex justify-content-start justify-content-lg-end"
          >
            <label class="label-" for="stock">{{ $t('SELL_VIEW.STOCK') }}</label>
          </div>
          <div
            class="col-12 col-lg-8 justify-content-center justify-content-lg-start d-flex flex-row"
          >
            <input
              class="text-field-step2-stock"
              id="stock"
              type="number"
              :value="stock"
              @input="updateStock"
              step="1"
              min="1"
            />
<!--             <button class="button-infinite" @click="infiniteStock">
              Infinite
            </button> -->
          </div>
        </div>
        <p class="error-text-small" v-if="isInvalidStock">{{ $t('SELL_VIEW.STOCK_INVALID') }}</p>

      </div>
    </form>

    <div class="buttons">
      <button class="n-btn-primary" @click="proceedToPublish">
        {{ $t('SELL_VIEW.PROCEES_TO_PUBLISH') }}
      </button>
      <button class="n-btn-weak" @click="$emit('go-back-to-page-1')">{{ $t('BUTTON.CANCEL') }}</button>
      <button class="n-btn-link weak" @click="goBack">{{ $t('BUTTON.CANCEL') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemName: String,
    currency: String,
    price: Number,
    stock: Number,
    isInvalidCurrency: Boolean,
    isInvalidPrice: Boolean,
    isInvalidStock: Boolean
  },
  methods: {
    validateInput() {
      this.$emit('validate-input');
    },
    infiniteStock() {
      this.$emit('infinite-stock');
    },
    proceedToPublish() {
      this.$emit('proceed-to-publish');
    },
    goBack() {
      this.$emit('go-back');
    },
    updatePrice(event) {
      const value = event.target.value;
      this.$emit('validate-input');
      this.$emit('update:price', value);
    },
    updateStock(event) {
      const value = event.target.value;
      this.$emit('update:stock', value);
    },
  },
}
</script>

<style scoped>
.currency-stock-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.button-infinite {
  height: 31px;
  border-radius: 8px;
  border: none;
  margin-left: 10px; 
  margin-top: 12px;
  background-color: #ccc;
  color: #555;
  font-size: 11px;
  font-weight: 700;
  padding: 8px;
}
@media all and (max-width: 480px) {
  .button-infinite {
    margin-top: 0px;
  }
}

</style>
