<template>
	<div class="overlay-container" @click="clickContainer">
    <div class="n-container">
      <div class="container_" @click="clickActiveArea">
        <div class="post-info-container">
          <div class="post-info-left-container">
            <div class="profile-image" v-bind:style="{ backgroundImage: 'url(' + userData.profileImageUrl + ')' }"></div>
          </div>
          <div class="post-info-right-container">
            <div class="post-textarea-wrapper">
              <textarea id="post-textarea" :placeholder="$t('OVERLAY_POST.PLACEHOLDER_POST_TXT')" v-bind:value="postText" v-on:input="onChangePostText" v-bind:maxlength="MAX_POST_CHARACTERS"></textarea> 
            </div>
            <!-- Post Image -->
            <div class="post-image-container" v-if="postImageUrl != null">
              <div class="post-image" v-bind:style="{ backgroundImage: 'url(' + postImageUrl + ')' }">
                <button class="post-image-delete-button" @click="removeSelectedImage"></button>
              </div>  
            </div>
            <div class="post-ui-container">
              <div class="post-ui-container-wrapper">
                <button class="button-select-image" @click="selectImage"></button>
                <p class="post-text-left-count"><span>{{ $t('OVERLAY_POST.CHARACTER') }}:</span> {{postTextLeftCount}}</p>  
              </div>
              <div>
                <p class="error-text" v-show="isInvalidFileSize">{{ $t('OVERLAY_POST.IMAGE_LIMIT') }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="post-buttons-wrapper">
          <button class="n-btn-primary" :class="{'button--loading': isPosting}" @click="post" :disabled="!isPostValid || isPosting">
            {{ buttonTitle }}
          </button>
          <button class="n-btn-weak" @click="handleCloseEvent">{{ $t('BUTTON.CANCEL') }}</button>
        </div>
      </div>
    </div>
	</div>
</template>

<style scoped>
.n-container {
  border: none;
}
.container_ {
  width: 60%;
  padding: 24px;
  margin: 200px auto;
  background-color: #fff;
  border: 2px solid #000;
}
.button--loading {
  background-image:url('@/assets/img/loader.gif');
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}
.button-select-image {
  width: 30px;
  height: 30px;
  background-image: url("@/assets/img/icon-image.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.post-info-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.post-info-left-container {
  display: flex;
  justify-content: right;
  width: 20%;
}
.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #000;
}
.post-info-right-container {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
}
.post-textarea-wrapper {
  flex-grow: 1;
}
.post-info-right-container textarea {
  width: 90%;
  height: 100px;
  outline: none;
  border:none;
  border-bottom: 1px solid #ccc;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  float: right; 
}
.post-ui-container {
  width: 90%;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  -moz-column-gap: 12px;
}
.post-ui-container-wrapper {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.post-buttons-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: start;
}
.post-buttons-wrapper button {
  width: 200px;
  margin: 6px;
  display:inline-block;
}
.post-text-left-count {
  color: #888;
  font-size: 12px;
  float: right;
}
.post-image-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 12px 0;
}
.post-image {
  width: 90%;
  height: 100px;
  display: flex;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
}
.post-image-delete-button {
  width: 24px;
  height: 24px;
  margin-left: 2px;
  margin-top: 2px;
  background-image: url("@/assets/img/icon-close.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
/*------------------------------------------
  Animation
--------------------------------------------*/
@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
@media all and (min-width: 1024px) and (max-width: 1280px) { }
@media all and (min-width: 768px) and (max-width: 1024px) { }
@media all and (min-width: 480px) and (max-width: 768px) { 
  .post-cell-container {
    max-width: 100%;
  }
}
@media all and (max-width: 480px) { 
  .container_ {
    width: 98%;
  }
  .post-container {
    width: inherit;
  }
}
</style>

<script>
import { defineComponent } from 'vue';
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import shared from '@/shared';
import axios from "axios";

export default defineComponent({
  name: 'OverlayPost',
  data() {
  	return {
  		postText: "",
      isPosting: false,
      isPostValid: false,
      buttonTitle:  this.$t('BUTTON.POST_NOMAL'),
      postTextLeftCount: this.MAX_POST_CHARACTERS,
      membershipId: null,
      isInvalidFileSize: false,
      postImageUrl: null,
      selectedImageFile: null,
  	}
  },
  setup: () => { 
    /* Initialize Firebase */
    initializeApp(shared.firebaseConfig());
  },
  updated: () => {
    let textarea = document.getElementById("post-textarea");
    textarea.focus();
  },
  props: {
    userData: {
      type: Object,
      default: () => ({ count: 0 })
    }
  },
  inject: {
    LOCAL_STORAGE_KEY_MEMBERSHIP: {
      from: 'LOCAL_STORAGE_KEY_MEMBERSHIP'
    },
    API_BASE_URL: {
      from: 'API_BASE_URL'
    },
    API_ENDPOINT_CREATE_POST: {
      from: 'API_ENDPOINT_CREATE_POST'
    },
    MAX_POST_CHARACTERS: {
      from: 'MAX_POST_CHARACTERS'
    }
  },
  methods: {
    clickContainer: function() {
      this.handleCloseEvent();
    },
    clickActiveArea: function(event) {
      event.stopPropagation();
    },
    post: function() {
      if (!this.postText || this.postText === "") {
        return;
      }
      let _this = this;
      
      this.handleErrorAndGoTop = shared.handleErrorAndGoTop.bind(this);
      if (this.$route.query.membershipId) { 
        this.membershipId = this.$route.query.membershipId;
      } else { 
        this.handleErrorAndGoTop("Sorry, something went wrong", "Please login again", 3000);
        return;
      }
      // Show loder on button
      this.showLoaderOnButton();

      function postData(imgUrl) {
        let apiUrl = _this.API_BASE_URL + _this.API_ENDPOINT_CREATE_POST;
        // posttextの改行を\nで置き換え
        let postTextForFirestore = _this.postText.replaceAll(/[\n\r]/g, "\\n");
        var postData = {};
        if (imgUrl) {
          postData = {
            walletAddress: _this.userData.walletAddress,
            text: postTextForFirestore,
            membershipId: _this.membershipId,
            imageUrl: imgUrl
          };
        } else {
          postData = {
            walletAddress: _this.userData.walletAddress,
            text: postTextForFirestore,
            membershipId: _this.membershipId
          };
        }
        axios.post(apiUrl, postData)
        .then(result => {
          // Hide loder on button
          _this.resetInterface();
          // dimiss & reload posts
          _this.dismiss();
          if (result.data.data) {
            _this.$emit('post-created', result.data.data);
          }
        })
        .catch(err => {
          console.error(err);
          // Hide loder on button
          _this.resetInterface();
        });
      }
      // Upload image and get URL
      if (this.selectedImageFile && this.selectedImageFile.type) {
        var imageExtension = "";
        switch(this.selectedImageFile.type) {
          case "image/png":
          imageExtension = ".png";
          break;
          case "image/jpeg":
          imageExtension = ".jpeg";
          break;
          case "image/jpg":
          imageExtension = ".jpg";
          break;
          default:
          this.handleError = shared.handleError.bind(this);
          this.handleError();
          return;
        }
        let timestamp = Date.now();
        let imagePath = `/post_images/${this.membershipId}/${this.userData.walletAddress}/${timestamp}${imageExtension}`;
        const storage = getStorage();
        const storageRef = ref(storage, imagePath);
        // 'file' comes from the Blob or File API
        uploadBytes(storageRef, this.selectedImageFile).then((snapshot) => {
          return getDownloadURL(snapshot.ref);
        }).then(downloadURL => {
          postData(downloadURL);
        });
      } else {
        postData();
      }
    },
    showLoaderOnButton: function() {
      this.isPosting = true;
      this.buttonTitle = this.$t('BUTTON.POSTING');
    },
    resetInterface: function() {
      this.isPosting = false;
      this.buttonTitle = this.$t('BUTTON.POST');
      this.selectedImageFile = null;
      this.postImageUrl = null;
    },
    handleCloseEvent: function() {
      let _this = this;
      this.showAlert = shared.showDraftLossAlert.bind(this);
      this.showAlert(()=> {
        _this.postText = "";
        _this.$emit('close-overlay-post');
      });
    },
    dismiss: function() {
      this.postText = "";
      this.$emit('dismiss-overlay-post');
    },
    onChangePostText: function(event) {
      this.postText = event.target.value;
      this.postTextLeftCount = this.MAX_POST_CHARACTERS - this.postText.length;
      if (this.postText.length > 0) {
        this.isPostValid = true;
      } else {
        this.isPostValid = false;
      }
    },
    selectImage: function(event) {  
      event.stopPropagation();
      let _this = this;
      var input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/png, image/jpeg, image/jpg';
      input.onchange = e => { 
        if(e.target.files.length > 0) {
          var file = e.target.files[0];
          let maxfilesize = 1024 * 1024  * 2;  // 2 Mb
          if (file.size > maxfilesize) {
            _this.isInvalidFileSize = true;
            return;
          } else {
            _this.isInvalidFileSize = false;
          }
          _this.selectedImageFile = file;
          _this.postImageUrl = URL.createObjectURL(file);
        }
      }
      input.click();
    },
    removeSelectedImage: function(event) {
      event.stopPropagation();
      this.selectedImageFile = null;
      this.postImageUrl = null;
    }
  }, 
  emits: ["dismiss-overlay-post", "close-overlay-post", "post-created"]
});

</script>
