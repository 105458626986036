import { createStore } from 'vuex';
export default createStore({
  state: {
    selectedProduct: null,
    currentLanguage:  (navigator.language || navigator.userLanguage) === 'ja' ? 'ja' : 'en'
  },
  mutations: {
    setSelectedProduct(state, payload) {
      state.selectedProduct = payload;
    },
    CHANGE_LANGUAGE(state, payload) {
      state.currentLanguage = payload;
    }
  },
  actions: {
    changeLanguage({ commit }, newLang) {
      commit('CHANGE_LANGUAGE', newLang);
    },
  },
  getters: {
    currentLang: state =>  state.currentLanguage
  }
});
