import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import AxiosPlugin from "./plugins/axiosPlugin.js";
import MasonryWall from "@yeger/vue-masonry-wall";
// import { store } from './store'
import store from './store'
import "./styles/custom.scss";
import i18n from '@/language';
import "@mdi/font/css/materialdesignicons.css";

let app = createApp(App)
  .use(router)
  .use(VueSweetalert2)
  .use(AxiosPlugin)
  .use(MasonryWall)
  .use(store)
  .use(i18n);

app.provide("SHARE_URL", process.env.VUE_APP_SHARE_URL);
app.provide("MORALIS_SERVER_URL", process.env.VUE_APP_MORALIS_SERVER_URL);
app.provide("MORALIS_APP_ID", process.env.VUE_APP_MORALIS_APP_ID);
app.provide("MORALIS_MASTER_KEY", process.env.VUE_APP_MORALIS_MASTER_KEY);
app.provide("OPENSEA_TOKEN_ADDRESSS", "");
app.provide("RARIBLE_TOKEN_ADDRESSS", "");
app.provide("SUPERRARE_TOKEN_ADDRESSS", "");
app.provide("API_BASE_URL", process.env.VUE_APP_API_BASE_URL);
app.provide("API_ENDPOINT_MEMBERSHIP_ID", "/membershipId");
app.provide("API_ENDPOINT_MEMBERSHIP", "/membership");
app.provide("API_ENDPOINT_POSTS", "/posts");
app.provide("API_ENDPOINT_POST", "/post");
app.provide("API_ENDPOINT_COMMENTS", "/comments");
app.provide("API_ENDPOINT_LIKES", "/likes");
app.provide("API_ENDPOINT_NOTIFICATIONS", "/notifications");
app.provide("API_ENDPOINT_NEWS", "/news");
app.provide("API_ENDPOINT_USER", "/user");
app.provide("API_ENDPOINT_OGP", "/ogp");
app.provide("API_ENDPOINT_REPORT", "/report");
app.provide("API_ENDPOINT_CREATE_USER", "/createUser");
app.provide("API_ENDPOINT_RANK", "/engagementRanking");
app.provide("API_ENDPOINT_UPDATE_USER", "/updateUser");
app.provide("API_ENDPOINT_CREATE_MEMBERSHIP", "/createMembership");
app.provide("API_ENDPOINT_UPDATE_MEMBERSHIP", "/updateMembership");
app.provide("API_ENDPOINT_CREATE_POST", "/createPost");
app.provide("API_ENDPOINT_CREATE_COMMENT", "/createComment");
app.provide("API_ENDPOINT_CREATE_LIKE", "/createLike");
app.provide("API_ENDPOINT_CREATE_LIKE_COMMENT", "/createLikeComment");
app.provide("API_ENDPOINT_CREATE_PRODUCT", "/createProduct");
app.provide("API_STRIPE_ONBOARD", "/onboard");
app.provide("API_STRIPE_DASHBOARD", "/dashboardLink");
app.provide("API_STRIPE_PRODUCTS", "/products");
app.provide("API_STRIPE_PAYMENT_INTENT", "/paymentIntent");
app.provide("API_ENDPOINT_DELETE_LIKE", "/deleteLike");
app.provide("API_ENDPOINT_DELETE_POST", "/deletePost");
app.provide("API_ENDPOINT_DELETE_COMMENT", "/deleteComment");
app.provide("API_ENDPOINT_DELETE_LIKE_COMMENT", "/deleteLikeComment");
app.provide("API_ENDPOINT_WHITELIST", "/whitelist");
app.provide("API_ENDPOINT_DEFLAG_NOTIFICATIONS", "/deflagNotifications");
app.provide("API_ENDPOINT_DEFLAG_NEWS", "/deflagNews");
app.provide("API_ENDPOINT_CREATE_TIP_NOTIFICATION", "/tipNotification");
app.provide("API_ENDPOINT_ACTIVE_TOKEN_ADDRESSES", "/activeTokenAddresses");
app.provide("API_ENDPOINT_CREATE_SLACK_WEBHOOK", "/createSlackWebhook");
app.provide("API_ENDPOINT_REGISTER_TO_MEMBERSHIP", "/registerToMembership");
app.provide("API_ENDPOINT_REGISTER_STRIPE_CONNECT_ACCOUNT", "/registerStripeConnectAccount");
app.provide("API_ENDPOINT_GET_STRIPE_ACCOUNT", "/stripeConnectAccount");
app.provide("API_ENDPOINT_GET_STRIPE_ACCOUNT_ID", "/stripeAccountId");
app.provide("API_ENDPOINT_DELETE_STRIPE_ACCOUNT", "/deleteStripeAccount");
app.provide("API_ENDPOINT_KYC_STATUS", "/kycStatus");
app.provide("API_ENDPOINT_STRIPE_PUBLIC_KEY", "/stripePublicKey");
app.provide("API_ENDPOINT_ENQUIRY_CONTACT", "/enquiryContact");
app.provide("API_ENDPOINT_IS_ADMIN", "/isAdmin");
app.provide("API_ENDPOINT_DELETE_MEMBERSHIP", "/deleteNews");
app.provide("API_ENDPOINT_DELETE_PRODUCT", "/deleteProduct");
app.provide("API_ENDPOINT_LIST_PURCHASED_PRODUCT", "/purchased-products");

app.provide(
  "DEFAULT_URL_TRANSPARENT_IMAGE",
  "https://firebasestorage.googleapis.com/v0/b/nft-community-develop.appspot.com/o/ui%2Ftransparent012.png?alt=media&token=a72ce302-e75c-4826-9ce4-9b1c5becc9d1"
);
app.provide("LOCAL_STORAGE_KEY_MEMBERSHIP", "membership");
app.provide("LOCAL_STORAGE_KEY_LIKES", "likes");
app.provide("SESSION_STORAGE_KEY_PRODUCT", "selectedProduct");
app.provide("MAX_POST_CHARACTERS", 400);
app.provide("NOTIFICATION_TYPE_TIP", "tip");
app.provide("NOTIFICATION_TYPE_SHOP", "shop");

app.mount("#app");
