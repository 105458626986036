<template>
    <div class="header">
      <button @click="goBack" class="back-button"></button>
      <h2>Checkout⛱</h2>
      <div class="dummy"></div> <!-- ダミー要素 -->
    </div>

  <div class="checkout-container">
    <!-- Product -->
    <div class="product-info">
      <div class="product-info-inner">
        <h5>{{ item?.name }}</h5>
        <img :src="item?.imageUrls[0]" alt="Product Image" class="product-image">
        <p class="product-price">{{ formattedPrice }} (tax inc.)</p>
        <!-- <p class="product-description">{{ item?.description }}</p> -->
        <div>
          <label>Quantity:</label>
          <input type="number" name="unit" v-model="quantity" style="width: 50px" @change="updateQuantity">
        </div>
        
      </div>
    </div>

    <!-- Stripe Form -->
    <div class="payment-container">
      <div class="payment-container-inner">
        <StripeForm 
        :item="item"
        :quantity="quantity"
        @processing="showSpinner = $event" 
        />  
      </div>
    </div>
  </div>

  <OverlaySpinner v-show="showSpinner"></OverlaySpinner>
</template>

<style type="text/css" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  padding: 0 20px; 
  height: 100px;
}

.back-button {
  background-image: url('@/assets/img/icon-back.png'); 
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.dummy {
  width: 32px; /* back-buttonと同じ幅 */
  height: 32px; /* back-buttonと同じ高さ */
}

.checkout-container {
  max-width: 1280px;
  margin: auto;
  display: flex;
}

.product-info,.payment-container {
  flex: 1;
}
.product-info-inner,.payment-container-inner {
  max-width: 800px;
  margin: 24px auto;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 32px;
}

.product-info h4 {
  font-size: 24px;
  margin-top: 0;
}
.product-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.product-price {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.product-description {
  font-size: 16px;
  color: #666;
}

.product-info, .payment-container {
  flex: 1;
  padding: 20px;
}

@media (max-width: 768px) {
  .payment-container,
  .product-info {
    padding: 10px;
  }
  .checkout-container {
    flex-direction: column;
  }
  .product-info-inner,
  .payment-container-inner {
    width: 100%;
  }
}

</style>

<script>
import StripeForm from "@/components/StripeForm.vue";
import OverlaySpinner from "@/views/OverlaySpinner.vue";
// import { useStore } from 'vuex';

export default {
  components: {
    StripeForm,
    OverlaySpinner,
  },
  inject: {
    SESSION_STORAGE_KEY_PRODUCT: {
      from: 'SESSION_STORAGE_KEY_PRODUCT'
    }
  },
  computed: {
    formattedPrice() {
      // 通貨が設定されていない場合は空文字を返す
      if (!this.item?.currency) return '';

      // 価格の取得と通貨の変換
      let priceValue = this.item.price * this.quantity;
      let fractionDigits = 0; // 小数点以下の桁数
      if (this.item.currency !== 'jpy') {
        priceValue = priceValue / 100; // 100で割る
        fractionDigits = 2; // USDとEURの場合は小数点以下2桁
      }

      // 通貨記号の設定
      let currencySymbol = '';
      switch (this.item.currency) {
        case 'jpy':
          currencySymbol = '￥';
          break;
        case 'usd':
          currencySymbol = '$';
          break;
        case 'eur':
          currencySymbol = '€';
          break;
        default:
          currencySymbol = this.item.currency.toUpperCase() + ' '; // 未知の通貨コードはそのまま表示
      }

      // カンマ区切りと小数点以下桁数のフォーマット
      const formattedValue = priceValue.toLocaleString(undefined, {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      });

      // 価格と通貨記号の結合
      return currencySymbol + formattedValue;
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1); // 1つ前のページに戻る
    },
    updateQuantity() {
      if (this.quantity < 1) {
        this.quantity = 1;
      }
    }
  },
  data() {
    return {
      item: null,
      showSpinner: false,
      quantity: 1
    };
  },
  mounted() {
    // const store = useStore();

    // ストアからデータを取得
    this.item =  JSON.parse(sessionStorage.getItem(this.SESSION_STORAGE_KEY_PRODUCT));
  }

};
</script>