<template>
  <div class="nft-cell-container card" v-bind:style="externalUrl && 'cursor: pointer;'" @click="goNFTPage">
    <img :src="state.coverImageUrl" class="card-img-top" alt="..." @error="imageLoadError" />
    <div class="card-body">
      <p class="issuer-name">{{ issuerName }}</p>
      <p class="membership-title">{{ tokenName }}</p>
      <div class="container-content-address">
        <p class="membership-contract-address">
          {{ $t('NFT_CELL.TOKEN_ADDRESS') }}: {{ renderTokenAddress }}
        </p>
        <div @click="onClicKMemberShipClipboard(tokenAddress)" class="icon-copy"></div>
      </div>
      <div class="container-content-address">
        <div class="token-information" v-if="!fromProfileView">
          <p class="membership-token-id" v-if="!isFT">{{ $t('NFT_CELL.TOKEN_ID') }}: {{ tokenId }}</p>
          <p class="balance" v-else>{{ $t('NFT_CELL.BALANCE') }}: {{ balance }}</p>
        </div>
        <button class="button-go-nft" v-show="externalUrl"></button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.nft-cell-container {
  position: relative;
  height: 370px;
  width: 200px;
  overflow: hidden;
  border-radius: 8px;
  text-align: left;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.nft-cell-container img {
  width: 100%;
  height: 200px;
}
.container-content-address {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icon-copy {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-image: url("@/assets/img/icon-copy.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.card-body {
  padding-left: 10px;
  padding-right: 10px;
}

.membership-token-id {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  white-space: nowrap;
}

.balance {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  white-space: nowrap;
}
.membership-contract-address {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto;
  font-size: 12px;
}
.issuer-name {
  margin-top: 4px;
  font-weight: 700;
  font-size: 0.7em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.membership-title {
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.membership-count {
  font-weight: 500;
  color: #fff;
}
.upper-section {
  text-align: center;
}
.button-go-nft {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: none;
  background-color: #fff;
  background-image: url("@/assets/img/icon-external-link.png");
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
}
</style>

<script>
import { defineComponent, reactive, onMounted } from "vue";
import axios from "axios";
export default defineComponent({
  name: "NFTCell",
  data() {
    return {};
  },
  setup: (props) => {
    const state = reactive({
      data: null,
      coverImageUrl: require("@/assets/img/image_placeholder.png"),
    });
    if (!props.tokenUri) {
      console.error("Err: token uri is null");
      //
      // REVIEW：いちいちエラーで止まるのでアラートを非表示にしている
      //
      // alert("Sorry, something went wrong. Please try again");
      // return;
    }
    // 非同期でTokenURIから画像データを取得し、セル背景画像にセット
    async function getThumbnail() {
      if(props.isFT) {
        state.coverImageUrl = props.tokenUri;
        return;
      }
      state.data = await axios.get(props.tokenUri);
      let imageUrl = state.data.data.image;
      if (!state.data || !imageUrl) {
        console.error("Err: token uri reponse is null");
        alert(
          "Sorry, something went wrong. Please try again  - error code: 1003"
        );
        return;
      }
      if (imageUrl.startsWith("ipfs")) {
        // IPFSの場合はURLを変換
        // const ipfsAddress = imageUrl.split("/").pop();
        let urlElementArray = imageUrl.split("/");
        let ipfsAddress = urlElementArray.slice(1).join("/").toString();
        state.coverImageUrl = "https://ipfs.io/ipfs" + ipfsAddress;
      } else {
        state.coverImageUrl = imageUrl;
      }
    }
    onMounted(() => {
      getThumbnail();
    });
    return {
      state,
      getThumbnail,
    };
  },
  props: {
    onClicKMemberShipClipboard: {
      type: Function,
    },
    issuerName: {
      type: String,
      default: "",
    },
    tokenName: {
      type: String,
      default: "",
    },
    tokenUri: {
      type: String,
      default: "@/assets/img/cover.png",
    },
    tokenAddress: {
      type: String,
      default: "",
    },
    tokenId: {
      type: String,
      default: "",
    },
    membershipId: {
      type: String,
      default: "-1",
    },
    chain: {
      type: String,
    },
    externalUrl: {
      type: String,
      default: "",
    },
    isFT: {
      type: Boolean,
      default: false
    },
    balance: {
      type: String,
      default: ''
    },
    fromProfileView: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    renderTokenAddress() {
      return `${this.tokenAddress.substring(0, 16)}...`;
    },
  },
  methods: {
    imageLoadError() {
      event.target.src = require("@/assets/img/image_placeholder.png");
    },
    clickClipboard: (tokenAddress) => {
      event.stopPropagation();
      console.log(tokenAddress);

      // if (this.tokenAddress) {
      //   // this.onClicKMemberShipClipboard();
      // }
    },
    goNFTPage: function () {
      if (this.externalUrl) {
        window.open(this.externalUrl, "_blank").focus();
      }
    },
  },
});
</script>
