<template>
  <div class="tab-content-container">
    <form>
      <div>
        <label class="label-small">{{$t('ADMIN_VIEW.CONTENT_BULLETIN.TITLE')}}*</label>
        <input
          type="text"
          placeholder=""
          v-bind:value="bulletinTitle"
          v-on:input="onChangeBulletinTitle"
          maxlength="30"
        />
      </div>
      <div>
        <p class="error-text" v-show="isInvalidBulletinTitle">
          {{$t('ADMIN_VIEW.CONTENT_BULLETIN.TITLE_INVALID')}}
        </p>
      </div>
      <div>
        <label class="label-small">{{$t('ADMIN_VIEW.CONTENT_BULLETIN.DESCRIPTION')}}*</label>
        <textarea
          v-bind:value="bulletinDescription"
          v-on:input="onChangeBulletinDescription"
          maxlength="2000"
        ></textarea>
      </div>
      <div>
        <p class="error-text" v-show="isInvalidBulletinDescription">
          {{$t('ADMIN_VIEW.CONTENT_BULLETIN.DESCRIPTION_INVALID')}}
        </p>
      </div>
      <div>
        <label class="label-small"> {{$t('ADMIN_VIEW.CONTENT_BULLETIN.LINK')}}</label>
        <input
          type="url"
          placeholder="https://www.example.com"
          v-bind:value="bulletinLink"
          v-on:input="onChangeBulletinLink"
          maxlength="300"
        />
      </div>
      <div>
        <p class="error-text" v-show="isInvalidBulletinLink">
          {{$t('ADMIN_VIEW.CONTENT_BULLETIN.LINK_INVALID')}}
        </p>
      </div>
    </form>
    <div
      class="edit-image"
      v-bind:style="{ backgroundImage: 'url(' + bulletinImageUrl + ')' }"
      @click="selectBulletinImage"
    >
      <div class="edit-image-overlay">
        <p class="noselect">{{$t('ADMIN_VIEW.CONTENT_BULLETIN.BOARD_IMG')}}</p>
      </div>
      <button class="button-delete-image" @click="deleteBulletinImage"></button>
    </div>
    <p class="error-text" v-show="isInvalidFileSizeBulletin">
      {{$t('ADMIN_VIEW.CONTENT_BULLETIN.BOARD_IMG_LIMIT')}}
    </p>
    <div class="buttons">
      <button class="n-btn-weak" @click="cancel">{{ $t('BUTTON.CANCEL_UPPER') }}</button>
      <button class="n-btn-destructive" @click="removeNews">{{ $t('ADMIN_VIEW.CONTENT_BULLETIN.BTN_REMOVE_NEWS') }}</button>
      <button class="n-btn-primary" @click="updateBulletinInfo">{{ $t('BUTTON.SAVE_UPPER') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentBulletin",
  props: {
    bulletinTitle: String,
    onChangeBulletinTitle: Function,
    isInvalidBulletinTitle: Boolean,
    bulletinDescription: String,
    onChangeBulletinDescription: Function,
    isInvalidBulletinDescription: Boolean,
    bulletinLink: String,
    onChangeBulletinLink: Function,
    isInvalidBulletinLink: Boolean,
    bulletinImageUrl: String,
    deleteBulletinImage: Function,
    isInvalidFileSizeBulletin: Boolean,
    cancel: Function,
    removeNews: Function,
    updateBulletinInfo: Function,
    selectBulletinImage: Function,
  },
};
</script>
<style>
.tab-content-container {
  padding: 12px;
}
.edit-image {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 24px auto;
  border: 2px solid #000;
  background-color: #eee;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border-radius: 8px;
}
.edit-image-overlay {
  width: 200px;
  height: 200px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  text-align: center;
}
.edit-image-overlay p {
  position: absolute;
  width: inherit;
  top: 0;
  left: 0;
  margin: 0;
  line-height: 200px;
  color: #fff;
  font-weight: 700;
}
.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 12px;
}
.buttons button {
  min-width: 200px;
  margin-top: 32px;
}
.button-delete-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  margin-right: 12px;
  margin-top: 12px;
  border: none;
  border-radius: 15px;
  background-image: url("@/assets/img/icon-close.png");
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
