<template>
  <div class="n-container">
    <div class="change-language">
   <Language/>
    </div>
    <div id="top-header">

      <div id="top-header-logo"></div>
      <h1 id="main-header">DeepBeach</h1>
    </div>
    <p style="font-weight: bold" id="main-header">
      {{ $t('TOP_VIEW.USE_POLYGON_ETHER') }}
    </p>

    <div class="n-container" style="display: flex; justify-content: center">
      <button
        class="border-button"
        style="width: 240px !important"
        @click="openAbout"
      >
      {{ $t('TOP_VIEW.WHAT_DEEPBEACH') }}
      </button>
    </div>
    <!-- <div class="wallet-address-container wrap-wallet-address"  v-if="metaMaskAccountInfo.address"> -->
    <div class="wallet-address-container wrap-wallet-address">
      <div>
        <div class="chain-name-container">
          <p :title="chainName">{{ chainName }}</p>
        </div>
        <div class="icon-wallet"></div>
        <p :title="walletAddress">{{ walletAddress }}</p>
      </div>
    </div>
    <button
      class="n-btn-primary metamask-btn"
      v-show="!web3ModalConnectInfo.address && !metaMaskAccountInfo.address && !metamaskNotDetect"
      @click="connectWallet_"
      :disabled="isConnecting"
    >
      <span v-if="isConnecting">  {{ $t('TOP_VIEW.CONNECTING') }}</span>
      <div v-else class="metamask-btn-container">
        <div class="img-wallet-metamask">
          <img :src="require('@/assets/img/metamask.png')" width="22" height="22">
        </div>
        <span>MetaMask</span>
      </div>
    </button>
    <div class="web3modal-container" v-show="!metaMaskAccountInfo.address">
      <w3m-button  :label="''"  class="custom-button" />
    </div>
    <div v-if="isWalletConnected" class="disconnect-wallet" @click="disconnectWallet">
      <span>{{ $t('TOP_VIEW.DISCONNECT')}}</span>
    </div>
    <div
      v-if="isWalletConnected"
      class="n-container"
      id="membership-container-wapper"
    >
      <div id="membership-container-header">
        <button id="button-reload" @click="reload"></button>
      </div>

      <h3 v-if="chainId === 1 || chainId === 137">{{$t('TOP_VIEW.NFT_COMMUNITY')}}⛱</h3>
      <div v-else class="network-buttons">
        <h3> {{  $t('TOP_VIEW.SWITCH_NETWORK')}}⛱</h3>
        <button class="n-btn-primary" @click="connectToPolygon">
          <img src="@/assets/img/icon-polygon.png" class="button-icon" />Switch
          {{ $t('TOP_VIEW.TO_POLYGON') }}
        </button>
        <button class="n-btn-primary" @click="connectToEthereum">
          <img src="@/assets/img/icon-ethereum.png" class="button-icon" />Switch
          {{ $t('TOP_VIEW.TO_ETHEREUM') }}
        </button>
      </div>

      <div class="loader-container" v-if="!didLoadMembership">
        <div class="loader"></div>
      </div>
      <p v-if="isMembershipEmpty"> {{ $t('TOP_VIEW.NO_NFT') }}</p>
      <div ref="membershipContainer" id="membership-container"></div>
    </div>
    <OverlaySpinner
      v-show="showSpinner"
      :message="spinnerMessage"
    ></OverlaySpinner>
  </div>
  <MainFooter />
</template>

<style scoped>
#header-container {
  display: flex;
  justify-content: flex-end;
}
.wrap-wallet-address {
  width: 75%;
  margin: auto;
}
.border-button {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
  width: 200px !important;
  height: 40px;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  border: 2px solid #000 !important;
  border-radius: 8px;
  background-color: #fff !important;

}
.icon-link {
  width: 24px !important;
  height: 24px !important;
  background-position: center;
  background-size: contain;
  barkground-repeat: no-repeat;
  background-image: url("@/assets/img/icon-new-window-black.png");
}
#top-header {
  justify-content: center;
  align-items: center;
  gap: 12px;
  display: flex;
}
#top-header-logo {
  width: 40px;
  height: 40px;
  background-image: url("@/assets/img/db-logo.png");
  background-size: contain;
  background-repeat: no-repeat;
}
#main-header {
  color: #000;
}
#membership-container-header {
  display: flex;
  justify-content: flex-end;
}
#membership-container-wapper {
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 8px;
  margin-top: 2px;
}
#membership-container {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}
.whitelist-container-parent {
  background-color: #fff;
  border: 2px solid #000;
}
.whitelist-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px;
}
.loader-container {
  width: 100%;
  height: 100px;
}
.loader {
  width: 50px;
  height: 50px;
  margin: auto;
  background-image: url("@/assets/img/loader.gif");
  background-size: contain;
}
.network-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.network-buttons button {
  display: flex;
  align-items: center;
  width: 320px;
}
.button-icon {
  width: 24px; /* Adjust as needed */
  height: 24px; /* Adjust as needed */
  margin-right: 8px; /* Adjust as needed */
}
#button-reload {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: none;
  padding: 0; /* This is to center button image  */
  overflow: hidden;
  background-color: transparent;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("@/assets/img/icon-reload.png");
}
.web3modal-container {
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
margin-top: 6px;
}
.custom-button {
  width: 100% !important;
  display: flex;
  justify-content: center;
}
.metamask-btn {
  width: 240px;
}

.metamask-btn-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.img-wallet-metamask {
  margin-right: 8px;
}
.disconnect-wallet {
  width: 70%;
  margin: auto;
  text-align: end;
  text-decoration: underline;
  cursor: pointer;
  padding: 0px 2px;
}
@media all and (min-width: 1024px) and (max-width: 1280px) {
}
@media all and (min-width: 768px) and (max-width: 1024px) {
}
@media all and (min-width: 480px) and (max-width: 768px) {
}
@media all and (max-width: 480px) {
  .wrap-wallet-address {
    width: 100%;
  }
  #membership-container {
    width: 100%;
  }
}
</style>

<script>
import { createApp, reactive, h } from "vue";
import shared from "@/shared";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import MembershipCell from "@/components/MembershipCell.vue";
import OverlaySpinner from "@/views/OverlaySpinner.vue";
// import Web3 from 'web3/dist/web3.min.js';
import Web3 from "web3";
import Moralis from "moralis";
import axios from "axios";
import MainFooter from "@/components/MainFooter.vue";
import web3ConnectImage from "@/assets/img/web3-connect.png";
import { disconnectWeb3Modal, connectWeb3Modal} from  '@/utils/web3Modal.js'
import Language  from '@/components/Language.vue'
import swalContainer from  '@/utils/swalContainer.js'
export default {
  name: "TopView",
  data() {
    return {
      isWalletConnected: false,
      isConnecting: false,
      walletAddress: null,
      isMembershipEmpty: false,
      didLoadMembership: false,
      chainName: "",
      chainId: null,
      showSpinner: false,
      spinnerMessage: "",
      whilelist: [],
      activeTokenAddresses: [],
      web3Modal: null,
      web3ModalConnectInfo: {
        address: null,
        chainId: null
      },
      metaMaskAccountInfo: {
        address: null,
        chainId: null
      }, 
      timeOutCallReload: null,
      setTimeOutObj: {
        customeWeb3Modal: null,
        customeWeb3Account: null,
        removeOrCreateCustomeElement: null,

      },
      metamaskNotDetect:false,
      runShowPopup: 0,
      oldEventWalletConnect: null,
      // showNotMetama: null,
    };
  },
  setup() {
    const firebaseApp = initializeApp(shared.firebaseConfig());
    const firebaseAnalytics = getAnalytics(firebaseApp);
    const fireaseDB = getFirestore(firebaseApp);
    return {
      firebaseApp,
      firebaseAnalytics,
      fireaseDB,
    };
  },
  inject: {
    MORALIS_SERVER_URL: {
      from: "MORALIS_SERVER_URL",
    },
    MORALIS_APP_ID: {
      from: "MORALIS_APP_ID",
    },
    MORALIS_MASTER_KEY: {
      from: "MORALIS_MASTER_KEY",
    },
    API_BASE_URL: {
      from: "API_BASE_URL",
    },
    API_ENDPOINT_MEMBERSHIP_ID: {
      from: "API_ENDPOINT_MEMBERSHIP_ID",
    },
    API_ENDPOINT_CREATE_USER: {
      from: "API_ENDPOINT_CREATE_USER",
    },
    API_ENDPOINT_WHITELIST: {
      from: "API_ENDPOINT_WHITELIST",
    },
    API_ENDPOINT_CREATE_MEMBERSHIP: {
      from: "API_ENDPOINT_CREATE_MEMBERSHIP",
    },
    API_ENDPOINT_ACTIVE_TOKEN_ADDRESSES: {
      from: "API_ENDPOINT_ACTIVE_TOKEN_ADDRESSES",
    },
    API_ENDPOINT_REGISTER_TO_MEMBERSHIP: {
      from: "API_ENDPOINT_REGISTER_TO_MEMBERSHIP",
    },
  },
  async mounted() {
    
    try {
      await Moralis.start({
        apiKey: this.MORALIS_MASTER_KEY,
      });
    } catch (e) {
      console.error(e);
    }
    /* Connect to wallet from metamask */
   await this.connectWallet_();

   /* Connect to wallet from web3 modal */
   this.handleConnectWeb3Modal()

  },

  updated() {
    var membershipContainer = document.getElementById("membership-container");
    if (this.didLoadMembership) {
      console.log("Membership already loaded");
      this.didLoadMembership = true;
      return;
    }
    if (membershipContainer) {
      if (this.isWalletConnected) {
        this.getActiveTokenAddresses((err, tokenAddresses) => {
          if (err) {
            console.error("failed to get active token addresses", err);
            return;
          }
          this.activeTokenAddresses = tokenAddresses;
          // GET All NFTs that client owns
          this.fetchAllOwnedNFTs(this.walletAddress, (cells) => {
            if (!cells) {
              // Clear membership container
              var child = membershipContainer?.lastElementChild;
              while (child) {
                membershipContainer.removeChild(child);
                child = membershipContainer.lastElementChild;
              }
              this.didLoadMembership = true;
              return;
            }
            // Load membership cells
            this.loadMembershipCells(membershipContainer, cells);
            this.didLoadMembership = true;
          });
        });
      } else {
        alert("Wallet is not connected. Please connect using 'Connect' button");
      }
    } else {
      console.error("membershipContainer is null");
    }
  },
  methods: {
    disconnectWallet() {
      if (this.web3ModalConnectInfo.address) {
        disconnectWeb3Modal()
      }
      this.metaMaskAccountInfo.address = null;
      this.metaMaskAccountInfo.chainId = null;
      this.web3ModalConnectInfo.address = null;
      this.web3ModalConnectInfo.chainId = null;
      this.isWalletConnected = false;
      this.didLoadMembership = false;
      this.walletAddress = null;
      this.chainName = null;
    },
    customeWeb3Modal() {
      if (this.setTimeOutObj.customeWeb3Modal) {
        clearTimeout(this.setTimeOutObj.customeWeb3Modal)
        this.setTimeOutObj.customeWeb3Modal = null;
      }
      this.setTimeOutObj.customeWeb3Modal =  setTimeout(() => {
      const targetElement = document.querySelector('w3m-button')?.shadowRoot?.querySelector('w3m-connect-button')?.shadowRoot?.querySelector('wui-connect-button')?.querySelector('#wallect-collect-cutom-container');
      const parentNode = document.querySelector('w3m-button')?.shadowRoot?.querySelector('w3m-connect-button')?.shadowRoot?.querySelector('wui-connect-button');
        if (!targetElement && parentNode) {
         if(!parentNode) return;
         // parentNode.innerHTML = '';
         const newDiv = document.createElement('div');
         parentNode.appendChild(newDiv);
        newDiv.setAttribute('id', 'wallect-collect-cutom-container');
        newDiv.style.display = 'flex';
        newDiv.style.alignItems = 'center';
        newDiv.style.justifyContent = 'center';
        newDiv.style.width = '210px';
       
        const newImg = document.createElement('img');
        newImg.src = web3ConnectImage;
        newImg.setAttribute('id', 'wallect-collect-img');
        newImg.setAttribute('width', '22px');
        newImg.setAttribute('height', '22px');
        newImg.style.marginRight = '8px';
        newDiv.appendChild(newImg);
        const newSpan = document.createElement('span');
        newSpan.innerHTML = 'WalletConnect';
        newDiv.appendChild(newSpan);
       
      }
      }, 0);
    },
    customeWeb3Account() {
      if (this.setTimeOutObj.customeWeb3Account) {
        clearTimeout(this.setTimeOutObj.customeWeb3Account)
        this.setTimeOutObj.customeWeb3Account = null;
      }
      this.setTimeOutObj.customeWeb3Account = setTimeout(() => {
        const targetElement = document.querySelector('w3m-button')?.shadowRoot?.querySelector('w3m-account-button')?.shadowRoot?.querySelector('wui-account-button').shadowRoot?.querySelector('button');
        if (targetElement) {
          targetElement.remove();
        } 
      }, 0);
    },

    removeOrCreateCustomeElement() {
      if (this.setTimeOutObj.removeOrCreateCustomeElement) {
        clearTimeout(this.setTimeOutObj.removeOrCreateCustomeElement)
        this.setTimeOutObj.removeOrCreateCustomeElement = null;
      }
      this.setTimeOutObj.removeOrCreateCustomeElement = setTimeout(() => {
        const targetElement = document.querySelector('w3m-button')?.shadowRoot?.querySelector('w3m-connect-button')?.shadowRoot?.querySelector('wui-connect-button').querySelector('#wallect-collect-cutom-container');
        const parentNode = document.querySelector('w3m-button')?.shadowRoot?.querySelector('w3m-connect-button')?.shadowRoot?.querySelector('wui-connect-button');
        if (targetElement && parentNode?.textContent?.includes('Connecting...')) {
          targetElement.remove();
        }
        if(parentNode && !parentNode?.textContent?.includes('Connecting...')) {
          this.customeWeb3Modal();
        }
      }, 5);
    },

    async forceLogoutFromMetaMask() {
      // // await shared.disconnectMetamask(this.metaMaskAccountInfo.address)
      // this.metaMaskAccountInfo.address = null;
      // this.metaMaskAccountInfo.chainId = null;
    },
    checkIfConnected() {
      console.log("checking if connected..");
      this.checkIfConnected = null;
      this.checkIfConnected = shared.checkIfConnected.bind(this);
      this.checkIfConnected((isConnected, error) => {
        console.log("checkIfConnected", isConnected, error);
      });
    },
   async connectWallet_() {
      // コールバックが幾つも登録されることを避けるため、まずはBindを解除
      this.connectWallet = null;
      this.connectWallet = shared.connectWalletMetamask.bind(this);
      this.isConnecting = true;
      this.connectWallet((err, event, walletData) => {
        this.isConnecting = false;
        this.metamaskNotDetect = false;
        if (err && !this.web3ModalConnectInfo.address) {
          console.error(err, event);
          this.isWalletConnected = false;
          this.didLoadMembership = false;
          if (event === "Request timed out") {
            swalContainer.requestTimeoutMetamask();
          } else if (event === "User rejected the request.") {
            swalContainer.userRejectMetamask();
          } else {
            if(event === 'metamask not detected') {
              this.metamaskNotDetect = true;
            }
          }
          return;
        }

        // remove swal modal
        this.$swal.close();
        let walletObj = JSON.parse(walletData);
       
        if (!walletObj.walletAddress || !walletObj.chainId) {
          console.log("wallet is disconnected?", event, walletData);
          this.isWalletConnected = false;
          this.didLoadMembership = false;
          this.walletAddress = null;
          this.chainName = null;
           // Save account from metamask
           this.metaMaskAccountInfo = {
            address: walletObj.walletAddress,
            chainId: walletObj.chainId
          }
          return;
        }
        this.walletAddress = walletObj.walletAddress;
        this.chainId = walletObj.chainId;
       
        this.metaMaskAccountInfo = {
          address : walletObj.walletAddress,
          chainId: walletObj.chainId
        }
        if(walletObj.walletAddress) {
          // Force log out web3 modal
          disconnectWeb3Modal();
          this.customeWeb3Modal();
          this.web3ModalConnectInfo = {
            address: null,
            chainId: null
          };
        }
        this.setChainName(this.chainId);
        switch (event) {
          case "connected":
            console.log("connected");
            this.isWalletConnected = true;
            break;
          case "accountsChanged":
            console.log("accountsChanged");
            this.isWalletConnected = true; // REVIEW:: Account changeが検知できるということかは、ウォレットがつながっていると解釈して良い？？
            this.didLoadMembership = false;
            break;
          case "chainChanged":
            console.log("chainChanged");
            this.isWalletConnected = true;
            this.reload();
            break;
          default:
            break;
        }
      
      });
    },
    async handleConnectWeb3Modal() {
      this.customeWeb3Modal();
      connectWeb3Modal(async (err, event, walletData) => {
        let walletObj = JSON.parse(walletData);
        if (event === 'actionOpenOrCloseModal') {
          this.removeOrCreateCustomeElement();
          if (!walletData.isConnected && !this.metaMaskAccountInfo.address) {
              this.isWalletConnected = false;
              this.didLoadMembership = false;
              this.walletAddress = null;
              this.chainName = null;
               // Update web3Modal account
              this.web3ModalConnectInfo = {
                address: null,
                chainId: null
              }
            }
        } 
          /* Check walletAddress exist  */
          if (walletObj.walletAddress) {
            // Update wallletAddress and chainId
            this.isWalletConnected = true;
            this.walletAddress = walletObj.walletAddress;
            this.chainId = walletObj.chainId;
            this.setChainName(this.chainId);
            this.customeWeb3Account();
            // Update metamask account
            this.metaMaskAccountInfo = {
              address: null,
              chainId: null
            }
            // this.metamaskNotDetect = false;
            // Update web3Modal account
            this.web3ModalConnectInfo = {
              address: walletObj.walletAddress,
              chainId: walletObj.chainId
            }
          }
            /* Check walletAddress not exist  */
          if ((!walletObj.walletAddress || !walletObj.chainId)) {
            this.customeWeb3Modal();
            if (!this.metaMaskAccountInfo.address) {
              this.isWalletConnected = false;
              this.didLoadMembership = false;
              this.walletAddress = null;
              this.chainName = null;
              // Need show modal if metamask is not installed when the fist time access page
              if(this.metamaskNotDetect && event === 'connectWeb3ModalStart' && this.runShowPopup === 0 && !this.oldEventWalletConnect) {
                swalContainer.otherErrorMetamask();
                this.runShowPopup++;
              }
            }
          }
        this.oldEventWalletConnect = event;
      })
    },
  
    setChainName(chainId) {
      switch (chainId) {
        case 1:
          this.chainName = "Ethereum";
          break;
        case 137:
          this.chainName = "Polygon";
          break;
        default:
          this.chainName = "Unsupported chain";
          this.didLoadMembership = true;
          swalContainer.supportNetwork()
          this._removeChildMembership();
          break;
      }
    },
    async connectToPolygon() {
      try {
        if (this.metaMaskAccountInfo.address) {
          const provider = window.ethereum;
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x89",
                chainName: "Polygon Mainnet",
                nativeCurrency: {
                  name: "MATIC",
                  symbol: "MATIC",
                  decimals: 18,
                },
                rpcUrls: ["https://rpc-mainnet.maticvigil.com"],
                blockExplorerUrls: ["https://explorer.matic.network/"],
              },
            ],
          });
        } else {
          const elementAcountCustome = document.querySelector('w3m-button')?.shadowRoot?.querySelector('w3m-account-button')?.shadowRoot?.querySelector('wui-account-button');
          // Dispatch the click event on the element
          elementAcountCustome.dispatchEvent(new Event('click'));
        }
      } catch (error) {
        console.error("User rejected request:", error);
      }
    },
    async connectToEthereum() {
      try {
        if (this.metaMaskAccountInfo.address) {
          const provider = window.ethereum;
          await provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x1" }],
          });
        } else {
          const elementAcountCustome = document.querySelector('w3m-button')?.shadowRoot?.querySelector('w3m-account-button')?.shadowRoot?.querySelector('wui-account-button');
          // Dispatch the click event on the element
          elementAcountCustome.dispatchEvent(new Event('click'));
        }
      } catch (error) {
        console.error("User rejected request:", error);
      }
    },
    getWhitelist(callback) {
      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_WHITELIST;
      axios
        .get(apiUrl, {})
        .then((result) => {
          callback(null, result.data);
        })
        .catch((err) => {
          console.log("api err", err);
          callback(err, null);
        });
    },
    getActiveTokenAddresses(callback) {
      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_ACTIVE_TOKEN_ADDRESSES;
      axios
        .get(apiUrl, {})
        .then((result) => {
          if (!result.data || !result.data.token_addresses) {
            callback("error", null);
            return;
          }
          callback(null, result.data.token_addresses);
        })
        .catch((err) => {
          console.error(err);
          callback(err, null);
        });
    },
    fetchAllOwnedNFTs(walletAddress, callback) {
      const web3 = new Web3(Web3.givenProvider || "ws://localhost:8545");
      (async () => {
        // const chainId = await web3.eth.getChainId();
        if(this.metaMaskAccountInfo.address) {
          this.chainId = await web3.eth.getChainId();
        } 
        var chainHex = null;
        var chain = "";
        switch (this.chainId) {
          case 1:
            chain = "eth";
            chainHex = "0x1";
            break;
          case 137:
            chain = "polygon";
            chainHex = "0x89";
            break;
          default:
            chain = "Unsupported chain;";
            this.didLoadMembership = true;
            swalContainer.supportNetwork()
            this._removeChildMembership();
            return;
        }
        this.setChainName(this.chainId);
        try {
          const userNFTs = await Moralis.EvmApi.nft.getWalletNFTs({
            chain: chainHex,
            address: walletAddress,
            format: "decimal",
            tokenAddresses: [],
          });
          /** Get FT by wallet address */ 
          const userFTs = await shared.getFTsByWalletAdress(walletAddress, chain)

          let result = userNFTs?.raw["result"];
          if (result || userFTs) {
            /** Filter Spam */
            result = result.filter((element) => !element.possible_spam)
            
            var cells = [];
            const nfts = result.map((element) => {
              return {
                issuerName:  element["name"] ?? "",
                tokenName: JSON.parse(element["metadata"])?.name ?? "",
                tokenUri: element["token_uri"],
                tokenAddress: element["token_address"],
                tokenId:  element["token_id"],
                chain: chain,
              }
            })
            // Merge NFT and FT
            const nft_fts = [...nfts, ...userFTs]
            
            if (nft_fts.length > 0) {
              this.isMembershipEmpty = false;
            } else {
              this.isMembershipEmpty = true;
            }

            nft_fts.forEach((element, index) => {
              let issuerName = element["issuerName"];
              let tokenAddress = element["tokenAddress"];
              let tokenId = element["tokenId"];
              let tokenUri = element["tokenUri"];
              let tokenName = element["tokenName"];
              const isFT = element['isFT'];
              const balance = element['balance'] ?? "";
              // var metadata = element["metadata"];
              // var tokenName = "";
              // if (metadata) {
              //   var json = JSON.parse(metadata);
              //   tokenName = json["name"];
              // }
              var isValid = false;
              this.activeTokenAddresses.forEach((data) => {
                let tokenAddress_ = data?.tokenAddress;
                let chain_ = data?.chain;
                if (chain === chain_ && tokenAddress === tokenAddress_) {
                  isValid = true;
                }
              });
              
              const props = reactive({
                issuerName: issuerName,
                tokenName: tokenName,
                membershipId: `${index}`,
                tokenUri: tokenUri,
                tokenAddress: `${tokenAddress}`,
                tokenId: `${tokenId}`,
                isAdmin: false,
                chain: chain,
                isValid: isValid,
                API_BASE_URL: this.API_BASE_URL,
                API_ENDPOINT_MEMBERSHIP_ID: this.API_ENDPOINT_MEMBERSHIP_ID,
                isFT,
                balance,
              });
              const comp = h(MembershipCell, {
                onClickMembershipSettingEvent: (e) =>
                  console.log(
                    "TopVue: click memebership setting event receieved!",
                    e
                  ),
                onClickMembershipEvent: (e) => {
                  if (!e.profileImageUrl) {
                    // handle error
                    swalContainer.somethingWrong1006();
                    return;
                  }
                  this.spinnerMessage = "loading..";
                  this.showSpinner = true;
                  // Create / Update User Profile Image everytime user login
                  let apiUrl_ =
                    this.API_BASE_URL + this.API_ENDPOINT_CREATE_USER;
                  axios
                    .post(apiUrl_, {
                      walletAddress: walletAddress,
                      nftImage: e.profileImageUrl,
                    })
                    .then(() => {
                      /*
                    // 対応するメンバーシップページのタイムラインへ遷移
                    //
                    // STEP 1: メンバーシップがDBに存在するかをAPIで確認
                    // STEP 2: メンバーシップが存在すれば対応するメンバーシップページへ
                    // STEP 3: メンバーシップが存在しなければメンバーシップ作成ページへ
                    */
                      let apiUrl =
                        this.API_BASE_URL + this.API_ENDPOINT_MEMBERSHIP_ID;
                      axios
                        .get(apiUrl, {
                          params: {
                            tokenAddress: tokenAddress,
                            tokenId: tokenId,
                            chain: chain,
                            isFT,
                          },
                        })
                        .then((result) => {
                          this.showSpinner = false;
                          let isMembershipExist =
                            result.data.is_membership_exist;
                          if (isMembershipExist) {
                            this.spinnerMessage =
                              "Entering existing memebership...";
                            let membershipId = result.data.membership_id;
                            if (!membershipId) {
                              console.error(
                                "membership id is null",
                                membershipId
                              );
                              this.$swal({
                                title: `Sorry, something went wrong ${membershipId}`,
                                text: "Please try again.",
                                type: "warn",
                                showCancelButton: false,
                                confirmButtonText: "Reload Page",
                              }).then((result) => {
                                if (result.value) {
                                  this.$router.go();
                                }
                              });
                              return;
                            }
                            this.$router.push({
                              name: "timeline",
                              query: {
                                membershipId: membershipId,
                                tokenAddress: tokenAddress,
                                tokenId: tokenId,
                                nftImageUrl: e.profileImageUrl,
                                isFT,
                              },
                            });

                            //
                            // TODO: @mention用に、/users/memberships:membershipID を登録
                            //
                            this.registerToMembership(
                              membershipId,
                              walletAddress
                            );
                          } else {
                            // Create membership
                            this.spinnerMessage =
                              "You are the one who intiates the membership of this NFT, creating membership...";
                            let apiUrl_ =
                              this.API_BASE_URL +
                              this.API_ENDPOINT_CREATE_MEMBERSHIP;
                            axios
                              .post(apiUrl_, {
                                chain: chain,
                                contractAddress: tokenAddress,
                                name: issuerName,
                                walletAddress: walletAddress,
                              })
                              .then((result) => {
                                this.$router.push({
                                  name: "timeline",
                                  query: {
                                    membershipId: result.data.membership_id,
                                    tokenAddress: result.data.token_address,
                                    tokenId: tokenId,
                                    nftImageUrl: e.profileImageUrl,
                                    isFT
                                  },
                                });

                                //
                                // TODO: @mention用に、/users/memberships:membershipID を登録
                                //
                                this.registerToMembership(
                                  result.data.membership_id,
                                  walletAddress
                                );
                              })
                              .catch((error) => {
                                console.error(error);
                                if (error.response.status === 403) {
                                  swalContainer.registerMembershipErr403();
                                } else if (error.response.status === 405) {
                                  swalContainer.registerMembershipErr405();
                                } else {
                                  swalContainer.otherError();
                                }
                                this.showSpinner = false;
                              });
                          }
                        })
                        .catch((err) => {
                          console.error("api err", err);
                          this.showSpinner = false;
                        });
                    })
                    .catch((err) => {
                      console.error("api err", err);
                      this.showSpinner = false;
                    });
                },
                onClickMembershipClipboard: () => {
                  this.$swal({
                    title: "Token address is copied to clipboard!",
                    position: "top-end",
                    type: "success",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                },
              });
              var Cell = createApp(comp, props);
              cells.push(Cell);
            });
            callback(cells);
          } else {
            console.error("err");
            callback(null);
          }
        } catch (e) {
          console.error(e);
          // handle error
          swalContainer.somethingWrong1006();
          return;
        }
      })();
    },
    /*
    getBalance: function(web3) {
      (async () => {
        var contract = new web3.eth.Contract(ABI, ADDRESS);
        // var totalSupply = await contract.methods.totalSupply().call();
        // console.log("totalSupply", totalSupply);
        var balance = await contract.methods.balanceOf(this.walletAddress).call();
        console.log("balance", balance);
        web3.eth.getBalance(this.walletAddress, (err, balance_) => {
          if (err) {
            console.log("Error: cannot get balance", err);
            return;
          }
          balance_ = web3.utils.fromWei(balance_, "ether") + " ETH";
          console.log("balance_", balance_);
        });
        var objects = [];
        var tokens = [];
        for (var i = 0; i < balance; i++) {
            tokens.push(await contract.methods.tokenOfOwnerByIndex(this.walletAddress, i).call());
        }
        for(var j = 0; j < tokens.length; j++){
            objects.push(await contract.methods.tokenURI(tokens[i]).call());
        }
      })();
    },
    */
    loadMembershipCells(parent, cells) {
      if (!parent) {
        alert(
          "Sorry, Something went wrong. Please try again. - error code: 1004"
        );
        return;
      }
      // Remove all parent child
      var child = parent.lastElementChild;
      while (child) {
        parent.removeChild(child);
        child = parent.lastElementChild;
      }
      cells.forEach((cell) => {
        // inserting to dom
        const wrapper = document.createElement("div");
        cell.mount(wrapper);
        parent.append(wrapper);
      });
    },
    reload() {
      this.didLoadMembership = false;
      console.log("reloading..", this.didLoadMembership);
      var membershipContainer = document.getElementById("membership-container");
      // Remove all parent child
      var child = membershipContainer?.lastElementChild;
      while (child) {
        membershipContainer.removeChild(child);
        child = membershipContainer.lastElementChild;
      }
      if (membershipContainer) {
          if (this.isWalletConnected) {
           this.getActiveTokenAddresses((err, tokenAddresses) => {
          if (err) {
            console.error("failed to get active token addresses", err);
            return;
          }
          this.activeTokenAddresses = tokenAddresses;
          this.fetchAllOwnedNFTs(this.walletAddress, (cells) => {
            if (!cells) {
              // Clear membership container
              var child = membershipContainer?.lastElementChild;
              while (child) {
                membershipContainer.removeChild(child);
                child = membershipContainer.lastElementChild;
              }
              return;
            }
            this.loadMembershipCells(membershipContainer, cells);
            this.didLoadMembership = true;
           });
          });
        } else {
          alert(
            "Wallet is not connected. Please connect using 'Connect' button"
          );
        }
      } else {
        alert(
          "Sorry, something went wrong. Please try again - error code: 1005"
        );
      }
    },
    openRoadmap() {
      window
        .open(
          "https://docs.google.com/presentation/d/10aOeCYq_a4bIv4Na0Uq9KF3YzdhVruQkrNdBMg5hSU0/edit?usp=sharing",
          "_blank"
        )
        .focus();
    },
    openAbout() {
      // window.open("/about", '_blank').focus();
      this.$router.push("/about");
    },
    applyForAlpha() {
      window.open("https://forms.gle/YP7MiH2uQ3fAGrw36", "_blank").focus();
    },
    registerToMembership(membershipId, walletAddress) {
      console.log("registering to memberhsip", membershipId, walletAddress);

      //
      // TODO: Call and Handle API
      //
      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_REGISTER_TO_MEMBERSHIP;
      axios
        .post(apiUrl, {
          membershipId: membershipId,
          walletAddress: walletAddress,
        })
        .then((response) => {
          console.log("register to memberhsip success", response);
        })
        .catch((err) => {
          console.error("register to memberhsip err", err);
        });
    },
    _removeChildMembership() {
      var membershipContainer = document.getElementById("membership-container");
      // Remove all parent child
      var child = membershipContainer?.lastElementChild;
      while (child) {
        membershipContainer.removeChild(child);
        child = membershipContainer.lastElementChild;
      }
    }
  },
  components: {
    OverlaySpinner,
    MainFooter,
    Language
  },
};
</script>
