<template>
  <div class="wrap-form">
    <h3>{{ $t('SELL_VIEW.READY_PUBLISH') }}</h3>
    <ShopCell :item="item" />
    <p class="stock" v-if="stock !== null">{{ $t('SELL_VIEW.STOCK') }}: {{ stock }}</p>
    <p class="stock" v-else>{{ $t('SELL_VIEW.STOCK_UNDERFINED') }}</p>
    <div class="buttons">
      <button class="n-btn-primary" @click="publishProduct">{{ $t('SELL_VIEW.BTN_PUBLISH') }}</button>
      <button class="n-btn-weak" @click="goBackToPage1">{{ $t('SELL_VIEW.BTN_FIX_INFO') }}</button>
      <button class="n-btn-link weak" @click="goBack">{{ $t('BUTTON.CANCEL') }}</button>
    </div>
  </div>
</template>

<script>
import ShopCell from "@/components/ShopCell.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Page3Component",
  components: {
    ShopCell,
  },
  props: {
    item: Object,
    stock: [Number, String],
    currentPage: Number,
  },
  setup(props, { emit }) {
    const goBackToPage1 = () => {
      emit("go-back-to-page1");
    };

    const publishProduct = () => {
      emit("publish-product");
    };

    const goBack = () => {
      emit("go-back");
    };

    return {
      goBackToPage1,
      publishProduct,
      goBack,
    };
  },
});
</script>

<style scoped>
.stock {
  text-align: left;
  color: #888;
  font-weight: bold;
  font-size: 12px;
}
</style>
