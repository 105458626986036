<template>
  <div class="item-info-container mb-3">
    <div class="product-img">
      <swiper :modules="modules" :slides-per-view="1" :space-between="50" :loop="true" navigation
        :pagination="{ clickable: true }" @swiper="onSwiper" @slideChange="onSlideChange">
        <swiper-slide v-for="(imageUrl, index) in item?.imageUrls" :key="index" class="swipe-card-wrapper">
          <div class="swipe-card">
            <div class="image-container" :style="{ backgroundImage: `url(${imageUrl})` }"
              @click="onImageClick(imageUrl)"></div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="purchased-product-details mt-4 mr-4">
      <div class="name-purchased-dt d-flex justify-content-between">
        <span class="product-name font-weight-bold"> {{ item?.name }}</span>
        <span class="download-receive">
          <a :href="item.receiptUrl" target="_blank">{{ $t('PURCHASED_ITEM_CELL.DOWNLOAD_RECEIPT') }}</a>
        </span>
      </div>
      <div class="quantity text-left mt-3 text-gray">{{ `${$t('PURCHASED_ITEM_CELL.QUANTITY')}: ${item.quantity}` }}</div>
      <div class="amont-purchased d-flex justify-content-between">
        <div >
        <span class="price text-left mr-1">{{ formattedAmount }}</span>
          
          <span class="currency">{{ item?.currency.toUpperCase() }}</span>
          <span class="tax-inc">{{ $t('PURCHASED_ITEM_CELL.INCLUDE_TAX') }}</span>
        </div>
        <div class="purchased-dt">
          <span>{{ formatPurchasedDt(item.purchasedAt) }}</span>
        </div>
      </div>

    </div>

  </div>
  <!-- Overlay Image -->
  <OverlayImage :shopDetail="shopDetail" :imageUrl="selectedImageUrl" v-if="showOverlayImage"
    v-on:close-overlay-image="onCloseOverlayImage"></OverlayImage>
</template>

<style scoped>
.swipe-card-wrapper {
}
.swipe-card {
  padding: 36px;
}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel-container img {
  width: 100%;
  height: auto;
}
w
.description {
  font-size: 14px;
  line-height: 28px;
  text-align: left;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.price-container {
  text-align: left;
}
.price {
  font-size: 20px;
  font-weight: 700;
  color: #888;
}
.currency {
  font-size: 20px;
  font-weight: 500;
  color: #888;
}
.button-buy {
  width: 200px;
  margin-top: 32px;
}
.button-readmore {
  color: #888;
  align-self: flex-end;
}
.tax-inc {
  margin-left: 12px;
  color: #888;
  font-size: 12px;
}
.purchased-info {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  color: #888;
}
.download-receive {
  text-align: right;
}
.item-info-container {
  width: 70%;
  display: flex;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 8px;
  margin: 0 auto;
}
.product-img {
  width: 30%;
  display: flex;
}
.swipe-card {
 padding: 8px;
}
.image-container {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 132px;
  width: 132px;
  border-radius: 8px;
  cursor: pointer;
  margin: auto;
}
.swiper  {
  width: 214px;
}

.purchased-product-details {
  width: 70%;
}

.quantity {
  color: #888;
}
.purchased-dt {
  font-size: 12px;
  color: #888;
}
@media all and (min-width: 1024px) and (max-width: 1280px) {
}
@media all and (min-width: 768px) and (max-width: 1024px) {
}
@media all and (min-width: 480px) and (max-width: 768px) {
}
@media all and (max-width: 480px) {
}
@media all and (max-width: 1024px) {
  .item-info-container {
    width: 100%;
    flex-direction: column;

  }

  .purchased-product-details {
    width: 100%;
    padding: 0 12px;
  }

}
</style>

<script>
import { defineComponent } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import OverlayImage from "@/views/OverlayImage.vue";
import moment from 'moment'
export default defineComponent({
  name: "PurchasedItemCell",
  components: {
    Swiper,
    SwiperSlide,
    OverlayImage
  },
  data() {
    return {
      showOverlayImage: false,
      shopDetail: []
    };
  },
  computed: {
    divStyle() {
      return {
        backgroundImage: `url(${this.item?.imageUrl})`,
      };
    },
    formattedPrice() {
      let priceValue = this.item?.amount;

      if (this.item?.currency !== 'jpy') {
        priceValue = priceValue / 100;  // 10で割る処理をこの位置に移動
      }

      return new Intl.NumberFormat("ja-JP", { // 'ja-JP' に変更して、日本の数値書式に従ってカンマを入れる
        style: "decimal",
        minimumFractionDigits: this.item?.currency === 'jpy' ? 0 : 2 // 日本円の場合は小数点なし、それ以外は2桁の小数点以下
      }).format(priceValue);
    },
    formattedAmount() {
      let amountVal = this.item?.amount;
      if (this.item?.currency !== 'jpy') {
        amountVal = amountVal / 100;  // 10で割る処理をこの位置に移動
      }
      return new Intl.NumberFormat("ja-JP", { // 'ja-JP' に変更して、日本の数値書式に従ってカンマを入れる
        style: "decimal",
        minimumFractionDigits: this.item?.currency === 'jpy' ? 0 : 2 // 日本円の場合は小数点なし、それ以外は2桁の小数点以下
      }).format(amountVal);
    }
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  inject: {
    API_BASE_URL: {
      from: 'API_BASE_URL'
    },
    API_ENDPOINT_GET_STRIPE_ACCOUNT: {
      from: 'API_ENDPOINT_GET_STRIPE_ACCOUNT'
    },
    API_ENDPOINT_GET_STRIPE_ACCOUNT_ID: {
      from: 'API_ENDPOINT_GET_STRIPE_ACCOUNT_ID'
    },
    SESSION_STORAGE_KEY_PRODUCT: {
      from: 'SESSION_STORAGE_KEY_PRODUCT'
    }
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    const truncate = (text, length, suffix) => {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    };
    
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, A11y],
      truncate,
    };
  },
  methods: {
    onClickCell: function () {},
    onImageClick: function (imageUrl) {
      if (!imageUrl) return;
      const images = [...this.item.imageUrls];
      const index = images.indexOf(imageUrl);
      console.log(imageUrl);
      console.log(images);
      console.log(index);

      if (index !== -1) {
        images.splice(index, 1); // Remove the element
        images.unshift(imageUrl); // Insert the element at the beginning
      }
      const listImage = [...images];
      this.shopDetail = listImage;
      this.showOverlayImage = true;
    },
    onCloseOverlayImage: function () {
      this.showOverlayImage = false;
      this.shopDetail = [];
    },
    formatPurchasedDt(date) {
      const currentLang = this.$store.getters.currentLang;
      return currentLang === 'ja' ?  moment(date).format('YYYY/MM/DD') : moment(date).format('YYYY MMMM.DD');
    }
  },
});
</script>
