import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import TopView from '../views/TopView.vue'
import TimelineView from '../views/TimelineView.vue'
import ProfileSettingView from '../views/ProfileSettingView.vue'
import MembershipSettingView from '../views/MembershipSettingView.vue'
import NotificationView from '../views/NotificationView.vue'
import AdminView from '../views/AdminView.vue'
import ProfileView from '../views/ProfileView.vue'
import EngagementRankView from '../views/EngagementRankView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import TermsView from '../views/TermsView.vue'
import PrivacyView from '../views/PrivacyView.vue'
import AboutView from '../views/AboutView.vue'
import SellView from '../views/SellView.vue'
import PaymentView from '../views/PaymentView.vue'



const routes = [
  {
    path: '/',
    name: 'top',
    component: TopView
  },
  {
    path: '/timeline',
    name: 'timeline',
    component: TimelineView,
    props: true
  },
  {
    path: '/profile-setting',
    name: 'profile-setting',
    component: ProfileSettingView
  },
  {
    path: '/membership-setting',
    name: 'membership-setting',
    component: MembershipSettingView,
    props: true
  },
  {
    path: '/notification',
    name: 'notification',
    component: NotificationView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    props: true
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    props: true
  },
  {
    path: '/engagement-rank',
    name: 'engagement-rank',
    component: EngagementRankView,
    props: true
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsView
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/sell',
    name: 'sell',
    component: SellView
  },
  {
    path: '/payment',
    name: 'payment',
    props: true,
    component: PaymentView
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundView
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router
