<template>
  <div class="overlay-container">
    <div class="card">
      <div class="header">{{ $t('ADMIN_VIEW.COMMERCE_ACCOUNT.EDIT_PUBLIC_KEY') }}</div>
      <div class="message" v-html="$t('ADMIN_VIEW.COMMERCE_ACCOUNT.CAUTION')"></div>
      <input type="text" v-model="publicKey" placeholder="pk_live_xxx...">
      <div class="buttons">
        <button class="n-btn-primary" @click="save" :disabled="!publicKey || publicKey === ''">{{ $t('BUTTON.SAVE_UPPER') }}</button>
        <button  class="n-btn-weak" @click="cancel">{{ $t('BUTTON.CANCEL_UPPER') }}</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.overlay-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.card {
  width: 60%; /* Default width for PC */
  max-width: 600px; /* Optional: if you want to limit the maximum width */
  height: auto; /* Adjust height as per content */
  background: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
/* Responsive width for mobile */
@media (max-width: 768px) {
  .card {
    width: 90%;
  }
}
.header {
  font-weight: bold;
}
.message {
  color: red;
  font-size: 14px;
}
input {
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.buttons {
  display: flex;
  justify-content: center;
}
</style>

<script>
import { defineComponent, ref, inject, onMounted } from 'vue';
import axios from 'axios';

export default defineComponent({
  name: 'OverlayEditPublicKey',
  setup(props, { emit }) {
    const publicKey = ref('');
    const apiBaseUrl = inject("API_BASE_URL");
    const apiEndpoint = inject("API_ENDPOINT_STRIPE_PUBLIC_KEY");

    const accountId = ref(props.accountId);

    const membershipId = ref(null);
    onMounted(() => {
      const url = window.location.href;
      const urlObj = new URL(url);
      const queryParams = urlObj.searchParams;
      membershipId.value = queryParams.get('membershipId');
    });

    const save = () => {
      emit('showspinner');
      
      // console.log('Public key saved:', publicKey.value, apiBaseUrl, apiEndpoint);
      // console.log("accountId", accountId.value);
      // console.log("membershipId", membershipId.value);
      // console.log("publicKey", publicKey.value);

      let apiUrl = apiBaseUrl + apiEndpoint;
      axios.post(apiUrl, {
        membershipId: membershipId.value,
        accountId: accountId.value,
        publicKey: publicKey.value,
      })
      .then(() => {
        // handleSuccess();
        emit('hidespinner');
        emit('close');
      })
      .catch((err) => {
        console.error(err);
        // handleError();
        emit('hidespinner');
        emit('close');
      });

    };

    const cancel = () => {
      console.log('Cancelled');
      emit('close');
    };

    return {
      apiEndpoint,
      publicKey,
      save,
      cancel
    };
  },
  props: {
    accountId: {
      type: String,
    },
  },
});
</script>
