import {
  createWeb3Modal,
  defaultConfig,
  useDisconnect,
} from "@web3modal/ethers/vue";
import { CONNECT_TYPE, LOCAL_STORAGE_KEY } from "@/const/config";
import Web3 from 'web3'
let web3Modal;
let walletInfo;
export const _createWeb3Modal = () => {
  try {
    // 1. Get projectId at https://cloud.walletconnect.com
    const projectId = process.env.VUE_APP_WEB3MODAL_KEY;
    // 2. Set chains
    const chains = [
      //  Ethereum
      {
        chainId: 1,
        name: "Ethereum",
        currency: "ETH",
        explorerUrl: process.env.VUE_APP_ETHER_EXPLORE_URL,
        rpcUrl: process.env.VUE_APP_ETHER_RPC_URL,
      },
      //  Polygon
      {
        chainId: 137,
        name: "Polygon Mainnet",
        currency: "MATIC",
        explorerUrl: process.env.VUE_APP_POLYGON_EXPLORE_URL,
        rpcUrl: process.env.VUE_APP_POLYGON_RPC_URL,
      },
    ];
    // 3. Create modal
    const metadata = {
      name: "DeepBeach",
      description: "DeepBeach",
      url: process.env.VUE_APP_SHARE_URL, // origin must match your domain & subdomain
      icons: [`${process.env.VUE_APP_SHARE_URL}/img/db-logo.0b9016b4.png`],
    };
    // const excludedWallets = ["coinbase", "metamask", "trustwallet"]; // List of wallets to exclude
    return createWeb3Modal({
      ethersConfig: defaultConfig({ metadata }),
      chains,
      projectId,
      enableAnalytics: true, // Optional - defaults to your Cloud configuration,
      themeVariables: {
        "--w3m-accent": "#000",
        "--w3m-border-radius-master": "1px",
        "--wui-spacing-l": "26px",
        "--w3m-font-family": "Roboto Mono",
        "--w3m-color-mix": "#000000",
        "--w3m-logo-image-url": `${process.env.VUE_APP_SHARE_URL}/img/db-logo.0b9016b4.png`,
      },
      themeMode: "light",
      enableWalletConnect: false, // Optional - true by default
      enableInjected: false, // Optional - true by default
      enableEIP6963: false, // Optional - true by default
      enableCoinbase: false, // Optional - true by default,
    });
  } catch (error) {
    console.error("Have error when connect modal", error);
    return null;
  }
};

export const disconnectWeb3Modal = () => {
  try {
    const { disconnect } = useDisconnect();
    disconnect();
  } catch (error) {
    console.error("Can not disconnect web3modal===>", error);
  }
};

export const connectWeb3Modal = async (callback) => {
  web3Modal = _createWeb3Modal();
  /* Handle change account address or chain Id */
  walletInfo = {
    walletAddress: web3Modal?.getAddress() || null,
    chainId: web3Modal?.getChainId() || null,
    connectType: CONNECT_TYPE.WEB3_MODAL,
  };
  sessionStorage.setItem(
    LOCAL_STORAGE_KEY.WEB3_MODAL_ACCOUNT_INFO,
    JSON.stringify({
      walletAddress: walletInfo.walletAddress,
      chainId: walletInfo.chainId,
    })
  );
  callback(null, "connectWeb3ModalStart", JSON.stringify(walletInfo));

  web3Modal.subscribeProvider(async (event) => {
    walletInfo = {
      walletAddress: event?.address,
      chainId: event?.chainId,
      connectType: CONNECT_TYPE.WEB3_MODAL,
      error: event?.error,
      isConnected: event?.isConnected,
    };
    const resultChangeAcountInfo = isChangeAccount(walletInfo);
    let event2 = "web3AccountsChanged";
    if (resultChangeAcountInfo.isChangeAddress) {
      event2 = "accountsChanged";
    }
    if (resultChangeAcountInfo.isChangeChanId) {
      event2 = "connectWeb3ModalStart";
    }
    callback(null, event2, JSON.stringify(walletInfo));
    sessionStorage.setItem(
      LOCAL_STORAGE_KEY.WEB3_MODAL_ACCOUNT_INFO,
      JSON.stringify({
        walletAddress: walletInfo.walletAddress,
        chainId: walletInfo.chainId,
      })
    );

    // }
  });

  // Handle open modal
  web3Modal.subscribeState(async (newState) => {
    callback(
      null,
      "actionOpenOrCloseModal",
      JSON.stringify({ ...newState, ...walletInfo })
    );
    // this.removeOrCreateCustomeElement();
    if (!newState.open) return;
    let setTimeOut;
    if (setTimeOut) {
      clearTimeout(setTimeOut);
    }
    setTimeOut = setTimeout(() => {
      const elementToRemove = document
        .querySelector("w3m-modal")
        ?.shadowRoot?.querySelector("w3m-router")
        ?.shadowRoot?.querySelector("w3m-connect-view")
        ?.shadowRoot?.querySelector("wui-flex")
        .querySelector("wui-list-wallet:nth-child(3)");
      if (
        elementToRemove &&
        elementToRemove?.getAttribute("name") === "MetaMask"
      ) {
        elementToRemove.remove();
      }
    }, 0);
  });
};

export const connectWeb3ModalAsync = () => {
  return new Promise((resolve, reject) => {
    connectWeb3Modal((err, event, walletData) => {
      if (err) {
        reject(err);
      } else {
        resolve({ err, event, walletData });
      }
    });
  });
};

export const isChangeAccount = (currentAccountInfo) => {
  const oldDataInfo = JSON.parse(
    sessionStorage.getItem(LOCAL_STORAGE_KEY.WEB3_MODAL_ACCOUNT_INFO)
  );
  return {
    isChangeAddress:
      currentAccountInfo?.walletAddress !== oldDataInfo?.walletAddress,
    isChangeChanId: currentAccountInfo?.chainId !== oldDataInfo?.chainId,
  };
};

export const makeTransactionWeb3Modal = async (recipientAddress, ethAmount) => {
  if (!web3Modal) {
    web3Modal = _createWeb3Modal();
  }
  const web3ModalProvider = await web3Modal.getWalletProvider();
  if (!web3ModalProvider) {
    return {
      connect: false,
    };
  }
  const web3 = new Web3(web3ModalProvider);
  const accounts = await web3.eth.getAccounts();
  const senderAddress = accounts[0];
  const gasPrice = await web3.eth.getGasPrice();
  const gasLimit = 21000; // Gas limit for a simple Ether transfer is fixed at 21000
  const tx = {
    from: senderAddress,
    to: recipientAddress,
    value: web3.utils.toWei(String(ethAmount), "ether"),
    gasPrice: gasPrice,
    gas: gasLimit,
  };
  try {
    const receipt = await web3.eth.sendTransaction(tx);
    console.log("Transaction receipt:", receipt);
    return {
      connect: true,
      receipt,
    };
  } catch (error) {
    console.error("Error sending transaction:", error);
    return {
      connect: true,
      error,
    };
  }
};