<template>
  <MainHeader
    :title="title"
    :walletAddress="selfWalletAddress"
    :chainName="chainName"
    :membershipId="membershipId"
    :isAdmin="isAdmin"
  />
  <div class="n-container" style="margin-top: 90px">
    <div
      class="profile-image"
      v-bind:style="{ backgroundImage: 'url(' + profileImageUrl + ')' }"
    ></div>
    <p class="user-name-text">{{ userName }}</p>
    <div class="wallet-address-text">
      <div class="icon-wallet"></div>
      {{ profileWalletAddress }}
    </div>
    <div class="profile-info-container">
      <div class="user-info-sign large pink">
        <div class=""></div>
        <p class="text-black">
          Engagement Score
          <span class="strong-text">{{ engagementScore }}</span>
        </p>
      </div>
      <div class="user-info-sign">
        <div class="icon-post-black"></div>
        <p class="text-black">
          <span class="">{{ postsCount }}</span> posts
        </p>
      </div>
      <div class="user-info-sign">
        <div class="icon-comment-black"></div>
        <p class="text-black">
          <span class="">{{ commentsCount }}</span> comments received
        </p>
      </div>
      <div class="user-info-sign">
        <div class="icon-like-black"></div>
        <p class="text-black">
          <span class="">{{ likesCount }}</span> likes received
        </p>
      </div>
    </div>
    <div class="action-buttons-container" v-if="!isSelf">
      <p v-if="userName">Tip {{ userName }}</p>
      <div class="transfer-buttons">
        <button class="n-btn-primary" @click="sendETH">TIP by Token</button>
        <!-- <button class="n-btn-primary" disabled>Tip with custom tokens (Coming soon)</button> -->
      </div>
    </div>
    <h3>{{ userName }}'s Tokens</h3>
    <div id="nft-container">
      <NFTCell
        v-for="(item, key) in nfts"
        :key="key"
        :membershipId="membershipId"
        :issuerName="item?.issuerName"
        :tokenAddress="item?.tokenAddress"
        :tokenId="item?.tokenId"
        :tokenUri="item?.tokenUri"
        :tokenName="item?.tokenName"
        :externalUrl="item?.externalUrl"
        :isFT="item?.isFT"
        :fromProfileView="true"
        :onClicKMemberShipClipboard="clickMembershipClipboard"
      />
      <div class="loader" v-show="isLoadingNFT"></div>
    </div>
  </div>
  <OverlayTransferToken
    v-show="doTransferToken"
    :userName="userName"
    :profileImageUrl="profileImageUrl"
    :chainName="chainName.toLowerCase()"
    v-on:dismiss-overlay-transfer-token="onDismissOverlayTransferToken"
    v-on:transfer-eth="onTransferETH"
  >
  </OverlayTransferToken>
</template>

<style scoped>
h3 {
  margin-top: 42px;
}
.profile-image {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 12px auto;
  border: 1px solid #888;
  background-color: #000;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
}
.user-name-text,
.wallet-address-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-name-text {
  margin: 6px 0;
  font-weight: 600;
  font-size: 24px;
}
.wallet-address-text {
  margin: 24px 6px;
  font-size: 14px;
  color: #000;
}
#nft-container {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.loader {
  width: 100%;
  height: 40px;
  background-color: transparent;
  background-image: url("@/assets/img/loader-small.gif");
  background-position: center;
  background-size: 20px 20px;
  background-repeat: no-repeat;
}
.transfer-buttons {
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: centers;
}
.action-buttons-container {
  background-color: #fff;
  border: 2px solid #000;
  padding: 20px;
  margin-top: 24px;
  margin-bottom: 48px;
}
.action-buttons-container p {
  margin: 4px 0;
  font-weight: 600;
  color: #657786;
}
@media all and (min-width: 1024px) and (max-width: 1280px) {
}
@media all and (min-width: 768px) and (max-width: 1024px) {
}
@media all and (min-width: 480px) and (max-width: 768px) {
}
@media all and (max-width: 480px) {
  #nft-container {
    width: 100%;
    margin: auto;
  }
}
</style>

<script>
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
// import Moralis from 'moralis/dist/moralis.min.js';
import Moralis from "moralis";
// const { EvmChain } = require("@moralisweb3/common-evm-utils");
import OverlayTransferToken from "@/views/OverlayTransferToken.vue";
import MainHeader from "@/components/MainHeader.vue";
import NFTCell from "@/components/NFTCell.vue";
import shared from "@/shared";
import axios from "axios";

export default defineComponent({
  name: "ProfileView",
  data() {
    return {
      selfWalletAddress: null,
      profileWalletAddress: null,
      membershipId: null,
      chainName: "",
      isLoadingNFT: true,
      isAdmin: false,
      isSelf: false,
      doTransferToken: false,
      userName: "",
      profileImageUrl: "",
      title: "---",
      postsCount: 0,
      likesCount: 0,
      commentsCount: 0,
      engagementScore: 0,
      nfts: [],
    };
  },
  unmounted() {
    // Unbound shared functions
    this.initMoralis = null;
    shared.initMoralis.bind(null);
    this.connectWallet = null;
    shared.connectWallet.bind(null);
    this.checkNFT = null;
    shared.checkNFT.bind(null);
    this.handleErrorAndGoTop = null;
    shared.handleErrorAndGoTop.bind(null);
    this.getChainParamFromChainId = null;
    shared.getChainParamFromChainId.bind(null);

    this.makeTransactionEth = null;
    shared.makeTransactionEth.bind(null);
  },
  mounted() {
    // FIXME: RankCellから来る場合は何故かスクロールの途中から表示される（プロフィール画像が隠れる）ので、トップに位置を戻しているが、根本解決していない
    window.scrollTo({ top: 0, behavior: "smooth" });

    /* Bind shared functions */
    this.connectWallet = shared.connectWallet.bind(this);
    this.initMoralis = shared.initMoralis.bind(this);
    this.checkNFT = shared.checkNFT.bind(this);
    this.getChainParamFromChainId = shared.getChainParamFromChainId.bind(this);
    this.handleErrorAndGoTop = shared.handleErrorAndGoTop.bind(this);

    this.makeTransactionEth = shared.makeTransactionEth.bind(this);

    const route = useRoute();
    this.profileWalletAddress = route.query.user;

    if (!this.profileWalletAddress) {
      this.handleErrorAndGoTop(
        "Sorry, something went wrong",
        "Please login again",
        3000
      );
      return;
    }

    /* Get membership data from local cache */
    let membershipData = sessionStorage.getItem(
      this.LOCAL_STORAGE_KEY_MEMBERSHIP
    );
    if (!membershipData) {
      this.handleErrorAndGoTop(
        "Sorry, something went wrong",
        "Please login again",
        3000
      );
      return;
    }
    let membershipObj = JSON.parse(membershipData);
    let tokenAddress = membershipObj.tokenAddress;
    let tokenId = membershipObj.tokenId;

    if (this.$route.query.membershipId) {
      this.membershipId = this.$route.query.membershipId;
    } else {
      this.handleErrorAndGoTop(
        "Sorry, something went wrong",
        "Please login again",
        3000
      );
      return;
    }

    /* Init Moralis */
    this.initMoralis(
      this.MORALIS_SERVER_URL,
      this.MORALIS_APP_ID,
      this.MORALIS_MASTER_KEY
    );

    /* クライアントのウォレットアドレスを取得 （Header表示用）*/
    let _this = this;
    this.connectWallet((err, event, walletData) => {
      if (err) {
        _this.handleErrorAndGoTop(
          "Sorry, something went wrong",
          "Please login again",
          3000
        );
        return;
      }
      let walletObj = JSON.parse(walletData);
      if (!walletObj.walletAddress || !walletObj.chainId) {
        _this.handleErrorAndGoTop(
          "Wallet Disconnected",
          "Please login again",
          3000
        );
        return;
      }
      // Set wallet address
      _this.selfWalletAddress = walletObj.walletAddress;
      // Show Tip Button if this user is not self user
      if (
        _this.selfWalletAddress.toLowerCase() !=
        _this.profileWalletAddress.toLowerCase()
      ) {
        _this.isSelf = false;
      } else {
        _this.isSelf = true;
      }
      // Set chain name
      switch (walletObj.chainId) {
        case 1:
          _this.chainName = "Ethereum";
          break;
        case 137:
          _this.chainName = "Polygon";
          break;
        default:
          // alert("Currently we support Ethereum and Polygon");
          // _this.$router.go(-1);
          _this
            .$swal({
              title: "Currently we support Ethereum and Polygon",
              type: "Please switch to Ethereum and Polygon chain",
              showCancelButton: false,
              confirmButtonColor: "#000",
              timer: 5000,
            })
            .then(() => {
              _this.$router.go(-1);
            });
          break;
      }
      let chainParam = _this.getChainParamFromChainId(walletObj.chainId);
      /* Check NFT */
      _this.checkNFT(
        chainParam,
        _this.selfWalletAddress,
        tokenAddress,
        tokenId,
        function (isValidTokenExist) {
          if (!isValidTokenExist) {
            _this.handleErrorAndGoTop(
              "Required NFT not found - code 105",
              "You have no memebership NFT for viewing this page",
              3000
            );
            return;
          }
          /* Adminかどうかをチェック、フラグをセット */
          let apiUrl = _this.API_BASE_URL + _this.API_ENDPOINT_MEMBERSHIP;
          axios
            .get(apiUrl, {
              params: {
                membershipId: _this.membershipId,
                walletAddress: _this.selfWalletAddress,
              },
            })
            .then((result) => {
              let dataObj = JSON.parse(JSON.stringify(result.data));
              _this.title = dataObj.name;
              _this.isAdmin = dataObj.is_admin;
            })
            .catch((err) => {
              console.error(err);
              _this.handleErrorAndGoTop(
                "Sorry, something went wrong",
                "Please login again",
                3000
              );
            });
          _this.getProfile(_this.membershipId, _this.profileWalletAddress);
          if (_this.profileWalletAddress && walletObj.chainId) {
            let chainName_ = _this.chainName.toLowerCase();
            let parent = document.getElementById("nft-container");
            _this.getUserNFTs(parent, chainName_, _this.profileWalletAddress);
          }
        }
      );
    });
  },
  inject: {
    MORALIS_SERVER_URL: {
      from: "MORALIS_SERVER_URL",
    },
    MORALIS_APP_ID: {
      from: "MORALIS_APP_ID",
    },
    MORALIS_MASTER_KEY: {
      from: "MORALIS_MASTER_KEY",
    },
    LOCAL_STORAGE_KEY_MEMBERSHIP: {
      from: "LOCAL_STORAGE_KEY_MEMBERSHIP",
    },
    API_BASE_URL: {
      from: "API_BASE_URL",
    },
    API_ENDPOINT_MEMBERSHIP: {
      from: "API_ENDPOINT_MEMBERSHIP",
    },
    API_ENDPOINT_USER: {
      from: "API_ENDPOINT_USER",
    },
    API_ENDPOINT_CREATE_TIP_NOTIFICATION: {
      from: "API_ENDPOINT_CREATE_TIP_NOTIFICATION",
    },
  },
  components: {
    MainHeader,
    NFTCell,
    OverlayTransferToken,
  },
  methods: {
    getProfile: function (membershipId, walletAddress) {
      this.showSpinner = true;

      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_USER;
      let _this = this;
      axios
        .get(apiUrl, {
          params: {
            walletAddress: walletAddress,
            membershipId: membershipId,
          },
        })
        .then((result) => {
          let dataObj = JSON.parse(JSON.stringify(result.data));
          _this.userName = dataObj.name;
          _this.profileImageUrl = dataObj.profile_image_url;
          if (dataObj.engagement) {
            let engagementObj = JSON.parse(JSON.stringify(dataObj.engagement));
            if (engagementObj.posts_count) {
              _this.postsCount = engagementObj.posts_count;
            }
            if (engagementObj.comments_count) {
              _this.commentsCount = engagementObj.comments_count;
            }
            if (engagementObj.likes_count) {
              _this.likesCount = engagementObj.likes_count;
            }
            if (engagementObj.engagement_score) {
              _this.engagementScore = engagementObj.engagement_score;
            }
          }
          _this.showSpinner = false;
        })
        .catch((err) => {
          console.error(err);
          _this.showSpinner = false;
          _this.handleErrorAndGoTop(
            "Sorry, something went wrong",
            "Please login again",
            3000
          );
        });
    },
    getUserNFTs: function (parent, chainId, walletAddress) {
      this.isLoadingNFT = true;
      (async () => {
        /*
        // Deprecated
        var options = {
          chain: `${chainId}`,
          address: walletAddress,
        };
        const userNFTs = await Moralis.Web3API.account.getNFTs(options);
        */
        var chainHex;
        switch (chainId) {
          case "eth":
            chainHex = "0x1";
            break;
          case "polygon":
            chainHex = "0x89";
            break;
          default:
            break;
        }
        console.log(chainId, chainHex);
        const userNFTs = await Moralis.EvmApi.nft.getWalletNFTs({
          chain: chainHex,
          address: walletAddress,
          format: "decimal",
          tokenAddresses: [],
        });
        let result = userNFTs.raw["result"];
        if (result) {
          /** Filter Spam */
          result = result.filter((element) => !element.possible_spam)
          
          result.forEach((element) => {
            let issuerName = element["name"];
            let tokenAddress = element["token_address"];
            let tokenId = element["token_id"];
            let tokenUri = element["token_uri"];
            var metadata = element["metadata"];
            var tokenName = "";
            var externalUrl = null;
            if (metadata) {
              // metadata = metadata.replace(/\\/g, ''); //!!: 2022/5/20 から急にエラー"Uncaught SyntaxError: Unexpected token "を吐くようになり、コメントアウトしたら動いた
              var json = JSON.parse(metadata);
              tokenName = json["name"];
              externalUrl = json["external_url"];
            }
            let nft = {
              issuerName: issuerName,
              tokenAddress: tokenAddress,
              tokenId: tokenId,
              tokenUri: tokenUri,
              tokenName: tokenName,
              externalUrl: externalUrl,
            };
            this.nfts.push(nft);
          });
        }
        //  /** Get FT by wallet address */ 
         const userFTs = await shared.getFTsByWalletAdress( walletAddress, this.chainName.toLowerCase()) ?? []
         // Merge NFT and FT
        this.nfts = [...this.nfts, ...userFTs]

        this.isLoadingNFT = false;
      })();
    },
    clickMembershipClipboard: function (tokenAddress) {
      event.stopPropagation();
      let _this = this;
      navigator.clipboard.writeText(tokenAddress);
      _this.handleClipboard_ = shared.handleShowClipboard.bind(_this);
      _this.handleClipboard_();
    },
    sendETH: function () {
      if (!this.doTransferToken) {
        this.doTransferToken = true;
      }
    },
    onDismissOverlayTransferToken: function () {
      this.doTransferToken = false;
    },
    onTransferETH: function (value) {
      // Dismiss transfer token overlay
      this.doTransferToken = false;
      let _this = this;
      _this
        .$swal({
          title: "Please check MetaMask & Confirm Transaction⏩",
          html: `It may take a few minutes before ${_this.userName} receive your Tip. </br> Thanks for your gratuity!`,
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#000",
        })
        .then(async () => {
          // Deprecated
          /*
        // !!: トランスファーの前にAuthenticateしないとエラーとなる//
        Moralis.authenticate().then(function () {
          // Transfer ETH
          (async () => {
              await Moralis.transfer({
                amount: Moralis.Units.ETH(value),
                receiver: _this.profileWalletAddress,
                type: "native",
              }).then((e) => {
                alert("transaction is sucesfully confirmed", e);
                // トークンの受け手に通知をする 
                let apiUrl = _this.API_BASE_URL + _this.API_ENDPOINT_CREATE_TIP_NOTIFICATION;
                axios.post(apiUrl, {
                  membershipId: _this.membershipId,
                  from: _this.selfWalletAddress,
                  to: _this.profileWalletAddress,
                  amount: value,
                  symbol: "ETH"
                })
                .then(() => {
                  // do nothing
                })
                .catch(err => {
                  console.error(err);
                });    
              });
          })().catch(err => {
              console.error(err);
          });
        });
        */
          /*
        // sending 0.5 tokens with 18 decimals
        // Custom token
        const options = {type: "erc20",
                     amount: value,
                     Receiver: _this.profileWalletAddress,
                     contractAddress: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8"};
        const result = await  await Moralis.Web3API.token.transfer(options);
        // const options = {type: "native", amount: Moralis.Units.ETH(value), receiver: _this.profileWalletAddress};
        // let result = await Moralis.transfer(options);
        console.log("send result", result);
        */

          this.makeTransactionEth(
            _this.profileWalletAddress,
            value,
            (receipt, error) => {
              if (error) {
                console.log("transaction canceled or failed");
                alert(error?.message || "transaction canceled or failed");
                // Cancelした場合もここに入る
                return;
              }
              // トークンの受け手に通知をする
              let apiUrl =
                _this.API_BASE_URL + _this.API_ENDPOINT_CREATE_TIP_NOTIFICATION;
              var ftSymbol = "{Native Token}";
              if (_this.chainName.toLowerCase() === "ethereum") {
                ftSymbol = "ETH";
              } else if (_this.chainName.toLowerCase() === "polygon") {
                ftSymbol = "Matic";
              }
              axios
                .post(apiUrl, {
                  membershipId: _this.membershipId,
                  from: _this.selfWalletAddress,
                  to: _this.profileWalletAddress,
                  amount: value,
                  symbol: ftSymbol,
                })
                .then(() => {
                  // do nothing
                })
                .catch((err) => {
                  console.error(err);
                });
            }
          );
        });
    },
  },
});
</script>
