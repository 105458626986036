<template>
  <MainHeader
    :title="title"
    :walletAddress="walletAddress"
    :chainName="chainName"
    :membershipId="membershipId"
    :isAdmin="isAdmin"
    :nftImageUrl="nftImageUrl"
  />
  <div id="cta-top" v-show="isShowCta">
    <p>
      <a href="https://forms.gle/JDHVDTheZEwumS3X8" target="_blank"
        >{{ $t('TIMELINE.CONTACT_US') }}</a
      >
      {{ $t('TIMELINE.CONTACT_US_EXPLAIN') }}
    </p>
    <button id="button-close-cta" @click="closeCta"></button>
  </div>
  <div class="n-container" style="margin-top: 120px" v-show="isValidTokenExist">
    <!-- Fixed headerの高さ＋マージン分開ける -->
    <div
      id="cover"
      v-bind:style="{ backgroundImage: 'url(' + coverImageUrl + ')' }"
    >
      <div class="loader-container" v-if="!didLoadCoverImage">
        <div class="loader"></div>
      </div>
    </div>
    <div class="bulletin-board" v-if="bulletinTitle != null">
      <div class="bulletin-board-header">
        <p> {{ $t('TIMELINE.NEWS') }}</p>
        <Popper hover content="Admin members can set announcements here">
          <div class="icon-tooltip"></div>
        </Popper>
      </div>
      <div class="bulletin-board-body">
        <h3>{{ bulletinTitle }}</h3>
        <p>{{ bulletinText }}</p>
        <img
          class="bulletin-image"
          :src="bulletinImageUrl"
          v-if="bulletinImageUrl"
        />
        <p class="weak-text">{{ bulletinTimestamp }}</p>
        <a :href="`${bulletinLink}`" target="_blank" v-if="bulletinLink"
          >{{ $t('TIMELINE.EXTERNAL_LINK') }}</a
        >
        <div class="loader-container" v-if="!didLoadBulletinImage">
          <div class="loader"></div>
        </div>
      </div>
    </div>
    <hr class="hr-ranking" v-if="bulletinTitle != null" />

    <!-- Ranking -->
    <div class="n-container">
      <h2 style="margin-top: 64px; margin-bottom: 64px">
        {{ $t('TIMELINE.TOP_MEMBER') }}
      </h2>
      <div class="rank-c"  v-show="showUserRanking">
        <div id="rank-container"></div>
      <button class="n-btn-link see-more" @click="goEngagementRankingPage(membershipId)">
        {{ $t('TIMELINE.SEE_MORE') }}
      </button>
      </div>
    </div>
    <div class="infinite-loop-loader" v-if="isLoadingHighEngagementUser"></div>
    <hr class="hr-ranking" v-show="showUserRanking" />

    <div>
      <h2 ref="shopRef" style="margin-top: 64px; margin-bottom: 64px">Shop</h2>
      <div v-if="!shopItems?.length && !isLoadingShop">Product is empty</div>
      <masonry-wall :items="shopItems" :column-width="280" :gap="12">
        <template #default="{ item, index }">
          <ShopCell
          :isAdmin="isAdmin" 
          :key="index" 
          :item="item" 
          v-on:show-spinner="showSpinner = $event"
          @image-click="onShopImageClick"
          @delete-product-item="handleDeleteProduct"
          />
        </template>
      </masonry-wall>
      <div class="infinite-loop-loader" v-if="isLoadingShop"></div>

      <!-- TODO  Token Gate MVP2 で、「全て見る」を実装 -->
<!--       <button v-if="shopItems?.length > 0" class="n-btn-link see-more" @click="goShopPage(membershipId)">
        See More >
      </button> -->
    </div>
    <hr class="hr-ranking" />

    <div class="n-container" id="posts-container">
      <h2 style="margin-top: 64px; margin-bottom: 64px"> {{ $t('TIMELINE.WHAT_MEMBER_SAY') }}</h2>
      <p v-if="posts.length === 0">{{ $t('TIMELINE.THE_FIRST_MEMBER_SAY') }}</p>

      <button v-if="kycCompleted" id="button-sell" @click="showSellView"></button>
      <button id="button-post" @click="showPostView"></button>

      <masonry-wall :items="posts" :column-width="280" :gap="12">
        <template #default="{ item, index }">
          <PostCell
            :key="index"
            :clientWalletAddress="walletAddress"
            :membershipId="membershipId"
            :postData="item"
            v-on:click-profile-event="goProfile(membershipId, $event, value)"
            v-on:click-comment-event="onClickCommentEvent"
            v-on:success-delete-post-event="onSuccessDeletePost"
            v-on:fail-delete-post-event="onFailDeletePost"
            v-on:click-report-post-event="onHandleReport"
          />
        </template>
      </masonry-wall>
      <div class="infinite-loop-loader" v-if="isLoadingPosts"></div>
    </div>
  </div>
  <MainFooter />

  <!-- Overlay Post -->
  <OverlayPost
    v-show="showOverlayPost"
    :userData="userData"
    v-on:close-overlay-post="onCloseOverlayPost"
    v-on:dismiss-overlay-post="onDismissOverlayPost"
    v-on:post-created="addNewPost"
  ></OverlayPost>
  <!-- Overlay Comment -->
  <OverlayComment
    v-show="showOverlayComment"
    v-on:click-profile-event="goProfile(membershipId, $event, value)"
    :postData="commentTargetPostData"
    :userData="userData"
    :ogpData="ogpData"
    :showOverlayComment="showOverlayComment"
    :title="title"
    :isAdmin="isAdmin"
    v-on:close-overlay-comment="onCloseOverlayComment"
    v-on:did-like-post="onDidLikePost"
    v-on:did-delete-like-post="onDidDeleteLikePost"
    v-on:success-delete-post-event="onSuccessDeletePost"
    v-on:click-report-post-event="onHandleReport"
  ></OverlayComment>
  <!-- Overlay Image -->
  <OverlayImage
    :shopDetail="shopDetail"
    :imageUrl="selectedImageUrl"
    v-if="showOverlayImage"
    v-on:close-overlay-image="onCloseOverlayImage"
  ></OverlayImage>
  <!-- Overlay spinner -->
  <OverlaySpinner v-show="showSpinner"></OverlaySpinner>
</template>

<style scoped>
#cta-top {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  width: 100vw;
  height: 32px;
  position: fixed;
  left: 0;
  top: 114px;
  background-color: #fff;
  z-index: 2;
}
#cta-top p {
  font-size: 12px;
  font-weight: 600;
}
#shop {
  margin-top: 64px; 
  margin-bottom: 64px
}
#button-close-cta {
  width: 16px;
  height: 16px;
  background-image: url("@/assets/img/icon-close.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
}
#posts-container {
  width: 100%;
  padding: 0 !important;
}
#rank-container {
  display: flex;
  gap: 8px;
  padding: 12px;
  white-space: nowrap;
  overflow-x: scroll;
  cursor: pointer;
}
#cover {
  height: 260px;
/*  border: 2px solid #000;*/
  background-color: #000;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
}
#button-post {
  position: fixed;
  z-index: 2;
  right: 64px;
  bottom: 64px;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: none;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  background-color: #000;
  background-image: url("@/assets/img/icon-post.png");
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
  -webkit-box-shadow: 2px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px rgba(0, 0, 0, 0.2);
}
#button-sell {
  position: fixed;
  z-index: 2;
  right: 64px;
  bottom: 184px;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: none;
  color: #fff;
  background-color: #000;
  background-image: url("@/assets/img/icon-sell.png");
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
  -webkit-box-shadow: 2px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px rgba(0, 0, 0, 0.2);
}
.hr-ranking {
  margin-top: 120px;
}
.bulletin-board {
  margin-top: 24px;
  background-color: #fff;
  color: #000;
  border: 2px solid #000;
  padding: 24px 0;
  border-radius: 8px;
}
.bulletin-board-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  border-bottom: 2px solid #000;
}
.bulletin-board-body h3 {
  font-weight: 700;
  font-size: 20px;
}
.bulletin-board-body p {
  margin: 12px 24px;
  font-weight: 500;
  font-size: 18px;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.bulletin-image {
  width: calc(100% - 48px);
  height: 300px;
  margin-top: 24px;
  object-fit: contain;
}
#general-posts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  gap: 16px;
}
.loader-container {
  width: 100%;
  height: inherit;
  position: relative;
}
.loader {
  width: 50px;
  height: 50px;
  margin: auto;
  background-image: url("@/assets/img/loader.gif");
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.see-more {
  margin-top: 32px;
}
#posts-container {
  scroll-margin-top: 180px;
}
@media all and (min-width: 1024px) and (max-width: 1280px) {
}
@media all and (min-width: 768px) and (max-width: 1024px) {
}
@media all and (min-width: 480px) and (max-width: 768px) {
}
@media all and (max-width: 480px) {
  .n-container {
    margin-top: 150px !important;
  }
  #cover {
    width: 100%;
    height: 200px;
    margin: auto;
  }
  .bulletin-board {
    width: inherit;
  }
  #button-post {
    right: 32px;
    bottom: 32px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
  #button-sell {
    right: 32px;
    bottom: 106px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
  .post-cell-container {
    max-width: 100%;
  }
  #posts-container {
    width: inherit;
  }
  #cta-top {
    width: 106vw !important;
    top: 135px;
  }
}
/* @media all and(max-width: 376px){
  
} */
</style>

<script>
import { defineComponent, createApp, reactive, h, ref, watchEffect } from "vue";
import { useRoute } from "vue-router";
import shared from "@/shared";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import PostCell from "@/components/PostCell.vue";
import RankCell from "@/components/RankCell.vue";
import ShopCell from "@/components/ShopCell.vue";
import OverlayPost from "@/views/OverlayPost.vue";
import OverlayComment from "@/views/OverlayComment.vue";
import OverlaySpinner from "@/views/OverlaySpinner.vue";
import OverlayImage from "@/views/OverlayImage.vue";
import Popper from "vue3-popper";
import axios from "axios";
import moment from "moment";
import { scroller } from "vue-scrollto/src/scrollTo";

export default defineComponent({
  name: "TimelineView",
  data() {
    return {
      title: "- - -",
      coverImageUrl: "",
      showOverlayPost: false,
      showOverlayComment: false,
      showOverlayImage: false,
      commentTargetPostData: {},
      tokenAddress_: null,
      tokenId_: null,
      membershipId: null,
      walletAddress: null,
      userName: null,
      userProfileImageUrl: null,
      chainName: null,
      isValidTokenExist: false,
      posts: [],
      postProps: [],
      userData: {},
      ogpData: {},
      shopDetail: null,
      showSpinner: false,
      willHideOverlay: false,
      apiRequest: null,
      bulletinTitle: null,
      bulletinText: "",
      bulletinTimestamp: "",
      bulletinLink: "",
      bulletinImageUrl: "",
      isAdmin: false,
      lastScrollTop: 0,
      lastTimestamp: null,
      isLoadingPosts: false,
      isLoadingShop: false,
      shoudSkipRenderPosts: false,
      didLoadBulletinImage: true,
      didLoadCoverImage: false,
      isShowCta: true,
      selectedImageUrl: null,
      showUserRanking: false,
      connectWallet: null,
      loadingAPost: false,
      shopItems: [],
      kycCompleted: false,
      isFT: false,
      isLoadingHighEngagementUser: false,
    };
  },
  setup() {
    const shopRef = ref(null);
    const route = useRoute();

    const scrollToShop = () => {
      if (shopRef.value) {
        shopRef.value.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }

    // route.hash の変更を監視
    watchEffect(() => {
      if (route.hash === '#shop') {
        // !!: 諸々の描画更新を待つため、ディレイを入れないとスクロールしない
        setTimeout(() => {
            scrollToShop();
            window.history.replaceState(null, null, ' ');
        }, 5000); 
      }
    });

    // アクションボタン（...）以外をクリックした場合は、ドロップダウンを非表示
    window.onclick = function (event) {
      if (!event.target.matches(".button-show-actions")) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        for (var i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("show")) {
            openDropdown.classList.remove("show");
          }
        }
      }
    };

    // shopRef と scrollToShop をテンプレートに公開
    return { shopRef };
  },
  updated() {
    const route = useRoute();
    if (this.willHideOverlay) {
      this.showOverlayComment = false;
      this.willHideOverlay = false;
      return;
    }
    if (!this.willHideOverlay) {
      if (this.$route.query.postId && this.$route.query.membershipId) {
        if (!this.$route.query.membershipId) {
          // Go back to top
          this.handleErrorAndGoTop(
            "Wallet Disconnected",
            "Please login again",
            3000
          );
          return;
        }

        let membershipId = this.$route.query.membershipId;
        let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_POST;
        let _this = this;

        if (this.loadingAPost) {
          // console.log("A POSTをローディング中なので切る");
          return;
        }
        if (this.showOverlayComment) {
          // console.log("すでにコメントが表示されているので切る");
          return;
        }

        this.loadingAPost = true;
        axios
        .get(apiUrl, {
          params: {
            membershipId: membershipId,
            postId: route.query.postId,
          },
        })
        .then((result) => {
          // Set profile image
          let dataObj = JSON.parse(JSON.stringify(result.data.post));

          // Overlay comment viewに渡すpostDataを作成
          _this.commentTargetPostData.postId = route.query.postId;
          _this.commentTargetPostData.walletAddress = dataObj.user;
          let formattedMessage = `${dataObj.text}`.replaceAll("\\n", "\n"); // 改行のためフォーマット
          _this.commentTargetPostData.userMessage = formattedMessage;
          _this.commentTargetPostData.comments = dataObj.comments;
          _this.commentTargetPostData.likes = dataObj.likes;
          _this.commentTargetPostData.postImageUrl = dataObj.image_url;
          let date = new Date(dataObj.created_at);
          let agoDate = moment(
            date,
            "ddd MMM DD YYYY HH:mm:ss GMT Z"
          ).fromNow();
          _this.commentTargetPostData.ago = agoDate;
          _this.userData.walletAddress = _this.walletAddress;
          _this.userData.userName = _this.userName;
          _this.userData.userProfileImageUrl = _this.userProfileImageUrl;

          // Show comment view
          _this.loadingAPost = false;
          _this.showOverlayComment = true;

          // ユーザーデータを取得
          let apiUrl = _this.API_BASE_URL + _this.API_ENDPOINT_USER;
          axios
            .get(apiUrl, {
              params: {
                walletAddress: dataObj.user,
                membershipId: membershipId,
              },
              cancelToken: _this.apiRequest.token,
            })
            .then((result) => {
              // Set profile image
              let dataObj_ = JSON.parse(JSON.stringify(result.data));
              _this.commentTargetPostData.userName = dataObj_.name;
              _this.commentTargetPostData.profileImageUrl =
                dataObj_.profile_image_url;

              // // Show comment view
              // _this.showOverlayComment = true;
            })
            .catch((err) => {
              if (err.message === "api_request_canceled") {
                return;
              }
              _this.handleError_ = shared.handleError.bind(_this);
              _this.handleError_();
            });
        })
        .catch((err) => {
          console.error(err);
          _this.handleError_ = shared.handleError.bind(_this);
          _this.handleError_();

          _this.loadingAPost = false;
        });
      }
    }
  },
  unmounted() {
    // 無限スクロールイベントの解除
    window.removeEventListener("scroll", this.onScroll);
    // Unbound shared functions
    this.initMoralis = null;
    shared.initMoralis.bind(null);
    this.connectWallet = null;
    shared.connectWallet.bind(null);
    this.checkNFT = null;
    shared.checkNFT.bind(null);
    this.handleErrorAndGoTop = null;
    shared.handleErrorAndGoTop.bind(null);
    this.getChainParamFromChainId = null;
    shared.getChainParamFromChainId.bind(null);
  },
  mounted() {
    // 無限スクロールイベントの登録
    window.addEventListener("scroll", this.onScroll);
    //
    this.initMoralis = shared.initMoralis.bind(this);
    this.connectWallet = shared.connectWallet.bind(this);
    this.checkNFT = shared.checkNFT.bind(this);
    this.getChainParamFromChainId = shared.getChainParamFromChainId.bind(this);
    this.handleErrorAndGoTop = shared.handleErrorAndGoTop.bind(this);
    let _this = this;

    this.apiRequest = axios.CancelToken.source();

    // Set membership Id by url query
    if (this.$route.query.membershipId) {
      this.membershipId = this.$route.query.membershipId;
    } else {
      // Go back to top
      this.handleErrorAndGoTop = shared.handleErrorAndGoTop.bind(_this);
      this.handleErrorAndGoTop();
      return;
    }

    // 0. Token Address,Token ID,MembershipをsessionStorageに保存
    this.tokenAddress_ = this.$route.query.tokenAddress;
    this.tokenId_ = this.$route.query.tokenId;
    this.isFT = this.$route.query.isFT;
   
    if (this.tokenAddress_ && this.membershipId && (this.isFT || (!this.isFT && this.tokenId_))) {
      sessionStorage.removeItem(this.LOCAL_STORAGE_KEY_MEMBERSHIP);
      sessionStorage.setItem(
        this.LOCAL_STORAGE_KEY_MEMBERSHIP,
        JSON.stringify({
          tokenAddress: this.tokenAddress_,
          tokenId: this.tokenId_,
          name: "",
          isFT: this.isFT
        })
      );
    } else {
      let membershipData = sessionStorage.getItem(
        this.LOCAL_STORAGE_KEY_MEMBERSHIP
      );
    
      let membershipObj = JSON.parse(membershipData);
      this.tokenAddress_ = membershipObj.tokenAddress;
      this.tokenId_ = membershipObj.tokenId;
      this.isFT = membershipObj.isFT;
    }

    // 1. 指定されたtoken addressとtokenIdから、Moralis経由でオーナーのウォレットアドレスを取得
    // 2. 上記で取得したウォレットアドレスが、Metamaskのウォレットアドレスと一致するかを確認
    // 3. 一致するようであれば、ワンタイムパスワード（定期的に更新する）とmembershipIdをクエリに添えて、membership情報をAPI経由で取得する
    let membershipData = sessionStorage.getItem(
      this.LOCAL_STORAGE_KEY_MEMBERSHIP
    );
    let membershipObj = JSON.parse(membershipData);
    if (!membershipObj) {
      this.$router.push("/");
      return;
    }

    /* Init Moralis */
    this.initMoralis(
      this.MORALIS_SERVER_URL,
      this.MORALIS_APP_ID,
      this.MORALIS_MASTER_KEY
    );

    /* Connect to wallet */
    this.connectWallet((err, event, walletData) => {
      if (err) {
        console.error("wallet likely disconnected", event, walletData);
        _this.handleErrorAndGoTop(
          "Wallet Disconnected",
          "Please login again",
          3000
        );
        return;
      }
      if (event === "accountsChanged") {
        // TOP以外は、ウォレットを切り替えたらTOPページに戻る
        if (_this.$route.path !== "/") {
          _this
            .$swal({
              title: "Wallet Changed",
              text: `You need to login again`,
              type: "warning",
              showCancelButton: false,
              showConfirmButton: false,
              timer: 3000,
            })
            .then(() => {
              _this.$router.push("/");
            });
          return;
        }
      }
      let walletObj = JSON.parse(walletData);
      if (!walletObj.walletAddress || !walletObj.chainId) {
        return;
      }
      _this.walletAddress = walletObj.walletAddress;
      var chain = _this.getChainParamFromChainId(walletObj.chainId);
      if (!chain) {
        _this.handleErrorAndGoTop(
          "Sorry, something went wrong",
          "Please login again",
          3000
        );
        return;
      }
      _this.chainName = chain;

      //
      _this.showSpinner = true;
      _this.loadProfile(_this.walletAddress, _this.membershipId, (err) => {
        if (err) {
          alert("Failed to load your profile");
          _this.showSpinner = false;
          return;
        }
        // ユーザーの保有NFTに所定のToken Address/Token IDが含まれているかをチェック
        _this.checkNFT(
          `${chain}`,
          _this.walletAddress,
          _this.tokenAddress_,
          _this.tokenId_,
          function (isValidTokenExist) {
            // Needs to set this.isValidTokenExist flag to show content
            _this.isValidTokenExist = isValidTokenExist;
            // Hide spinner
            _this.showSpinner = false;
            if (!_this.isValidTokenExist) {
              _this
                .$swal({
                  title: "Required NFT not found - code 106",
                  text: "You have no memebership NFT for viewing this page",
                  type: "warning",
                  showCancelButton: false,
                  confirmButtonText: "Start over",
                })
                .then((result) => {
                  if (result.value) {
                    _this.$router.push("/");
                  }
                });
              return;
            }

            // Load membership info
            _this.loadMembership(_this.membershipId);

            // Load products
            _this.getProducts(_this.membershipId);

            // Load posts
            let doRefresh = true;
            _this.loadPosts(_this.membershipId, doRefresh, (err, newPosts) => {
              if (err) {
                _this.handleError_ = shared.handleError.bind(_this);
                _this.handleError_();
                return;
              }
              _this.posts = [...newPosts];
              // _this.renderPosts(container_, newPosts, doRefresh);
            });

            // Show rank
            _this.getAndRenderRank(_this.membershipId);
          }
        );
      });

      /* Check Stripe KYC status to show/hide Sell button*/
      this.getKycStatus(_this.membershipId, _this.walletAddress);
    });


  },
  props: {
    nftImageUrl: {
      type: String,
    },
  },
  inject: {
    MORALIS_SERVER_URL: {
      from: "MORALIS_SERVER_URL",
    },
    MORALIS_APP_ID: {
      from: "MORALIS_APP_ID",
    },
    MORALIS_MASTER_KEY: {
      from: "MORALIS_MASTER_KEY",
    },
    API_BASE_URL: {
      from: "API_BASE_URL",
    },
    LOCAL_STORAGE_KEY_MEMBERSHIP: {
      from: "LOCAL_STORAGE_KEY_MEMBERSHIP",
    },
    LOCAL_STORAGE_KEY_LIKES: {
      from: "LOCAL_STORAGE_KEY_LIKES",
    },
    API_ENDPOINT_MEMBERSHIP: {
      from: "API_ENDPOINT_MEMBERSHIP",
    },
    API_ENDPOINT_POSTS: {
      from: "API_ENDPOINT_POSTS",
    },
    API_ENDPOINT_USER: {
      from: "API_ENDPOINT_USER",
    },
    API_ENDPOINT_POST: {
      from: "API_ENDPOINT_POST",
    },
    API_ENDPOINT_OGP: {
      from: "API_ENDPOINT_OGP",
    },
    API_ENDPOINT_REPORT: {
      from: "API_ENDPOINT_REPORT",
    },
    API_ENDPOINT_CREATE_LIKE: {
      from: "API_ENDPOINT_CREATE_LIKE",
    },
    API_ENDPOINT_DELETE_LIKE: {
      from: "API_ENDPOINT_DELETE_LIKE",
    },
    API_ENDPOINT_DELETE_POST: {
      from: "API_ENDPOINT_DELETE_POST",
    },
    API_ENDPOINT_RANK: {
      from: "API_ENDPOINT_RANK",
    },
    API_STRIPE_PRODUCTS: {
      from: "API_STRIPE_PRODUCTS"
    },
    API_ENDPOINT_GET_STRIPE_ACCOUNT: {
      from: "API_ENDPOINT_GET_STRIPE_ACCOUNT"
    },
    API_ENDPOINT_GET_STRIPE_ACCOUNT_ID: {
      from: "API_ENDPOINT_GET_STRIPE_ACCOUNT_ID"
    },
    DEFAULT_URL_TRANSPARENT_IMAGE: {
      from: "DEFAULT_URL_TRANSPARENT_IMAGE",
    },
    API_ENDPOINT_KYC_STATUS: {
      from: "API_ENDPOINT_KYC_STATUS"
    }
  },
  components: {
    MainHeader,
    MainFooter,
    OverlayPost,
    OverlayComment,
    OverlaySpinner,
    OverlayImage,
    Popper,
    PostCell,
    ShopCell,
  },
  methods: {
    handleDeleteProduct(data) {
     this.showSpinner = data.loading;
     if(data?.productId) {
      this.shopItems = this.shopItems.filter((x) => x.productId !== data.productId)
     }
    },

    onScroll: function () {
      let _this = this;

      // Timelineページでなれば無限スクロールの必要がない、Skip
      let lastUrlPath = window.location.pathname.split("/").pop();
      if (lastUrlPath != "timeline") {
        return;
      }

      //
      var isScrollingDownward = false;
      var st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > this.lastScrollTop) {
        isScrollingDownward = true;
      } else {
        isScrollingDownward = false;
      }
      this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling

      //
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        // you're at the bottom of the page
        if (isScrollingDownward) {
          if (!_this.isLoadingPosts) {
            _this.isLoadingPosts = true;
            // Load posts
            let doRefresh = false;
            _this.loadPosts(_this.membershipId, doRefresh, (err, newPosts) => {
              _this.isLoadingPosts = false;

              _this.posts = _this.removeDuplicates(
                [..._this.posts, ...newPosts],
                "created_at"
              );

              // _this.posts = [..._this.posts, ...newPosts];
            });
          }
        }
      }
    },
    removeDuplicates: function (arr, prop) {
      const uniqueArray = [];
      const seen = new Set();

      for (const item of arr) {
        if (!seen.has(item[prop])) {
          uniqueArray.push(item);
          seen.add(item[prop]);
        }
      }
      return uniqueArray;
    },
    loadProfile: function (walletAddress, membershipId, callback) {
      let _this = this;
      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_USER;
      axios
        .get(apiUrl, {
          params: {
            walletAddress: walletAddress,
            membershipId: membershipId,
          },
        })
        .then((result) => {
          let dataObj = JSON.parse(JSON.stringify(result.data));
          if (dataObj.profile_image_url) {
            _this.userProfileImageUrl = dataObj.profile_image_url;
          }
          if (dataObj.name) {
            _this.userName = dataObj.name;
          }
          callback();
        })
        .catch((err) => {
          console.error(err);
          callback(err);
        });
    },
    loadMembership: function (membershipId) {
      let _this = this;

      // Show loader
      _this.didLoadBulletinImage = false;

      // Membershipの基本情報とBulletinとデータを取得
      let apiUrl = _this.API_BASE_URL + _this.API_ENDPOINT_MEMBERSHIP;
      axios
        .get(apiUrl, {
          params: {
            membershipId: membershipId,
            walletAddress: this.walletAddress,
          },
        })
        .then((result) => {
          let dataObj = JSON.parse(JSON.stringify(result.data));
          if (dataObj.bulletin) {
            let bulletin = dataObj.bulletin;
            _this.bulletinTitle = bulletin.title;
            _this.bulletinText = bulletin.text;
            let date = new Date(bulletin.created_at);
            let agoDate = moment(
              date,
              "ddd MMM DD YYYY HH:mm:ss GMT Z"
            ).fromNow();
            _this.bulletinTimestamp = agoDate;
            _this.bulletinLink = bulletin.external_url;
            if (bulletin.image_url === _this.DEFAULT_URL_TRANSPARENT_IMAGE) {
              _this.bulletinImageUrl = "";
            } else {
              _this.bulletinImageUrl = bulletin.image_url;
            }
            // Bulletin Linkが設定されており、かつ、Bulletin Imageがない場合は、OGP画像を設定
            if (!_this.bulletinImageUrl && _this.bulletinLink) {
              _this.getOgp = shared.getOgp.bind(_this);
              _this.getOgp(_this.bulletinLink, () => {});
            }
          }
          // Hide bulletin loader
          _this.didLoadBulletinImage = true;
          // Set isAdmin
          _this.isAdmin = dataObj.is_admin;
          let name = dataObj.name;
          let coverImageUrl = dataObj.cover_image_url;
          _this.title = name;
          _this.coverImageUrl = coverImageUrl;
          // Hide cover image loader
          _this.didLoadCoverImage = true;
          // LocalにcacheしたMembershipデータのname（ヘッダーのタイトルになる）を設定
          let membershipObj_ = JSON.parse(
            sessionStorage.getItem(_this.LOCAL_STORAGE_KEY_MEMBERSHIP)
          );
          if (membershipObj_) {
            membershipObj_.name = _this.title;
            sessionStorage.setItem(
              _this.LOCAL_STORAGE_KEY_MEMBERSHIP,
              JSON.stringify(membershipObj_)
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    loadPosts: function (membershipId, doRefresh, callback) {
      let _this = this;
      if (doRefresh) {
        this.lastTimestamp = null;
      }
      if (!this.lastTimestamp) {
        this.lastTimestamp = Date.now();
      }
      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_POSTS;
      axios
        .get(apiUrl, {
          params: {
            membershipId: membershipId,
            walletAddress: this.walletAddress,
            lastTimestamp: this.lastTimestamp,
          },
          cancelToken: _this.apiRequest.token,
        })
        .then((result) => {
          if (result.status != 200) {
            callback(result.status);
          } else {
            let dataObj = JSON.parse(JSON.stringify(result.data));
            let posts = dataObj.posts;
            let posts_ = dataObj.posts;
            if (!posts_.length || posts_.length <= 0) {
              callback(null, posts);
              return;
            }
            let lastObject = posts_.reduce(function (prev, curr) {
              return prev.created_at < curr.created_at ? prev : curr;
            });
            if (lastObject) {
              _this.lastTimestamp = lastObject.created_at;
            }
            _this.posts.push(...posts);
            callback(null, posts); // return only new posts
          }
        })
        .catch((err) => {
          callback(err, null);
        });
    },
    renderPosts: function (parent, posts, doRefresh) {
      if (!parent) {
        if (!this.shoudSkipRenderPosts) {
          this.$swal({
            title: "Sorry, something went wrong",
            text: "Please try again. error code: 2001",
            type: "warning",
            showCancelButton: false,
            confirmButtonColor: "#AAB8C2",
            timer: 1500,
          }).then((result) => {
            if (result.value) {
              window.location.reload();
            }
          });
        }
        this.shoudSkipRenderPosts = true;
        return;
      } else {
        this.shoudSkipRenderPosts = false;
      }
      if (doRefresh) {
        // Remove all posts
        var child = parent.lastElementChild;
        while (child) {
          parent.removeChild(child);
          child = parent.lastElementChild;
        }
      }
      // Add event lister to clicking link on post cell
      var elements = document.getElementsByClassName("post-link-object");
      for (var i = 0; i < elements.length; i++) {
        // 無限ロード時に重複してイベント登録するため、まずはイベントをここで削除し、その後再登録する
        elements[i].removeEventListener("click", this.openLink);
        elements[i].addEventListener("click", this.openLink);
      }
    },
   async getAndRenderRank(membershipId) {
      var parent = document.getElementById("rank-container");

      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_RANK;
      let _this = this;
      this.isLoadingHighEngagementUser = true;
     await  axios
        .get(apiUrl, {
          params: {
            membershipId: membershipId,
          },
        })
        .then((result) => {
          let dataObj = JSON.parse(JSON.stringify(result.data));
          let rankArray = dataObj.rank;
          // Set show/hide flag of user ranking
          this.isLoadingHighEngagementUser = false;
          if (rankArray.length > 0) {
            _this.showUserRanking = true;
          } else {
            _this.showUserRanking = false;
          }
          //
          rankArray.forEach((data, index) => {
            let rankObj = JSON.parse(JSON.stringify(data));
            let rank = index + 1; // indexは0から始まるので
            const props = reactive({
              walletAddress: rankObj.walletAddress,
              membershipId: membershipId,
              rank: rank,
              apiBaseUrl: this.API_BASE_URL,
              apiEndpointUser: this.API_ENDPOINT_USER,
              axiosPlugin: _this.$axios,
            });
            const comp = h(RankCell, {
              onClickRankCell: (value) => {
                _this.goProfile(_this.membershipId, value);
              },
            });
            var Cell = createApp(comp, props);
            const wrapper = document.createElement("div");
            Cell.mount(wrapper);
            parent.append(wrapper);
          });
        })
        .catch((err) => {
          console.error(err);
          this.isLoadingHighEngagementUser = false;
        });
    },
    goProfile: function (membershipId, value) {
      this.$router.push({
        name: "profile",
        query: { membershipId: membershipId, user: value },
      });
    },
    openLink: function (event) {
      event.stopPropagation();
      if (event.target.innerText) {
        window.open(event.target.innerText, "_blank").focus();
      }
    },
    showPostView: function () {
      this.userData.walletAddress = this.walletAddress;
      this.userData.userName = this.userName;
      this.userData.profileImageUrl = this.userProfileImageUrl;
      this.showOverlayPost = true;
    },
    showSellView: function () {
      this.$router.push({
        name: "sell",
        query: {
          membershipId: this.membershipId,
        },
      });
    },
    getKycStatus:function(membershipId, walletAddress) {
      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_GET_STRIPE_ACCOUNT;
      axios
      .get(apiUrl, {
        params: {
          membershipId: membershipId,
          walletAddress: walletAddress,
        },
      })
      .then((result) => {
        let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_KYC_STATUS;
        axios
        .get(apiUrl, {
          params: {
            accountId: result.data.account_id,
          },
          cancelToken: this.apiRequest.token,
        })
        .then((result) => {
          if (result.data.payouts_enabled && result.data.charges_enabled) {
            this.kycCompleted = true;
          } else {
            this.kycCompleted = false;
          }
        })
        .catch((err) => {
          console.error("KYC status err", err);
        });
      })
      .catch((err) => {
        console.error("Stripe account err", err);
      });
    },
    getProducts: function(membershipId) {
      this.isLoadingShop = true;
      // Fetch stripe account id
      // let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_GET_STRIPE_ACCOUNT;
      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_GET_STRIPE_ACCOUNT_ID;
      axios
      .get(apiUrl, {
        params: {
          membershipId: membershipId,
        },
      })
      .then((result) => {
        if (result.data.account_id) {
          // Fetch products
          apiUrl = this.API_BASE_URL + this.API_STRIPE_PRODUCTS;
          axios
          .get(apiUrl, {
            params: {
              membershipId: membershipId,
              accountId: result.data.account_id
            },
          })
          .then((result) => {
            this.isLoadingShop = false;
            this.shopItems = [];

            for (const data of result.data) {
              let product = data.product;
              let prices = data.prices;
              
              //
              // TODO: ここでデータバリデーションを追加し、データが不正の商品は非表示
              //

              if (prices?.length > 0) {
                let price = prices[0];
                let unitAmount = price.unit_amount;
                let currency = price.currency;

                let item = {
                  name: product.name,
                  description: product.description,
                  isPublished: true,
                  price: unitAmount,
                  currency: currency,
                  imageUrls: product.images,
                  productId: product.id,
                  priceId: price.id,
                  inventory: data.inventory
                } 

                // console.log("item", item);

                this.shopItems.push(item);
              }
            }
          })
          .catch((err) => {
            console.error(err);
            this.isLoadingShop = false;
          });

        } else {
          this.isLoadingShop = false;

          //
          // TODO: Handle error
          //
        }
      })
      .catch((err) => {
        console.log("load shop err", err);
        this.isLoadingShop = false;
      });
    },
    onCloseOverlayPost: function () {
      this.showOverlayPost = false;
    },
    onCloseOverlayImage: function () {
      this.showOverlayImage = false;
      this.shopDetail = [];
    },
    onDismissOverlayPost: function () {
      this.showOverlayPost = false;
    },
    onClickCommentEvent: function (value) {
      console.log("click comment event", value);

      // Set comment view data
      this.commentTargetPostData.postId = value.postId;
      this.commentTargetPostData.walletAddress = value.walletAddress;
      this.commentTargetPostData.userName = value.userName;
      this.commentTargetPostData.profileImageUrl = value.profileImageUrl;
      this.commentTargetPostData.userMessage = value.userMessage;
      this.commentTargetPostData.ago = value.ago;
      this.commentTargetPostData.comments = value.comments;
      this.commentTargetPostData.likes = value.likes;
      this.commentTargetPostData.postImageUrl = value.postImageUrl;
      this.userData.walletAddress = this.walletAddress;
      this.userData.userName = this.userName;
      this.userData.userProfileImageUrl = this.userProfileImageUrl;
      this.ogpData.imageUrl = value.ogpImageUrl;
      this.ogpData.title = value.ogpTitle;
      this.ogpData.description = value.ogpDescription;
      this.ogpData.url = value.ogpUrl;

      // Show comment view
      this.showOverlayComment = true;
    },
    onHandleReport: function (value) {
      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_REPORT;
      axios
        .post(apiUrl, value)
        .then(() => {
          this.$swal({
            position: "top-right",
            icon: "success",
            title: "post is reported.",
            type: "success",
            showCancelButton: false,
            confirmButtonText: "OK",
            iconColor: "#000",
            timer: 1500,
          });
        })
        .catch(() => {
          this.$swal({
            title: "Sorry, something went wrong",
            text: `Please try again`,
            type: "warning",
            showCancelButton: false,
            showConfirmButton: true,
            timer: 2500,
          });
        });
    },
    onSuccessDeletePost: function (value) {
      this.$swal({
        position: "top-right",
        icon: "success",
        title: "Post is deleted.",
        type: "success",
        showCancelButton: false,
        confirmButtonText: "OK",
        iconColor: "#000",
        timer: 1500,
      });
      this.posts = this.posts.filter((object) => {
        return object.id !== value;
      });
    },
    onFailDeletePost: function () {
      console.error("onFailDeletePost");
      this.$swal({
        title: "Sorry, something went wrong",
        text: `Please try again`,
        type: "warning",
        showCancelButton: false,
        showConfirmButton: true,
        timer: 2500,
      });
    },
    onCloseOverlayComment: function () {
      this.commentTargetPostData = {}; // !!: this is required to show comments on OverlayComment if the same comment selected repeadedtly
      if (this.$route.query.postId) {
        this.willHideOverlay = true;
        this.showOverlayComment = false;
        this.$router.push({
          name: "timeline",
          query: {
            membershipId: this.membershipId,
          },
        });

        // Cancel axios requests (only GET profile request)
        this.apiRequest.cancel("api_request_canceled");
      } else {
        this.showOverlayComment = false;
      }
    },
    onDidLikePost: function (values) {
      // FIXME: 毎回ロードするのは通信コストが高いため、ローカル上でUIを変更するようにする
      // update cell prop to sync data
      const find = this.posts.findIndex((item) => item.id === values.post_id);
      if (find !== -1) {
        const updatedArray = this.posts.map((item, index) => {
          if (index === find) {
            const listLikes = [...item.likes, this.walletAddress];
            const newListLikes = Array.from(new Set(listLikes));
            return {
              ...item,
              ...{
                likes: newListLikes,
              },
            };
          }
          return item;
        });
        this.posts = [...updatedArray];
      }
    },
    onDidDeleteLikePost: function (values) {
      // 毎回ロードするのは通信コストが高いため、ローカル上でUIを変更するようにする
      // update cell prop to sync data
      const find = this.posts.findIndex((item) => item.id === values.post_id);
      if (find !== -1) {
        const updatedArray = this.posts.map((item, index) => {
          if (index === find) {
            const listLikes = item.likes.filter(
              (item) => item !== this.walletAddress
            );
            return {
              ...item,
              ...{
                likes: listLikes,
              },
            };
          }
          return item;
        });
        this.posts = [...updatedArray];
      }
    },
    onShopImageClick(imageUrl, itemShop) {
      if (!imageUrl) return;
      const images = [...itemShop.imageUrls];
      const index = images.indexOf(imageUrl);
      console.log(imageUrl);
      console.log(images);
      console.log(index);

      if (index !== -1) {
        images.splice(index, 1); // Remove the element
        images.unshift(imageUrl); // Insert the element at the beginning
      }
      const listImage = [...images];
      this.shopDetail = listImage;
      this.showOverlayImage = true;
    },
    closeCta: function () {
      if (this.isShowCta) {
        this.isShowCta = false;
      }
    },
    goEngagementRankingPage: function (membershipId) {
      this.$router.push({
        name: "engagement-rank",
        query: { membershipId: membershipId },
        params: { walletAddress: this.walletAddress },
      });
    },
    goShopPage: function(membershipId) {
      console.log("go shop page", membershipId);

    },
    addNewPost(data) {
      let newPosts = this.posts.slice();
      newPosts.push(data);
      newPosts.sort(function (x, y) {
        return y.created_at - x.created_at;
      });
      this.posts = newPosts;
      const firstScrollTo = scroller();
      firstScrollTo("#posts-container", { offset: -180 });
    },
  },
});
</script>
