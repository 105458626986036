import { createI18n } from 'vue-i18n'
// File translate
import enLocale from './locale/en';
import jaLocale from './locale/ja';

const messages = {
  en: enLocale,
  ja: jaLocale,
};

const getLanguage = () => {
  const  userLang = navigator.language || navigator.userLanguage; 
  
  return userLang === 'ja' ? 'ja' : 'en'
};

const i18n = createI18n({
  locale: getLanguage(),
  messages,
});
export default i18n;