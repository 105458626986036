<template>
  <div
    class="membership-cell-container card"
    :class="{ active: isValid }"
    @click="clickMembershipEvent"
  >
    <img
      :src="state.coverImageUrl"
      class="card-img-top"
      alt="..."
      @error="imageLoadError"
    />
    <div class="card-body">
      <p class="issuer-name">{{ issuerName }}</p>
      <p class="membership-title">{{ tokenName }}</p>
      <div class="container-content-address">
        <p class="membership-contract-address">
          {{ i18n.global.t('MEMBER_SHIPCELL.TOKEN_ADDRESS') }}: {{ renderTokenAddress }}
        </p>
        <div @click="copyClipboard" class="icon-copy"></div>
      </div>
      <div  class="container-content-address">
        <p v-if="!isFT" class="membership-token-id">{{ i18n.global.t('MEMBER_SHIPCELL.TOKEN_ID') }}: {{ tokenId }}</p>
        <p v-if="isFT" class="balance">{{ i18n.global.t('MEMBER_SHIPCELL.BALANCE') }}: {{ balance }}</p>
        <div class="bottom-section">
          <div v-if="isValid" class="icon-people"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.membership-cell-container {
  position: relative;
  height: 370px;
  width: 200px;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
  text-align: left;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.membership-cell-container img {
  width: 100%;
  height: 200px;
}
.membership-cell-container.active {
  opacity: 1;
}
.card-body {
  padding-left: 10px;
  padding-right: 10px;
}
.membership-token-id {
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  white-space: nowrap;
}
.balance {
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  white-space: nowrap;
}
.container-content-address {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.membership-contract-address {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto;
  font-size: 12px;
}
.issuer-name {
  margin-top: 4px;
  font-weight: 700;
  font-size: 0.7em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.membership-title {
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.membership-count {
  font-weight: 500;
  color: #fff;
}
.label-admin {
  padding: 6px 12px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  color: #fff;
  background-color: #ff6767;
}
.icon-people {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-image: url("@/assets/img/people.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.icon-copy {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-image: url("@/assets/img/icon-copy.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.upper-section {
  position: relative;
  text-align: center;
}
.bottom-section {
  /* position: absolute; */
  /* bottom: 13px; */
  /* right: 5px; */
  width: inherit;
  display: flex;
  padding: 4px;
  justify-content: space-between;
  align-items: center;
}
.bottom-section button {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: none;
  background-color: #333;
  background-image: url("@/assets/img/icon-gear.png");
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
}
</style>

<script>
import { defineComponent, reactive, onMounted } from "vue";
import axios from "axios";
import i18n from "@/language";
export default defineComponent({
  name: "MembershipCell",
  data() {
    return {
      isMembershipExist: false,
      i18n,
    };
  },
  setup: (props) => {
    const state = reactive({
      data: null,
      coverImageUrl: require("@/assets/img/image_placeholder.png"),
    });
    // console.log("props", props);
    if (!props.tokenUri) {
      console.error("token uri is null", props);
      //
      // REVIEW：いちいちエラーで止まるのでアラートを非表示
      //
      // alert("Sorry, something went wrong. Please try again");
      // return;
    }
    // 非同期でTokenURIから画像データを取得し、セル背景画像にセット
    async function getThumbnail() {
      try {
        if(props.isFT) {
          state.coverImageUrl = props.tokenUri;
          return;
        }
        state.data = await axios.get(props.tokenUri); 
        let imageUrl = state.data.data.image;
        if (!state.data || !imageUrl) {
          console.error("token uri reponse is null");
          //
          // REVIEW：いちいちエラーで止まるのでアラートを非表示
          //
          // alert("Sorry, something went wrong. Please try again - error code: 1002");
          return;
        }
        if (imageUrl.startsWith("ipfs")) {
          // IPFSの場合はURLを変換
          // const ipfsAddress = imageUrl.split("/").pop();
          let urlElementArray = imageUrl.split("/");
          let ipfsAddress = urlElementArray.slice(1).join("/").toString();
          state.coverImageUrl = "https://ipfs.io/ipfs" + ipfsAddress;
        } else {
          state.coverImageUrl = imageUrl;
        }
      } catch (e) {
        // Too much error on console
        // console.error(e);
      }
    }
    onMounted(() => {
      getThumbnail();
    });
    return {
      state,
      getThumbnail,
    };
  },
  props: {
    issuerName: {
      type: String,
      default: "",
    },
    tokenName: {
      type: String,
      default: "",
    },
    memberCount: {
      type: String,
      default: "0",
    },
    tokenUri: {
      type: String,
      default: "@/assets/img/cover.png",
    },
    tokenAddress: {
      type: String,
      default: "",
    },
    tokenId: {
      type: String,
      default: "",
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    membershipId: {
      type: String,
      default: "-1",
    },
    chain: {
      type: String,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    API_BASE_URL: {
      type: String,
    },
    API_ENDPOINT_MEMBERSHIP_ID: {
      type: String,
    },
    isFT: {
      type: Boolean,
      default: false
    },
    balance: {
      type: String,
      default: '0'
    },
  },
  computed: {
    renderTokenAddress() {
      return `${this.tokenAddress.substring(0, 16)}...`;
    },
  },
  emits: [
    "click-membership-event",
    "click-membership-setting-event",
    "click-membership-clipboard",
  ],
  methods: {
    imageLoadError() {
      event.target.src = require("@/assets/img/image_placeholder.png");
    },
    clickMembershipSettingEvent: function () {
      this.$emit("click-membership-setting-event", this.membershipId);
    },
    copyClipboard: function (event) {
      event.stopPropagation();
      navigator.clipboard.writeText(this.tokenAddress);
      this.$emit("click-membership-clipboard");
    },
    clickMembershipEvent: function () {
      var values = {
        membershipId: this.membershipId,
        profileImageUrl: this.state.coverImageUrl,
      };
      this.$emit("click-membership-event", values);
    },
  },
});
</script>
