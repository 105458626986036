export default {
  BUTTON: {
    CANCEL: "Cancel",
    POSTING: "Posting..",
    POST: "POST",
    REPORT: "Report",
    DELETE: "Delete",
    POST_NOMAL: "Post",
    KEEP_WRITING: "Keep writing",
    QUIT_WRITING: "Quit writing",
    NEXT: "Next",
    BACK: "Back",
    UPDATE: "Update",
    LOGOUT: "Logout",
    CANCEL_UPPER: "CANCEL",
    SAVE_UPPER: "SAVE",
    GO_DASHBOARD: "Go to Dashboard",
    PAY: 'Pay'
  },
  TOP_VIEW: {
    USE_POLYGON_ETHER: "*We currently support Ethereum and Polygon",
    WHAT_DEEPBEACH: "What is DeepBeach?",
    CONNECTING: "Connecting...",
    DISCONNECT: "Disconnect",
    NFT_COMMUNITY: "Enter Token Community",
    SWITCH_NETWORK: "Switch to Supported Network",
    TO_POLYGON: "to Polygon Network",
    TO_ETHEREUM: "to Ethereum Network",
    NO_NFT: "No tokens are available in your wallet",
    POPUP: {
      TITLE_CONNECT_METAMASK: "Connect on MetaMask",
      PLEASE_CHECK_METAMASK: "Please check MetaMask.",
      PLEASE_RELOAD_PAGE:
        "If nothing happens for a while, maybe something went wrong. Please reload the page.",
      TITLE_DO_HAS_METAMASK: "Please Connect your wallet👛",
      CONTENT_DO_HAS_METAMASK: "Please connect your wallet and join DeepBeach！⛱️",
        // "<div style='text-align: left;'><div><b>If you have MetaMask installed:</b> Please check it.</div><br/><div><b>If not:</b> Please use the browser with MetaMask support.<br/> <div style='background-color: #eee; font-size: 16px; margin-top: 12px;'> <ul style='list-style-type: disc;'><li>PC/Mac →Use <a href='https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn' target='_blank'>MetaMask Extension</a></li><li>Mobile(iOS) →Download <a href='https://apps.apple.com/app/metamask-blockchain-wallet/id1438144202' target='_blank'>MetaMask App</a></li> <li>Mobile(Android) →Download <a href='https://play.google.com/store/apps/details?id=io.metamask&gl=US' target='_blank'>MetaMask App</a></li></ul></div></div></div>",
      TITLE_NEED_APPROVE_METAMASK: "You need to approve request on MetaMask",
      CONTENT_NEED_APPROVE_METAMASK:
        "Please click 'Connect Wallet' button and approve the request.",
      BTN_RELOAD_PAGE: "Reload page",
      BTN_STAY_PAGE: "Stay on page",
      TITLE_USE_POLUGON_ETHER: "Currently we support Ethereum & Polygon Chain",
      CONTENT_USE_POLUGON_ETHER: "Please switch to supported chain",
      TITLE_SOMETHING_WRONG_1006:
        "Sorry, something went wrong - error code: 1006",
      CONTENT_SOMETHING_WRONG_1006: "Please try again.",
      TITLE_REGISTER_MEMBERSHIP_403: "Sorry, unsupported NFT",
      CONTENT_REGISTER_MEMBERSHIP_403:
        '" style="width:60px;height:60px;"><div> If the NFT is created with some NFT marketplaces like OpenSea or Rarible, those NFT cannot create membership page. It is because they have the same contract address and not being able to identify as unique NFT collection. </div>',
      TITLE_REGISTER_MEMBERSHIP_405: "Sorry, something went wrong",
      CONTENT_REGISTER_MEMBERSHIP_405:
        "This membership page is currently going through some technical issues. Please come back later.",
    },
  },
  POPUP: {
    TITLE_OTHER_ERROR: "Sorry, something went wrong",
    CONTENT_OTHER_ERROR: "Please try again",
    TITLE_COMMON_ERROR: "Sorry, something went wrong",
    ERR_1001_CONTENT: "Please try again. error code: 1001",
    TITLE_TOKEN_COPIED: "Token address is copied to clipboard!",
    TITLE_DRAFT: "Are you sure?",
    CONTENT_DRAFT: "Are you sure?",
    PLEASE_LOGIN_AGAIN: "Please login again",
    TITLE_WALLET_DISCONNECT: "Wallet Disconnected",
    TITLE_SUPPORT_NETWORK: "Currently we support Ethereum and Polygon",
    CONTENT_SUPPORT_NETWORK: "Please switch to Ethereum and Polygon chain",
    TITLE_NOT_FOUND_NFT: "Required NFT not found - code 103",
    CONTENT_NOT_FOUND_NFT: "You have no memebership NFT for viewing this page",
    CONTENT_NEW_POST_SELL:
      ' The members now can see the product on "Shop" section on the timeline🛍',
    TITLE_WALLET_DISCONNECTED: "Wallet Disconnected",
    TITLE_WALLET_CHANGED: "Wallet Changed",
    NEED_TO_LOGIN: "You need to login again",
    TITLE_REQUIRED_NFT_CODE_104: "Required NFT not found - code 104",
    CONTENT_REQUIRED_NFT_CODE_104:
      "You have no memebership NFT for viewing this page",
    TITLE_COPY_SHARED_SUCCESS: 'Share URL copied to clipboard!',
    PLEASE_TRY_AGAIN: 'Please try again',
    TITLE_OUT_OF_STOCK: 'Sorry, the item is out of stock',
    TITLE_PAYMENT_SUCCESS: 'Payment Succeeded',
    CONTENT_PAYMENT_SUCCESS: '<p>The seller will be in touch with you.</p> <p>For enquiry, contact <a href="mailto: {contact}">{contact}</a></p>'
  },
  MEMBER_SHIPCELL: {
    TOKEN_ADDRESS: "Token Address",
    TOKEN_ID: "Token ID",
    BALANCE: 'Balance'
  },
  TIMELINE: {
    CONTACT_US: "Contact Us",
    CONTACT_US_EXPLAIN:
      "for adding more admin members who can set cover image and news of this membership page.",
    NEWS: "News",
    EXTERNAL_LINK: "External Link >",
    TOP_MEMBER: "High Engagement Members",
    SEE_MORE: " See More >",
    WHAT_MEMBER_SAY: "What Members Say",
    THE_FIRST_MEMBER_SAY: "Be the 1st to post on this membership!",
  },
  SHOP_CELL: {
    BUY: "Buy",
    CONFIRM_DEL_TITLE: "Are you sure deleting this product?",
    CONFIRM_DEL_BODY: "You cannot undo this operation.",
    DELETE_ITEM_SUCCESS: 'Product is deleted.',
  },
  OVERLAY_POST: {
    CHARACTER: "Characters",
    IMAGE_LIMIT: "You can use a image upto 2MB",
    PLACEHOLDER_POST_TXT: "Share what you think",
  },
  SELL_VIEW: {
    SELL_ITEM: "SELL ITEM",
    PLEASE_NOTE: "Please Note",
    SELL_GUIDE:
      " You must not list items with tangible or intangible speculative elements for sale. If you violate this policy, your account may be suspended.",
    ITEM_NAME: "Item Name",
    ITEM_NAME_PLACEHOLDER: "Enter Item Name",
    ITEM_NAME_INVALID: "Item Name is required",
    DESCRIPTION: "Description",
    DESCRIPTION_PLACEHOLDER: "Enter Item Description",
    DESCRIPTION_INVALID: "Item Description is required",
    IMAGE: "Images",
    VALIDATE_IMAGE: "You need to set at least one image.",
    CURRENCY: "Currency",
    CURRENCY_PLACEHOLDER: "Select Currency",
    CURRENCY_INVALID: "Please select currency",
    PRICE_INCLUDE_TAX: "Price(tax inc.)",
    PRICE_INCLUDE_TAX_INVALID: "Please set price",
    STOCK: "Stock",
    STOCK_INVALID: "Please set stock",
    PROCEES_TO_PUBLISH: "Proceed to publish",
    READY_PUBLISH: "Ready to Publish?",
    STOCK_UNDERFINED: "Stock: Infinite",
    BTN_PUBLISH: "Publish",
    BTN_FIX_INFO: "Fix information",
  },
  CONFIRM: {
    LEAVING_PAGE: "You did not publish product. Are you sure leaving page?",
  },
  NOTIFICATION: {
    NO_NOTIFICATION: "No notifications yet",
  },
  PROFILE_SETTING: {
    CREATE_MEMBERSHIP: " Create Membership",
    MEMBERSHIP_QUOTA: "*Free plan can make upto 2 memberships",
    BTN_UPGRADE_PLAN: "Upgrade Plan",
    UPLOAD_IMG_LIMIT: "You can use a image upto 2MB",
    NAME: "Name",
    NAME_PLACEHOLDER: "Enter Your Name",
    NAME_INVALID:
      " *User name must be more than 4 characters and less than 24 charcters",
    ENGAGEMENT_SCORE: "Engagement Score",
    POSTS_NUMBER: "posts",
    COMMENT_RECEIVE: "comments received",
    LIKE_RECEIVE: "likes received",
    CONTACT: "Contact",
    PURCHASED_HISTORY_TAB: 'Purchased History',
    PROFILE_SETTING_TAB: 'Profile Setting',
    PURCHASED_PRODUCT_TITLE: 'Purchased Products'
  },
  NFT_CELL: {
    TOKEN_ADDRESS: "Token Address",
    TOKEN_ID: "Token ID",
    BALANCE: 'Balance'
  },
  ADMIN_VIEW: {
    TAB_BASIC_INFO: "Basic Info",
    TAB_BULLETIN_BOARD: "Bulletin Board",
    TAB_SLACK_INTEGRATION: "Slack Integration",
    TAB_COMMERCIAL_ACCOUNT: "Commerce Account",
    BASIC_INFO: {
      NAME: "Name",
      NAME_PLACEHOLDER: "Enter membership name",
      NAME_INVALID: "Name is required",
      COVER_IMG: "Cover Image",
      COVER_IMG_LIMIT: "You can use an image upto 2MB",
    },
    CONTENT_BULLETIN: {
      TITLE: "Title",
      TITLE_INVALID: " Title is required",
      DESCRIPTION: "Description",
      DESCRIPTION_INVALID: "Description is required",
      LINK: "Link",
      LINK_INVALID: "URL is invalid (We currently support https)",
      BOARD_IMG: "Board Image",
      BOARD_IMG_LIMIT: "You can use an image upto 2MB",
      BTN_REMOVE_NEWS: "Remove News",
    },
    SLACK_INTEGRATION: {
      SEND_NOTI_TO_SLACK: "Send notifications to your Slack channel",
      SEND_NOTI_NOTE:
        "When new posts/comments are made by the members, the notifications will send out.",
      THE_DETAIL_TXT: "The detail of the post is",
      NOT_REVEALED: "NOT revealed",
      IN_THE_NOTI: " in the notifications.",
      ACCESS_TOKEN: "Access Token",
      ACCESS_TOKEN_VALIDATE: "Please enter correct Access Token",
      HOW_TO_GET_TOKEN: "How to get Access Token",
      CHANEL_ID: "Channel ID",
      HOW_TO_CHECK: "How to check channel ID",
      CHAIN_ID_INVALID: "Please enter correct Channel ID",
    },
    COMMERCE_ACCOUNT: {
      COUNTRY: "Country",
      COUNTRY_INVALID: "Please select your country",
      EMAIL: "Email",
      EMAIL_EXPLAIN: "The email address is also used for customer enquiry",
      EMAIL_INVALID: "Please enter correct email address",
      STEP_SELL_ITEMS: "Only a Few Steps To Sell Your Items!",
      STEP_1: "Step1: Verify Your ID",
      COMPLETE_VETIFY: "Complete Verification",
      STEP1_NOTE: "If the status does not change. Please check below.",
      STEP1_NOTE_1:
        '1. Click "Complete Verification" button above and complete the process.',
      STEP1_NOTE_2:
        "2. Check verification email in your mailbox and complete the process.",
      STEP1_NOT_DONE: "!! Your Stripe account registration may be declined.",
      CONTACT_STRIPE: "Contact Stripe for Support",
      ACCOUNT_ID: "Account ID",
      STEP2_COPY_PASTE_PUBLIC_KEY: "Step2: Copy & paste your public API key.",
      HOW_TO_FIND_API_KEY: "How to Find Your API Key?",
      PUBLIC_KEY: "Public Key",
      BTN_CREATE_COMMERCE_ACC: "Create Commerce Account",
      BTN_DELETE_ACC: "Delete Commerce Account",
      EDIT_PUBLIC_KEY: "Edit Public Key",
      CAUTION:
        "Caution!!<br>Wrong public key causes payment malfunction.<br>Be careful of editing this information.",
    },
  },
  OVERLAY_PUBLIC_KEY: {
    HOW_TO_COPY: "How to Copy your API Key",
    BTN_GO_DASHBOARD: " Go Dashboard",
  },
  NOTIFICATION_CELL: {
    YOUR_PURCHASE: 'Your purchased',
    PURCHASE_DT: 'Purchase date',
    CHECK_ON_DASHBOARD: 'Check your purchase on the dashboard'
  },
  STRIPE_FORM: {
    CREDIT_CARD_INFO: 'Credit Card Info',
    BILLING_ADDRESS: 'Billing Address',
    FULL_NAME: 'Full Name',
    EMAIL: 'Email',
    COUNTRY: 'Country',
    SELECT_COUNTRY: 'Select Your Country',
    POSTAL_CD: 'Post code',
    CITY: 'City',
    ADDRESS: 'Address',
    BILLING_SAME_ADDRESS: 'Billing and shipping addresses are the same',
    SHIPPING_ADDRESS: 'Shipping Address',
  },
  ABOUT_VIEW: {
    FEATURE: 'Features',
    USE_CASE: 'Use Case',
    HOW_WORK: 'How It Works',
    CONTACT: 'Contact',
    UNLOCK_NFT: 'Unlock the Power of Your NFTs.',
    CREATE_EXCLUSIVE_NFT: 'Create exclusive NFT-based membership communities with just one click!',
    GET_START: 'Get Started',
    FEATURES: 'Features',
    NFT_GATED_COMMUNITIES: 'NFT-Gated Communities',
    EASY_CREATE:'Easily create private, NFT-gated communities, accessible only by the NFT holders.',
    ONE_CLICK: ' One-Click Creation',
    SINGLE_CLICK: 'Instantly create your community with a single click.',
    CONNECT_WITH_HOLDER: 'Connect with NFT Holders',
    BRING_TOGETHER:'Bring together NFT holders to share posts, comments, and tips.',
    CONTRIBUTION_RANKING: 'Contribution Ranking',
    CONTRIBUTION_RANKING_NOTE: 'Visualize and reward active members through contribution ranking.',
    CUSTOMIZABLE_DESIGN: 'Customizable Design',
    CUSTOMIZABLE_DESIGN_NOTE: 'Personalize the look and feel of your community.',
    USE_CASES: 'Use Cases',
    USE_CASES_1: 'Exchange information and network with fellow NFT fans',
    USE_CASES_2: 'A meeting place for those who attended the same event',
    USE_CASES_3: 'A space for NFT artists and collectors to connect',
    USE_CASES_4: 'Exclusive membership communities and online salons',
    HOW_IT_WORK: 'How It Works',
    ACCESS_TITLE: 'Access',
    ACCESS_CONTENT: 'Visit the DeepBeach website and connect your wallet.',
    CREATE_TITLE: 'Create',
    CREATE_CONTENT: 'Click to instantly create an NFT-gated community for your desired NFT collection.',
    ENGAGE_TITLE: 'Engage',
    ENGAGE_CONTENT: 'Start interacting with fellow NFT holders within the community.',
    GET_IN_TOUCH: 'Get In Touch',
    CONTACT_US: 'Contact Us',
    BTN_FOLLOW_TWITTER: 'Follow us on X'
  },
  ENGAGEMENT_RANKING: {
    ENGAGE_RANKING: 'Engagement Ranking'
  },
  MEMBERSHIP_SETTING: {
    MEMBERSHIP_SETTING: 'Membership Setting',
    MEMBERSHIP_ID: 'Membership ID',
    ADMIN_EMAIL: 'Admin Email',
    COMUNITY_NAME: 'Community Name',
    DESCRIPTION: 'Description',
    UPLOAD_COVER_IMG: 'Upload Cover Image',
    NFT_CONTRACT_ADDRESS: 'NFT Contract Address',
    TOKEN_ID: 'TokenID(*optional)',
    CHAIN: 'Chain'
  },
  OVERLAY_TRANSFER_TOKEN: {
    TIP_NOTE: '*Your tip will be notified to the user!',
  },
  PAYMENT_VIEW: {
    CHECKOUT: 'Checkout',
    INCLUDES_TAX: 'tax inc.',
    QUANTITY: 'Quantity'
  },
  PURCHASED_ITEM_CELL: {
    QUANTITY: 'Quantity',
    DOWNLOAD_RECEIPT: 'Download receipt',
    INCLUDE_TAX: '(tax inc.)'
  }
};
