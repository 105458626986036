<template>
  <div class="shop-cell-container" @click="onClickCell">
    <div class="item-info-container">
      <div :class="{
        'item-name' : isAdmin
      }">
        <h3>{{ item?.name }}</h3>
        <div v-if="isAdmin" class="dropdown">
          <button @click="showAction" class="button-show-actions"></button>
          <div :id="`product-${item.productId}`" class="dropdown-content">
            <button @click="deleteProduct">Delete</button>
          </div>
        </div>
      </div>
      <swiper
        :modules="modules"
        :slides-per-view="1"
        :space-between="50"
        :loop="true"
        navigation
        :pagination="{ clickable: true }"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide
          v-for="(imageUrl, index) in item?.imageUrls"
          :key="index"
          class="swipe-card-wrapper"
        >
          <div class="swipe-card">
            <div
              class="image-container"
              :style="{ backgroundImage: `url(${imageUrl})` }"
              @click="onImageClick(imageUrl)"
            ></div>
          </div>
        </swiper-slide>
      </swiper>

      <div class="price-container">
        <div class="price">
        <span>
          {{ formattedPrice }}
          <span class="currency">{{ item?.currency.toUpperCase() }}</span>
          <span class="tax-inc">(tax inc.)</span>
        </span>
        </div>
        <div v-show="item.inventory <= DISPLAY_INVENTORY_NUMBER"  class="inventory-number">
        <span class="stock-number">
         Stock left: {{ item.inventory }} 
        </span>
       </div>
      </div>

      <div
        class="description"
        :style="{ maxHeight: showFullDescription ? '600px' : '300px' }"
      >
        <p v-if="!showFullDescription">
          {{ truncate(item?.description, 200, "...") }}
        </p>
        <p v-else>{{ item?.description }}</p>
        <button
          class="n-btn-link button-readmore"
          v-if="item?.description && item.description.length > 200"
          @click="showFullDescription = !showFullDescription"
        >
          {{ showFullDescription ? "閉じる" : "もっと見る" }}
        </button>
      </div>

      <button class="n-btn-primary button-buy" v-show="item?.isPublished" @click="proceedToPayment">
        {{ $t('SHOP_CELL.BUY') }}
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.shop-cell-container {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 8px;
}
.swipe-card-wrapper {
}
.swipe-card {
  padding: 36px;
}
.image-container {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 200px;
  border-radius: 8px;
  cursor: pointer;
}
.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel-container img {
  width: 100%;
  height: auto;
}
.item-info-container {
  padding: 32px;
}
.description {
  font-size: 14px;
  line-height: 28px;
  text-align: left;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.price-container {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  height: 5vh;
}
.price {
  font-size: 20px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
}
.currency {
  font-size: 20px;
  font-weight: 500;
  color: #888;
}
.button-buy {
  width: 200px;
  margin-top: 32px;
}
.button-readmore {
  color: #888;
  align-self: flex-end;
}
.tax-inc {
  margin-left: 12px;
  color: #888;
  font-size: 12px;
}
.dropdown {
  float: right;
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  top: 2.8em;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  right: 0;
  z-index: 2;

  & button {
    border: none;
    width: 100%;
    height: 40px;
    font-size: 16px;
    color: #555;
    border: none;
    &:hover {
      background-color: #ddd;
    }
  }
  &.show {
    display: block;
  }
}
.button-show-actions {
  width: 50px;
  height: 50px;
  padding: 0; /* This is to center button image  */
  border: none;
  display: inline-block;
  background-image: url("@/assets/img/icon-dots.png");
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    background-color: #ddd;
  }
}
.item-name {
  display: flex;
  justify-content: space-between;
}
.inventory-number {
  margin-top: 4px;
  color: rgb(250, 119, 1);
  font-size: 12px;
  border: solid rgb(250, 119, 1) 2px;
  border-radius: 6px;
  padding: 2px 6px;
  font-weight: 600;
}
@media all and (min-width: 1024px) and (max-width: 1280px) {
}
@media all and (min-width: 768px) and (max-width: 1024px) {
}
@media all and (min-width: 480px) and (max-width: 768px) {
}
@media all and (max-width: 480px) {
}
</style>

<script>
import { defineComponent } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, A11y } from "swiper/modules";
// import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';
import Web3 from 'web3';
import axios from "axios";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
const DISPLAY_INVENTORY_NUMBER = 3;
export default defineComponent({
  name: "ShopCell",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      itemName: "null",
      showFullDescription: false,
      showDropdown:false,
      DISPLAY_INVENTORY_NUMBER
    };
  },
  computed: {
    divStyle() {
      return {
        backgroundImage: `url(${this.item?.imageUrl})`,
      };
    },
    formattedPrice() {
      let priceValue = this.item?.price;

      if (this.item?.currency !== 'jpy') {
        priceValue = priceValue / 100;  // 10で割る処理をこの位置に移動
      }

      return new Intl.NumberFormat("ja-JP", { // 'ja-JP' に変更して、日本の数値書式に従ってカンマを入れる
        style: "decimal",
        minimumFractionDigits: this.item?.currency === 'jpy' ? 0 : 2 // 日本円の場合は小数点なし、それ以外は2桁の小数点以下
      }).format(priceValue);
    }
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required:true
    }
  },
  inject: {
    API_BASE_URL: {
      from: 'API_BASE_URL'
    },
    API_ENDPOINT_GET_STRIPE_ACCOUNT: {
      from: 'API_ENDPOINT_GET_STRIPE_ACCOUNT'
    },
    API_ENDPOINT_GET_STRIPE_ACCOUNT_ID: {
      from: 'API_ENDPOINT_GET_STRIPE_ACCOUNT_ID'
    },
    SESSION_STORAGE_KEY_PRODUCT: {
      from: 'SESSION_STORAGE_KEY_PRODUCT'
    },
    API_ENDPOINT_DELETE_PRODUCT: {
      from: 'API_ENDPOINT_DELETE_PRODUCT'
    },
  },
  setup(props) {
    const onSwiper = () => {
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    const truncate = (text, length, suffix) => {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    };
    
    const router = useRouter(); // ルーターインスタンスを取得
    const proceedToPayment = async function() {
      sessionStorage.setItem(this.SESSION_STORAGE_KEY_PRODUCT, JSON.stringify(props.item));
      this.$emit('show-spinner', true); // 処理終了を親コンポーネントに通知

      try {
        // Membership IDをURLクエリを取得
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const searchParams = new URLSearchParams(url.search);
        const membershipId = searchParams.get("membershipId"); 

        // Account IDを取得
        const result = await this.getStripeAccountId(membershipId);
        const accountId = result.data.account_id;

        this.$emit('show-spinner', false);

        //
        router.push({ 
          name: 'payment', 
          query: { accountId: accountId, membershipId: membershipId } 
        });

      } catch(error) {
        console.error("failed to proceed to payment", error);
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Sorry, something went wrong. please try again.',
          timer: 3000, // 3秒後にalertを自動で閉じる
          timerProgressBar: true,
          showConfirmButton: true,
        });
        router.go(-1); // 1つ前のページに戻る
      }

    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, A11y],
      truncate,
      proceedToPayment
    };
  },
  emits: ["image-click", "delete-product-item"],
  methods: {
    async deleteProduct(event) {
      event.stopPropagation();
      this.$swal({
        title: this.$t('SHOP_CELL.CONFIRM_DEL_TITLE'),
        text: this.$t('SHOP_CELL.CONFIRM_DEL_BODY'),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        cancelButtonColor: '#000',
        confirmButtonText: 'OK',
        confirmButtonColor: '#ccc'
      }).then(async (result) => {
        if (result.value) {
          this.showDropdown = false;
          const membershipId = this.$route.query?.membershipId;
          const priceId = this.item.priceId;
          const productId = this.item.productId;
          if (!membershipId || !priceId) return;
          // // Get account Id
          const result = await this.getStripeAccountId(membershipId);
          const accountId = result.data.account_id;
          try {
            this.$emit("delete-product-item", {loading:true});
            let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_DELETE_PRODUCT;
            const result = await axios.post(apiUrl, {
              membershipId,
              accountId,
              priceId
            });
            if (result.status === 200) {
              this.$emit("delete-product-item", {loading:false, productId });
              this.$swal({
                position: "top-right",
                icon: "success",
                title: this.$t('SHOP_CELL.DELETE_ITEM_SUCCESS'),
                type: "success",
                showCancelButton: false,
                confirmButtonText: "OK",
                iconColor: "#000",
                timer: 1500,
              });
            } else {
              this.$swal({
                title: "Sorry, something went wrong",
                text: `Please try again`,
                type: "warning",
                showCancelButton: false,
                showConfirmButton: true,
                timer: 2500,
              });
              this.$emit("delete-product-item", {loading:false});
            }
          } catch (error) {
            console.error("failed to get stripe account id");
            this.$emit("delete-product-item", {loading:false});
            return;
          }
        }
      });
    },
    showAction(event) {
      event.stopPropagation();
       // Hide menu in case some others are shown
       let dropDowns = document.getElementsByClassName("dropdown-content");
      for (var i = 0; i < dropDowns.length; i++) {
        if (dropDowns[i].id !== `product-${this.item.productId}`) {
          dropDowns[i].classList.remove("show");
        }
      }
      // Show menu
      let div = document.getElementById(`product-${this.item.productId}`);
      div.classList.toggle("show");
    },
    onClickCell: function () {},
    onImageClick: function (imageUrl) {
      this.$emit("image-click", imageUrl, this.item);
    },
    incrementImageIndex: function () {
      if (this.currentImageIndex < this.images.length - 1) {
        this.currentImageIndex++;
      }
    },
    decrementImageIndex: function () {
      if (this.currentImageIndex > 0) {
        this.currentImageIndex--;
      }
    },
    getWalletAddress: async function() {
      // Web3インスタンスの作成
      const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");

      // 接続されているアカウント（ウォレットアドレス）を取得
      const accounts = await web3.eth.getAccounts();

      // 最初のアカウントがウォレットアドレス
      if (accounts.length > 0) {
        const walletAddress = accounts[0];
        console.log("Wallet Address:", walletAddress);
        return walletAddress;
      } else {
        console.log("No wallet connected");
        return null;
      }
    },
    getStripeAccountId: async function(membershipId) {
      try {
        let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_GET_STRIPE_ACCOUNT_ID;
        const result = axios.get(apiUrl, { params: {
          membershipId: membershipId,
          }
        });
        return result;
      } catch(error) {
        console.error("failed to get stripe account id");
        return;
      }
    },

  },
});
</script>
