export default {
  BUTTON: {
    CANCEL: "キャンセル",
    POSTING: "投稿中...",
    POST: "投稿",
    REPORT: "報告",
    DELETE: "削除",
    POST_NOMAL: "投稿",
    KEEP_WRITING: "書き続ける",
    QUIT_WRITING: "書くのをやめる",
    NEXT: "次へ",
    BACK: "戻る",
    UPDATE: "更新する",
    LOGOUT: "ログアウト",
    CANCEL_UPPER: 'キャンセル',
    SAVE_UPPER: '保存',
    GO_DASHBOARD: 'ダッシュボードへ',
    PAY: '支払う'
  },
  TOP_VIEW: {
    USE_POLYGON_ETHER: "*現在、イーサリアムとポリゴンをサポートしています",
    WHAT_DEEPBEACH: "DeepBeachとは？",
    CONNECTING: "接続中...",
    DISCONNECT: "切断する",
    NFT_COMMUNITY: "トークンコミュニティに参加する",
    SWITCH_NETWORK: "サポートされているネットワークに切り替える",
    TO_POLYGON: "Polygonネットワークに切り替える",
    TO_ETHEREUM: "Ethereumネットワークに切り替える",
    NO_NFT: "ウォレット内で利用可能なトークンがありません",
    POPUP: {
      TITLE_CONNECT_METAMASK: "MetaMaskに接続する",
      PLEASE_CHECK_METAMASK: "MetaMaskを確認してください。",
      PLEASE_RELOAD_PAGE:
        "しばらく何も起こらない場合は、何か問題が発生している可能性があります。ページをリロードしてください。",
        TITLE_DO_HAS_METAMASK: "ウォレットを接続してください👛",
      CONTENT_DO_HAS_METAMASK: "ウォレットを接続して DeepBeach に参加してください！⛱️",
      TITLE_NEED_APPROVE_METAMASK:
        "MetaMaskでリクエストを承認する必要があります",
      CONTENT_NEED_APPROVE_METAMASK:
        "「ウォレットに接続」ボタンをクリックし、リクエストを承認してください。",
      BTN_RELOAD_PAGE: "ページをリロードする",
      BTN_STAY_PAGE: "ページにとどまる",
      TITLE_USE_POLUGON_ETHER: "現在、EthereumとPolygon Chainに対応しています",
      CONTENT_USE_POLUGON_ETHER:
        "サポートされているチェーンに切り替えてください",
      TITLE_SOMETHING_WRONG_1006:
        "申し訳ありませんが、問題が発生しました - エラーコード: 1006",
      CONTENT_SOMETHING_WRONG_1006: "もう一度お試しください。",
      TITLE_REGISTER_MEMBERSHIP_403:
        "申し訳ありませんが、サポートされていないNFTです",
      CONTENT_REGISTER_MEMBERSHIP_403:
        '" style="width:60px;height:60px;"><div> OpenSeaやRaribleなどのいくつかのNFTマーケットプレイスで作成された場合、これらのNFTはメンバーシップページを作成することができません。これは、同じ契約アドレスを持ち、ユニークなNFTコレクションとして識別することができないためです。</div>',
      TITLE_REGISTER_MEMBERSHIP_405:
        "申し訳ありませんが、何か問題が発生しました",
      CONTENT_REGISTER_MEMBERSHIP_405:
        "このメンバーシップページは現在技術的な問題を抱えています。後でもう一度お試しください。",
    },
  },
  POPUP: {
    TITLE_OTHER_ERROR: "申し訳ありませんが、何か問題が発生しました",
    CONTENT_OTHER_ERROR: "もう一度お試しください",
    TITLE_COMMON_ERROR: "申し訳ありません、何か問題が発生しました",
    ERR_1001_CONTENT: "もう一度お試しください。エラーコード：1001",
    TITLE_TOKEN_COPIED: "トークンアドレスがクリップボードにコピーされました！",
    TITLE_DRAFT: "よろしいですか？",
    CONTENT_DRAFT: "よろしいですか？",
    PLEASE_LOGIN_AGAIN: "もう一度ログインしてください",
    TITLE_WALLET_DISCONNECT: "ウォレットが切断されました",
    TITLE_SUPPORT_NETWORK: "現在、EthereumとPolygonをサポートしています",
    CONTENT_SUPPORT_NETWORK: "EthereumとPolygonチェーンに切り替えてください",
    TITLE_NOT_FOUND_NFT: "要求されたNFTが見つかりません - コード103",
    CONTENT_NOT_FOUND_NFT:
      "このページを表示するためのメンバーシップNFTがありません",
    CONTENT_NEW_POST_SELL:
      "会員は今、タイムラインの「ショップ」セクションで商品を見ることができます🛍",
    TITLE_WALLET_DISCONNECTED: "ウォレットが切断されました",
    TITLE_WALLET_CHANGED: "ウォレットが変更されました",
    NEED_TO_LOGIN: "もう一度ログインする必要があります",
    TITLE_REQUIRED_NFT_CODE_104: "必要なNFTが見つかりません - コード104",
    CONTENT_REQUIRED_NFT_CODE_104:
      "このページを表示するためのメンバーシップNFTがありません",
      TITLE_COPY_SHARED_SUCCESS: '共有URLがクリップボードにコピーされました！',
      PLEASE_TRY_AGAIN: 'もう一度お試しください',
TITLE_OUT_OF_STOCK: '申し訳ありませんが、この商品は在庫切れです',
TITLE_PAYMENT_SUCCESS: '支払いが成功しました',
CONTENT_PAYMENT_SUCCESS: '<p>出品者がお客様と連絡を取ります。</p> <p>お問い合わせは、<a href="href="mailto: {contact}">{contact}</a>までご連絡ください。</p>'
  },
  MEMBER_SHIPCELL: {
    TOKEN_ADDRESS: "トークンアドレス",
    TOKEN_ID: "トークンID",
    BALANCE: 'バランス'
  },
  TIMELINE: {
    CONTACT_US: "お問い合わせ",
    CONTACT_US_EXPLAIN:
      "この会員ページのカバー画像とニュースを設定できる管理者メンバーを追加するためにお問い合わせください。",
    NEWS: "ニュース",
    EXTERNAL_LINK: "外部リンク >",
    TOP_MEMBER: "エンゲージメントが高いメンバー",
    SEE_MORE: " もっと見る >",
    WHAT_MEMBER_SAY: "メンバーの声",
    THE_FIRST_MEMBER_SAY: "この会員ページに投稿するのは1番目になります！",
  },
  SHOP_CELL: {
    BUY: "購入する",
    CONFIRM_DEL_TITLE: "この製品を削除してもよろしいですか?",
    CONFIRM_DEL_BODY: "この操作は元に戻せません。",
    DELETE_ITEM_SUCCESS: '商品は削除されました。',
  },
  OVERLAY_POST: {
    CHARACTER: "キャラクター",
    IMAGE_LIMIT: "画像は2MBまで使用できます",
    PLACEHOLDER_POST_TXT: "思っていることを共有する",
  },
  SELL_VIEW: {
    SELL_ITEM: "アイテムを販売する",
    PLEASE_NOTE: "ご注意ください",
    SELL_GUIDE:
      " あなたは具体的または具体化できない投機的要素を持つアイテムを販売リストに掲載してはいけません。このポリシーに違反すると、あなたのアカウントが停止される場合があります。",
    ITEM_NAME: "アイテム名",
    ITEM_NAME_PLACEHOLDER: "アイテム名を入力してください",
    ITEM_NAME_INVALID: "アイテム名は必須です",
    DESCRIPTION: "説明",
    DESCRIPTION_PLACEHOLDER: "アイテムの説明を入力してください",
    DESCRIPTION_INVALID: "アイテムの説明は必須です",
    IMAGE: "画像",
    VALIDATE_IMAGE: "少なくとも1つの画像を設定する必要があります。",
    CURRENCY: "通貨",
    CURRENCY_PLACEHOLDER: "通貨を選択してください",
    CURRENCY_INVALID: "通貨を選択してください",
    PRICE_INCLUDE_TAX: "価格（税込）",
    PRICE_INCLUDE_TAX_INVALID: "価格を設定してください",
    STOCK: "在庫",
    STOCK_INVALID: "在庫を設定してください",
    PROCEES_TO_PUBLISH: "公開に進む",
    READY_PUBLISH: "公開の準備ができましたか？",
    STOCK_UNDERFINED: "在庫：無制限",
    BTN_PUBLISH: "公開する",
    BTN_FIX_INFO: "情報修正",
  },

  CONFIRM: {
    LEAVING_PAGE: "商品を公開していません。ページを離れてもよろしいですか？",
  },
  NOTIFICATION: {
    NO_NOTIFICATION: "まだ通知はありません",
  },
  PROFILE_SETTING: {
    CREATE_MEMBERSHIP: "メンバーシップを作成する",
    MEMBERSHIP_QUOTA: "*無料プランでは最大2つのメンバーシップを作成できます",
    BTN_UPGRADE_PLAN: "プランをアップグレードする",
    UPLOAD_IMG_LIMIT: "最大2MBの画像を使用できます",
    NAME: "名前",
    NAME_PLACEHOLDER: "名前を入力してください",
    NAME_INVALID: " *ユーザー名は4文字以上24文字以下である必要があります",
    ENGAGEMENT_SCORE: "エンゲージメントスコア",
    POSTS_NUMBER: "投稿数",
    COMMENT_RECEIVE: "受け取ったコメント",
    LIKE_RECEIVE: "受け取ったいいね",
    PURCHASED_ITEM: "購入した商品はありますか？ショップにお問い合わせください",
    CONTACT: "連絡先",
    PURCHASED_HISTORY_TAB: '購入履歴',
    PROFILE_SETTING_TAB: 'プロファイル設定',
    PURCHASED_PRODUCT_TITLE: '購入した製品'
  },
  NFT_CELL: {
    TOKEN_ADDRESS: "トークンアドレス",
    TOKEN_ID: "トークンID",
    BALANCE: 'バランス'
  },
  ADMIN_VIEW: {
    TAB_BASIC_INFO: '基本情報', // Basic Info
    TAB_BULLETIN_BOARD: '掲示板', // Bulletin Board
    TAB_SLACK_INTEGRATION: 'Slack連携', // Slack Integration
    TAB_COMMERCIAL_ACCOUNT: 'コマースアカウント', // Commerce Account
    BASIC_INFO: {
      NAME: '名称',
      NAME_PLACEHOLDER: 'メンバー名を入力してください',
      NAME_INVALID: '名称は必須入力です',
      COVER_IMG: 'カバー画像',
      COVER_IMG_LIMIT: '最大2MBまでの画像を使用できます',
    },
    CONTENT_BULLETIN: {
      TITLE: 'タイトル',
      TITLE_INVALID: 'タイトルは必須入力です',
      DESCRIPTION: '内容',
      DESCRIPTION_INVALID: '内容は必須入力です',
      LINK: 'リンク',
      LINK_INVALID: 'URLが無効です (httpsのみ対応しています)',
      BOARD_IMG: '掲示板画像',
      BOARD_IMG_LIMIT: '最大2MBまでの画像を使用できます',
      BTN_REMOVE_NEWS: 'ニュース削除',
    },
    SLACK_INTEGRATION: {
      SEND_NOTI_TO_SLACK: 'Slackチャンネルに通知を送信', // Send notifications to your Slack channel
      SEND_NOTI_NOTE: 'メンバーが新規投稿/コメントを行うと、通知が送信されます。', // When new posts/comments are made by the members, the notifications will be sent.
      THE_DETAIL_TXT: '投稿の詳細情報は', // The detail of the post is
      NOT_REVEALED: '「表示されない」', // NOT revealed
      IN_THE_NOTI: '通知のなかで', // in the notifications.
      ACCESS_TOKEN: 'アクセス トークン', // Access Token
      ACCESS_TOKEN_VALIDATE: '正しいアクセス トークンを入力してください', // Please enter correct Access Token
      HOW_TO_GET_TOKEN: 'アクセス トークンの取得方法', // How to get Access Token
      CHANEL_ID: 'チャンネルID', // Channel ID
      HOW_TO_CHECK: 'チャンネルIDを確認する方法', // How to check channel ID
      CHAIN_ID_INVALID: '正しいチャンネルIDを入力してください', // Please enter correct Channel ID (Corrected typo)
    },
    COMMERCE_ACCOUNT: {
      COUNTRY: '国',
      COUNTRY_INVALID: '国を選択してください',
      EMAIL: 'Eメール',
      EMAIL_EXPLAIN: 'メールアドレスはお客様からのお問い合わせにも使用されます',
      EMAIL_INVALID: '正しいEメールアドレスを入力してください',
      STEP_SELL_ITEMS: '商品を販売するためのわずかなステップのみ！',
      STEP_1: 'ステップ1：IDを確認する',
      COMPLETE_VETIFY: '確認を完了する',
      STEP1_NOTE: 'ステータスが変更されない場合は、以下を確認してください。',
      STEP1_NOTE_1: '1. 上記の「確認を完了する」ボタンをクリックしてプロセスを完了します。',
      STEP1_NOTE_2: '2. メールボックスで確認用のメールを確認し、プロセスを完了します。',
      STEP1_NOT_DONE: '!! Stripeアカウントの登録が拒否される可能性があります。',
      CONTACT_STRIPE: 'サポートのためにStripeに連絡する',
      ACCOUNT_ID: 'アカウントID',
      STEP2_COPY_PASTE_PUBLIC_KEY: 'ステップ2：公開APIキーをコピー＆ペーストする',
      HOW_TO_FIND_API_KEY: 'APIキーの探し方',
      PUBLIC_KEY: '公開キー',
      BTN_CREATE_COMMERCE_ACC: 'コマースアカウントを作成する',
      BTN_DELETE_ACC: 'コマースアカウントを削除する',
      EDIT_PUBLIC_KEY: '公開キーを編集する',
      CAUTION: '注意事項!!<br>誤った公開キーは支払いの機能不全を引き起こす可能性があります。<br>この情報を編集する際には注意してください。'
    }
  },
  OVERLAY_PUBLIC_KEY: {
    HOW_TO_COPY: 'APIキーのコピー方法',
    BTN_GO_DASHBOARD: 'ダッシュボードに移動'
  },
  NOTIFICATION_CELL: {
    YOUR_PURCHASE: 'ご購入された商品',
    PURCHASE_DT: '購入日',
    CHECK_ON_DASHBOARD: 'ダッシュボードで購入内容を確認してください'
  },
  STRIPE_FORM: {
    CREDIT_CARD_INFO: 'クレジットカード情報',
    BILLING_ADDRESS: '請求先住所',
    FULL_NAME: '氏名',
    EMAIL: 'メールアドレス',
    COUNTRY: '国',
    SELECT_COUNTRY: '国を選択してください',
    POSTAL_CD: '郵便番号',
    CITY: '都道府県',
    ADDRESS: '住所',
    BILLING_SAME_ADDRESS: '請求先と配送先の住所は同じです',
    SHIPPING_ADDRESS: '配送先住所',
  },
  ABOUT_VIEW: {
    FEATURE: '特徴',
    USE_CASE: '利用シーン',
    HOW_WORK: '動作方法',
    CONTACT: 'お問い合わせ',
    UNLOCK_NFT: 'NFTの力を解き放て。',
    CREATE_EXCLUSIVE_NFT: 'たった1クリックで独占的なNFTベースのメンバーシップコミュニティを作成しましょう！',
    GET_START: 'はじめる',
    FEATURES: '特徴',
    NFT_GATED_COMMUNITIES: 'NFTゲートドコミュニティ',
    EASY_CREATE:'NFTホルダーのみがアクセスできる、簡単にプライベートなNFTゲートドコミュニティを作成できます。',
    ONE_CLICK: 'たった1クリックで作成',
    SINGLE_CLICK: 'たった1クリックでコミュニティを即座に作成できます。',
    CONNECT_WITH_HOLDER: 'NFTホルダーとつながる',
    BRING_TOGETHER:'NFTホルダーを集め、投稿、コメント、チップを共有しましょう。',
    CONTRIBUTION_RANKING: '貢献度ランキング',
    CONTRIBUTION_RANKING_NOTE: '貢献度ランキングを通じて活発なメンバーを可視化し、報酬を与えることができます。',
    CUSTOMIZABLE_DESIGN: 'カスタマイズ可能なデザイン',
    CUSTOMIZABLE_DESIGN_NOTE: 'コミュニティの見た目を個別にカスタマイズできます。',
    USE_CASES: '利用シーン',
    USE_CASES_1: 'NFTファンとの情報交換やネットワーキング',
    USE_CASES_2: '同じイベントに参加した人々の出会いの場',
    USE_CASES_3: 'NFTアーティストとコレクターのつながりのスペース',
    USE_CASES_4: '独占的なメンバーシップコミュニティやオンラインサロン',
    HOW_IT_WORK: '動作方法',
    ACCESS_TITLE: 'アクセス',
    ACCESS_CONTENT: 'DeepBeachのウェブサイトにアクセスし、ウォレットを接続します。',
    CREATE_TITLE: '作成',
    CREATE_CONTENT: 'クリックして、希望するNFTコレクションのためのNFTゲートドコミュニティを即座に作成します。',
    ENGAGE_TITLE: '参加',
    ENGAGE_CONTENT: 'コミュニティ内の他のNFTホルダーとの対話を開始します。',
    GET_IN_TOUCH: '連絡する',
    CONTACT_US: 'お問い合わせ',
    BTN_FOLLOW_TWITTER: 'Xでフォローする'
  },
  ENGAGEMENT_RANKING: {
    ENGAGE_RANKING: 'Engagement Ranking'
  },
  MEMBERSHIP_SETTING: {
    MEMBERSHIP_SETTING: 'メンバーシップ設定',
    MEMBERSHIP_ID: 'メンバーシップID',
    ADMIN_EMAIL: '管理者メールアドレス',
    COMUNITY_NAME: 'コミュニティ名',
    DESCRIPTION: '説明',
    UPLOAD_COVER_IMG: 'カバー画像のアップロード',
    NFT_CONTRACT_ADDRESS: 'NFT契約アドレス',
    TOKEN_ID: 'トークンID（オプション）',
    CHAIN: 'チェーン'
  },
  OVERLAY_TRANSFER_TOKEN: {
    TIP_NOTE: '*ユーザーに対してチップが通知されます！'
  },
  PAYMENT_VIEW: {
    CHECKOUT: 'チェックアウト',
    INCLUDES_TAX: '税込',
    QUANTITY: '数量'
  },
  PURCHASED_ITEM_CELL: {
    QUANTITY: '数量',
    DOWNLOAD_RECEIPT: '領収書をダウンロード',
    INCLUDE_TAX: '(税込)'
  }
};
