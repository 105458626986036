<template>
  <div class="comment-cell-container" v-show="message">
    <div class="left-column">
      <div
        class="profile-image"
        v-bind:style="{ backgroundImage: 'url(' + profileImageUrl + ')' }"
        @click="onClickProfileImage"
      ></div>
    </div>
    <div class="right-column">
      <div class="right-top-container">
        <p>{{ userName }}</p>
        <div class="dropdown">
          <button @click="showActions()" class="button-show-actions"></button>
          <div v-bind:id="'comment-' + commentId" class="dropdown-content">
            <button @click="clickReportEvent">Report</button>
            <button @click="clickDeleteEvent" v-show="isOwnComment">
              Delete
            </button>
          </div>
        </div>
      </div>
      <div class="right-middle-container">
        <p ref="messageRef">{{ message }}</p>
      </div>
      <div class="right-bottom-container">
        <div>
          <button
            class="button-like"
            :class="{ 'did-like': didLike }"
            v-bind:id="'button-like-' + commentId"
            @click="clickLikeEvent"
          ></button>
          <p>{{ likesCount }}</p>
        </div>
        <div>
          <p class="weak-text">{{ ago }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.comment-cell-container {
  display: flex;
  width: 60%;
  padding: 12px;
  margin: 24px auto;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
}
.left-column {
  display: flex;
  width: 20%;
}
.right-column {
  width: 80%;
}
.profile-image {
  width: 50px;
  height: 50px;
  margin: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 25px;
  background-color: #ccc;
  cursor: pointer;
}
.right-top-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.right-top-container p {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 12px;
  font-weight: 700;
  font-color: #555;
}
.right-middle-container {
}
.right-middle-container p {
  padding: 12px;
  text-align: left;
  line-height: 30px;
  font-weight: 300;
  font-size: 18px;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.right-bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}
.right-bottom-container div {
  display: flex;
  gap: 12px;
  align-items: center;
}
.right-bottom-container button {
  width: 30px;
  height: 30px;
  border: none;
  background-color: transparent;
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
}
.right-bottom-container p {
  margin: 0;
  font-weight: 500;
  color: #555;
}

.button-like {
  background-image: url("@/assets/img/icon-like-light.png");
}
.button-like.did-like {
  background-image: url("@/assets/img/icon-like-filled-black.png");
}
.button-show-actions {
  width: 50px;
  height: 50px;
  padding: 0; /* This is to center button image  */
  border: none;
  width: 50px;
  height: 50px;
  display: inline-block;
  background-color: transparent;
  background-image: url("@/assets/img/icon-dots.png");
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
}
.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
.dropbtn:hover,
.dropbtn:focus {
  background-color: #3e8e41;
}
.dropdown {
  float: right;
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  right: 0;
  z-index: 1;
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown button:hover {
  background-color: #ddd;
}
.dropdown-content button {
  width: 100%;
  height: 40px;
  font-size: 16px;
  color: #555;
  border: none;
}
.show {
  display: block;
}
@media all and (min-width: 1024px) and (max-width: 1280px) {
}
@media all and (min-width: 768px) and (max-width: 1024px) {
}
@media all and (min-width: 480px) and (max-width: 768px) {
  .comment-cell-container {
    max-width: 100%;
    width: 100%;
    padding: 0px;
  }
}
@media all and (max-width: 480px) {
  .comment-cell-container {
    max-width: 100%;
    width: 100%;
    padding: 0px;
  }
}
</style>

<script>
import { defineComponent } from "vue";
import moment from "moment";
import axios from "axios";

export default defineComponent({
  name: "CommentCell",
  data() {
    return {
      ago: "",
      userName: "",
      message: "",
      profileImageUrl: "",
      didLike: false,
      likesCount: 0,
      commenterWalletAddress: null,
      isOwnComment: false,
    };
  },
  computed: {
    loaderImageMedium: function () {
      return require("@/assets/img/loader-medium.gif");
    },
  },
  mounted() {
    this.refresh();
  },
  props: {
    commentId: {
      type: String,
    },
    postId: {
      type: String,
    },
    membershipId: {
      type: String,
    },
    clientWalletAddress: {
      type: String,
    },
  },
  inject: {
    API_BASE_URL: {
      from: "API_BASE_URL",
    },
    API_ENDPOINT_COMMENTS: {
      from: "API_ENDPOINT_COMMENTS",
    },
    API_ENDPOINT_USER: {
      from: "API_ENDPOINT_USER",
    },
    API_ENDPOINT_CREATE_LIKE_COMMENT: {
      from: "API_ENDPOINT_CREATE_LIKE_COMMENT",
    },
    API_ENDPOINT_DELETE_LIKE_COMMENT: {
      from: "API_ENDPOINT_DELETE_LIKE_COMMENT",
    },
    API_ENDPOINT_DELETE_COMMENT: {
      from: "API_ENDPOINT_DELETE_COMMENT",
    },
    API_ENDPOINT_REPORT: {
      from: "API_ENDPOINT_REPORT",
    },
  },
  emits: ["on-success-delete-comment", "on-fail-delete-comment"],
  methods: {
    refresh: function () {
      this.reset();
      this.profileImageUrl = this.loaderImageMedium;
      if (this.$refs.messageRef.innerHTML) {
        this.$refs.messageRef.innerHTML =
          this.$refs.messageRef.innerHTML.replace(
            /(https:\/\/[^\s]+)/g,
            `<div value='$1' class='comment-link-object' style='color: #1DA1F2; cursor: pointer;'>$1</div>`
          );
      }
      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_COMMENTS;
      axios
        .get(apiUrl, {
          params: {
            commentIds: [this.commentId],
          },
          // cancelToken: this.apiRequest.token
        })
        .then((result) => {
          if (result.data.comments) {
            result.data.comments.sort(
              (a, b) => parseFloat(b.created_at) - parseFloat(a.created_at)
            );
            result.data.comments.forEach((comment) => {
              let formattedText = `${comment.text}`.replaceAll("\\n", "\n");
              this.message = formattedText;
              var date = new Date(comment.created_at);
              let agoDate = moment(
                date,
                "ddd MMM DD YYYY HH:mm:ss GMT Z"
              ).fromNow();
              this.ago = agoDate;
              if (comment.likes) {
                this.likesCount = comment.likes.length;
                this.didLike = comment.likes.includes(this.clientWalletAddress);
              }
              if (comment.user) {
                this.commenterWalletAddress = comment.user;
                if (comment.user === this.clientWalletAddress) {
                  this.isOwnComment = true;
                } else {
                  this.isOwnComment = false;
                }
                let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_USER;
                this.$axios
                  .get(apiUrl, {
                    params: {
                      walletAddress: comment.user,
                      membershipId: this.membershipId,
                    },
                  })
                  .then((result) => {
                    let dataObj = JSON.parse(JSON.stringify(result.data));
                    if (dataObj.profile_image_url) {
                      this.profileImageUrl = dataObj.profile_image_url;
                    }
                    if (dataObj.name) {
                      this.userName = dataObj.name;
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
            });
          }
        })
        .catch((err) => {
          console.error(err);
          if (err.message === "api request canceled") {
            // do nothing
          }
        });
    },
    showActions: function () {
      var id_ = `comment-${this.commentId}`;
      document.getElementById(id_).classList.toggle("show");
    },
    clickReportEvent: function () {
      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_REPORT;
      axios
        .post(apiUrl, {
          membershipId: this.membershipId,
          postId: this.postId,
          reportedWalletAddress: this.commenterWalletAddress,
          reporterWalletAddress: this.clientWalletAddress,
          reportedMessage: this.message,
        })
        .then(() => {
          this.$swal({
            type: "success",
            title: "Report Sent✉️",
            text: `Thanks for your cooperation. We'll look into it.`,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: "OK",
            timer: 3000,
          });
        })
        .catch((err) => {
          console.error(err);
          this.$swal({
            type: "warning",
            title: "Report Failed",
            text: `Sorry, something went wrong. Please try again`,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: "OK",
            timer: 3000,
          });
        });
    },
    clickDeleteEvent: function () {
      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_DELETE_COMMENT;
      axios
        .delete(apiUrl, {
          data: {
            walletAddress: this.clientWalletAddress,
            membershipId: this.membershipId,
            postId: this.postId,
            commentId: this.commentId,
          },
        })
        .then(() => {
          this.$swal({
            position: "top-right",
            icon: "success",
            title: "Comment is deleted.",
            type: "success",
            showCancelButton: false,
            confirmButtonText: "OK",
            iconColor: "#000",
            timer: 1500,
          });
          this.$emit("on-success-delete-comment", this.commentId);
        })
        .catch((err) => {
          alert(err);
          console.error("delete comment error", err);
          this.$emit("on-fail-delete-comment");
        });
    },
    clickLikeEvent: function () {
      this.didLike = !this.didLike;
      if (this.didLike) {
        this.likesCount += 1;
      } else {
        this.likesCount -= 1;
      }
      if (this.didLike) {
        let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_CREATE_LIKE_COMMENT;
        axios
          .post(apiUrl, {
            walletAddress: this.clientWalletAddress,
            commentId: this.commentId,
          })
          .then(() => {})
          .catch((err) => {
            console.error("create comment like err", err);
          });
      } else {
        let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_DELETE_LIKE_COMMENT;
        axios
          .delete(apiUrl, {
            data: {
              walletAddress: this.clientWalletAddress,
              commentId: this.commentId,
            },
          })
          .then(() => {})
          .catch((err) => {
            console.error("delete like comment error", err);
          });
      }
    },
    onClickProfileImage: function (event) {
      if (!this.commenterWalletAddress) return;
      event.stopPropagation();

      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_USER;
      this.$axios
        .get(apiUrl, {
          params: {
            walletAddress: this.commenterWalletAddress,
            membershipId: this.membershipId,
          },
        })
        .then(() => {
          this.$router.push({
            name: "profile",
            query: {
              user: this.commenterWalletAddress,
              membershipId: this.membershipId,
            },
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    reset: function () {
      this.ago = null;
      this.userName = null;
      this.message = null;
      this.profileImageUrl = null;
      this.didLike = false;
      this.likesCount = null;
      this.commenterWalletAddress = null;
    },
  },
  watch: {
    commentId: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.reset();
        }
      },
    },
  },
});
</script>
