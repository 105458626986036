<template>
  <div class="rank-cell-container" @click="onClickCell">
    <div class="icon-rank"></div>
    <p class="text-rank">{{rank}}</p>
    <div class="profile-image" v-bind:style="{ backgroundImage: 'url(' + profileImageUrl + ')' }"></div>
    <p class="medium-text text-overflow-ellipsis">{{ userName }}</p>
    <p class="text-pink">{{ score }}</p>
  </div>
</template>

<style scoped>
.rank-cell-container {
  width: 135px;
  padding: 16px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 8px;
}
.profile-image {
  width: 50px;
  height: 50px;
  margin: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 25px;
  background-color: #000;
}
.icon-rank {
  width: 24px;
  height: 24px;
  margin: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('@/assets/img/icon-rank-black.png');
}
.text-rank {
  font-weight: 600;
  font-size: 20px;
  color: #000;
}
@media all and (min-width: 1024px) and (max-width: 1280px) { }
@media all and (min-width: 768px) and (max-width: 1024px) { }
@media all and (min-width: 480px) and (max-width: 768px) { }
@media all and (max-width: 480px) { }
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RankCell',
  data() {
    return {
      profileImageUrl: "",
      userName: "",
      score: 0,
      isOwn: false
    }
  },
  mounted() {
    this.profileImageUrl = "@/assets/img/loader-medium.gif"; // Show loader
    this.userName = this.walletAddress.substring(0, 8);
    let apiUrl = this.apiBaseUrl + this.apiEndpointUser;
    let _this = this;
    this.axiosPlugin.get(apiUrl, {
      params: {
        walletAddress: this.walletAddress,
        membershipId: this.membershipId
      }
    })
    .then(result => {
      let dataObj = JSON.parse(JSON.stringify(result.data));
      if (dataObj.profile_image_url) {
        _this.profileImageUrl = dataObj.profile_image_url;
      }
      if (dataObj.name) {
        _this.userName = dataObj.name;
      }
      if (dataObj.engagement) {
        let engagementObj = JSON.parse(JSON.stringify(dataObj.engagement));
        _this.score = engagementObj.engagement_score
      }
    })
    .catch(err => {
      console.error(err);
    });
  },
  props: {
    walletAddress: {
      type: String,
      default: ""
    },
    membershipId: {
      type: String,
      default: ""
    },
    apiBaseUrl: {
      type: String,
    },
    apiEndpointUser: {
      type: String,
    },
    rank: {
      type: Number,
      default: 0
    },
    axiosPlugin: {
      type: Object
    }
  },
  emits: ["click-rank-cell"],
  methods: {
    onClickCell: function() {
      this.$emit("click-rank-cell", this.walletAddress);
    }
  },
});
</script>
