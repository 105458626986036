<template>
  <MainHeader
    :title="title"
    :walletAddress="walletAddress"
    :chainName="chainName"
    :membershipId="membershipId"
    :isAdmin="isAdmin"
  />
  <div id="n-container" style="margin-top: 120px">
    <div id="contents">
      <div class="tab_box">
        <!-- Tab -->
        <div class="tab-container">
          <ul class="tab-list">
            <!-- <li class="tab-item active">Create Membership</li> -->
            <li class="tab-item" :class="{ 'active': isActive === 'B'}" @click="change('B')">{{ $t('PROFILE_SETTING.PROFILE_SETTING_TAB')  }}</li>
            <li class="tab-item" :class="{ 'active': isActive === 'C'}" @click="change('C')">{{ $t('PROFILE_SETTING.PURCHASED_HISTORY_TAB')  }}</li>
          </ul>
        </div>
        <div class="n-container">
          <div v-if="isActive === 'A'">
            <div class="n-container" id="membership-container"></div>
            <div id="buttons">
              <div>
                <button class="n-btn-primary" @click="goToCreateMembershipPage">
                  {{ $t('PROFILE_SETTING.CREATE_MEMBERSHIP') }}
                </button>
                <p class="weak-text text-white">
                  {{ $t('PROFILE_SETTING.MEMBERSHIP_QUOTA') }}
                </p>
              </div>
              <button class="n-btn-primary"> {{ $t('PROFILE_SETTING.BTN_UPGRADE_PLAN') }}</button>
            </div>
          </div>
          <!-- Profile tab content -->
          <div v-else-if="isActive === 'B'">
            <div class="profile-image" v-bind:style="{ backgroundImage: 'url(' + profileImageUrl + ')' }"
              @click="selectProfileImage">
              <div class="profile-sub-info">
                <button id="button-update-profile-image"></button>
                <div class="admin-mark" v-show="isAdmin">Admin</div>
              </div>
            </div>
            <p class="wallet-address-text">{{ walletAddress }}</p>
            <p class="error-text" v-show="isInvalidFileSize">
              {{ $t('PROFILE_SETTING.UPLOAD_IMG_LIMIT') }}
            </p>
            <form>
              <label> {{ $t('PROFILE_SETTING.NAME') }}</label>
              <input
                type="text"
                name=""
                :placeholder=" $t('PROFILE_SETTING.NAME_PLACEHOLDER')"
                v-bind:value="userName"
                v-on:input="onChangeUserName"
                maxlength="24"
              />
              <p class="error-text" v-show="isErrorName">
                {{ $t('PROFILE_SETTING.NAME_INVALID') }}
              </p>
            </form>
            <div style="margin-top: 16px">
              <button
                class="n-btn-primary"
                id="button-update"
                :class="{ loading: isUpdating }"
                @click="updateUser"
                :disabled="isUpdating"
              >
                <span v-show="!isUpdating">  {{ $t('BUTTON.UPDATE') }}</span>
              </button>
            </div>
            <!-- Engagement data -->
            <div style="margin-top: 16px" class="engagement-info-container">
              <div class="user-info-sign large">
                <div class=""></div>
                <p class="text-black">
                  {{ $t('PROFILE_SETTING.ENGAGEMENT_SCORE') }}
                  <span class="strong-text">{{ engagementScore }}</span>
                </p>
              </div>
              <div class="user-info-sign">
                <div class="icon-post-black"></div>
                <p class="text-black">
                  <span class="">{{ postsCount }}</span>   {{ $t('PROFILE_SETTING.POSTS_NUMBER') }}
                </p>
              </div>
              <div class="user-info-sign">
                <div class="icon-comment-black"></div>
                <p class="text-black">
                  <span class="">{{ commentsCount }}</span> {{ $t('PROFILE_SETTING.COMMENT_RECEIVE') }}
                </p>
              </div>
              <div class="user-info-sign">
                <div class="icon-like-black"></div>
                <p class="text-black">
                  <span class="">{{ likesCount }}</span>  {{ $t('PROFILE_SETTING.LIKE_RECEIVE') }}
                </p>
              </div>
              <br />
              <!--               <p class="weak-text">
                *Note: Those data takes some time to update.
              </p> -->
            </div>

            <!-- Shop Info -->
            <div v-if="shopEmailAddress != null" class="profile-info-container" style="margin-top: 24px">
              <!--            <h3>Shop History</h3>
              <p class="weak-text">
                *The history is displayed up to six months ago.
              </p>
              <div class="shop-history-container">
                <ShopHistoryCell
                  v-for="(item, key) in shopHistories"
                  :key="key"
                />
              </div> -->

              <h3>{{ $t('PROFILE_SETTING.PURCHASED_ITEM') }}</h3>
              <p class="shop-contact">
                {{ $t('PROFILE_SETTING.CONTACT') }}:
                <a :href="`mailto:${shopEmailAddress}`">{{
                  shopEmailAddress
                  }}</a>
              </p>
            </div>

            <!-- Logout button -->
            <div class="n-container">
              <button
                class="n-btn-primary"
                id="button-logout"
                @click="logout(this.LOCAL_STORAGE_KEY_MEMBERSHIP)"
              >
              {{ $t('BUTTON.LOGOUT') }}
              </button>
            </div>

            <!-- NFTs -->
            <h3 v-if="nfts?.length > 0" style="margin-top: 32px">
              {{ userName }}'s Tokens
            </h3>
            <div id="nft-container">
              <NFTCell
                v-for="(item, key) in nfts"
                :key="key"
                :membershipId="membershipId"
                :issuerName="item?.issuerName"
                :tokenAddress="item?.tokenAddress"
                :tokenId="item?.tokenId"
                :tokenUri="item?.tokenUri"
                :tokenName="item?.tokenName"
                :externalUrl="item?.externalUrl"
                :isFT="item?.isFT"
                :balance="item?.balance"
                :onClicKMemberShipClipboard="clickMembershipClipboard"
              />
              <div class="loader" v-show="isLoadingNFT"></div>
            </div>
          </div>
          <!-- Purchased tab -->
          <div v-if="isActive === 'C'">
            <div class="purchased-items">
              <h2 class="purchased-items-title">{{ $t('PROFILE_SETTING.PURCHASED_PRODUCT_TITLE')  }}</h2>
              <!-- <masonry-wall :items="purchasedItems" :column-width="280" :gap="12"> -->
                <!-- <template #default="{ item, index }"> -->
                <template v-for="(item, idx) in purchasedItems" :key="idx">
                  <PurchasedItemCell  :item="item" v-on:show-spinner="showSpinner = $event" />
                </template>
                <!-- </template> -->
              <!-- </masonry-wall> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#membership-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}
#nft-container {
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
ul {
  padding: 0;
}
li {
  margin: auto;
  list-style: none;
}
.tab_list {
  overflow: hidden;
  text-align: center;
}
.tab_list li {
  width: 140px;
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  transition: 0.1s;
  color: #888;
}
.tab_list li:not(:first-child) {
  border-left: none;
}
.tab_list li.active {
  color: #0091ff;
  font-weight: bold;
  border-bottom: 3px solid #0091ff;
  cursor: auto;
}
.wallet-address-text {
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 24px 6px;
  font-size: 14px;
  color: #000;
}
.profile-image {
  position: relative;
  width: 200px;
  height: 200px;
  margin: auto;
  border: 1px solid #888;
  background-color: #000;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border-radius: 8px;
}
.profile-sub-info {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#button-remove-profile-image {
  float: left;
  width: 30px;
  height: 30px;
  margin-left: 12px;
  margin-top: 12px;
  border-radius: 15px;
  background-image: url("@/assets/img/icon-close.png");
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
}
#button-update-profile-image {
  float: left;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 15px;
  background-image: url("@/assets/img/icon-edit.png");
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
}
#button-update {
  position: relative;
  padding: 8px 16px;
  border: none;
  outline: none;
  cursor: pointer;
  width: 120px;
  height: 36px;
}
.loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}
#button-logout {
  width: 120px;
  height: 36px;
  background-color: #aab8c2;
}
.shop-history-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 50%;
  margin: 24px auto;
}
.shop-contact {
  font-size: 14px;
}
.tab-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tab-item {
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    transition: 0.1s;
    color: #888;
}

.tab-item.active {
  color: #000;
    font-weight: bold;
    border-bottom: 3px solid #000;
    cursor: auto;
}
.purchased-items-title {
  margin-bottom: 0.5em;
}
@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}
form {
  margin-top: 24px;
}
#buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
}
#buttons button {
  width: 200px;
  margin: auto;
}
@media all and (min-width: 1024px) and (max-width: 1280px) {
}
@media all and (max-width: 1024px) {
  .shop-history-container {
    width: 100%;
  }
  .tab-item {
    width: 140px;
}
}
</style>

<script>
import { defineComponent } from "vue";
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import Moralis from 'moralis/dist/moralis.min.js';
import Moralis from "moralis";
import shared from "@/shared";
import MainHeader from "@/components/MainHeader.vue";
import NFTCell from "@/components/NFTCell.vue";
// import ShopHistoryCell from "@/components/ShopHistoryCell.vue";
import axios from "axios";
import swalContainer from  '@/utils/swalContainer.js'

import PurchasedItemCell from "@/components/PurchasedItemCell.vue";
import * as moment from "moment";
export default defineComponent({
  name: "ProfileSettingView",
  data() {
    return {
      isActive: "B",
      profileImageUrl: "",
      title: "- - -",
      membershipId: "",
      walletAddress: "",
      chainName: "",
      userName: "",
      selectedImageFile: false,
      isErrorName: false,
      isInvalidFileSize: false,
      isLoadingImage: false,
      isUpdating: false,
      isAdmin: false,
      postsCount: 0,
      commentsCount: 0,
      likesCount: 0,
      engagementScore: 0.0,
      isLoadingNFT: false,
      nfts: [],
      shopHistories: [{}, {}, {}],
      shopEmailAddress: null,
      purchasedItems:[]
    };
  },
  computed: {
    loaderImageSmall: function () {
      return require("@/assets/img/loader-small.gif");
    },
  },
  setup() {
    initializeApp(shared.firebaseConfig());
  },
  unmounted() {
    // Unbound shared functions
    this.initMoralis = null;
    shared.initMoralis.bind(null);
    this.connectWallet = null;
    shared.connectWallet.bind(null);
    this.logout = null;
    shared.logout.bind(null);
    this.checkNFT = null;
    shared.checkNFT.bind(null);
    this.handleErrorAndGoTop = null;
    shared.handleErrorAndGoTop.bind(null);
    this.getChainParamFromChainId = null;
    shared.getChainParamFromChainId.bind(null);
  },
  mounted() {
    let _this = this;

    /* Bind shared function */
    this.connectWallet = shared.connectWallet.bind(this);
    this.initMoralis = shared.initMoralis.bind(this);
    this.logout = shared.logout.bind(this);
    this.checkNFT = shared.checkNFT.bind(this);
    this.handleErrorAndGoTop = shared.handleErrorAndGoTop.bind(this);
    this.getChainParamFromChainId = shared.getChainParamFromChainId.bind(this);

    /* Init Moralis */
    this.initMoralis(
      this.MORALIS_SERVER_URL,
      this.MORALIS_APP_ID,
      this.MORALIS_MASTER_KEY
    );

    // Show loader on profile image on mounted
    this.profileImageUrl = this.loaderImageSmall;

    /* Set header title from cached data */
    let membershipObj = JSON.parse(
      sessionStorage.getItem(this.LOCAL_STORAGE_KEY_MEMBERSHIP)
    );
    if (!membershipObj) {
      // Local cacheが無い。異常事態なのでトップへ戻る
      console.error("Profile: error: No required cache, going back to top..");
      this.logout(this.LOCAL_STORAGE_KEY_MEMBERSHIP);
      this.handleErrorAndGoTop(
        this.$t('POPUP.TITLE_COMMON_ERROR'), 
        this.$t('POPUP.PLEASE_LOGIN_AGAIN'),
        3000
      );
      return;
    }
    if (this.$route.query.membershipId) {
      this.membershipId = this.$route.query.membershipId;
    } else {
      this.handleErrorAndGoTop(
        this.$t('POPUP.TITLE_COMMON_ERROR'), 
        this.$t('POPUP.PLEASE_LOGIN_AGAIN'),
        3000
      );
      return;
    }
    this.title = membershipObj.name;
    let tokenAddress = membershipObj.tokenAddress;
    let tokenId = membershipObj.tokenId;

    /* Connect to wallet */
    this.connectWallet(async (err, event, walletData) => {
      if (err) {
        _this.handleErrorAndGoTop(
         _this.$t('POPUP.TITLE_WALLET_DISCONNECTED'),
         _this.$t('POPUP.PLEASE_LOGIN_AGAIN'),
          3000
        );
        return;
      }
      if (event === "accountsChanged") {
        _this.handleErrorAndGoTop(
          _this.$t('POPUP.TITLE_WALLET_CHANGED'),
          _this.$t('POPUP.NEED_TO_LOGIN'),
          3000
        );
        return;
      }
      let walletObj = JSON.parse(walletData);
      if (!walletObj.walletAddress || !walletObj.chainId) {
        _this.handleErrorAndGoTop(
          _this.$t('POPUP.TITLE_WALLET_DISCONNECTED'),
          _this.$t('POPUP.PLEASE_LOGIN_AGAIN'),
          3000
        );
        return;
      }
      _this.walletAddress = walletObj.walletAddress;
      switch (walletObj.chainId) {
        case 1:
          _this.chainName = "Ethereum";
          break;
        case 137:
          _this.chainName = "Polygon";
          break;
        default:
          swalContainer.warningDiffNetwork()
          break;
      }

      /* Get Shop Enquiry address */
      try {
        let apiUrl = _this.API_BASE_URL + _this.API_ENDPOINT_ENQUIRY_CONTACT;
        const result = await axios.get(apiUrl, {
          params: {
            membershipId: _this.membershipId, 
            walletAddress: _this.walletAddress,
          }
        });
        _this.shopEmailAddress = result.data.contact;
        console.log("result enquiry contact", _this.membershipId, _this.walletAddress, result.data.contact);
      } catch (error) {
        console.log("cannot get shop enquiry contact", error);
      }


      /* Check NFT */
      let chainParam = _this.getChainParamFromChainId(walletObj.chainId);
      _this.checkNFT(
        chainParam,
        _this.walletAddress,
        tokenAddress,
        tokenId,
        function (isValidTokenExist) {
          if (!isValidTokenExist) {
            _this.handleErrorAndGoTop(
              _this.$t('POPUP.TITLE_REQUIRED_NFT_CODE_104'),
              _this.$t('POPUP.CONTENT_REQUIRED_NFT_CODE_104'),
              3000
            );
            return;
          }
          /* Check if user is admin to show/hide header admin button  */
          let apiUrl = _this.API_BASE_URL + _this.API_ENDPOINT_MEMBERSHIP;
          axios
            .get(apiUrl, {
              params: {
                membershipId: _this.membershipId,
                walletAddress: _this.walletAddress,
              },
            })
            .then((result) => {
              let dataObj = JSON.parse(JSON.stringify(result.data));
              _this.isAdmin = dataObj.is_admin;
            })
            .catch((err) => {
              console.error(err);
            });

          /* Get user NFTs */
          _this.getUserNFTs();

          /* Load profile */
          _this.loadProfile(
            _this.walletAddress,
            _this.membershipId,
            (err, userObj) => {
              if (err) {
                this.$router.push("timeline");
                return;
              }
              if (userObj.profile_image_url) {
                _this.profileImageUrl = userObj.profile_image_url;
              }
              if (userObj.name) {
                _this.userName = userObj.name;
              }
              if (userObj.engagement) {
                let engagementObj = userObj.engagement;

                if (engagementObj.posts_count) {
                  _this.postsCount = engagementObj.posts_count;
                }
                if (engagementObj.comments_count) {
                  _this.commentsCount = engagementObj.comments_count;
                }
                if (engagementObj.likes_count) {
                  _this.likesCount = engagementObj.likes_count;
                }
                if (engagementObj.engagement_score) {
                  _this.engagementScore = engagementObj.engagement_score;
                }
              }
            }
          );
        }
      );
      /* Get list purchased product */ 
     await this.getPurchasedProduct();
    });
  },
  inject: {
    LOCAL_STORAGE_KEY_MEMBERSHIP: {
      from: "LOCAL_STORAGE_KEY_MEMBERSHIP",
    },
    MORALIS_SERVER_URL: {
      from: "MORALIS_SERVER_URL",
    },
    MORALIS_APP_ID: {
      from: "MORALIS_APP_ID",
    },
    MORALIS_MASTER_KEY: {
      from: "MORALIS_MASTER_KEY",
    },
    API_BASE_URL: {
      from: "API_BASE_URL",
    },
    API_ENDPOINT_MEMBERSHIP: {
      from: "API_ENDPOINT_MEMBERSHIP",
    },
    API_ENDPOINT_USER: {
      from: "API_ENDPOINT_USER",
    },
    API_ENDPOINT_UPDATE_USER: {
      from: "API_ENDPOINT_UPDATE_USER",
    },
    API_ENDPOINT_ENQUIRY_CONTACT: {
      from: "API_ENDPOINT_ENQUIRY_CONTACT",
    },
    API_ENDPOINT_LIST_PURCHASED_PRODUCT: {
      from: "API_ENDPOINT_LIST_PURCHASED_PRODUCT",
    },
  },
  components: {
    MainHeader,
    NFTCell,
    // ShopHistoryCell,
    PurchasedItemCell
  },
 
  methods: {
    change: function (num) {
      this.isActive = num;
    },
    // removeProfileImage: function() {
    //   console.log("removing profile..");
    // },
    loadProfile: function (walletAddress, membershipId, callback) {
      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_USER;
      axios
        .get(apiUrl, {
          params: {
            walletAddress: walletAddress,
            membershipId: membershipId,
          },
        })
        .then((result) => {
          let userObj = JSON.parse(JSON.stringify(result.data));
          callback(null, userObj);
        })
        .catch((err) => {
          console.error(err);
          callback(err, null);
        });
    },
    onChangeUserName: function (event) {
      this.userName = event.target.value;
    },
    updateUser: function () {
      this.isErrorName = false;
      this.isUpdating = true;
      var _this = this;
      if (this.userName && this.userName.length > 3) {
        let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_UPDATE_USER;
        if (this.selectedImageFile && this.selectedImageFile.type) {
          const storage = getStorage();
          var imageExtension = "";
          switch (this.selectedImageFile.type) {
            case "image/png":
              imageExtension = ".png";
              break;
            case "image/jpeg":
              imageExtension = ".jpeg";
              break;
            default:
              shared.handleError();
              this.isErrorName = true;
              this.isUpdating = false;
              return;
          }
          let imagePath = `/profile_images/${this.walletAddress}${imageExtension}`;
          const storageRef = ref(storage, imagePath);
          uploadBytes(storageRef, this.selectedImageFile)
            .then((snapshot) => {
              return getDownloadURL(snapshot.ref);
            })
            .then((downloadURL) => {
              axios
                .post(apiUrl, {
                  walletAddress: this.walletAddress,
                  name: this.userName,
                  profileImageUrl: downloadURL,
                })
                .then(() => {
                  _this.isUpdating = false;
                  _this.handleSuccess = shared.handleSuccess.bind(_this);
                  _this.handleSuccess();
                  _this.selectedImageFile = null;
                })
                .catch((err) => {
                  console.error(err);
                  _this.isUpdating = false;
                  _this.handleError = shared.handleError.bind(_this);
                  _this.handleError();
                });
            });
        } else {
          // ユーザー名のみアップデート /userUpdate
          axios
            .post(apiUrl, {
              walletAddress: this.walletAddress,
              name: this.userName,
            })
            .then((result) => {
              let resultObj = JSON.parse(JSON.stringify(result.data));
              _this.isUpdating = false;
              if (resultObj.is_user_exist) {
                _this.handleSuccess = shared.handleSuccess.bind(_this);
                _this.handleSuccess();
              } else {
                _this.handleError = shared.handleError.bind(_this);
                _this.handleError();
              }
            })
            .catch((err) => {
              console.error(err);
              _this.isUpdating = false;
              _this.handleError = shared.handleError.bind(_this);
              _this.handleError();
            });
        }
      } else {
        this.isErrorName = true;
        this.isUpdating = false;
      }
    },
    selectProfileImage: function () {
      let _this = this;
      var input = document.createElement("input");
      input.type = "file";
      input.accept = "image/png, image/jpeg";
      input.onchange = (e) => {
        if (e.target.files.length > 0) {
          var file = e.target.files[0];
          let maxfilesize = 1024 * 1024 * 2; // 2 Mb
          if (file.size > maxfilesize) {
            _this.isInvalidFileSize = true;
            return;
          } else {
            _this.isInvalidFileSize = false;
          }
          _this.profileImageUrl = URL.createObjectURL(file);
          _this.selectedImageFile = file;
        }
      };
      input.click();
    },
    clickMembershipClipboard: function (tokenAddress) {
      event.stopPropagation();
      let _this = this;
      navigator.clipboard.writeText(tokenAddress);
      _this.handleClipboard_ = shared.handleShowClipboard.bind(_this);
      _this.handleClipboard_();
    },
    goToCreateMembershipPage: function () {
      this.$router.push("create-membership");
    },
    getUserNFTs: function () {
      this.isLoadingNFT = true;
      (async () => {
        /*
        // Deprecated
        var options = {
          chain: `${this.chainName.toLowerCase()}`,
          address: this.walletAddress,
        };
        const userNFTs = await Moralis.Web3API.account.getNFTs(options);
        */
        var chainHex;
        switch (`${this.chainName.toLowerCase()}`) {
          case "eth":
            chainHex = "0x1";
            break;
          case "polygon":
            chainHex = "0x89";
            break;
          default:
            break;
        }
        const userNFTs = await Moralis.EvmApi.nft.getWalletNFTs({
          chain: chainHex,
          address: this.walletAddress,
          format: "decimal",
          tokenAddresses: [],
        });
        let result = userNFTs.raw["result"];
        if (result) {
          /** Filter Spam */
          result = result.filter((element) => !element.possible_spam)
          
          result.forEach((element) => {
            let issuerName = element["name"];
            let tokenAddress = element["token_address"];
            let tokenId = element["token_id"];
            let tokenUri = element["token_uri"];
            var metadata = element["metadata"];
            var tokenName = "";
            var externalUrl = null;
            if (metadata) {
              var json = JSON.parse(metadata);
              tokenName = json["name"];
              externalUrl = json["external_url"];
            }
            let nft = {
              issuerName: issuerName,
              tokenAddress: tokenAddress,
              tokenId: tokenId,
              tokenUri: tokenUri,
              tokenName: tokenName,
              externalUrl: externalUrl,
            };
            this.nfts.push(nft);
          });
        }
        /** Get FT by wallet address */ 
        const userFTs = await shared.getFTsByWalletAdress( this.walletAddress, this.chainName.toLowerCase())
         // Merge NFT and FT
        this.nfts = [...this.nfts, ...userFTs]
        
        this.isLoadingNFT = false;
      })();
    },
    async getPurchasedProduct() {
      const apiUrl = this.API_BASE_URL + this.API_ENDPOINT_LIST_PURCHASED_PRODUCT;
      const res =  await axios
        .get(apiUrl, {
          params: {
            membershipId: this.membershipId,
            walletAddress: this.walletAddress,
          },
        })
        this.purchasedItems = res.data.data.map((x) => {
          return {
            name: x.item_name,
            price: x.price,
            amount: x.amount,
            currency: x.currency,
            quantity: x.quantity,
            imageUrls: x.image_urls,
            purchasedAt: moment(x.purchased_at).format('YYYY-MM-DD HH:mm'),
            receiptUrl: x.receipt_url
          }
        })
    }
  },
 
});
</script>
