import axios from "axios";
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';
import LRUCache from 'lru-cache';

const AxiosPlugin = {
	install(app) {
		if (!app.config.globalProperties.$axios) {
			const lruOptions = {
			  max: 20,
			  ttl: 1000 * 60 * 30,
			  maxSize: 200 * 1024 * 1024,
			  sizeCalculation: () => {
			    return 1
			  }
			}
	      	const axios_ = axios.create({
			    baseURL: '/',
			    headers: { 'Cache-Control': 'no-cache',  'Content-Type': 'application/json'},
			    withCredentials: true,
			    // adapter: cacheAdapterEnhancer(axios.defaults.adapter),
			    adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter), {threshold: 2 * 1000, cache: new LRUCache(lruOptions)}),
			    timeout: 30000
			});
	      	app.config.globalProperties.$axios = axios_;
	  	}
	  	
		/*
		const lruOptions = {
		  max: 20,
		  ttl: 1000 * 60 * 30,
		  maxSize: 200 * 1024 * 1024,
		  sizeCalculation: () => {
		    return 1
		  }
		}
      	const axios_ = axios.create({
		    baseURL: '/',
		    headers: { 'Cache-Control': 'no-cache',  'Content-Type': 'application/json'},
		    withCredentials: true,
		    // adapter: cacheAdapterEnhancer(axios.defaults.adapter),
		    adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter), {threshold: 2 * 1000, cache: new LRUCache(lruOptions)}),
		    timeout: 30000
		});
		app.provide("$axios", axios_);
	  	*/
	}
};

export default AxiosPlugin;