<template>
	<div class="n-container">
    Not Found
	</div>
</template>

<style scoped>
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NotFoundView',
  data() {
  	return {
  	}
  }
});

</script>