<template>
  <div
    class="post-cell-container"
    v-bind:id="'post-cell-container-' + postData?.id"
    @click="onClickCell"
  >
    <div class="left-column">
      <div
        class="profile-image"
        v-bind:style="{ backgroundImage: 'url(' + profileImageUrl + ')' }"
        @click="goProfile"
      ></div>
    </div>
    <div class="right-column">
      <div class="right-top-container">
        <p>{{ userName }}</p>
        <div class="dropdown">
          <button @click="showActions" class="button-show-actions"></button>
          <div v-bind:id="'post-' + postData?.id" class="dropdown-content">
            <button @click="report">{{ $t('BUTTON.REPORT') }}</button>
            <button @click="delete_" v-show="isOwnPost">{{ $t('BUTTON.DELETE') }}</button>
          </div>
        </div>
      </div>
      <div class="right-middle-container">
        <p id="user-message" ref="input1">{{ userMessage }}</p>
      </div>
      <div
        class="ogp-container"
        v-if="ogpUrl !== null && ogpUrl !== ''"
        @click="openLink"
      >
        <div
          class="ogp-image"
          :style="{ backgroundImage: 'url(' + ogpImageUrl + ')' }"
          v-if="ogpImageUrl !== null && ogpImageUrl !== ''"
        ></div>
        <div class="ogp-info-wrapper">
          <p class="ogp-title pargraph-line-break-1">{{ ogpTitle }}</p>
          <p class="ogp-description pargraph-line-break-2">
            {{ ogpDescription }}
          </p>
          <p class="ogp-url pargraph-line-break-1">{{ ogpUrl }}</p>
        </div>
      </div>
      <div
        class="post-image"
        :style="{ backgroundImage: 'url(' + postData.image_url + ')' }"
        v-if="postData.image_url != null"
        @click="showPostImage"
      ></div>
      <div class="right-bottom-container">
        <div>
          <button
            class="button-comment"
            v-bind:id="'button-comment-' + postData.id"
            @click="clickCommentEvent"
            :class="{ 'did-comment': didComment }"
          ></button>
          <p v-if="postData.comments.length">{{ postData.comments.length }}</p>
          <p v-else>0</p>
          <button
            class="button-like"
            v-bind:id="'button-like-' + postData.id"
            @click="clickLikeEvent"
            :class="{ 'did-like': didLike }"
          ></button>
          <p>{{ likesCount }}</p>
        </div>
      </div>
      <div>
        <p class="weak-text">{{ ago }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.ogp-container {
  background-color: beige;
  text-align: left;
  margin: 24px;
  border-radius: 8px;
}
.ogp-info-wrapper {
  padding: 6px 24px;
}
.ogp-image {
  width: 100%;
  height: 120px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.ogp-title {
  font-weight: 600;
  font-size: 14px;
  color: #555;
  margin: 4px 0;
}
.ogp-description {
  font-weight: 500;
  font-size: 12px;
  color: #888;
  margin: 4px 0;
}
.ogp-url {
  font-weight: 600;
  font-size: 12px;
  color: #888;
  margin: 4px 0;
}
.weak-text {
  float: right;
  margin-right: 12px;
  color: #aaa !important;
}
.post-image {
  margin: 24px;
  height: 100px;
  background-position: center center;
  background-size: cover;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-color: beige;
  pointer-events: none;
}
.pargraph-line-break-1 {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.pargraph-line-break-2 {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.post-cell-container {
  display: flex;
  overflow: hidden;
  background-color: #fff;
  cursor: pointer;
  padding: 12px;
  border: 1px solid #000;
  border-radius: 8px;
}
.left-column {
  display: flex;
  width: 20%;
}
.right-column {
  width: 80%;
}
.profile-image {
  width: 50px;
  height: 50px;
  margin: auto;
  border: 1px solid #ddd;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 25px;
  background-color: #000;
  cursor: pointer;
}
.right-top-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.right-top-container p {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 12px;
  font-weight: 700;
  font-color: #555;
}
.right-middle-container p {
  padding: 24px;
  word-wrap: break-word;
  text-align: left;
  line-height: 30px;
  font-weight: 500;
  font-size: 18px;
  white-space: pre-wrap;
}
.right-bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}
.right-bottom-container div {
  display: flex;
  gap: 12px;
  align-items: center;
}
.right-bottom-container button {
  width: 30px;
  height: 30px;
  border: none;
  background-color: transparent;
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
}
.right-bottom-container p {
  margin: 0;
  font-weight: 500;
  color: #555;
}
.button-comment {
  background-image: url("@/assets/img/icon-comment-light.png");
}
.button-comment.did-comment {
  background-image: url("@/assets/img/icon-comment-black.png");
}
.button-like {
  background-image: url("@/assets/img/icon-like-light.png");
}
.button-like.did-like {
  background-image: url("@/assets/img/icon-like-filled-black.png");
}
.button-show-actions {
  width: 50px;
  height: 50px;
  padding: 0; /* This is to center button image  */
  border: none;
  width: 50px;
  height: 50px;
  display: inline-block;
  background-image: url("@/assets/img/icon-dots.png");
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
}
.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
.dropbtn:hover,
.dropbtn:focus {
  background-color: #3e8e41;
}
.dropdown {
  float: right;
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  right: 0;
  z-index: 1;
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown button:hover {
  background-color: #ddd;
}
.dropdown-content button {
  width: 100%;
  height: 40px;
  font-size: 16px;
  color: #555;
  border: none;
}
.dropdown-content.show {
  display: block;
}
/*.show {display: block;}*/
@media all and (min-width: 1024px) and (max-width: 1280px) {
}
@media all and (min-width: 768px) and (max-width: 1024px) {
}
@media all and (min-width: 480px) and (max-width: 768px) {
  .post-cell-container {
    max-width: 100%;
  }
}
@media all and (max-width: 480px) {
  .post-cell-container {
    max-width: 100%;
  }
}
</style>

<script>
import { defineComponent } from "vue";
import moment from "moment";
import axios from "axios";

export default defineComponent({
  name: "PostCell",
  data() {
    return {
      ago: "",
      profileImageUrl: "",
      ogpImageUrl: null,
      postImageUrl: null,
      userName: "",
      likesCount: 0,
      didLike: false,
      didComment: false,
      isOwnPost: false,
      ogpTitle: "",
      ogpDescription: "",
      ogpUrl: "",
      userMessage: "",
    };
  },
  computed: {
    loaderImageMedium: function () {
      return require("@/assets/img/loader-medium.gif");
    },
  },
  mounted() {
    if (this.postData.text) {
      let formattedText = `${this.postData.text}`.replaceAll("\\n", "\n");
      this.userMessage = formattedText;
      if (this.userMessage) {
        this.$refs.input1.innerHTML = this.$refs.input1.innerHTML.replace(
          /(https:\/\/[^\s]+)/g,
          `<div value='$1' class='post-link-object' style='color: #1DA1F2; text-decoration: underline;' >$1</div>`
        );
        this.$refs.input1.innerHTML = this.$refs.input1.innerHTML.replace(
          /(http:\/\/[^\s]+)/g,
          `<div value='$1' class='post-link-object' style='color: #1DA1F2; text-decoration: underline;'>$1</div>`
        );
        // GET OGP
        let matches = this.userMessage.match(/\bhttps?:\/\/\S+/gi);
        if (matches && matches.length > 0) {
          let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_OGP;
          let _this = this;
          this.$axios
            .get(apiUrl, {
              params: {
                url: matches[0],
              },
            })
            .then((result) => {
              let dataObj = JSON.parse(JSON.stringify(result.data));
              _this.ogpTitle = dataObj.title;
              _this.ogpDescription = dataObj.description;
              _this.ogpImageUrl = dataObj.url;
              _this.ogpUrl = matches[0];
            })
            .catch((err) => {
              console.error(err, matches[0]);
            });
        }
      }
    }
    // Set timestamp
    if (this.postData.created_at) {
      let date = new Date(this.postData.created_at);
      let agoDate = moment(date, "ddd MMM DD YYYY HH:mm:ss GMT Z").fromNow();
      this.ago = agoDate;
    }
    // Likes配列に自分のwallet addressが入っていたら、likes buttonをdisable
    this.didLike = this.postData.likes.includes(this.clientWalletAddress);
    if (this.postData.commenters) {
      this.didComment = this.postData.commenters.includes(
        this.clientWalletAddress
      );
    }
    // ライク時にAPIのレスポンスを待たずにすぐにライクのカウントをIncrementするため、変数を分けた
    this.likesCount = this.postData.likes.length;
    if (this.postData.user === this.clientWalletAddress) {
      this.isOwnPost = true;
    } else {
      this.isOwnPost = false;
    }
    this.profileImageUrl = this.loaderImageMedium;
    this.userName = this.postData.user.substring(0, 8);
    let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_USER;
    let _this = this;
    this.$axios
      .get(apiUrl, {
        params: {
          walletAddress: this.postData.user,
          membershipId: this.membershipId,
        },
      })
      .then((result) => {
        let dataObj = JSON.parse(JSON.stringify(result.data));
        if (dataObj.profile_image_url) {
          _this.profileImageUrl = dataObj.profile_image_url;
        }
        if (dataObj.name) {
          _this.userName = dataObj.name;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  },
  props: {
    postData: {
      type: Object,
      default: null,
    },
    clientWalletAddress: {
      type: String,
      default: "",
    },
    membershipId: {
      type: String,
    },
  },
  inject: {
    API_BASE_URL: {
      from: "API_BASE_URL",
    },
    API_ENDPOINT_USER: {
      from: "API_ENDPOINT_USER",
    },
    API_ENDPOINT_CREATE_USER: {
      from: "API_ENDPOINT_CREATE_USER",
    },
    API_ENDPOINT_OGP: {
      from: "API_ENDPOINT_OGP",
    },
    API_ENDPOINT_REPORT: {
      from: "API_ENDPOINT_REPORT",
    },
    API_ENDPOINT_CREATE_LIKE: {
      from: "API_ENDPOINT_CREATE_LIKE",
    },
    API_ENDPOINT_DELETE_LIKE: {
      from: "API_ENDPOINT_DELETE_LIKE",
    },
    API_ENDPOINT_DELETE_POST: {
      from: "API_ENDPOINT_DELETE_POST",
    },
  },
  emits: [
    "my-event",
    "click-comment-event",
    "click-like-event",
    "click-profile-event",
    "click-image-event",
  ],
  methods: {
    setDesignParttern: function (patternName) {
      switch (patternName) {
        case "pattern1":
          this.pattern = 1;
          break;
      }
    },
    showActions: function (event) {
      event.stopPropagation();
      // Hide menu in case some others are shown
      let dropDowns = document.getElementsByClassName("dropdown-content");
      for (var i = 0; i < dropDowns.length; i++) {
        if (dropDowns[i].id !== `post-${this.postData.id}`) {
          dropDowns[i].classList.remove("show");
        }
      }
      // Show menu
      let div = document.getElementById(`post-${this.postData.id}`);
      div.classList.toggle("show");
    },
    /*
    pin: function() {
      console.log("pinning..", this.postData.id);
    },
    removePin: function() {
      console.log("removing pin..", this.postData.id);
    },
    */
    report: function (event) {
      event.stopPropagation();
      let values = {
        membershipId: this.membershipId,
        postId: this.postData.id,
        reportedWalletAddress: this.postData.user,
        reporterWalletAddress: this.clientWalletAddress,
        reportedMessage: this.userMessage,
      };
      this.$emit("click-report-post-event", values);
    },
    delete_: function (event) {
      event.stopPropagation();
      // Delete post
      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_DELETE_POST;
      axios
        .delete(apiUrl, {
          data: {
            walletAddress: this.clientWalletAddress,
            membershipId: this.membershipId,
            postId: this.postData.id,
          },
        })
        .then(() => {
          // Timelineにイベントをemitして投稿をリロード
          this.$emit("success-delete-post-event", this.postData.id);
        })
        .catch((err) => {
          console.error(err);
          this.$emit("fail-delete-post-event", err);
        });
    },
    clickCommentEvent: function (e) {
      var postData = {
        postId: this.postData.id,
        walletAddress: this.postData.user,
        userName: this.userName,
        profileImageUrl: this.profileImageUrl,
        userMessage: this.userMessage,
        ago: this.ago,
        likes: this.postData.likes,
        comments: this.postData.comments,
        ogpTitle: this.ogpTitle,
        ogpDescription: this.ogpDescription,
        ogpImageUrl: this.ogpImageUrl,
        ogpUrl: this.ogpUrl,
        postImageUrl: this.postData.image_url,
      };
      this.$emit("click-comment-event", postData);
      if (e) {
        e.stopPropagation();
      }
    },
    clickLikeEvent: function (e) {
      this.didLike = !this.didLike;
      if (this.didLike) {
        this.likesCount += 1;
      } else {
        this.likesCount -= 1;
      }
      e.stopPropagation();
      if (this.didLike) {
        let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_CREATE_LIKE;
        this.$axios
          .post(apiUrl, {
            walletAddress: this.clientWalletAddress,
            membershipId: this.membershipId,
            postId: this.postData.id,
          })
          .then(() => {})
          .catch((err) => {
            console.error(err);
          });
      } else {
        let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_DELETE_LIKE;
        this.$axios
          .delete(apiUrl, {
            data: {
              walletAddress: this.clientWalletAddress,
              membershipId: this.membershipId,
              postId: this.postData.id,
            },
          })
          .then(() => {})
          .catch((err) => {
            console.error(err);
          });
      }
    },
    goProfile: function (event) {
      this.$emit("click-profile-event", this.postData.user);
      event.stopPropagation();
    },
    onClickCell: function () {
      this.clickCommentEvent();
    },
    openLink: function (event) {
      window.open(this.ogpUrl, "_blank").focus();
      event.stopPropagation();
    },
    showPostImage: function (event) {
      event.stopPropagation();
      this.$emit("click-image-event", this.postImageUrl);
    },
  },
});
</script>
