<template>      
    <div class="payment-form">
      <p class="section-title">{{ $t('STRIPE_FORM.CREDIT_CARD_INFO') }}</p>
      <div id="card-element"></div>

      <p class="section-title">{{ $t('STRIPE_FORM.BILLING_ADDRESS') }}</p>
      <label>
        {{ $t('STRIPE_FORM.FULL_NAME') }}
        <input v-model="billingDetails.name" :placeholder="$t('STRIPE_FORM.FULL_NAME')" />
      </label>
      <label>
        {{ $t('STRIPE_FORM.EMAIL') }}
        <input v-model="billingDetails.email" type="email" :placeholder="$t('STRIPE_FORM.EMAIL')" />
      </label>
      <label>
        {{ $t('STRIPE_FORM.COUNTRY') }}
        <select v-model="billingDetails.address.country">
          <option disabled value=""> {{ $t('STRIPE_FORM.SELECT_COUNTRY') }}</option>
          <option v-for="country in countries" :key="country.code" :value="country.code">
            {{ country.name }}
          </option>
        </select>
      </label>
      <label>
        {{ $t('STRIPE_FORM.POSTAL_CD') }}
        <input v-model="billingDetails.address.postal_code" :placeholder="$t('STRIPE_FORM.POSTAL_CD') " />
      </label>
      <label>
        {{ $t('STRIPE_FORM.CITY') }}
        <input v-model="billingDetails.address.city" :placeholder="$t('STRIPE_FORM.CITY')" />
      </label>
      <label>
        {{ $t('STRIPE_FORM.ADDRESS') }}
        <input v-model="billingDetails.address.line1" :placeholder="$t('STRIPE_FORM.ADDRESS')" />
      </label>

      <div class="checkbox-container">
        <input type="checkbox" v-model="sameAddress">
        <span class="checkbox-label">  {{ $t('STRIPE_FORM.BILLING_SAME_ADDRESS') }}</span>
      </div>

      <!-- Shipping Address Form -->
      <div v-if="!sameAddress">
        <p class="section-title"> {{ $t('STRIPE_FORM.SHIPPING_ADDRESS') }}</p>
        <label>
          {{ $t('STRIPE_FORM.FULL_NAME') }}
          <input v-model="shippingDetails.name" :placeholder="$t('STRIPE_FORM.FULL_NAME')" />
        </label>
        <label>
          {{ $t('STRIPE_FORM.COUNTRY') }}
          <select v-model="shippingDetails.address.country">
            <option disabled value="">{{ $t('STRIPE_FORM.SELECT_COUNTRY') }}</option>
            <option v-for="country in countries" :key="country.code" :value="country.code">
              {{ country.name }}
            </option>
          </select>
        </label>
        <label>
          {{ $t('STRIPE_FORM.POSTAL_CD') }}
          <input v-model="shippingDetails.address.postal_code" :placeholder="$t('STRIPE_FORM.POSTAL_CD')" />
        </label>
        <label>
          {{ $t('STRIPE_FORM.CITY') }}
          <input v-model="shippingDetails.address.city" :placeholder="$t('STRIPE_FORM.CITY')" />
        </label>
        <label>
          {{ $t('STRIPE_FORM.ADDRESS') }}
          <input v-model="shippingDetails.address.line1" :placeholder="$t('STRIPE_FORM.ADDRESS')" />
        </label>
      </div>

      <!-- <button class="n-btn-primary" @click="handlePayment">Pay</button> -->
      <button class="n-btn-primary" @click="handlePayment" :disabled="!isFormValid">{{ $t('BUTTON.PAY') }}</button>
      <button class="n-btn-weak" @click="goBack">{{ $t('BUTTON.CANCEL') }}</button>
    </div>
</template>

<style type="text/css" scoped>
.section-title {
  margin-top: 32px;
  font-weight: bold;
}
.payment-form label {
  display: block;
  margin-bottom: 15px;
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  color: #888;
}

.payment-form input, .payment-form select {
  width: 100%;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 14px;
}

.payment-form button {
  width: 100%;
  margin-top: 24px;
  transition: background-color 0.3s;
}

.checkbox-container {
  margin-top: 24px;
  display: flex;
  align-items: center;
}

.checkbox-container input {
  width: auto; /* 元のスタイルを上書き */
  padding: 0;  /* 元のスタイルを上書き */
  border: none; /* 元のスタイルを上書き */
  border-radius: 0; /* 元のスタイルを上書き */
  font-size: inherit; /* 元のスタイルを上書き */
}

.checkbox-label {
  margin-left: 12px;
  font-size: 12px;
  white-space: nowrap; /* This will prevent the text from breaking into multiple lines */
}


.payment-form button:hover {
  background-color: beige;
  color: #000;
}

#card-element {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
}
</style>

<script>
import { loadStripe } from "@stripe/stripe-js";
import { watch } from 'vue';
import countries from "@/assets/json/stripe-countries.json";
import shared from "@/shared";
import axios from "axios";
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      stripe: null,
      elements: null,
      countries: countries,
      selectedCountry: null,
      billingDetails: {
        name: '',
        email: '',
        address: {
          country: '',
          postal_code: '',
          city: '',
          line1: ''
        }
      },
      sameAddress: true, // Added this property
      shippingDetails: { // Added this property
        name: '',
        address: {
          country: '',
          postal_code: '',
          city: '',
          line1: '',
        }
      },
      isCardValid: false,
      walletAddress: null
    };
  },
  props: {
    item: Object,
    quantity: Number
  },
  inject: {
    API_STRIPE_PAYMENT_INTENT: {
      from: "API_STRIPE_PAYMENT_INTENT",
    },
    API_BASE_URL: {
      from: 'API_BASE_URL'
    },
    API_ENDPOINT_STRIPE_PUBLIC_KEY: {
      from: 'API_ENDPOINT_STRIPE_PUBLIC_KEY'
    },
    API_ENDPOINT_ENQUIRY_CONTACT: {
      from: 'API_ENDPOINT_ENQUIRY_CONTACT'
    }
  },
  computed: {
    isFormValid() {
      // カード情報、請求先住所、配送先住所（sameAddressがfalseの場合のみ）が全て入力されているかを確認
      const isBillingDetailsValid = this.billingDetails.name && this.billingDetails.email && 
        this.billingDetails.address.country && this.billingDetails.address.postal_code &&
        this.billingDetails.address.city && this.billingDetails.address.line1;
      
      //
      const isShippingDetailsValid = this.sameAddress ? true : 
        this.shippingDetails.name && this.shippingDetails.address.country &&
        this.shippingDetails.address.postal_code && this.shippingDetails.address.city &&
        this.shippingDetails.address.line1;
      
      return this.isCardValid && isBillingDetailsValid && isShippingDetailsValid;
    }
  },
  setup(props) {

    watch(() => props.item, (newValue, oldValue) => {
      console.log('Item changed:', newValue, oldValue);
      console.log("product id", props.item?.productId);
      console.log("price id", props.item?.priceId);

    }, { deep: true, immediate: true });

  },
  async mounted() {
    this.connectWallet = shared.connectWallet.bind(this);
    const _this = this;
    this.connectWallet(async(err, event, walletData) => {
      if (err) {
        console.error(err);
        _this.handleErrorAndGoTop(
          _this.$t('POPUP.TITLE_COMMON_ERROR'),
          _this.$t('POPUP.PLEASE_LOGIN_AGAIN'),
          3000
        );
        return;
      }
      let walletObj = JSON.parse(walletData);
      if (!walletObj.walletAddress) {
        console.error("wallet is disconnected?", event, walletData);
        _this.handleErrorAndGoTop(
          _this.$t('POPUP.TITLE_COMMON_ERROR'),
          _this.$t('POPUP.PLEASE_LOGIN_AGAIN'),
          3000
        );
        return;
      }

      console.log("the walletAddress", walletObj.walletAddress);
      this.walletAddress = walletObj.walletAddress;

      try {
        const accountId = this.getAccountIdFromURL();
        const membershipId = this.getMembershipIdFromURL();
        let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_STRIPE_PUBLIC_KEY;
        const result = await axios.get(apiUrl, {
            params: {
              accountId: accountId,
              membershipId: membershipId,
              walletAddress: walletObj.walletAddress,
            }
          });
        console.log("Aquired public key", result.data.stripe_public_key);
        if(!result.data.stripe_public_key) {
          this.handleError();
        }
        this.initStripeElement(result.data.stripe_public_key);

      } catch(error) {
        console.error("error get stripe key", error);
        this.handleError();
      }

    });
  },
  unmounted() {
    this.connectWallet = null;
    shared.connectWallet.bind(null);
  },
  methods: {
    async handleError(statusCode) {
      if (statusCode === 403) {
        await Swal.fire({
          title: this.$t('POPUP.TITLE_OUT_OF_STOCK'), //'Sorry, the item is out of stock',
          showConfirmButton: true,
        });
      } else {
        await Swal.fire({
          icon: 'error',
          title:  this.$t('POPUP.TITLE_COMMON_ERROR'),
          text:  this.$t('POPUP.PLEASE_TRY_AGAIN'), // 'Please try again',
          showConfirmButton: true,
        });
      }
    },
    async handleSuccess() {
      try {
        //
        // FIXME：成功ダイアログの表示まで時間がかかるので、先にメアドは取得しておく
        //
        let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_ENQUIRY_CONTACT;
        const result = await axios.get(apiUrl, {
          params: {
            membershipId: this.getMembershipIdFromURL(),
            walletAddress: this.walletAddress,
          }
        });

        Swal.fire({
          icon: 'success',
          title: this.$t('POPUP.TITLE_PAYMENT_SUCCESS'), //'Payment Succeeded',
          html:  this.$t('POPUP.TITLE_PAYMENT_SUCCESS', {contact: result.data.contact }), //`<p>The seller will be in touch with you.</p> <p>For enquiry, contact <a href="mailto:${result.data.contact}">${result.data.contact}</a></p>`,
          showConfirmButton: true,
          timer: 6000,
        }).then(() => {
          window.history.back();// 1つ前のページに戻る
        });

      } catch(err) {
        console.error(err);
      }
    },
    async initStripeElement(stripePublicKey) {
      const accountId = this.getAccountIdFromURL();
      this.stripe = await loadStripe(stripePublicKey, {
          stripeAccount: accountId
        });

      const appearance = {
        theme: 'flat',
        variables: { colorPrimaryText: '#262626' }
      };
      this.elements = this.stripe.elements({appearance});

      const cardStyle = {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "14px",
          "::placeholder": {
            color: "#aab7c4"
          },
          padding: "10px"
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      };
      const card = this.elements.create('card', {
        style: cardStyle,
        hidePostalCode: true
      });
      card.mount('#card-element');

      // カード情報の変更をリッスン
      card.addEventListener('change', (event) => {
        // カード情報が正しく入力されているかを判定
        console.log("card elem event", event);
        this.isCardValid = event.complete;
      });

      // const options = { mode: 'billing' };
      // const addressElement = this.elements.create('address', options);
      // addressElement.mount('#billing-address');
    },
    async handlePayment() {
      // const currentUrl = window.location.href;
      // const url = new URL(currentUrl);
      // const searchParams = new URLSearchParams(url.search);
      // const accountId = searchParams.get("accountId");
      const accountId = this.getAccountIdFromURL();
      const membershipId = this.getMembershipIdFromURL();
      this.$emit('processing', true); // 処理開始を親コンポーネントに通知


      // 2. Stripeサーバーサイドエンドポイントにリクエストを送信
      const addressInfo = {
        billingAddress: this.billingDetails
      }; 
      if (!this.sameAddress) {
        console.log("Bill and Ship address are different!!");
        addressInfo.shippingAddress = this.shippingDetails;
      }
      let apiUrl = this.API_BASE_URL + this.API_STRIPE_PAYMENT_INTENT;
      try {
        const paymentIntent = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: this.item.price,  // 金額（例: 1000 = $10.00）
            currency: this.item.currency,
            productId: this.item.productId,
            priceId: this.item.priceId,
            paymentMethodTypes: ["card"],
            accountId: accountId,
            membershipId: membershipId,
            addressInfo: addressInfo,
            quantity: this.quantity,
            itemName: this.item.name
          }),
        }).then(res => res.json());
        // console.log("paymentIntent", paymentIntent.client_secret);


        /*
        const result = await this.stripe.confirmCardPayment(paymentIntent.client_secret, {
          payment_method: {
            card: this.elements.getElement('card'),
            billing_details: this.billingDetails,
          },
          shipping: {
            name: this.shippingDetails.name,
            address: {
              line1: this.shippingDetails.address.line1,
              city: this.shippingDetails.address.city,
              postal_code: this.shippingDetails.address.postal_code,
              country: this.shippingDetails.address.country,
            },
            // phone: '（電話番号があればここに追加）',
          },
        });
        */

 
        // カード情報の確定
        const { paymentMethod } = await this.stripe.createPaymentMethod({
          type: 'card',
          card: this.elements.getElement('card'),
        });
        // サーバーサイドでPaymentIntentの確認
        const paymentIntentId = paymentIntent.client_secret.split('_secret')[0];
        console.log("paymentIntentId", paymentIntentId);
        let apiUrl_ = this.API_BASE_URL + "/confirmPaymentIntent";
        const result = await fetch(apiUrl_, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            paymentIntentId: paymentIntentId,
            paymentMethodId: paymentMethod.id,
            accountId: accountId,
            priceId: this.item.priceId,
            membershipId: membershipId,
            quantity: this.quantity,
            addressInfo: addressInfo, // add
            itemName: this.item.name, // add 
            amount: this.item.price,  // add 金額（例: 1000 = $10.00）
            currency: this.item.currency, // add
            userWalletAddress: this.walletAddress,
            image_urls: this.item.imageUrls,
            price: this.item.price,
            productId: this.item.productId
          })
        });

        if (result.status === 200) {
          this.handleSuccess();
        } else {
          this.handleError(result.status);
        }





        //
        // TODO:1.通知のためのデータベース登録 2.在庫管理
        //


        // 1. 支払いが成立した時点で、支払い情報をDBに登録
        //  - walletAddress/paymentID/{map} - created_at, customerID, product情報, price情報
        //  - !!: サーバー側で、通知も作成する
        // 2. 対象商品の在庫を、-1 する
        //
        //


      } catch (error) {
        console.error("Payment Error:", error);
        alert("支払い処理中にエラーが発生しました。");
      } finally {
        console.log("finally");
        this.$emit('processing', false); // 処理終了を親コンポーネントに通知
      }
    },
    getAccountIdFromURL() {
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      const searchParams = new URLSearchParams(url.search);
      const accountId = searchParams.get("accountId");
      return accountId;
    },
    getMembershipIdFromURL() {
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      const searchParams = new URLSearchParams(url.search);
      const membershipId = searchParams.get("membershipId");
      return membershipId;
    },
    goBack() {
      window.history.back();
    }
  },
};
</script>
