<template>
  <div class="n-container">
    <h1>Terms & Conditions</h1>
    <p>This agreement applies to the use of "deepbeach" (hereinafter referred to as "this service") provided by Next One Create Inc. (hereinafter referred to as "our company"). Please read these Terms of Use and agree to them before using the Service.</p>

    <section class="text-left">
      <h3>Article 1 (Application of Terms)</h3>
      <ol>
        <li>This agreement sets forth the terms and conditions for the provision of this service by our company and for the user to receive this service.</li>
        <li>In addition to these Terms, the Company may establish individual terms and other guidelines, etc. regarding the use of the Service with respect to the provision of the Service. In this case, the individual terms and other guidelines, etc. shall be applied in priority to the use of this service by the user as part of this agreement.</li>
        <li>When the user uses this service, it is assumed that the user has agreed to this agreement.</li>
        <li>If the user is a minor, the user must obtain the consent of a legal representative such as a person with parental authority regarding the use of this service. The Company considers that the use of this service by a minor user is done with the consent of a legal representative such as a person with parental authority.</li>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 2 (Registration for use)</h3>
      <ol>
        <li>The user can register for use by registering the necessary items according to the method specified by the Company.</li>
        <li>The user must provide us with accurate and up-to-date information regarding the registered items.</li>
        <li>If there is any change in the registered content, the user shall promptly notify the Company of the changed content.</li>
        <li>The Company shall not be held responsible for any damages or disadvantages suffered by the User due to inaccurate or false registration information, or due to failure to report changes.</li>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 3 (Management of ID and Password)</h3>
      <ol>
        <li>When a user registers for use, the Company will issue an ID and password.</li>
        <li>Users shall strictly manage and store their IDs and passwords, and shall not allow third parties to use them by lending, transferring, selling or otherwise using them. The Company shall not be held responsible for any damages or disadvantages suffered by the User due to insufficient management of IDs or passwords.</li>
        <li>If the user loses or forgets the ID or password, or if it is found that they are being used by a third party, the user shall immediately notify the Company to that effect.</li>
        <li>The Company shall regard all use of the Service by the ID and password issued to the User as an act of the User, regardless of whether or not the use is genuine by the User. You shall be responsible for any resulting liability.</li>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 4 (Intellectual Property Rights and Contents)</h3>
      <ol>
        <p>Intellectual property rights, including copyrights, and all other rights related to all materials that make up the Service belong to the Company or third parties who own such rights. The User shall not acquire any rights with respect to any materials of the Service, and shall not perform any act that infringes on the rights regarding the materials without the permission of the right holder. Permission to use the Service based on these Terms does not imply a license to use the rights of the Company or any third party that owns such rights in relation to the Service.</p>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 5 (Posting by users)</h3>
      <ol>
        <li>Information such as posts, reviews, comments, etc. by users in this service, images, illustrations and other contents posted, uploaded or made available for viewing by users (hereinafter referred to as "user posted information") Please use this service after fully understanding that it will be accessed and viewed by an unspecified number of users of the service. The user who posted the information shall bear all responsibility.</li>
        <li>Users may not post the following information.
          <ol>
            <li>Untrue information.</li>
            <li>Content containing obscene expressions or obscene images such as nudity.</li>
            <li>Content that damages the honor or credibility of others.</li>
            <li>Infringement of privacy rights, portrait rights, copyrights and other rights of third parties.</li>
            <li>Content containing computer viruses.</li>
            <li>Links or URLs to websites other than those approved by the Company.</li>
            <li>Others that the Company deems inappropriate.</li>
          </ol>
        </li>
        <li>The user shall permit the Company to use the User Posted Information free of charge. Upon granting the license, the user represents and warrants the following:
          <ol>
            <li>Regarding copyrights, neighboring rights, portrait rights, and any other rights related to User Posted Information, the legitimate right holder, or from the legitimate right holder necessary for the use of User Posted Information pertaining to the Service; have received any permission.</li>
            <li>Posting of User Contributed Information and use by the Company shall not infringe on the copyrights, related rights, portrait rights or any other rights of third parties.</li>
          </ol>
        </li>
        <li>The Company shall be able to monitor the content of User Posted Information for the purpose of allowing Users to use the Service safely.</li>
        <li>If the User Posted Information violates this Agreement or falls under any of the following reasons, the Company may delete the User Posted Information without prior notice to the User and may restrict User Posting. We shall be able to;
          <ol>
            <li>When a certain period of time has passed since posting.</li>
            <li>When it is deemed necessary for maintenance management of this service.</li>
            <li>When the capacity of User Posted Information, etc. exceeds the prescribed capacity of the equipment used by the Company, or when there is a risk of doing so.</li>
          </ol>
        </li>
        <li>The Company shall not be obliged to respond to the User regarding the reason for the deletion and restriction of posting in accordance with the preceding paragraph, and shall not be liable for any damages or disadvantages incurred by the User due to the deletion or restriction. In addition, the Company is not obligated to delete User Posted Information.</li>
        <li>Users shall agree in advance to the monitoring, deletion and restriction of posting of User Posted Information in accordance with this Article.</li>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 6 (Awarding Points)</h3>
      <ol>
        <li>If the user satisfies the conditions stipulated by the Company for the use of this service, the Company shall be able to grant the user the number of points stipulated by the Company.</li>
        <li>The user shall agree in advance that there is a possibility that the points held by the user may not be transferred if the model of the mobile phone terminal is changed.</li>
        <li>Users shall be able to use the points they possess in a manner specified by the Company.</li>
        <li>The Company may cancel all or part of the user's points if the user violates these Terms or if the Company deems it appropriate.</li>
        <li>The Company shall not be held responsible for any points that have been canceled or extinguished pursuant to this Article.</li>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 7 (Notice to Users)</h3>
      <ol>
        <p>The Company regularly or irregularly distributes e-mail magazines and push notifications of applications such as smartphones to users for the latest information and recommendations of the services provided by the Company.</p>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 8 (Changes, Additions, Suspension of Service Contents)</h3>
      <ol>
        <p>The Company may change, add, or suspend all or part of the content of the Service without prior notice to the User, and the User shall consent to this in advance.</p>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 9 (Personal Information)</h3>
      <ol>
        <p>The Company will appropriately handle personal information obtained through the use of this service by users in accordance with the Company's privacy policy.</p>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 10 (Prohibitions)</h3>
      <ol>
        <li>Users may not perform any of the following acts.
          <ol>
            <li>Acts that interfere with or may interfere with the operation of the Service.</li>
            <li>Acts that interfere with the use of this service by other users.</li>
            <li>Acts that infringe copyrights or other rights pertaining to the Service.</li>
            <li>Acts that infringe on the rights or interests of the Company, other users, or third parties (including, but not limited to, honor rights, privacy rights, and copyrights).</li>
            <li>Acts that violate public order and morals and other laws and acts that may violate them.</li>
            <li>Acts that violate these Terms.</li>
            <li>In addition to the preceding items, acts that the Company deems inappropriate in light of the purpose of the Service.</li>
          </ol>
        </li>
        <li>If the Company determines that the User has committed any of the acts set forth in the preceding paragraph, the Company may, without prior notice to the User, suspend use of all or part of the Service or take other measures deemed necessary and appropriate by the Company. can be taught. The Company shall not be liable for any damages or disadvantages incurred by the User due to the measures in this section.</li>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 11 (Exclusion of Antisocial Forces)</h3>
      <ol>
        <p>The User promises the following matters to the Company.</p>
        <li>You are not an organized crime group, a company affiliated with an organized crime group, a corporate racketeer, or a person equivalent thereto or a member thereof (hereinafter collectively referred to as "anti-social forces").</li>
        <li>The Company's officers (employees who execute business, directors, executive officers, or equivalent persons) are not anti-social forces.</li>
        <li>Do not allow anti-social forces to use your name to conclude this Agreement.</li>
        <li>Do not commit the following acts by yourself or by using a third party.
          <ol>
            <li>Acts that use threatening behavior or violence against the other party.</li>
            <li>Unreasonable demands beyond legal responsibility.</li>
            <li>Acts that use fraudulent means or force to interfere with the other party's business or damage the credibility of the other party.</li>
          </ol>
        </li>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 12 (Disclaimer)</h3>
      <ol>
        <li> If all or part of this service is suspended, interrupted, or delayed due to natural disasters, wars, acts of terrorism, riots, labor disputes, epidemics, enactment or abolishment of laws and regulations, intervention by government agencies, or other force majeure, the Company shall , shall not be held responsible for any damage or disadvantage caused to the user.</li>
        <li>The user shall understand that all or part of this service may be stopped, interrupted, or delayed due to communication line or computer failure, system maintenance, or other reasons. We are not responsible for any damages or disadvantages caused by In addition, we are not responsible for any damages or disadvantages caused by the user's usage environment.</li>
        <li>We do not guarantee, either explicitly or implicitly, the following matters.
          <ol>
            <li>Usefulness, completeness, accuracy, up-to-dateness, reliability, suitability for a particular purpose of the contents of this service and information provided through this service.</li>
            <li>Information provided by this service does not infringe on the rights of third parties.</li>
            <li>that the Service will continue to exist in the future.</li>
          </ol>
        </li>
        <li>The Company shall not be obligated to restore data, etc. in whole or in part, regardless of the reason, in the event that the data, etc. are lost, damaged, or falsified. We are not responsible for any damages caused to customers or third parties.</li>
        <li>In the event that the Company is liable to the User in connection with the use of the Service by the User, the Company shall not be liable for compensation exceeding the value of the relevant product, etc. We shall not be liable for any damages, indirect damages, special damages, future damages, or lost profits.</li>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 13 (Confidentiality)</h3>
      <ol>
        <p>When using this service, the user shall not disclose or leak to any third party any information disclosed or obtained by us, and shall not use it for purposes other than using this service. it won't work.</p>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 14 (Notice from our company)</h3>
      <ol>
        <li>When the Company notifies the user, it shall be done by sending an e-mail to the e-mail address registered by the user, by posting on the website related to this service, or by any other method that the Company deems appropriate.</li>
        <li>In the event that the Company notifies, if it is sent to the e-mail address in the preceding paragraph, the notice of the Company shall be deemed to have reached the user at the time it is recorded in the mail server of the e-mail address.</li>
        <li>The user shall promptly notify the Company if there is a change in the e-mail address in paragraph 1. Notices sent by the Company to the e-mail address before the change before receiving the notice of change in this paragraph shall be deemed to have reached the user at the time of transmission.</li>
        <li>The Company shall not be held responsible for any damages or disadvantages incurred by the User due to the User's failure to give notice as set forth in the preceding paragraph.</li>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 15 (Disputes with Third Parties)</h3>
      <ol>
        <li>Any dispute arising between the user and a third party in relation to this service shall be resolved by the user at their own expense and responsibility, and the Company shall not be held responsible.</li>
        <li> If the Company suffers damages (including attorney's fees) with respect to the preceding paragraph, the user shall compensate for the damages.</li>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 16 (Prohibition of Transfer of Rights and Obligations)</h3>
      <ol>
        <p>The user may assign, transfer, create a security interest in, or otherwise transfer all or part of the contractual status based on this agreement and the rights and obligations arising therefrom to a third party without the prior written consent of the Company. cannot be disposed of.</p>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 17 (Severability)</h3>
      <ol>
        <p>If any provision of this Agreement violates laws and regulations applicable to a contract based on this Agreement with a User and is deemed invalid, such provision shall, to the extent deemed to be in violation, be deemed to be void. shall not apply to Even in this case, the validity of other provisions of these Terms shall not be affected.</p>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 18 (Changes to these Terms)</h3>
      <ol>
        <p>If it becomes necessary to change these Terms, the Company may change these Terms based on Article 548-4 of the Civil Code (changes to standard terms and conditions). In the event of any change to these Terms, the Company shall determine the effective date and notify the following matters by sending an e-mail or other means by the effective date.</p>
        <li>Intention to change this agreement.</li>
        <li>Contents of this agreement after change.</li>
        <li>Effective date.</li>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 19 (Governing Law, Jurisdiction)</h3>
      <ol>
        <li>These Terms shall be interpreted in accordance with the laws of Japan.</li>
        <li>The Company and the User agree in advance that the Tokyo District Court shall be the exclusive jurisdictional court of first instance for the resolution of disputes arising between the Company and the User regarding the Service.</li>
      </ol>
    </section>
  </div>
</template>

<style scoped>
.text-left {
  text-align: left;
}
ol li {
  list-style-type: decimal;
  margin: 20px 0  20px 2em;
}
</style>
