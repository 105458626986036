<template>
  <div class="tab-content-container">
    <!-- <h3>Basic Info</h3> -->
    <form @submit.prevent>
      <label>{{$t('ADMIN_VIEW.BASIC_INFO.NAME')}}*</label>
      <input 
        type="text"
        :placeholder="$t('ADMIN_VIEW.BASIC_INFO.NAME_PLACEHOLDER')"
        v-bind:value="title"
        v-on:input="onChangeTitle"
        maxlength="30"
      />
      <p class="error-text" v-show="isInvalidName">{{$t('ADMIN_VIEW.BASIC_INFO.NAME_INVALID')}}</p>
    </form>

    <div
      class="edit-image"
      v-bind:style="{ backgroundImage: 'url(' + coverImageUrl + ')' }"
      @click="selectCoverImage"
    >
      <div class="edit-image-overlay">
        <p class="noselect">{{$t('ADMIN_VIEW.BASIC_INFO.COVER_IMG')}}</p>
      </div>
    </div>
    <p class="error-text" v-show="isInvalidFileSizeCover">
      {{$t('ADMIN_VIEW.BASIC_INFO.COVER_IMG_LIMIT')}}
    </p>

    <div class="buttons">
      <button class="n-btn-weak" @click="cancel">{{ $t('BUTTON.CANCEL_UPPER') }}</button>
      <button class="n-btn-primary" @click="updateBasicInfo">{{ $t('BUTTON.SAVE_UPPER') }}</button>
    </div>
  </div>
</template>
<script>
export default {
  name: "BasicInfo",
  props: {
    isActive: String,
    isInvalidFileSizeCover: Boolean,
    isInvalidName: Boolean,
    title: String,
    onChangeTitle: Function,
    coverImageUrl: String,
    selectCoverImage: Function,
    cancel: Function,
    updateBasicInfo: Function,
  },
};
</script>
<style>
.tab-content-container {
  padding: 12px;
}
.edit-image {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 24px auto;
  border: 2px solid #000;
  background-color: #eee;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border-radius: 8px;
}
.edit-image-overlay {
  width: 200px;
  height: 200px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  text-align: center;
}
.edit-image-overlay p {
  position: absolute;
  width: inherit;
  top: 0;
  left: 0;
  margin: 0;
  line-height: 200px;
  color: #fff;
  font-weight: 700;
}
.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 12px;
}
.buttons button {
  min-width: 200px;
  margin-top: 32px;
}
</style>
