<!-- Page1Component.vue -->
<template>
  <div class="wrap-form">
    <form style="margin-top: 24px" @submit.prevent>
      <div class="row">
        <div
          class="col-12 col-lg-4 justify-content-start d-flex justify-content-lg-end"
        >
          <label class="label-">{{ $t('SELL_VIEW.ITEM_NAME') }}</label>
        </div>
        <div class="col-12 col-lg-8 d-flex justify-content-lg-start">
          <input
            type="text"
            class="text-field"
            :placeholder="$t('SELL_VIEW.ITEM_NAME_PLACEHOLDER')"
            v-bind:value="itemName"
            v-on:input="onChangeItemName"
            maxlength="200"
          />
          <p class="error-text-small" v-show="isInvalidName">
            {{ $t('SELL_VIEW.ITEM_NAME_INVALID') }}
          </p>
        </div>
      </div>

      <div class="row">
        <div
          class="col-12 col-lg-4 d-flex justify-content-start justify-content-lg-end align-items-center"
        >
          <label class="label-"> {{ $t('SELL_VIEW.DESCRIPTION') }}</label>
        </div>
        <div class="col-12 col-lg-8 d-flex justify-content-lg-start">
          <textarea
            className="text-field"
            :placeholder=" $t('SELL_VIEW.DESCRIPTION_PLACEHOLDER')"
            v-bind:value="itemDescription"
            v-on:input="onChangeItemDescription"
            maxlength="1000"
          ></textarea>
          <p class="error-text-small" v-show="isInvalidDescription">
            {{ $t('SELL_VIEW.DESCRIPTION_INVALID') }}
          </p>
        </div>
      </div>
    </form>

    <!-- Image upload button -->
    <div class="wrap-image row" style="margin-top: 24px">
      <div
        class="col-lg-4 d-flex justify-content-start justify-content-lg-end"
      >
        <label class="label-">  {{ $t('SELL_VIEW.IMAGE') }}</label>
      </div>
      <div class="col-lg-8 d-flex justify-content-start">
        <div id="image-container" class="wrap-container-image">
          <div
            :class="[imageInput.className]"
            v-for="imageInput in imageInputs"
            :key="imageInput.id"
          >
            <label :for="imageInput.id">
            <div class="image-show">
              <img :id="imageInput.placeholderId" :src="imageInput.src" />

            </div>
            </label>
            <input
              :id="imageInput.id"
              type="file"
              accept="image/png,image/jpeg,image/gif"
              @change="onImageChange($event, imageInput)"
            />
            <button
              v-if="
                imageInput.src !== require('@/assets/img/icon-plus.png')
              "
              @click.stop="onDeleteImage(imageInput)"
            >
              ×
            </button>
          </div>
        </div>
      </div>
    </div>

    <p class="error-text-small" v-show="isInvalidImages">
      {{ $t('SELL_VIEW.VALIDATE_IMAGE') }}
    </p>

    <div class="buttons">
      <button class="n-btn-primary" @click="saveAndProceed">{{ $t('BUTTON.NEXT') }}</button>
      <button class="n-btn-link weak" @click="goBack">{{ $t('BUTTON.CANCEL') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemName: String,
    itemDescription: String,
    imageInputs: Array,
    isInvalidName: Boolean,
    isInvalidDescription: Boolean,
    isInvalidImages: Boolean,
    deleteImage: Function
  },
  methods: {
    onChangeItemName(event) {
      this.$emit('update:itemName', event.target.value);
    },
    onChangeItemDescription(event) {
      this.$emit('update:itemDescription', event.target.value);
    },
    onImageChange(e, imageInput) {
      this.$emit('image-change', e, imageInput);
    },
    onDeleteImage(imageInput) {
      this.$emit('delete-image', imageInput);
    },
    saveAndProceed() {
      this.$emit('save-and-proceed');
    },
    goBack() {
      this.$emit('go-back');
    },
  },
};
</script>

<style scoped>
.text-field {
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  margin-top: 8px;
  resize: none;
}

.error-text-small {
  color: #ff4d4f;
  margin-top: 8px;
  font-size: 12px;
}

.wrap-image {
  margin-top: 24px;
}

.wrap-container-image {
  display: flex;
  flex-wrap: wrap;
}

.image-upload label {
  cursor: pointer !important;
}
.image-upload {
  position: relative;
  width: 100px;
  height: 100px;
  background-color: #000;
  margin-top: 8px;
  margin-left: 8px;
  margin-bottom: 8px;
}
.image-upload img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.image-show {
  max-width: 110px; 
  max-height: 110px; 
  overflow: hidden; 
}
.image-upload input[type="file"] {
  display: none;
}
.image-upload button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
}
</style>
