<template>
  <MainHeader :title="title" :walletAddress="walletAddress" :chainName="chainName" :membershipId="membershipId" :isAdmin="isAdmin"/>
  <div class="n-container" style="margin-top: 120px;">
    <h2>{{ $t('ENGAGEMENT_RANKING.ENGAGE_RANKING') }}</h2>
    <div class="n-container">
      <table>
        <thead>
          <tr>
            <th v-for="(header, index) in headers"
                v-bind:key="index">
              {{header}}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in users"
              v-bind:key="user.id">
            <th>{{index + 1}}</th>
            <td>
              <div class="profile-image" v-bind:style="{ backgroundImage: 'url(' + user.imageUrl + ')' }" @click="goProfile(membershipId, user.walletAddress)"></div>
            </td>
            <td class="text-name">{{user.name}}</td>
            <!-- <td>{{user.balance.toLocaleString()}}</td> -->
            <td>{{user.score.toLocaleString()}}</td>
          </tr>
        </tbody>
      </table>
    </div>
	</div>
  <!-- Overlay spinner -->
  <OverlaySpinner v-show="showSpinner"></OverlaySpinner>
  <MainFooter />
</template>

<style scoped>
table {
  width: 100%;
  margin: auto;
  background-color: #fff;
  border-collapse: collapse;
  border-spacing: 50px 0;
}
thead {
  height: 40px;
  color: #fff;
  background-color: #AAB8C2;
}
tr {
  border-bottom: 1px solid #F5F8FA;
}
tr:nth-child(1) {
  color-rendering: ;
}
td {
  padding: 8px 0;
}
.profile-image {
  width: 50px;
  height: 50px; 
  border-radius: 25px;
  background-size: contain;
  background-color: #000;
  cursor: pointer;
}
.text-name {
  font-weight: 600;
}
@media all and (min-width: 1024px) and (max-width: 1280px) { }
@media all and (min-width: 768px) and (max-width: 1024px) { }
@media all and (min-width: 480px) and (max-width: 768px) { }
@media all and (max-width: 480px) { 
}
</style>

<script>
import { defineComponent } from 'vue';
import MainHeader from '@/components/MainHeader.vue';
import MainFooter from '@/components/MainFooter.vue';
import OverlaySpinner from '@/views/OverlaySpinner.vue';
import shared from '@/shared';
import swalContainer from  '@/utils/swalContainer.js'
const users = [];
const headers = [
  'Rank',
  'User',
  'Name',
  'Score'
];
 
export default defineComponent({
  name: 'EngagementRankView',
  data() {
  	return {
      users: users,
      headers: headers,
      walletAddress: null,
      membershipId: null,
      profileImageUrl: "",
      chainName: "",
      showSpinner: false,
      isAdmin: false
  	}
  },
  unmounted() {
    // Unbound shared functions
    this.initMoralis = null;
    shared.initMoralis.bind(null);
    this.logout = null;
    shared.logout.bind(null);
    this.connectWallet = null;
    shared.connectWallet.bind(null); 
    this.checkNFT = null;
    shared.checkNFT.bind(null);
    this.handleErrorAndGoTop = null;
    shared.handleErrorAndGoTop.bind(null);
    this.getChainParamFromChainId = null;
    shared.getChainParamFromChainId.bind(null);
  },
  mounted() {    
    let _this = this;
    
    /* Bind shared functions */
    this.initMoralis = shared.initMoralis.bind(this);
    this.logout = shared.logout.bind(this);
    this.connectWallet = shared.connectWallet.bind(this);
    this.checkNFT = shared.checkNFT.bind(this);
    this.handleErrorAndGoTop = shared.handleErrorAndGoTop.bind(this);
    this.getChainParamFromChainId = shared.getChainParamFromChainId.bind(this);

    /* Init Moralis */
    this.initMoralis(this.MORALIS_SERVER_URL, this.MORALIS_APP_ID, this.MORALIS_MASTER_KEY);

    /* Set header title from cached data */
    let membershipObj = JSON.parse(sessionStorage.getItem(this.LOCAL_STORAGE_KEY_MEMBERSHIP));
    if (!membershipObj) {
      this.logout(this.LOCAL_STORAGE_KEY_MEMBERSHIP); 
      this.handleErrorAndGoTop(this.$t('POPUP.TITLE_COMMON_ERROR'), this.$t('POPUP.PLEASE_LOGIN_AGAIN'), 3000);
      return;
    }
    let tokenAddress = membershipObj.tokenAddress;
    let tokenId = membershipObj.tokenId;
    this.title = membershipObj.name;  
    if (this.$route.query.membershipId) {
      this.membershipId = this.$route.query.membershipId;
    } else { 
      this.handleErrorAndGoTop(this.$t('POPUP.TITLE_COMMON_ERROR'), this.$t('POPUP.PLEASE_LOGIN_AGAIN'),  3000);
      return;
    }

    /* Connect to wallet */
    this.connectWallet((err, event, walletData) => {      
      if (err) {
        _this.handleUnAuthorizedError();
        return;
      }
      let walletObj = JSON.parse(walletData);
      if (!walletObj.walletAddress || !walletObj.chainId) {
        _this.handleErrorAndGoTop(this.$t('POPUP.TITLE_WALLET_DISCONNECT'), this.$t('POPUP.PLEASE_LOGIN_AGAIN'), 3000);
        return;
      }
      _this.walletAddress = walletObj.walletAddress;
      switch (walletObj.chainId) {
        case 1:
          _this.chainName = "Ethereum";
          break;
        case 137:
          _this.chainName = "Polygon";
          break;
        default:
          // alert("Currently we support Ethereum and Polygon");
          swalContainer.warningDiffNetwork();
          break;
      }
      /* Check NFT */
      let chainParam = _this.getChainParamFromChainId(walletObj.chainId);
      _this.checkNFT(chainParam, _this.walletAddress, tokenAddress, tokenId, function(isValidTokenExist) {
        if (!isValidTokenExist) {
          _this.handleErrorAndGoTop("Required NFT not found - code 102", "You have no memebership NFT for viewing this page", 3000);
          return;
        }
        /* Check if user is admin to show/hide header admin button */
        let apiUrl = _this.API_BASE_URL + _this.API_ENDPOINT_MEMBERSHIP;
        this.$axios.get(apiUrl, {
          params: {
            membershipId: _this.membershipId,
            walletAddress: _this.walletAddress
          }
        })
        .then(result => {
          let dataObj = JSON.parse(JSON.stringify(result.data));
          _this.title = dataObj.name;
          _this.isAdmin = dataObj.is_admin;
        })
        .catch(err => {
          console.error("EngagementRankView: failed to get membership", err);
        });

        /* Load profile */
        _this.loadProfile(_this.walletAddress, _this.membershipId, (err, userObj) =>{
          if (err) {
            this.$router.push('timeline');
            return;
          }
          // Set profile image
          if (userObj.profile_image_url) {
            _this.profileImageUrl = userObj.profile_image_url;
          }
          if (userObj.name) {
            _this.userName = userObj.name;
          }
          if (userObj.engagement) {
            let engagementObj = userObj.engagement;

            if (engagementObj.posts_count) {
              _this.postsCount = engagementObj.posts_count;
            } 
            if (engagementObj.comments_count) {
              _this.commentsCount = engagementObj.comments_count;
            }
            if (engagementObj.likes_count) {
              _this.likesCount = engagementObj.likes_count;
            } 
            if (engagementObj.engagement_score) {
              _this.engagementScore = engagementObj.engagement_score;
            } 
          }
        });
      });
    });
    this.appendRank(this.membershipId);
  },
  inject: {
    LOCAL_STORAGE_KEY_MEMBERSHIP: {
      from: 'LOCAL_STORAGE_KEY_MEMBERSHIP'
    },
    MORALIS_SERVER_URL: {
      from: 'MORALIS_SERVER_URL'
    },
    MORALIS_APP_ID: {
      from: 'MORALIS_APP_ID'
    },
    MORALIS_MASTER_KEY: {
      from: 'MORALIS_MASTER_KEY'
    },
    API_BASE_URL: {
      from: 'API_BASE_URL'
    },
    API_ENDPOINT_RANK: {
      from: 'API_ENDPOINT_RANK'
    },
    API_ENDPOINT_MEMBERSHIP: {
      from: 'API_ENDPOINT_MEMBERSHIP'
    }
  },
  components: {
    MainHeader,
    MainFooter,
    OverlaySpinner
  },
  methods: {
    appendRank: function(membershipId) {
      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_RANK;
      let _this = this;
      _this.showSpinner = true;
      this.$axios.get(apiUrl, {
        params: {
          membershipId: membershipId
        }
      })
      .then(result => {
        _this.showSpinner = false;
        _this.users = [];
        let dataObj = JSON.parse(JSON.stringify(result.data));
        let rankArray = dataObj.rank;
        rankArray.forEach(data => {
          let rankObj = JSON.parse(JSON.stringify(data));
          let user = {
            rank: rankObj.rank,
            walletAddress: rankObj.walletAddress,
            imageUrl: rankObj.profileImageUrl,
            name: rankObj.name,
            score: rankObj.score
          }
          _this.users.push(user);
        });
      })
      .catch(err => {
        console.error(err);
        _this.showSpinner = false;
      });
    },
    handleUnAuthorizedError: function() {
      let _this = this;
      this.$swal({
        title: 'No access right to this page',
        showCancelButton: false,
        confirmButtonText: "OK",
        timer: 3000
      }).then(() => {
        _this.$router.push('/');  
      });
    },
    goProfile: function(membershipId, value) {
      this.$router.push({ 
        name: 'profile', 
        query: {membershipId: membershipId,  user: value}
      });
    }
  }
});
</script>