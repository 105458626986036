<template>
  <footer id="main-footer">
    
    <div class="footer-wrapper"> 
      <div class="footer-content">
        <p class="weak-text">©︎Next1 Create Inc. All Rights Reserved.</p>
        <a class="weak-text" href="/terms" target="_blank">Terms & Conditions</a>
        <a class="weak-text" href="/privacy" target="_blank">Privacy Policy</a>
      </div>
      <a class="weak-text" href="https://moralis.io" v-if="$route.path === '/'" target="_blank"><img class="moralis-watermark" src="@/assets/img/Powered-by-Moralis-Badge-Text-Inverted.png"/></a>
    </div>
    
  </footer>
</template>

<style scoped>
  #main-footer {
    padding: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #14171A;
  }
  .footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1280px;
    width: 100%;
    margin: auto;
  }
  .footer-content {
    display: flex;
    align-items: center;
    gap: 24px;
  }
  .moralis-watermark {
    width: 200px;
    max-width: 100%;
    height: auto;
  }

  /* 600px以下の画面サイズでフッターコンテンツを縦並びにする */
  @media (max-width: 600px) {
    .footer-wrapper {
      flex-direction: column;
    }
    .moralis-watermark {
      margin-top: 20px;  /* 上の要素との間隔を調整 */
    }
  }

</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MainFooter',
  data() {
    return {

    }
  },
  props: {
    
  }
});
</script>
