<template>
  <div class="n-container">
    <h2>{{ $t('MEMBERSHIP_SETTING.MEMBERSHIP_SETTING') }}</h2>
    <p class="weak-text">{{ $t('MEMBERSHIP_SETTING.MEMBERSHIP_ID') }}: {{ membershipId }}</p>

    <div class="n-container">
      <form>
        <div>
          <label>{{ $t('MEMBERSHIP_SETTING.ADMIN_EMAIL') }}</label>
          <input type="email" name="" placeholder="name@email.com">
        </div>

        <div>
          <label>{{ $t('MEMBERSHIP_SETTING.COMUNITY_NAME') }}</label>
          <input type="text" name="">
        </div>

        <div>
          <label>{{ $t('MEMBERSHIP_SETTING.DESCRIPTION') }}</label>
          <textarea></textarea>
        </div>


        <div class="image-drop-area">{{ $t('MEMBERSHIP_SETTING.UPLOAD_COVER_IMG') }}</div>

      </form>
    </div>

    <div class="n-container">

      <div>
        <p class="nft-info"><span class="nft-info-title">{{ $t('MEMBERSHIP_SETTING.NFT_CONTRACT_ADDRESS') }}:</span> {{ nftContractAddress }}</p>
      </div>

      <div>
        <p class="nft-info"><span class="nft-info-title">{{ $t('MEMBERSHIP_SETTING.TOKEN_ID') }}:</span> {{ nftTokenId }}</p>
      </div>

      <div>
        <p class="nft-info"><span class="nft-info-title">{{ $t('MEMBERSHIP_SETTING.CHAIN') }}:</span> {{ nftChainName }}</p>
      </div>
      
    </div>

    <div class="buttons">
      <button class="n-btn-primary">{{ $t('BUTTON.UPDATE') }}</button>
      <button class="n-btn-weak" @click="cancel">{{ $t('BUTTON.CANCEL') }}</button>
    </div>

  </div>
</template>


<style scoped>
.image-drop-area {
  width: 60%;
  height: 200px;
  margin: auto;
  border-radius: 8px;
  background-color: #aaa;
}

.nft-info {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #555;
}

p {
  text-overflow: ellipsis;
}
.nft-info-title {
  color: #000;
  font-weight: 500;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
}
.buttons button {
  width: 200px;
  margin: auto;

}

/*------------------------------------------
  Responsive Grid Media Queries - 1280, 1024, 768, 480
   1280-1024   - デスクトップ（デフォルトのグリッド）
   1024-768    - タブレット横長
   768-480     - タブレット縦長
   480-less    - スマホ
--------------------------------------------*/
@media all and (min-width: 1024px) and (max-width: 1280px) { }
@media all and (min-width: 768px) and (max-width: 1024px) { }
@media all and (min-width: 480px) and (max-width: 768px) { }
@media all and (max-width: 480px) { 
  .image-drop-area {
    width: 100%;
    height: 100px;
    margin: auto;
    background-color: #aaa;
  }
}

</style>


<script>
import {defineComponent} from 'vue';

export default defineComponent ({
  name: 'MembershipSettingView',
  data() {
    return {
    }
  },
  props: {
    membershipId: {
      type: String,
      default: "-1"
    },
    nftContractAddress: {
      type: String,
      default: "NaN"
    },
    nftTokenId: {
      type: String,
      default: "NaN"
    },
    nftChainName: {
      type: String,
      default: "NaN"
    }
  },
  methods: {
    cancel: function() {
      var _this = this;
      // Show alert before leave comment view
      this.$swal({
      title: 'Are you sure?',
      text: "The change will be lost.",
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: "Keep editing",
      confirmButtonText: 'Leave'
      }).then((result) => {
        if (result.value) {
          _this.$router.go(-1);
        }
      });
    }
  }, 
  components: {
  }
});
</script>