<template>
  <div id="container_">
    <span id="membership-title" @click="goTimeline">
      <h2>{{ title }}</h2>
    </span>
    <span id="haader-buttons">
      <button id="button-notification" @click="clickNotificationEvent">
        <span
          v-bind:style="{
            backgroundImage: 'url(' + notificationIconImage + ')',
          }"
        ></span>
      </button>
      <!-- <button id="button-share" @click="clickShareEvent"><span></span></button> -->
      <button id="button-profile" @click="clickProfileEvent">
        <span
          v-bind:style="{ backgroundImage: 'url(' + profileImageUrl + ')' }"
        ></span>
      </button>
      <button id="button-admin" @click="clickAdminEvent" v-show="isAdmin">
        <span></span>
      </button>
      <div>
        <div class="icon-wallet"></div>
        <p>{{ walletAddress }}</p>
      </div>
      <div class="chain-name-container">
        <p>{{ chainName }}</p>
      </div>
    </span>
  </div>
</template>

<style scoped>
h2 {
  color: #000;
}
#container_ {
  /*Fixed header*/
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 115px;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: #ffffff;
  border-bottom: 2px solid #000;
}

#button-roadmap {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
  width: 120px !important;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid #fff !important;
  background-color: transparent !important;
}
#icon-link {
  width: 16px !important;
  height: 16px !important;
  background-position: center;
  background-size: contain;
  barkground-repeat: no-repeat;
  background-image: url("@/assets/img/icon-link.png");
}

#membership-title {
  text-align: left;
  margin-left: 24px;
  cursor: pointer;
}

#haader-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  gap: 12px;
  margin-right: 24px;
}
#haader-buttons div {
  display: flex;
  align-items: center;
  justify-content: center;
}
#haader-buttons div p {
  max-width: 80px;
  margin-left: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  /*color: #E1E8ED;*/
  color: #000;
}
#haader-buttons button {
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: none;
  padding: 0; /* This is to center button image  */
  overflow: hidden;
  /*background-color: #E1E8ED;*/
  background-color: #000;
}
#haader-buttons button > span {
  width: 50px;
  height: 50px;
  display: inline-block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
/*#button-notification>span {
  background-image:url('@/assets/img/icon-bell.png');
}*/
#button-share > span {
  background-image: url("@/assets/img/icon-share-white.png");
}
#button-profile > span {
  background-size: cover;
}
#button-admin > span {
  background-image: url("@/assets/img/icon-gear-white.png");
}

.icon-wallet {
  width: 20px;
  height: 20px;
  background-image: url("@/assets/img/icon-wallet.png");
  background-position: center;
  background-size: contain;
  barkground-repeat: no-repeat;
}
@media all and (min-width: 1024px) and (max-width: 1280px) {
}
@media all and (min-width: 768px) and (max-width: 1024px) {
}
@media all and (min-width: 480px) and (max-width: 768px) {
}
@media all and (max-width: 480px) {
  #container_ {
    height: 135px;
  }
  #haader-buttons {
    gap: 6px;
  }
  #haader-buttons button {
    width: 30px;
    height: 30px;
    border-radius: 15px;
  }
  #haader-buttons button > span {
    width: 30px;
    height: 30px;
    display: inline-block;
  }
}
</style>

<script>
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  name: "MainHeader",
  data() {
    return {
      profileImageUrl: "",
      notificationIconImage: "",
      didLoadProfile: false,
    };
  },
  computed: {
    notificationIconImageNornal: function () {
      return require("@/assets/img/icon-bell-white.png");
    },
    notificationIconImageFlagged: function () {
      return require("@/assets/img/icon-bell-dot-white.png");
    },
    loaderImageMedium: function () {
      return require("@/assets/img/loader-medium.gif");
    },
  },
  updated() {
    if (this.walletAddress && !this.didLoadProfile) {
      this.loadProfile();
    }
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    walletAddress: {
      type: String,
      default: "",
    },
    chainName: {
      type: String,
      default: "",
    },
    membershipId: {
      type: String,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    nftImageUrl: {
      type: String,
    },
  },
  inject: {
    API_BASE_URL: {
      from: "API_BASE_URL",
    },
    API_ENDPOINT_USER: {
      from: "API_ENDPOINT_USER",
    },
    LOCAL_STORAGE_KEY_MEMBERSHIP: {
      from: "LOCAL_STORAGE_KEY_MEMBERSHIP",
    },
    SHARE_URL: {
      from: "SHARE_URL",
    },
  },
  emits: [
    "click-profile-event",
    "click-share-event",
    "click-notification-event",
  ],
  methods: {
    loadProfile: function () {
      // Show loader
      this.profileImageUrl = this.loaderImageMedium;

      // Load profile image
      let _this = this;
      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_USER;
      axios
        .get(apiUrl, {
          params: {
            walletAddress: this.walletAddress,
            membershipId: this.membershipId,
          },
        })
        .then((result) => {
          let dataObj = JSON.parse(JSON.stringify(result.data));
          let profileImageUrl = dataObj.profile_image_url;
          let hasNewNotification = dataObj.has_new_notification;
          let hasNewNews = dataObj.has_new_news;
          _this.profileImageUrl = profileImageUrl;

          // Update内でロードするので、連続してAPIがコールされないためのフラグをセット
          _this.didLoadProfile = true;

          // Change notifcation icon if new notification flag set
          if (hasNewNotification || hasNewNews) {
            _this.notificationIconImage = _this.notificationIconImageFlagged;
          } else {
            _this.notificationIconImage = _this.notificationIconImageNornal;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    checkNotifications: function () {},
    clickProfileEvent: function () {
      this.$router.push({
        name: "profile-setting",
        query: {
          membershipId: this.membershipId,
        },
      });
    },
    clickNotificationEvent: function () {
      this.$router.push({
        name: "notification",
        query: {
          membershipId: this.membershipId,
        },
      });
    },
    clickShareEvent: function () {
      navigator.clipboard.writeText(this.SHARE_URL);
      this.$swal({
        title: this.$t('POPUP.TITLE_COPY_SHARED_SUCCESS'),// "Share URL copied to clipboard!",
        position: "top-end",
        type: "success",
        showConfirmButton: false,
        timer: 1500,
      });
    },
    clickAdminEvent: function () {
      this.$router.push({
        name: "admin",
        query: { membershipId: this.membershipId },
      });
    },
    clickRoadmap: function () {
      window
        .open(
          "https://docs.google.com/presentation/d/10aOeCYq_a4bIv4Na0Uq9KF3YzdhVruQkrNdBMg5hSU0/edit?usp=sharing",
          "_blank"
        )
        .focus();
    },
    goTimeline: function () {
      // this.$router.push( {name: "timeline", query: {membershipId: this.membershipId} } );
      let membershipData = sessionStorage.getItem(
        this.LOCAL_STORAGE_KEY_MEMBERSHIP
      );
      let membershipObj = JSON.parse(membershipData);
      let tokenAddress = membershipObj.tokenAddress;
      let tokenId = membershipObj.tokenId;
      this.$router.push({
        name: "timeline",
        query: {
          membershipId: this.membershipId,
          tokenAddress: tokenAddress,
          tokenId: tokenId,
        },
      });
    },
  },
});
</script>
