// import Web3 from 'web3/dist/web3.min.js'; // 20230325 commented out due to using npm web3 package
import Web3 from 'web3';
import Moralis from 'moralis';
import axios from "axios";
import { connectWeb3Modal, makeTransactionWeb3Modal} from '@/utils/web3Modal.js'
let web3;
let web3ModalInfo;
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_APP_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESS_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};
import i18n from "@/language";

export default {
  firebaseConfig: function() {
    return firebaseConfig;
  },
  initMoralis: async function(serverUrl, appId, masterKey) {
    // (async () => {
    //   await Moralis.start({ serverUrl, appId, masterKey });
    // })();
    try {
      await Moralis.start({
        apiKey: masterKey
      });
    } catch (e) {
      console.error(e);
    }
  },

  /*
  Use use handlec connect from metamask
  */
  connectWalletMetamask: async function(callback) {
    var timeoutId;
    web3 = new Web3(Web3.givenProvider || "ws://localhost:8545");
    function timeout(ms, promise) {
      const timeoutPromise = new Promise((resolve, reject) => {
        timeoutId = setTimeout(() => {
          reject(new Error('Request timed out'));
        }, ms);
        promise.then((result) => {
          clearTimeout(timeoutId);
          resolve(result);
        }, reject);
      });
      return timeoutPromise;
    }
    if (window.ethereum) {
      console.log("given provier", Web3.givenProvider);
      console.log("current provier", Web3.currentProvider);

      // detect Metamask account change
      window.ethereum.on('accountsChanged', (accounts) => {
        (async () => {
          let account = accounts[0];
          let chainId = await web3.eth.getChainId();
          let walletData = JSON.stringify({
            walletAddress: account,
            chainId: chainId
          });
          callback(null, 'accountsChanged', walletData);
        })();
      });
      // detect Network account change
      window.ethereum.on('chainChanged', function(networkId){         
        (async () => {
          let accounts = await web3.eth.getAccounts();
          let account = accounts[0];
          let chainId = parseInt(networkId, 16);
          let walletData = JSON.stringify({
            walletAddress: account,
            chainId: chainId
          });
          callback(null, 'chainChanged', walletData);
        })();
      });

      try {
        await timeout(5000, window.ethereum.request({ method: 'eth_requestAccounts' }));
        
        clearTimeout(timeoutId);
        
        let chainId = await web3.eth.getChainId();
        let accounts = await web3.eth.getAccounts();
        let account = accounts[0];
        let walletData = JSON.stringify({
          walletAddress: account,
          chainId: chainId
        });
        callback(null, "connected", walletData);
      } catch (error) {
        console.error('Error requesting accounts:', error);
        callback(true, error.message, null);
      }

    } else {
        callback(true, "metamask not detected", null);
    } 
  },

  /*
    Use check wallet connect from which source.
    From here, can update event to component
  */
  connectWallet: async function(callback) {
    console.log("connecting wallet..");
    var timeoutId;
    web3 = new Web3(Web3.givenProvider || "ws://localhost:8545");
    function timeout(ms, promise) {
      const timeoutPromise = new Promise((resolve, reject) => {
        timeoutId = setTimeout(() => {
          reject(new Error('Request timed out'));
        }, ms);
        promise.then((result) => {
          clearTimeout(timeoutId);
          resolve(result);
        }, reject);
      });
      return timeoutPromise;
    }
     const  accountMetamask = await web3.eth.getAccounts()?.[0];
     connectWeb3Modal(async (err, event, walletData) => {
      const convertWalletData = JSON.parse(walletData);
      web3ModalInfo = convertWalletData;
      if((convertWalletData?.walletAddress && !accountMetamask)  || event === 'actionOpenOrCloseModal') {
        callback(err, event, walletData);
      }
     })
    if (window.ethereum && !web3ModalInfo?.walletAddress ) {
    
      console.log("given provier", Web3.givenProvider);
      console.log("current provier", Web3.currentProvider);

      // detect Metamask account change
      window.ethereum.on('accountsChanged', (accounts) => {
        (async () => {
          let account = accounts[0];
          let chainId = await web3.eth.getChainId();
          let walletData = JSON.stringify({
            walletAddress: account,
            chainId: chainId
          });
          callback(null, 'accountsChanged', walletData);
        })();
      });
      // detect Network account change
      window.ethereum.on('chainChanged', function(networkId){         
        (async () => {
          let accounts = await web3.eth.getAccounts();
          let account = accounts[0];
          let chainId = parseInt(networkId, 16);
          let walletData = JSON.stringify({
            walletAddress: account,
            chainId: chainId
          });
          callback(null, 'chainChanged', walletData);
        })();
      });

      try {
        await timeout(5000, window.ethereum.request({ method: 'eth_requestAccounts' }));
        
        clearTimeout(timeoutId);
        
        let chainId = await web3.eth.getChainId();
        let accounts = await web3.eth.getAccounts();
        let account = accounts[0];
        let walletData = JSON.stringify({
          walletAddress: account,
          chainId: chainId
        });
        callback(null, "connected", walletData);
      } catch (error) {
        console.error('Error requesting accounts:', error);
        callback(true, error.message, null);
      }

    } else {
      if(!web3ModalInfo.walletAddress) {
        callback(true, "metamask not detected", null);
      }
    } 
  },
  checkIfConnected: async function(callback) {
    console.log("shared: checkIfConnected");
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: "eth_accounts" });
        if (accounts.length !== 0) {
          console.log("dapp already connected", accounts);
          callback(true, );
        } else {
          console.log("dapp not connected", accounts.length);
          callback(false, );
        }
      } catch (e) {
        console.error(e);
        callback(null, e);
      }
    } else {
      console.error("window.ethereum not available");
      callback(null, "window.ethereum not available");
    }
  },
  async initializeMetaMask(callback) {
    if (window.ethereum) {
      console.log("ethereum ok");
      callback();
    } else {
      console.log("ethereum NOT ok");
      const intervalId = setInterval(async () => {
        if (window.ethereum) {
          clearInterval(intervalId);
          callback();
        }
      }, 1000); // Check every second
    }
  },
  makeTransactionEth: async function(recipientAddress, ethAmount, callback) {
    const transactionWeb3Modal = await makeTransactionWeb3Modal(recipientAddress, ethAmount);
    if(transactionWeb3Modal.connect) {
      if(transactionWeb3Modal.error) {
        callback(null, transactionWeb3Modal.error);
      }
      if(transactionWeb3Modal.receipt) {
        callback(transactionWeb3Modal.receipt, null);
      }
      return;
    }
    if (!web3) {
      console.error('Not connected to MetaMask. Please connect first using the connectMetaMask function.');
      return;
    }
    const accounts = await web3.eth.getAccounts();
    const senderAddress = accounts[0];
    const gasPrice = await web3.eth.getGasPrice();
    const gasLimit = 21000; // Gas limit for a simple Ether transfer is fixed at 21000
    const tx = {
      from: senderAddress,
      to: recipientAddress,
      value: web3.utils.toWei(String(ethAmount), 'ether'),
      gasPrice: gasPrice,
      gas: gasLimit,
    };
    try {
      const receipt = await web3.eth.sendTransaction(tx);
      console.log('Transaction receipt:', receipt);
      callback(receipt, null);
    } catch (error) {
      console.error('Error sending transaction:', error);
      callback(null, error);
    }
  },
  logout: function(localCacheKey) {
    /* Clear local token related cache */
    sessionStorage.removeItem(localCacheKey); 
    this.$router.push('/');
  },
  handleError: function() {
    this.$swal({
      title:  i18n.global.t('POPUP.TITLE_COMMON_ERROR'), //'Sorry, something went wrong',
      text: i18n.global.t('POPUP.ERR_1001_CONTENT'), // "Please try again. error code: 1001",
      type: 'warning',
      showCancelButton: false,
      cancelButtonColor: '#1DA1F2',
      confirmButtonColor: '#AAB8C2',
      timer: 1500
        }).then((result) => {
        if (result.value) {
          window.location.reload();
        }
    });
  },
  handleShowClipboard: function () {
    this.$swal({
      title: i18n.global.t('POPUP.TITLE_TOKEN_COPIED'), //'Token address is copied to clipboard!',
      position: 'top-end',
      type: 'success',
      showConfirmButton: false,
      timer: 1500
    });
  },
  handleErrorAndGoTop: function(message, subMessage, timeout) {
    this.$swal({
      title: message,
      text: subMessage,
      type: 'warning',
      showCancelButton: false,
      showConfirmButton: false,
      timer: timeout
      }).then(() => {
      this.$router.push('/');
    });
  },
  handleSuccess: function() {
    this.$swal({
      position: 'top-end',
      icon: 'success',
      title: 'Success',
      type: 'success',
      showCancelButton: false,
      iconColor: '#000',
      timer: 1500
    });
  },
  showDraftLossAlert: function(callback) {
      this.$swal({
      title: i18n.global.t('POPUP.TITLE_DRAFT'), //'Are you sure?',
      text:  i18n.global.t('POPUP.CONTENT_DRAFT'), //'The draft will be lost.',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText:  i18n.global.t('BUTTON.KEEP_WRITING'), //'Keep writing',
      cancelButtonColor: '#000',
      confirmButtonText:  i18n.global.t('BUTTON.QUIT_WRITING'), //'Quit writing',
      confirmButtonColor: '#ccc'
      }).then((result) => {
        if (result.value) {
          callback();
        }
      });
  },
  validateUrl: function(value) {
    return value.match(/^https:\/\//);
  },
  validateEmail: function(value) {
    var regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return regex.test(value);
  },
  getOgp: function(url, callback) {
    (async () => {
      const response = await axios.get(url);
      const data = response.data;
      callback(data);
    })();
  },
  checkNFT: async function(chain, walletAddress, tokenAddress, tokenId, callback) {
    var isValidTokenExist = false;
    var chainHex;
    switch(chain) {
    case "eth":
      chainHex = "0x1";
      break;
    case "polygon":
      chainHex = "0x89";
      break;
    default:
      break;
    }
    if (!chainHex) {
      console.error("chainHex null");
      callback(isValidTokenExist);
      return;
    }
    try {
      // Check for NFT
      if(tokenId) {
        const userNFTs = await Moralis.EvmApi.nft.getWalletNFTs({
          "chain": chainHex,
          "address": walletAddress,
          "format": "decimal",
          "tokenAddresses": [],
        });
        if (!userNFTs.raw) {
          console.error("moralis response result is null");
        } else {
          for (var i=0; i < userNFTs.raw.result.length; i++) {
            if (!userNFTs.raw.result[i].token_address || !userNFTs.raw.result[i].token_id) {
              continue;
            }
            if (userNFTs.raw.result[i].token_address === tokenAddress && userNFTs.raw.result[i].token_id === tokenId) {
              // 所定のTokenがある場合のみ、ページを表示
              isValidTokenExist = true;
              break;
            }
          }
        }
      } else {
      // Check for FT
         const userFTs = await Moralis.EvmApi.token.getWalletTokenBalances({
          "chain": chainHex,
          "address": walletAddress
        });
        isValidTokenExist = !!userFTs.raw.find((x) => x?.token_address === tokenAddress && !x.possible_spam);
      }
     
      callback(isValidTokenExist);
    } catch (err) {
      console.error(err);
      callback(isValidTokenExist);
    }
  },
  getChainParamFromChainId: function(chainId) {
    var chainParam = null;
    switch (chainId) {
      case 1:
        chainParam = "eth";
        break;
      case 137:
        chainParam = "polygon";
        break;
      default:
        break;
    }
    return chainParam
  },
  isMobile: function() {
   if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
     return true
   } else {
     return false
   }
  },
  async getFTsByWalletAdress(walletAddress, chain) {
    try {
      let chainHex;
      switch(chain) {
      case "eth":
        chainHex = "0x1";
        break;
      case "polygon":
        chainHex = "0x89";
        break;
      default:
        break;
      }
      if (!chainHex) {
        console.error("chainHex null");
        return [];
      }
      const response = await Moralis.EvmApi.token.getWalletTokenBalances({
        "chain": chainHex,
        "address": walletAddress
      });
      // Format chain at here 
      const result = response.raw
      .filter((x) => !x.possible_spam)
      .map((x) => {
        return {
          issuerName: x['name'] ?? '',
          tokenName:  x['symbol'] ?? '',
          tokenUri:  x['logo'] ||  (x['thumbnail'] ?? ''),
          tokenAddress:  x['token_address'] ?? '',
          tokenId:  null,
          chain: chain,
          isFT: true,
          balance:  ((x['balance'] ?? 0) / 10 ** x.decimals).toFixed(2)
        }
       
      });
     
      return result;
    } catch (e) {
      console.error('Error when call shared.getWalletTokenBalances', e.message);
    }
  },
}