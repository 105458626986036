<template>
  <!--   <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
</template>

<style>
/*@import url("https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap");*/
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;600;700&display=swap');
body {
  background-color: #f5f8fa;
}
#overlay-image .swiper-button-next {
  background-color: #f5f8fa;
  border-radius: 8px;
  width: 38px;
  height: 38px;
}
#overlay-image .swiper-button-prev {
  background-color: #f5f8fa;
  border-radius: 8px;
  width: 38px;
  height: 38px;
}
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  /*font-family: 'Silkscreen', cursive;*/
/*  font-family: "Space Mono", monospace;*/
  font-family: 'Roboto Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2c3e50;*/
  color: #000;
}
/* Swal */
.swal2-title,
.swal2-html-container {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
/*  font-family: "Space Mono", monospace;*/
  font-family: 'Roboto Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  border-radius: 0px !important;
}
.swal2-cancel {
  border-radius: 8px !important;
}
.swal2-styled.swal2-confirm {
  border-radius: 8px !important;
  background-color: #000 !important;
}
.swal2-popup.swal2-confirm {
  background-color: #000;
}
.black-icon .swal2-success-circular-line-right, 
.black-icon .swal2-success-circular-line-left, 
.black-icon .swal2-success-fix, 
.black-icon .swal2-success-tip, 
.black-icon .swal2-error-x, 
.black-icon .swal2-error-line {
  background-color: black !important;
  color: black !important;
  stroke: black !important;
}
/* Common Background */
.bg_dot {
  background-color: #fff;
  background-image: radial-gradient(#888 50%, transparent 50%);
  background-size: 3px 3px;
}
.bg_plane {
  background-color: #eee;
}
/*Popper*/
.container-popper {
  width: 300px;
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.45);
}
:deep(.popper) {
  background: #fff;
  border: 2px solid #0091ff;
  padding: 20px;
  border-radius: 20px;
  color: #000;
  font-weight: bold;
}
:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #fff;
}
.container-popper button div {
  /*Offical mark icon */
  width: 20px;
  height: 20px;
  margin: 0 !important;
  padding: 0 !important;
  vertical-align: middle;
}
.popper {
  border-radius: 0px !important;
  background-color: #000 !important;
  color: #fff !important;
}
nav {
  padding: 30px;
}
nav a {
  font-weight: bold;
  color: #2c3e50;
}
nav a.router-link-exact-active {
  color: #42b983;
}
a {
  color: #1da1f2;
}
button {
  cursor: pointer;
/*  font-family: "Space Mono", monospace;*/
  font-family: 'Roboto Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 8px;
}
/* Tab UI */
ul {
  padding: 0;
}
li {
  margin: auto;
  list-style: none;
}
.tab_list {
  overflow: hidden;
  text-align: center;
}
.tab_list li {
  width: 140px;
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  transition: 0.1s;
  color: #888;
}
.tab_list li:not(:first-child) {
  border-left: none;
}
.tab_list li.active {
  color: #000;
  font-weight: bold;
  border-bottom: 3px solid #000;
  cursor: auto;
}
/* Container */
.n-container {
  width: 70%;
  margin: auto;
  padding: 32px;
  /*border: 1px solid #000;*/
}
.n-container .bordered {
  border: 1px solid #000;
}
.n-header {
  margin: auto;
  padding: 32px;
}
.history-back-button {
  float: left;
  border: none;
  text-decoration: underline;
  font-size: 16px;
  background-color: transparent;
  cursor: pointer;
}
.n-container:nth-child(n + 2) {
  margin-top: 24px;
}
.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  overflow: scroll;
  background-color: rgba(0, 0, 0, 0.5);
}
/*Button*/
.n-btn-primary {
  cursor: pointer;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  /*background-color: #0091FF;*/
  /*background-color: #1DA1F2;*/
  background-color: #000;
  border-radius: 8px;
  color: #fff;
  border: none;
  text-decoration: none;
}
.n-btn-primary:disabled {
  opacity: 0.4;
  cursor: default;
}
.n-btn-weak {
  cursor: pointer;
  padding: 8px 16px;
  font-size: 16px;
  /*background-color: #ccc;*/
  background-color: #aab8c2;
  border-radius: 8px;
  color: #fff;
  border: none;
  /*border-radius: 4px;*/
  text-decoration: none;
}
.n-btn-link {
  text-decoration: underline;
  border: none;
  background: transparent;
  font-size: 15px;
  color: #1da1f2;
}
.n-btn-link.weak {
  color: #aab8c2;
}
.n-btn-destructive {
  cursor: pointer;
  padding: 8px 16px;
  font-size: 16px;
  /*background-color: #ccc;*/
  background-color: #ff7d7d;
  color: #fff;
  border: none;
  border-radius: 8px;
  text-decoration: none;
}
/*Icon*/
.icon-tooltip {
  width: 30px;
  height: 30px;
  margin-left: 8px;
  background-image: url("@/assets/img/icon-tooltip-black.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.icon-official-mark {
  display: inline-block;
  background-image: url("@/assets/img/icon-official-mark.png");
  background-size: contain;
  background-repeat: no-repeat;
}
/*Text*/
.error-text {
  margin: 4px;
  color: #ff6969;
}
.error-text-small {
  margin: 4px;
  font-size: 14px;
  color: #ff6969;
}
.weak-text {
  font-size: 12px !important;
  color: #888 !important;
}
.medium-text {
  font-size: 16px !important;
  font-weight: 600;
}
.strong-text {
  font-size: 22px !important;
  font-weight: 600;
}
.text-white {
  color: #fff !important;
}
.text-gray {
  color: #888 !important;
}
.text-black {
  color: #000 !important;
}
.text-blue {
  color: #1da1f2 !important;
}
.text-pink {
  color: #ff1694 !important;
}
.text-main {
  font-size: 1.5em;
  font-weight: 700;
  color: #1da1f2;
}
.text-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
/* Wallet address */
.wallet-address-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.wallet-address-container div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.wallet-address-container p {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: 600;
}
.chain-name-container {
  padding: 0px 12px;
  color: #000;
  background-color: #fff;
/*  border: 1px solid #000;*/
  border-radius: 12px;
  height: 24px;
}
.chain-name-container p {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  color: #000;
}
.icon-wallet {
  width: 24px;
  height: 24px;
  background-image: url("@/assets/img/icon-wallet.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
/* Profile View Elements */
.icon-calendar {
  width: 20px;
  height: 20px;
  background-image: url("@/assets/img/icon-calendar.png");
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
}
.icon-comment {
  width: 20px;
  height: 20px;
  background-image: url("@/assets/img/icon-comment-white.png");
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
}
.icon-like {
  width: 20px;
  height: 20px;
  background-image: url("@/assets/img/icon-like-white.png");
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
}
.icon-post {
  width: 20px;
  height: 20px;
  background-image: url("@/assets/img/icon-pen.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.icon-comment-blue {
  width: 20px;
  height: 20px;
  background-image: url("@/assets/img/icon-comment-blue.png");
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
}
.icon-comment-black {
  width: 20px;
  height: 20px;
  background-image: url("@/assets/img/icon-comment-black.png");
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
}
.icon-like-blue {
  width: 20px;
  height: 20px;
  background-image: url("@/assets/img/icon-like-blue.png");
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
}
.icon-like-black {
  width: 20px;
  height: 20px;
  background-image: url("@/assets/img/icon-like-filled-black.png");
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
}
.icon-post-blue {
  width: 20px;
  height: 20px;
  background-image: url("@/assets/img/icon-pen-blue.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.icon-post-black {
  width: 20px;
  height: 20px;
  background-image: url("@/assets/img/icon-pen-black.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.profile-info-container {
  border-radius: 8px;
  border: 2px solid #000;
  padding: 16px;
  background-color: #fff;
}
.user-info-sign.large {
  width: 280px;
}
.user-info-sign.pink {
  /*border: 1px solid #ff1694;*/
  /*border-bottom: 1px solid #ff1694;*/
  border-bottom: 2px solid #000;
}
.user-info-sign {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 40px;
  margin: 12px auto;
  gap: 6px;
  /*border-bottom: 1px solid #1DA1F2;*/
  border-bottom: 2px solid #000;
}
.user-info-sign p {
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
}
/*Admin Mark*/
.admin-mark {
  width: 50px;
  height: 30px;
  padding: 4px;
  border-radius: 8px;
  font-size: 14px;
  color: white;
  font-weight: 600;
  background-color: #14171a;
}
hr {
  margin-top: 128px;
  background-color: #000;
  height: 2px;
  border: none;
}
/*Form*/
form {
  margin: auto;
}
form div {
  margin-bottom: 12px;
  display: flex;
  /*align-items: center;*/
  flex-wrap: wrap;
  justify-content: center;
}
form label {
  font-weight: bold;
  width: 120px;
  font-size: 22px;
  color: #777;
  text-align: right;
}
form input {
  width: 240px;
  height: 30px;
  margin-left: 8px;
  font-size: 15px;
  border-radius: 4px;
  border: 1px solid rgb(118, 118, 118);
}
form select {
  width: 240px;
  height: 30px;
  margin-left: 8px;
  font-size: 15px;
  border-radius: 8px;
}
form textarea {
  width: 240px;
  height: 100px;
  margin-left: 8px;
  font-size: 15px;
  border-radius: 4px;
}
form label.label-small {
  font-size: 18px;
}
form label.label-wide {
  width: 180px;
  font-size: 18px;
}

/*Swiper*/
.swiper-button-next::after,
.swiper-button-prev::after {
  color: black !important;
  font-size: 24px !important;
}
.swiper-pagination-bullet-active {
  background: #000 !important;
}

/*Infinite loader*/
.infinite-loop-loader {
  width: 100%;
  height: 80px;
  background-color: transparent;
  background-image: url("@/assets/img/loader-small.gif");
  background-position: center;
  background-size: 20px 20px;
  background-repeat: no-repeat;
}
.display-block {
  width: 17rem;
}
.change-language {
  display: flex;
  justify-content: end;
}
@media all and (min-width: 1024px) and (max-width: 1280px) {
}
@media all and (min-width: 768px) and (max-width: 1024px) {
}
@media all and (min-width: 480px) and (max-width: 768px) {
}
@media all and (max-width: 480px) {
  .n-container {
    width: 100%;
    padding: 24px 0;
    padding-left: 9px;
    padding-right: 9px;
  }
  form input {
    width: 240px;
    height: 30px;
    margin-left: 0px;
  }
  form label {
    text-align: center;
  }
}
</style>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  mounted() {
    document.title = "DeepBeach";
  },
});
</script>
