<template>
  <div class="overlay-container" @click="clickContainer">
    <div class="image-container">
      <swiper
        ref="mySwiper"
        :modules="modules"
        :slides-per-view="1"
        :space-between="50"
        :loop="true"
        navigation
        :pagination="{ clickable: true }"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        id="overlay-image"
        :initialSlide="0"
      >
        <swiper-slide
          v-for="(imageUrl, index) in shopDetail"
          :key="index"
          class="swipe-card-wrapper"
        >
          <div class="swipe-card">
            <div
              class="post-image"
              :style="{ backgroundImage: 'url(' + imageUrl + ')' }"
              v-if="imageUrl != null"
            ></div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<style scoped>
.swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%234c71ae'%2F%3E%3C%2Fsvg%3E") !important;
}

.swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%234c71ae'%2F%3E%3C%2Fsvg%3E") !important;
}
.image-container {
  margin-top: 10%;
/*  padding-left: 28rem;
  padding-right: 28rem;*/
  padding-left: 10rem; /* 28remから10remに変更 */
  padding-right: 10rem; /* 28remから10remに変更 */
}
.post-image {
  width: 100%;
  height: 50vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.button-close {
  width: 30px;
  height: 30px;
  float: right;
  margin-right: 12px;
  margin-top: 12px;
  background-image: url("@/assets/img/icon-close.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media all and (max-width: 480px) {
  .image-container {
    margin-top: 20%;
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
export default defineComponent({
  name: "OverlayImage",
  data() {
    return {
      swiper: null,
    };
  },
  setup() {
    const mySwiper = ref(null);

    const onSlideChange = () => {
      event.stopPropagation();
    };
    
    // 矢印ボタンのクリックイベントの伝播を停止するリスナーを追加
    onMounted(() => {
      const prevButton = mySwiper.value.$el.querySelector('.swiper-button-prev');
      const nextButton = mySwiper.value.$el.querySelector('.swiper-button-next');

      const stopPropagation = (event) => {
        event.stopPropagation();
      };

      if (prevButton) {
        prevButton.addEventListener('click', stopPropagation);
      }

      if (nextButton) {
        nextButton.addEventListener('click', stopPropagation);
      }
    });

    const truncate = (text, length, suffix) => {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    };
    return {
      onSlideChange,
      modules: [Navigation, Pagination, A11y],
      truncate,
      mySwiper
    };
  },
  props: {
    imageUrl: {
      type: Object,
    },
    shopDetail: {
      type: Object,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    clickContainer: function (event) {
      // クリックされた要素が矢印ボタンであるかどうかをチェック
      if (
        event.target.classList.contains('swiper-button-prev') ||
        event.target.classList.contains('swiper-button-next')
      ) {
        // 矢印ボタンがクリックされた場合は何もしない
        return;
      }

      this.handleCloseEvent();
    },
    clickActiveArea: function (event) {
      event.stopPropagation();
      this.dismiss();
    },
    handleCloseEvent: function () {
      event.stopPropagation();
      this.dismiss();
    },
    dismiss: function () {
      this.$emit("close-overlay-image");
      this.swiper.slideTo(0);
    },
  },
});
</script>
