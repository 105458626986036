<template>
  <div
    class="notification-cell-container"
    :class="{
      '.notication-shop' : isShop
    }"
    v-if="isDataValid"
    v-bind:style="{ backgroundColor: backgroundColor }"
    @click="clickNotificationEvent"
  >
    <div class="left-column">
      <div class="upper-section">
        <div
         v-if="!isShop"
          class="profile-image"
          v-bind:style="{ backgroundImage:`url(${profileImageUrl})` }"
          v-on="!isShop ? { click: goProfile } : { click: goItem }"
        ></div>
        <div v-if="isShop" class="app-logo">
        </div>
        <div class="message-container">
          <p class="main-text" v-if="!isShop">{{ text }}</p>
          <p v-if="isShop">
            {{ $t('NOTIFICATION_CELL.YOUR_PURCHASE') }}
            <span class="main-text-item">{{ notification?.item_name }}🛍</span>
          </p>
          <p class="sub-text" v-if="!isShop">{{ subText }}</p>
          <p v-if="isShop" class="link-purchase">
            <a :href="notification.receipt_url" target="_blank"> {{ $t('NOTIFICATION_CELL.CHECK_ON_DASHBOARD') }}</a>
          </p>
          <!-- <p class="price_currency" v-if="isShop">
            <span class="price-item">{{ price }}</span> {{ currency }}
          </p> -->
          <!-- <p class="weak-text" v-if="isShop">
            Purchase date: {{ purchased_at }}
          </p> -->
          <div
          v-if="isShop"
          class="item-img"
          :style="{ backgroundImage: 'url(' + notification?.image_url + ')' }"
          @click="goItem"
        ></div>
        </div>
      </div>
      <div class="bottom-section">
        <p class="weak-text timestamp">{{ ago }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.notification-cell-container {
  display: flex;
  max-width: 60%;
  margin: auto;
  padding: 12px;
  overflow: hidden;
  background-color: #fff;
  border: 2px solid #000;
  cursor: pointer;
  border-radius:8px;
  margin-bottom:10px;
}
.notication-shop {
  cursor: auto;
}
.left-column {
  width: 100%;
}
.wrap-content {
  display: flex;
  align-items: center;
}
.main-text-item {
  font-weight: bold;
  margin-left: 1px;
}
.price-item {
  font-weight: 800;
  color: #888;
}
.upper-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 12px;
}
.bottom-section {
  /* display: flex;
  justify-content: space-between; */
}
.bottom-section p {
  text-align: left;
}
.right-column {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
}
.right-column button {
  width: 50px;
  height: 50px;
  border: none;
  background-color: transparent;
  background-image: url("@/assets/img/icon-right-arrow.png");
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}
.profile-image {
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 25px;
  background-color: #000;
  cursor: pointer;
}
.app-logo {
  background-image: url("@/assets/img/db-logo.png");
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 25px;
  background-color: #FFF;
}
.profile-shop {
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50px;
  background-color: #000;
  cursor: pointer;
}
.message-container {
  width: calc(100% - 80px);
}
.message-container p {
  text-align: left;
}
.checkoutBtn {
  text-align: left;
  color: #1da1f2;
  text-decoration: underline;
}
.main-text {
  font-weight: 500;
  font-color: #000;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.sub-text {
  font-size: 14px;
  font-color: #aaa;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.timestamp {
  text-align: right !important;
  margin-right: 20px;
}
.item-img {
  width: 100px;
  height: 100px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0;
  background-color: transparent;
  border-radius: 8px;
}
.link-purchase {
  display: flex;
}
@media all and (min-width: 1024px) and (max-width: 1280px) {
}
@media all and (min-width: 768px) and (max-width: 1024px) {
}
@media all and (min-width: 480px) and (max-width: 768px) {
}
@media all and (max-width: 480px) {
  .notification-cell-container {
    max-width: 100%;
  }
}
</style>

<script>
import { defineComponent } from "vue";
import moment from "moment";
import axios from "axios";
export default defineComponent({
  name: "NotificationCell",
  data() {
    return {
      ago: "loading..",
      text: "loading..",
      subText: "",
      price: "",
      currency: "",
      purchased_at: "",
      profileImageUrl: "",
      backgroundColor: "#fff",
      isDataValid: false,
      isShop: false,
      apiRequest: null,
    };
  },
  mounted() {
    this.apiRequest = axios.CancelToken.source();
    this.type = this.notification.type;
    var date = new Date(Number(this.notification.created_at));
    let agoDate = moment(date, "ddd MMM DD YYYY HH:mm:ss GMT Z").fromNow();
    this.ago = agoDate;
    if (!this.notification.is_read) {
      this.backgroundColor = "#00EA00";
    } else {
      this.backgroundColor = "#fff";
    }
    let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_USER;
    if (this.type !== this.NOTIFICATION_TYPE_SHOP) {
      this.$axios
        .get(apiUrl, {
          params: {
            walletAddress: this.notification.from,
            membershipId: this.membershipId,
          },
          cancelToken: this.apiRequest.token,
        })
        .then((result) => {
          let dataObj = JSON.parse(JSON.stringify(result.data));
          this.profileImageUrl = dataObj.profile_image_url;
          let userName = dataObj.name;
          this.isDataValid = true; // 早めに枠だけでも表示

          // GET post text if notfication type is NOT tip
          if (this.type != this.NOTIFICATION_TYPE_TIP) {
            let apiUrl_ = this.API_BASE_URL + this.API_ENDPOINT_POST;
            axios
              .get(apiUrl_, {
                params: {
                  membershipId: this.membershipId,
                  postId: this.notification.post_id,
                },
                cancelToken: this.apiRequest.token,
              })
              .then((result) => {
                let dataObj_ = JSON.parse(JSON.stringify(result.data));
                let postText = dataObj_.post.text;
                switch (this.type) {
                  case "like_post":
                    this.text = `${userName} liked your post❤️`;
                    break;
                  case "like_comment":
                    this.text = `${userName} liked your comment❤️`;
                    break;
                  case "comment":
                    this.text = `${userName} commented on your post💬`;
                    break;
                }
                this.subText = postText.substring(0, 50) + "...";
                // this.isDataValid = true; // 元々ここ
              })
              .catch((err) => {
                console.error(err);
                // Remove itself if data is invalid
                this.isDataValid = false;
              });
          } else {
            this.text = `${userName} tip you ${this.notification.amount} ${this.notification.symbol}💸`;
            this.isDataValid = true;
          }
        })
        .catch((err) => {
          console.error(err);
          // Remove itself if data is invalid
          this.isDataValid = false;
        });
    } else {
      this.isShop = this.notification.type === this.NOTIFICATION_TYPE_SHOP;
      this.isDataValid = true;
      this.price = this.notification?.price
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.currency = this.notification?.currency;
      const datePurchased = new Date(Number(this.notification.purchased_at));
      const dateObj = moment(datePurchased, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
      this.purchased_at = dateObj.format("YYYY.MM.DD");
      this.profileImageUrl = this.notification?.image_url;
    }
  },
  computed: {
    isShowPrice: function () {
      return this.notfication?.price && this.notfication?.currency;
    },
  },
  inject: {
    API_BASE_URL: {
      from: "API_BASE_URL",
    },
    API_ENDPOINT_USER: {
      from: "API_ENDPOINT_USER",
    },
    API_ENDPOINT_POST: {
      from: "API_ENDPOINT_POST",
    },
    LOCAL_STORAGE_KEY_MEMBERSHIP: {
      from: "LOCAL_STORAGE_KEY_MEMBERSHIP",
    },
    NOTIFICATION_TYPE_TIP: {
      from: "NOTIFICATION_TYPE_TIP",
    },
    NOTIFICATION_TYPE_SHOP: {
      from: "NOTIFICATION_TYPE_SHOP",
    },
  },
  props: {
    doCancelHttpRequest: {
      type: Boolean,
    },
    notification: {
      type: Object,
    },
    membershipId: {
      type: String,
    },
  },
  emits: ["click-notification-event"],
  methods: {
   
    clickNotificationEvent: function () {
      if(this.isShop) return;
      if (this.type === this.NOTIFICATION_TYPE_TIP) {
        this.$router.push(
          `profile?membershipId=${this.membershipId}&user=${this.notification.from}`
        );
      } else {
        this.$router.push(
          `timeline?membershipId=${this.membershipId}&postId=${this.notification.post_id}&commentId=${this.notification.comment_id}`
        );
      }
    },
    cancelHttpRequest: function () {
      this.apiRequest.cancel("api_request_canceled");
    },
    goItem: function (event) {
      event.stopPropagation();
    },
    goProfile: function (event) {
      event.stopPropagation();
      this.$router.push({
        name: "profile",
        query: {
          user: this.notification.from,
          membershipId: this.membershipId,
        },
      });
    },
  },
  watch: {
    doCancelHttpRequest: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value == true) {
          this.cancelHttpRequest();
        }
      },
    },
    subText: {
      handler(newVal) {
        const cleanedValue = newVal.replaceAll("\\n", " ");
        if (cleanedValue !== newVal) {
          this.subText = cleanedValue;
        }
      },
      immediate: true,
    },
  },
});
</script>
