<template>
  <div class="like-cell-container" @click="onClickCell">
    <div
      class="profile-image"
      v-bind:style="{ backgroundImage: 'url(' + profileImageUrl + ')' }"
    ></div>
    <p class="user-name">{{ userName }}</p>
  </div>
</template>

<style scoped>
.like-cell-container {
  /*background-color: pink;*/
}

.profile-image {
  width: 50px;
  height: 50px;
  margin: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 25px;
  background-color: #000;
  cursor: pointer;
}

.user-name {
  width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/*------------------------------------------
  Responsive Grid Media Queries - 1280, 1024, 768, 480
   1280-1024   - デスクトップ（デフォルトのグリッド）
   1024-768    - タブレット横長
   768-480     - タブレット縦長
   480-less    - スマホ
--------------------------------------------*/
@media all and (min-width: 1024px) and (max-width: 1280px) {
}

@media all and (min-width: 768px) and (max-width: 1024px) {
}

@media all and (min-width: 480px) and (max-width: 768px) {
}

@media all and (max-width: 480px) {
}
</style>

<script>
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  name: "LikerCell",
  data() {
    return {
      profileImageUrl: "",
      userName: "",
      isOwn: false,
    };
  },
  mounted() {
    //
    // Fetch profile image url & use name
    //
    this.profileImageUrl = "@/assets/img/loader-medium.gif"; // Show loader
    this.userName = this.walletAddress.substring(0, 8);

    // Load profile image
    let apiUrl = this.apiBaseUrl + this.apiEndpointUser;
    let _this = this;
    axios
      .get(apiUrl, {
        params: {
          walletAddress: this.walletAddress,
          membershipId: this.membershipId,
        },
      })
      .then((result) => {
        let dataObj = JSON.parse(JSON.stringify(result.data));
        if (dataObj.profile_image_url) {
          _this.profileImageUrl = dataObj.profile_image_url;
        }
        if (dataObj.name) {
          _this.userName = dataObj.name;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  },
  props: {
    walletAddress: {
      type: String,
      default: "",
    },
    membershipId: {
      type: String,
      default: "",
    },
    apiBaseUrl: {
      type: String,
    },
    apiEndpointUser: {
      type: String,
    },
  },
  emits: ["click-liker-cell"],
  methods: {
    onClickCell: function () {
      let values = {
        wallet_address: this.walletAddress,
        membership_id: this.membershipId,
      };
      this.$emit("click-liker-cell", values);
    },
  },
});
</script>
