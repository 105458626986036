<template>
  <div class="tab-content-container">
    <form @submit.prevent>
      <div>
        <label class="label-small" for="country-select">{{$t('ADMIN_VIEW.COMMERCE_ACCOUNT.COUNTRY')}}*</label>
        <select
          @change="onChangeSelect"
          v-model="msgCopy"
          id="country-select"
          :disabled="isStripeAccountCreated"
        >
          <!-- <option disabled value="" class="text-gray">Select Contry</option> -->
          <option
            v-for="country in stripeCountries"
            :key="country.code"
            :value="country.code"
          >
            {{ country.name }}
          </option>
        </select>
      </div>
      <div v>
        <p class="error-text" v-if="isValidCountry === false">
          {{$t('ADMIN_VIEW.COMMERCE_ACCOUNT.COUNTRY_INVALID')}}
        </p>
      </div>
      <div class="wrap-input-email">
        <label class="label-small">   {{$t('ADMIN_VIEW.COMMERCE_ACCOUNT.EMAIL')}}*</label>
        <div>
          <input
            type="email"
            placeholder=""
            v-bind:value="commerceEmail"
            v-on:input="onChangeEmail"
            maxlength="500"
            :disabled="isStripeAccountCreated"
          />
          <p>* {{$t('ADMIN_VIEW.COMMERCE_ACCOUNT.EMAIL_EXPLAIN')}}</p>
        </div>
      </div>
      <div>
        <p class="error-text" v-if="isValidEmail === false">
          {{$t('ADMIN_VIEW.COMMERCE_ACCOUNT.EMAIL_INVALID')}}
        </p>
      </div>
    </form>


    <div class="onboard-section" v-if="isStripeAccountCreated === true">

      <div v-if="!isStep1Done || !isStripePublicKeySet" class="caution-kyc">
        <h3>  {{$t('ADMIN_VIEW.COMMERCE_ACCOUNT.STEP_SELL_ITEMS')}}⛱</h3>
        <div class="stripe-account-section">
          
          <!-- Step 1 -->
          <div class="step-1-section" :class="{ active: !isStep1Done}">
            <h3 class="step-label">
              <div class="d-flex" v-if="isStep1Done">
                <img src="@/assets/img/icon-check.png" alt="Icon" class="icon-check no-opacity">
              </div>
              {{$t('ADMIN_VIEW.COMMERCE_ACCOUNT.STEP_1')}}
            </h3>

            <div class="verification-buttons">
              <button class="n-btn-primary button-verification" @click="continueOnboard"> {{$t('ADMIN_VIEW.COMMERCE_ACCOUNT.COMPLETE_VETIFY')}}</button>
              <Popper hover content="Reload page to check the latest verification status">
                <button class="n-btn-reload" @click="reloadPage"></button>
              </Popper>
            </div>

            <div class="caution-kyc-sub" v-if="!isStep1Done && !isStripePublicKeySet">
              <ul>
                <b> {{$t('ADMIN_VIEW.COMMERCE_ACCOUNT.STEP1_NOTE')}}</b>
                <li>{{$t('ADMIN_VIEW.COMMERCE_ACCOUNT.STEP1_NOTE_1')}}</li>
                <li>>{{$t('ADMIN_VIEW.COMMERCE_ACCOUNT.STEP1_NOTE_2')}}
                  <img src="@/assets/img/thumbnail-stripe-email.png" alt="Stripe Verification Email">
                </li>
              </ul>
            </div>
            <!-- This case may happen when Stripe declines child account -->
            <div class="step-3-section" v-else>
              <p class="error-text">{{$t('ADMIN_VIEW.COMMERCE_ACCOUNT.STEP1_NOT_DONE')}}</p>
              <a style="text-decoration: underline;" href="https://support.stripe.com/?contact=true" target="_blank">{{$t('ADMIN_VIEW.COMMERCE_ACCOUNT.CONTACT_STRIPE')}}</a>
            </div>

            <div class="weak-text">
              <label class="label-small" style="margin-top: 12px">{{$t('ADMIN_VIEW.COMMERCE_ACCOUNT.ACCOUNT_ID')}}:</label
              >{{ stripeAccountId }}
            </div>
          </div>

          <!-- Step 2 -->
          <div class="step-2-section" :class="{ active: isStep1Done && !isStripePublicKeySet }">
            <h3 class="step-label">
            <div class="d-flex" v-if="isStripePublicKeySet">
              <img src="@/assets/img/icon-check.png" alt="Icon" class="icon-check no-opacity">
            </div>
            {{$t('ADMIN_VIEW.COMMERCE_ACCOUNT.STEP2_COPY_PASTE_PUBLIC_KEY')}}
            </h3>

            <div class="underline-button-container">
              <button @click="showOverlay = true"> {{$t('ADMIN_VIEW.COMMERCE_ACCOUNT.HOW_TO_FIND_API_KEY')}}</button>
              <!-- <div class="icon-external-link" /> -->
            </div>

            <div class="step-2-section-inner">
              <input type="text" placeholder="Paste your Public API Key here" v-model="apiKey" class="api-key-input" />
              <button 
              :disabled="!apiKey"
              class="n-btn-primary button-update-api-key" 
              @click="updateKey">{{ $t('BUTTON.UPDATE') }}</button>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <button class="n-btn-primary" :class="buttonClass" @click="goStripeConnectDashboard">
          {{ $t('BUTTON.GO_DASHBOARD') }}
        </button>    
        <div class="weak-text">
          <label class="label-small" style="margin-top: 12px">{{$t('ADMIN_VIEW.COMMERCE_ACCOUNT.ACCOUNT_ID')}}:</label
          >{{ stripeAccountId }}
        </div>
        <div class="weak-text public-key">
          <label class="label-small" style="margin-top: 12px">{{$t('ADMIN_VIEW.COMMERCE_ACCOUNT.PUBLIC_KEY')}}:</label>
          <span v-if="stripePublicKey?.length <= maxStringLength">{{ stripePublicKey }}</span>
          <span v-else>{{ stripePublicKey?.slice(0, maxStringLength) }}...</span>
          <button class="button-edit" @click="editPublicKey"></button>
        </div>
      </div>

    </div>

    <div v-else-if="isStripeAccountCreated === false">
      <div class="buttons">
        <button class="n-btn-primary" @click="createStripeConnectAccount">
          {{$t('ADMIN_VIEW.COMMERCE_ACCOUNT.BTN_CREATE_COMMERCE_ACC')}}
        </button>
      </div>
    </div>

    <div class="delete-commerce-account-ui" v-if="stripePublicKey">
      <div
        class="delete-commerce-account-ui d-flex flex-column justify-content-center align-items-center"
      >
        <button class="n-btn-link text-gray" @click="showDeleteAlert">
          {{$t('ADMIN_VIEW.COMMERCE_ACCOUNT.BTN_DELETE_ACC')}}
        </button>
      </div>
    </div>

  </div>

  <OverlayPublicKey 
  v-if="showOverlay" 
  :emailAddress="commerceEmail" 
  @dismiss="showOverlay = false"
  />

  <OverlayEditPublicKey
  v-if="showOverlayEditPublicKey"
  :accountId="stripeAccountId"
  @close="handleCloseOverlayEditPublicKey" 
  @showspinner="showSpinner = true"
  @hidespinner="showSpinner = false"
  />

  <OverlaySpinner 
  v-if="showSpinner"
  />

</template>

<style>
.n-btn-reload {
  background-color: #f0f0f0;
  color: #000;
  margin-left: 10px; /* Adjust the margin as needed */
  position: relative;
  border-radius: 21px; /* 半分のサイズに設定 */
  width: 28px;
  height: 28px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-image: url('@/assets/img/icon-reload.png'); /* アイコンのパスを設定 */
  background-size: 20px 20px; /* アイコンのサイズを調整 */
  background-repeat: no-repeat;
  background-position: center;
}
.verification-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

/* ... */
.red {
  background-color: black;
  border: 4px solid #fb6d4c;
}
.black {
  background-color: black;
}
.caution-kyc {
  font-size: 14px;
  font-weight: bold;
  color: #fb6d4c;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
}
.caution-kyc h3 {
  margin: auto;
  color: #000;
}
.caution-kyc-sub {
  font-size: 12px;
  font-weight: normal;
  color: #fb6d4c;
  text-align: left;
  margin-top: 12px;
}
.caution-kyc-sub img {
/*  margin-top: 12px;*/
  max-width: 100%;
}
.delete-commerce-account-ui {
  margin-top: 48px;
}
.delete-commerce-account-ui p {
  width: 80%;
  margin-top: 12px;
}
.tab-content-container {
  padding: 12px;
}
.edit-image {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 24px auto;
  border: 2px solid #000;
  background-color: #eee;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border-radius: 8px;
}
.edit-image-overlay {
  width: 200px;
  height: 200px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  text-align: center;
}
.edit-image-overlay p {
  position: absolute;
  width: inherit;
  top: 0;
  left: 0;
  margin: 0;
  line-height: 200px;
  color: #fff;
  font-weight: 700;
}
.public-key {
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 12px;
}
.buttons button {
  min-width: 200px;
  margin-top: 32px;
}
.button-edit {
  width: 24px;
  height: 24px;
  margin-left: 12px;
  border: none;
  border-radius: 12px;
  background-color: #555;
  background-image: url("@/assets/img/icon-edit.png");
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
}
.button-delete-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  margin-right: 12px;
  margin-top: 12px;
  border: none;
  border-radius: 15px;
  background-image: url("@/assets/img/icon-close.png");
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
}
.wrap-input-email {
  position: relative;
  padding-bottom: 70px;
}
.wrap-input-email p {
  width: 240px;
  position: absolute;
/*  left: 45.5%;*/
  top: 20px;
  text-align: left;
  color: #888 !important;
  font-size: 12px !important;
}
.wrap-input-email div {
  display: flex; 
  flex-direction: column;
}

.step-label {
  font-size: 14px;
  font-weight: bold;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.underline-button-container {
  display: inline-flex; /* インラインフレックスで要素を配置 */
  align-items: center; /* 中央にアイテムを配置 */
  border-bottom: 1px solid currentColor; /* 下線を追加 */
  margin-bottom: 24px;
  margin-top: 24px;
  color: #1da1f2;
}
.underline-button-container button {
  background: transparent;
  border: none;
  color: #1da1f2;
}


.step-1-section,.step-2-section {
  padding: 24px;
  border-radius: 8px;
  background-color: beige;
}
.step-1-section {
  margin: auto;
}
.step-1-section:not(.active) {
  opacity: 0.5;
  pointer-events: none; /* このスタイルで全てのマウスイベントを無効化 */
  width: 100%;
}
.step-2-section {
  margin: 20px auto;
}
.step-2-section:not(.active) {
  opacity: 0.5;
  pointer-events: none; /* このスタイルで全てのマウスイベントを無効化 */
}
.step-2-section-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-opacity {
  opacity: 1 !important;
}
.icon-check {
  width: 32px;
  height: 32px;
}

.api-key-input {
  margin-left: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 60%;
}
.button-update-api-key, .button-verification {
  min-width: 240px;
}
.button-update-api-key {
  margin-top: 18px;
}

.icon-external-link {
  width: 24px;
  height: 24px;
  background-image: url("@/assets/img/icon-external-link-blue.png");
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
}

.stripe-account-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 24px auto;
}

.onboard-section {
  padding: 24px;
  border-radius: 8px;
  background-color: #eee;
}

@media all and (max-width: 900px) {
  .step-1-section,.step-2-section {
    width: 100%;
  }
  .wrap-input-email p {
    width: 65%;
    top: 30px;
  }
  .delete-commerce-account-ui p {
    width: 100%;
  }
  .api-key-input {
    width: 100%;
  }
}

@media all and (max-width: 658px) {
  .wrap-input-email p {
    top: 60px;
  }
}

</style>

<script>
import Popper from "vue3-popper";
import OverlayPublicKey from '@/components/OverlayPublicKey.vue';
import OverlayEditPublicKey from '@/views/OverlayEditPublicKey.vue';
import OverlaySpinner from '@/views/OverlaySpinner.vue';


export default {
  name: "CommerceAccount",
  components: {
    Popper,
    OverlayPublicKey,
    OverlayEditPublicKey,
    OverlaySpinner
  },
  data() {
    return { 
      msgCopy: this.selectedCountry,
      showOverlay: false,
      showSpinner: false,
      showOverlayEditPublicKey: false,
      apiKey: '',
      maxStringLength: 20
    };
  },
  computed: {
    buttonClass() {
      if (this.payoutsEnabled && this.chargesEnabled) {
        return 'black';
      } else {
        return 'red';
      }
    },
    isStep1Done() {
      return this.payoutsEnabled && this.chargesEnabled;
    }
  },
  props: {
    selectedCountry: String,
    commerceEmail: String,
    stripeCountries: Array,
    isValidCountry: Boolean,
    isStripeAccountCreated: Boolean,
    isValidEmail: Boolean,
    onChangeEmail: Function,
    onChangeSelect: Function,
    goStripeConnectDashboard: Function,
    stripeAccountId: String,
    stripePublicKey: String,
    createStripeConnectAccount: Function,
    continueOnboard: Function,
    showDeleteAlert: Function,
    updatePublicKey: Function,
    payoutsEnabled: Boolean,
    chargesEnabled: Boolean,
    isStripePublicKeySet: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    reloadPage() {
      location.reload();
    },
    updateKey() {
      this.updatePublicKey(this.apiKey); 
    },
    editPublicKey() {
      this.showOverlayEditPublicKey = true;
    },
    handleCloseOverlayEditPublicKey() {
      this.showOverlayEditPublicKey = false;
      window.location.reload();
    }
  },
  watch: {
    selectedCountry() {
      this.msgCopy = this.selectedCountry;
    },
  },
};
</script>
