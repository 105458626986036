<template>
  <div class="tab-content-container">
    <div style="margin-bottom: 64px; padding: 16px; background-color: beige">
      <h3>{{  $t('ADMIN_VIEW.SLACK_INTEGRATION.SEND_NOTI_TO_SLACK')}}</h3>
      <ul style="text-align: left">
        <li>
          *{{  $t('ADMIN_VIEW.SLACK_INTEGRATION.SEND_NOTI_NOTE')}}
        </li>
        <li>
          *{{  $t('ADMIN_VIEW.SLACK_INTEGRATION.THE_DETAIL_TXT')}}
          <span style="text-decoration: underline">{{  $t('ADMIN_VIEW.SLACK_INTEGRATION.NOT_REVEALED')}}</span>{{  $t('ADMIN_VIEW.SLACK_INTEGRATION.IN_THE_NOTI')}}
        </li>
      </ul>
    </div>
    <form>
      <div>
        <label class="label-wide">{{  $t('ADMIN_VIEW.SLACK_INTEGRATION.ACCESS_TOKEN')}}*</label>
        <input
          type="text"
          placeholder=""
          v-bind:value="accessToken"
          v-on:input="onChangeAccessToken"
          maxlength="500"
        />
      </div>
      <div>
        <p class="error-text" v-show="isInvalidAccessToken">
          {{  $t('ADMIN_VIEW.SLACK_INTEGRATION.ACCESS_TOKEN_VALIDATE')}}
        </p>
      </div>
      <a
        class="weak-text text-blue"
        href="https://docs.celigo.com/hc/en-us/articles/7140655476507-How-to-create-an-app-and-retrieve-OAuth-token-in-Slack"
        target="_blank"
        > {{  $t('ADMIN_VIEW.SLACK_INTEGRATION.HOW_TO_GET_TOKEN')}}</a
      >
      <div style="margin-top: 24px">
        <label class="label-wide"> {{  $t('ADMIN_VIEW.SLACK_INTEGRATION.CHANEL_ID')}}*</label>
        <input
          type="text"
          placeholder=""
          v-bind:value="channelId"
          v-on:input="onChangeChannelId"
          maxlength="500"
        />
      </div>
      <a
        class="weak-text text-blue"
        href="https://help.socialintents.com/article/148-how-to-find-your-slack-team-id-and-slack-channel-id"
        target="_blank"
        >{{  $t('ADMIN_VIEW.SLACK_INTEGRATION.HOW_TO_CHECK')}}</a
      >
      <div>
        <p class="error-text" v-show="isInvalidChannelId">
          {{  $t('ADMIN_VIEW.SLACK_INTEGRATION.CHAIN_ID_INVALID')}}
        </p>
      </div>
    </form>
    <div class="buttons">
      <button class="n-btn-weak" @click="cancel">{{ $t('BUTTON.CANCEL_UPPER') }}</button>
      <button class="n-btn-primary" @click="updateSlackIntegration">
        {{ $t('BUTTON.SAVE_UPPER') }}
      </button>
    </div>
  </div>
</template>
<style>
.tab-content-container {
  padding: 12px;
}
</style>
<script>
export default {
  name: "SlackIntegration",
  props: {
    accessToken: String,
    onChangeAccessToken: Function,
    isInvalidAccessToken: Boolean,
    channelId: String,
    onChangeChannelId: Function,
    isInvalidChannelId: Boolean,
    cancel: Function,
    updateSlackIntegration: Function,
  },
};
</script>
