<template>
  <div class="overlay">
    <div class="card">
      <div class="card-header">
        {{ $t('OVERLAY_PUBLIC_KEY.HOW_TO_COPY') }}
      </div>
      <div class="card-content">
        <div class="section" v-for="n in 3" :key="n">
          <img :src="require('@/assets/img/icon-' + n + '.png')" alt="Icon" class="section-icon">
          <img :src="require('@/assets/img/thumbnail-' + n + '.png')" alt="Image" class="section-image">
          <p class="section-text">{{ sectionTexts[n - 1] }}</p> <!-- ここを変更しました -->
        </div>
      </div>
      <div class="card-footer">
        <button class="dashboard-button n-btn-primary" @click="goStripeDashboard">
          {{ $t('OVERLAY_PUBLIC_KEY.BTN_GO_DASHBOARD') }}
          <img src="@/assets/img/icon-external-link-white.png" alt="Dashboard Icon" class="button-icon">
        </button>
        <button class="n-btn-weak" @click="dismiss">{{ $t('BUTTON.CANCEL') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    sectionTexts() {
      return this.$store.getters.currentLang === 'en' ? [
        `Visit and login to your dashboard.\n*Use your email "${this.emailAddress}" and password you set when you conducted the verification process.`,
        'On "Home" tab on dashboard, click your "Public API key", which starts form "pk_live~".',
        'On DeepBeach, paste copied public key and tap "Update" button.'
      ]: [
        `ダッシュボードにアクセスしてログインしてください。\n* メールアドレス "${this.emailAddress}" と、確認プロセスを実施した際に設定したパスワードを使用してください。`,
        `ダッシュボードの「ホーム」タブで、「Public APIキー」をクリックしてください。このキーは「pk_live~」で始まります。`,
        `DeepBeach上で、コピーした公開キーを貼り付け、「更新」ボタンをタップしてください。`
      ]
    }
  },
  props: {
    emailAddress: {
      type: String,
      required: true
    }
  },
  methods: {
    goStripeDashboard: function() {
      window.open("https://dashboard.stripe.com/", "_blank");
      this.dismiss();
    },
    dismiss: function() {
      this.$emit('dismiss');
    }
  }
}
</script>

<style scoped>
.dashboard-button {
  display: flex;
  align-items: center; 
  justify-content: center;
}

.button-icon {
  margin-left: 10px; /* アイコンとテキストの間のスペースを調整 */
  height: 16px;      /* アイコンのサイズを調整 */
  width: auto;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  background-color: white;
  width: 80%;
  max-width: 1200px;
  max-height: calc(100vh - 10%); /* viewportの高さから10%を引いた値 */
  overflow-y: auto; /* 内容がオーバーフローした場合にスクロール */
  padding: 20px;
  border-radius: 8px;
  margin: 5% auto;
}

.card-header {
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
}
.card-content {
  display: flex;
  justify-content: space-between;
}

.section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 20px 10px;
  padding: 12px;
  font-size: 14px;
}
.section-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}
.section-image{
  width: 100%;
  height: 150px; /* この高さを調整して適切な値にする */
  object-fit: cover;  /* 画像が指定した高さに収まるように調整 */
}
.section-text {
  white-space: pre-line;
  text-align: left;
  height: 100px;
}

.card-footer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.card-footer button {
  margin: 6px auto;
  width: 240px;
}

@media (max-width: 600px) {
  .overlay { /* 誤字を修正 */
    overflow-y: auto; 
    align-items: flex-start;
  }
  .card {
    margin-top: 160px;
  }
  .card-content {
    flex-direction: column;
  }
  .section {
    margin-bottom: 80px;
  }
}
</style>
