<template>
  <div class="deep-beach">

    <!--     <header v-scroll="handleScroll">
      <h1>DeepBeach</h1>
      <img src="@/assets/img/db-logo.png" alt="DeepBeach Logo" class="logo" />
      <nav>
        <ul>
          <li><a href="#features">Features</a></li>
          <li><a href="#use-cases">Use Cases</a></li>
          <li><a href="#how-it-works">How It Works</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </nav>
    </header> -->

    <header :class="{ 'hide-header': hideHeader }">
      <img src="@/assets/img/db-logo.png" alt="DeepBeach Logo" class="logo" />
      <nav class="nav-menu">
        <a href="https://twitter.com/deepbeach_nft" target="_blank" class="btn-twitter n-btn-primary">
          <svg viewBox="0 0 1200 1227" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="none"
            fill="white" 
            class="twiiter-logo">
            <path
              d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z">
            </path>
          </svg>
         <span>
          {{ $t('ABOUT_VIEW.BTN_FOLLOW_TWITTER') }}
         </span>
         </a>
        <a href="#features">{{ $t('ABOUT_VIEW.FEATURE') }}</a>
        <a href="#use-cases">{{ $t('ABOUT_VIEW.USE_CASE') }}</a>
        <a href="#how-it-works">{{ $t('ABOUT_VIEW.HOW_WORK') }}</a>
        <a href="#contact">{{ $t('ABOUT_VIEW.CONTACT') }}</a>
      </nav>
      <button class="hamburger" @click="toggleMenu">
        <span></span>
        <span></span>
        <span></span>
      </button>
      <nav :class="['mobile-nav-menu', { 'show-menu': showMobileMenu }]">
        <a href="https://twitter.com/deepbeach_nft" target="_blank" class="btn-twitter n-btn-primary" @click="clickTwitterLink">
          <svg viewBox="0 0 1200 1227" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="none"
            fill="white" 
            class="twiiter-logo">
            <path
              d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z">
            </path>
          </svg>
         <span>
          {{ $t('ABOUT_VIEW.BTN_FOLLOW_TWITTER') }}
         </span>
         </a>
        <a href="#features" @click="toggleMenu">{{ $t('ABOUT_VIEW.FEATURE') }}</a>
        <a href="#use-cases" @click="toggleMenu">{{ $t('ABOUT_VIEW.USE_CASE') }}</a>
        <a href="#how-it-works" @click="toggleMenu">{{ $t('ABOUT_VIEW.HOW_WORK') }}</a>
        <a href="#contact" @click="toggleMenu">{{ $t('ABOUT_VIEW.CONTACT') }}</a>
      </nav>
    </header>

    <section id="hero" class="hero">
      <div class="hero-text">
        <h1>DeepBeach</h1>
        <h2>{{ $t('ABOUT_VIEW.UNLOCK_NFT') }} <br> --- <br>{{ $t('ABOUT_VIEW.CREATE_EXCLUSIVE_NFT') }} </h2>
        <a href="/" class="get-started-btn">{{ $t('ABOUT_VIEW.GET_START') }}</a>
      </div>
    </section>

    <div class="n-container">
      <section id="features" class="features">
        <h2>{{ $t('ABOUT_VIEW.FEATURES') }}</h2>
        <div class="section-content">
          <div class="text-content">
            <ol>
              <li>
                <h3>🔒 {{ $t('ABOUT_VIEW.NFT_GATED_COMMUNITIES') }}</h3>
                <p>{{ $t('ABOUT_VIEW.EASY_CREATE') }}</p>
              </li>
              <li>
                <h3>🚀 {{ $t('ABOUT_VIEW.ONE_CLICK') }}</h3>
                <p>{{ $t('ABOUT_VIEW.SINGLE_CLICK') }}.</p>
              </li>
              <li>
                <h3>🤝{{ $t('ABOUT_VIEW.CONNECT_WITH_HOLDER') }}</h3>
                <p>{{ $t('ABOUT_VIEW.BRING_TOGETHER') }}</p>
              </li>
              <li>
                <h3>🏆 {{ $t('ABOUT_VIEW.CONTRIBUTION_RANKING') }}</h3>
                <p>{{ $t('ABOUT_VIEW.CONTRIBUTION_RANKING_NOTE') }}</p>
              </li>
              <li>
                <h3>🎨 {{ $t('ABOUT_VIEW.CUSTOMIZABLE_DESIGN') }}</h3>
                <p>{{ $t('ABOUT_VIEW.CUSTOMIZABLE_DESIGN_NOTE') }}</p>
              </li>
            </ol>
          </div>
          <img src="@/assets/img/lp-feature.png" alt="Features" />
        </div>
      </section>

      <section id="use-cases" class="use-cases">
        <h2>{{ $t('ABOUT_VIEW.USE_CASES') }}</h2>
        <div class="section-content">
          <img src="@/assets/img/lp-usecases.png" alt="Use Cases" />
          <div class="text-content">
            <ul>
              <li>
                <h3>✨{{ $t('ABOUT_VIEW.USE_CASES_1') }}</h3>
              </li>
              <li>
                <h3>✨{{ $t('ABOUT_VIEW.USE_CASES_2') }}</h3>
              </li>
              <li>
                <h3>✨{{ $t('ABOUT_VIEW.USE_CASES_3') }}</h3>
              </li>
              <li>
                <h3>✨{{ $t('ABOUT_VIEW.USE_CASES_4') }}</h3>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section id="how-it-works" class="how-it-works">
        <h2>{{ $t('ABOUT_VIEW.HOW_IT_WORK') }}</h2>
        <div class="section-content">
          <div class="text-content">
            <ol>
              <li>
                <h3>❶ {{ $t('ABOUT_VIEW.ACCESS_TITLE') }}</h3>{{ $t('ABOUT_VIEW.ACCESS_CONTENT') }}
              </li>
              <li>
                <h3>❷ {{ $t('ABOUT_VIEW.CREATE_TITLE') }}</h3> {{ $t('ABOUT_VIEW.CREATE_CONTENT') }}
              </li>
              <li>
                <h3>❸ {{ $t('ABOUT_VIEW.ENGAGE_TITLE') }}</h3>{{ $t('ABOUT_VIEW.ENGAGE_CONTENT') }}
              </li>
            </ol>
          </div>
          <img src="@/assets/img/lp-howitworks.png" alt="How It Works" />
        </div>
      </section>

      <section id="contact" class="contact">
        <h2>{{ $t('ABOUT_VIEW.GET_IN_TOUCH') }}</h2>
        <div>
          <img src="@/assets/img/db-logo.png" alt="Get Started" />
          <div style="display: flex; justify-content: center;">
            <a href="https://forms.gle/JDHVDTheZEwumS3X8" class="get-started-btn" target="_blank">{{
              $t('ABOUT_VIEW.CONTACT_US') }}</a>
          </div>
        </div>
      </section>

      <!--     <section class="get-started">
        <h2>Ready to Dive In?</h2>
        <div class="section-content">
          <div class="text-content">
            <p><a href="https://deepbeach.io" style="color: #000;">Get Started with DeepBeach Today!</a></p>
          </div>
          <img src="@/assets/img/db-logo.png" alt="Get Started" />
        </div>
      </section> -->
    </div>


  </div>
</template>

<script>
export default {
  name: 'DeepBeachLandingPage',
  data() {
    return {
      showMobileMenu: false,
      hideHeader: false,
      lastScrollTop: 0,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    toggleMenu() {
      event.preventDefault(); 
      this.showMobileMenu = !this.showMobileMenu;
    },
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      this.hideHeader = scrollTop > this.lastScrollTop;
      this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    },
    clickTwitterLink() {
      window.open('https://twitter.com/deepbeach_nft', '_blank');
      this.toggleMenu();
    }
    // ...
  },
};
</script>

<style scoped>
.twiiter-logo {
  width: 16px;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5; /* Change the background color of the header */
  height: 80px;
  padding: 0 2rem; /* Add padding to the left and right sides of the header */
  transition: transform 0.3s ease-in-out; /* Add this line */
}

.hero-text h1, h2 {
  background-color: #fff;
  padding: 24px;
}

h1 {
  font-size: 42px;
}

.deep-beach {
  margin: 0 !important;
}

.hero-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
  color: #000;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 32px;
  height: 24px;
  cursor: pointer;
  background-image: url("@/assets/img/icon-bar.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.hamburger span {
  display: block;
  height: 3px;
  background-color: black;
  border-radius: 3px;
}

.mobile-nav-menu {
  display: none;
  flex-direction: column;
  gap: 1rem;
  background-color: #fff;
  padding: 1rem;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  transform: translateY(-100%);
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.mobile-nav-menu.show-menu {
  transform: translateY(0);
  opacity: 1;
}

header.hide-header {
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}

.n-btn-primary {
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
}
nav {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  gap: 12px;
  align-items: center
}

nav ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0 1rem;
}

nav ul li {
  margin: 0 1rem;
}

nav ul li a {
  text-decoration: none;
}

.hero {
  background-image: url('@/assets/img/db-logo.png');
  background-size: 120px;
  background-position: center;
  background-repeat: nrepeat;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff; /* Adjust the text color as needed */
}

.hero-content {
  text-align: center;
}

.logo {
  height: 50px; /* Adjust the size of the logo as needed */
}

.get-started-btn {
  width: 140px;
  text-align: center;
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #000; /* Adjust the button background color as needed */
  color: #fff; /* Adjust the button text color as needed */
  text-decoration: none;
  border-radius: 0px;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1rem;
  border: 2px solid #fff;
}

.get-started-btn:hover {
  background-color: #000; /* Adjust the button hover background color as needed */
}

.section-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  height: 100%;
}

.features,
.use-cases,
.how-it-works,
.get-started,
.contact {
  display: block;
  margin: 24px 0;
  padding: 24px;
  background-color: #fff;
}


.text-content {
  max-width: 50%;
  text-align: left;
}

img {
  max-width: 45%;
  height: auto;
}
.btn-twitter:hover {
  text-decoration: none;
  color: white;
}
/* Add your other styles here */
@media (max-width: 768px) {
  header {
    flex-wrap: wrap; /* Wrap header content on smaller screens */
    justify-content: center;
    height: auto; /* Adjust the header height for mobile devices */
  }

  nav ul li {
    margin: 0.5rem; /* Adjust the spacing between menu items on mobile devices */
  }

  .nav-menu {
    display: none;
  }
  .hamburger,
  .mobile-nav-menu {
    display: flex;
  }

  .features,
  .use-cases,
  .how-it-works,
  .get-started,
  .contact {
    padding: 8px;
  }


  .section-content {
    flex-direction: column;
  }

  .text-content {
    max-width: 100%;
    text-align: left;
    padding: 1rem 0;
  }

  img {
    max-width: 80%;
    margin: 1rem 0;
  }
}
</style>