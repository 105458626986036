<template>
  <div class="overlay-container" @click="clickContainer">
    <div class="n-container">
      <div class="container_" @click="clickActiveArea">
        <div class="post-info-container">
          <div class="left-column">
            <div
              class="profile-image"
              v-bind:style="{
                backgroundImage: 'url(' + postData.profileImageUrl + ')',
              }"
              @click="onClickProfileImage"
            ></div>
          </div>
          <div class="right-column">
            <div class="right-top-container">
              <p>{{ postData.userName }}</p>
              <div class="dropdown">
                <button
                  @click="showActions"
                  class="button-show-actions"
                ></button>
                <div id="post-overlay" class="dropdown-content">
                  <button @click="report">Report</button>
                  <button @click="delete_" v-show="isOwnPost">Delete</button>
                </div>
              </div>
            </div>
            <div class="right-middle-container">
              <p ref="messageRef">{{ postData.userMessage }}</p>
            </div>
            <!-- OGP -->
            <!-- <div class="ogp-container" v-if="ogpData.url !== null && ogpData.url !== ''" @click="openOgpUrl"> -->
            <div
              class="ogp-container"
              v-if="Object.keys(ogpData).length > 0"
              @click="openOgpUrl"
            >
              <div
                class="ogp-image"
                v-bind:style="{
                  backgroundImage: 'url(' + ogpData.imageUrl + ')',
                }"
              ></div>
              <div class="ogp-info-wrapper">
                <p class="ogp-title">{{ ogpData.title }}</p>
                <p class="ogp-description">{{ ogpData.description }}</p>
                <p class="ogp-url">{{ ogpData.url }}</p>
              </div>
            </div>
            <!-- Post Image -->
            <div
              class="post-image"
              v-if="postData.postImageUrl != null"
              @click="showPostImage"
            >
              <img :src="postData.postImageUrl" />
            </div>
            <div class="right-bottom-container">
              <div class="right-bottom-container-reaction-info">
                <div>{{ nonNullCommentsCount }} comments</div>
                <div class="like-info" @click="showLikers">
                  {{ likesCount }} likes
                </div>
              </div>
              <div>
                <button
                  class="button-like"
                  v-bind:id="'button-like-' + postData.postId"
                  @click="likePost"
                  :class="{ 'did-like': didLike }"
                ></button>
              </div>
              <p class="weak-text">{{ postData.ago }}</p>
            </div>
          </div>
        </div>
        <!-- LikerCell Container -->
        <div id="likers-container" :class="{ 'is--open': doShowLikers }">
          <LikerCell
            v-for="item in cachedLikes"
            :key="item"
            :walletAddress="item"
            :membershipId="membershipId"
            :apiBaseUrl="API_BASE_URL"
            :apiEndpointUser="API_ENDPOINT_USER"
          />
        </div>
        <hr />
        <div class="editor-container">
          <div class="left-column">
            <div
              class="profile-image"
              v-bind:style="{
                backgroundImage: 'url(' + userData.userProfileImageUrl + ')',
              }"
            ></div>
          </div>
          <div class="right-column">
            <div class="right-top-container">
              <p>{{ userData.userName }}</p>
            </div>
            <div class="right-middle-container">
              <textarea
                id="comment-textarea"
                placeholder="Add your comment"
                v-model="commentText"
                maxlength="300"
                v-on:input="onChangePostText"
              ></textarea>
            </div>
            <p class="post-text-left-count">
              <span>Characters:</span> {{ postTextLeftCount }}
            </p>
            <div class="right-bottom-container">
              <button class="n-btn-weak" @click="dismiss">Leave</button>
              <button
                class="n-btn-primary"
                :class="{ 'button--loading': isCommenting }"
                @click="comment"
                :disabled="!isCommentValid || isCommenting"
              >
                {{ buttonTitle }}
              </button>
            </div>
          </div>
        </div>
        <div style="display: flex; flex-direction: column-reverse !important">
          <CommentCell
            v-for="(commentId, index) in cachedCommentIds"
            :key="index"
            :commentId="commentId"
            :postId="postData.postId"
            :membershipId="membershipId"
            :clientWalletAddress="userData.walletAddress"
            v-on:on-success-delete-comment="
              onSuccessDeleteComment($event, value)
            "
            class="postcell-n my-3"
          />
        </div>
      </div>
    </div>
    <!-- Overlay Post Image -->
    <OverlayImage
      v-show="zoomPostImage"
      :imageUrl="postData.postImageUrl"
      :shopDetail="[postData.postImageUrl]"
      v-on:close-overlay-image="dismissPostImage"
    ></OverlayImage>
  </div>
</template>

<style scoped>
.n-container {
  border: none;
}
.container_ {
  width: 60%;
  padding: 24px;
  margin: auto;
  background-color: #f5f8fa;
}
.ogp-container {
  background-color: #e1e8ed;
  cursor: pointer;
  margin-bottom: 12px;
}
.ogp-image {
  width: 100%;
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.ogp-info-wrapper {
  padding: 12px 24px;
}
.ogp-title {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  color: #555;
}
.ogp-description {
  margin: 12px 0;
  font-size: 12px;
  color: #888;
}
.ogp-url {
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  color: #888;
  margin: 4px 0;
  overflow: hidden;
}
.post-image {
  background-color: beige;
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
}
.post-image img {
  width: 50%;
  border-radius: 8px;
}
.weak-text {
  color: #888 !important;
}
.button--loading {
  background-image: url("@/assets/img/loader.gif");
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}
.post-info-container {
  display: flex;
  text-align: left;
}
.right-column {
  display: flex;
  padding: 0 12px;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
}
.right-top-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.dropdown {
  float: right;
  position: relative;
  display: inline-block;
}
.dropdown-content button {
  width: 100%;
  height: 40px;
  font-size: 16px;
  color: #555;
  border: none;
}
.dropdown-content.show {
  display: block;
}
.dropdown button:hover {
  background-color: #ddd;
}
.button-show-actions {
  width: 50px;
  height: 50px;
  padding: 0; /* This is to center button image  */
  border: none;
  width: 50px;
  height: 50px;
  display: inline-block;
  background-image: url("@/assets/img/icon-dots.png");
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  right: 0;
  z-index: 1;
}
.right-middle-container p {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.right-bottom-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}
.right-bottom-container div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.right-bottom-container-reaction-info {
  color: #888;
  font-weight: 600;
}
.right-bottom-container-reaction-info div {
  margin: 0 8px;
}
.like-info {
  cursor: pointer;
}
#likers-container {
  background-color: #e1e8ed;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 12px;
  padding-left: 12px;
  height: 0;
  overflow: hidden;
  display: flex;
  justify-content: right;
  align-items: middle;
  column-gap: 24px;
  transition: height 0.2s ease-out;
}
#likers-container.is--open {
  padding-top: 12px;
  padding-bottom: 12px;
  height: 80px;
  overflow: hidden;
  transition: height 0.2s ease-out;
}
.editor-container {
  display: flex;
  text-align: left;
  margin-top: 24px;
}
.profile-image {
  width: 50px;
  height: 50px;
  margin: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 25px;
  background-color: #000;
  cursor: pointer;
}
#comments-container {
  width: 100%;
  min-height: 100px;
}
.comment-loader {
  width: 50px;
  height: 50px;
  margin: auto;
  background-image: url("@/assets/img/loader-medium.gif");
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}
.button-like {
  width: 30px;
  height: 30px;
  border: none;
  background-color: transparent;
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("@/assets/img/icon-like-light.png");
}
.button-like.did-like {
  background-image: url("@/assets/img/icon-like-filled-black.png");
}
.post-text-left-count {
  color: #888;
  font-size: 12px;
}
textarea {
  width: 100%;
  height: 100px;
  outline: none;
  border: none;
  border-bottom: 1px solid #ccc;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  background-color: transparent;
}
hr {
  height: 1px;
  background-color: #eee;
  border: none;
  color: #ff0000;
}
@media all and (min-width: 1024px) and (max-width: 1280px) {
}
@media all and (min-width: 768px) and (max-width: 1024px) {
}
@media all and (min-width: 480px) and (max-width: 768px) {
}
@media all and (max-width: 480px) {
  .container_ {
    width: 98%;
  }
  .post-image img {
    width: 100%;
  }
}
</style>

<script>
import { defineComponent } from "vue";
import OverlayImage from "@/views/OverlayImage.vue";
import CommentCell from "@/components/CommentCell.vue";
import LikerCell from "@/components/LikerCell.vue";
import shared from "@/shared";
import axios from "axios";

export default defineComponent({
  name: "OverlayComment",
  data() {
    return {
      commentText: "",
      didCommentLoad: false,
      didRemoveAllCommentsOnce: false,
      isLoadingComments: false,
      isCommentValid: false,
      cachedCommentIds: [],
      cachedLikes: [],
      didSetCachedLikeData: false,
      loadingCount: 0,
      likesCount: 0,
      didLike: false,
      isCommenting: false,
      buttonTitle: "Comment",
      doShowLikers: false,
      didLoadLikers: false,
      membershipId: null,
      zoomPostImage: false,
      postTextLeftCount: this.MAX_POST_CHARACTERS,
      ogpData: {},
      isOwnPost: false,
    };
  },
  computed: {
    nonNullCommentsCount: function () {
      if (this.cachedCommentIds) {
        if (this.cachedCommentIds) {
          return this.cachedCommentIds.length;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
  },
  mounted() {
    this.handleErrorAndGoTop = shared.handleErrorAndGoTop.bind(this);
    if (this.$route.query.membershipId) {
      this.membershipId = this.$route.query.membershipId;
    } else {
      this.handleErrorAndGoTop(
        "Sorry, something went wrong",
        "Please login again",
        3000
      );
      return;
    }
  },
  updated() {
    if (this.commentText) {
      if (this.commentText.length > 0) {
        this.isCommentValid = true;
      } else {
        this.isCommentValid = false;
      }
    }
    if (!this.showOverlayComment) {
      // !!: Dismissしてもupdatedが呼ばれコメントを無駄にロードしてしまうので、それをdidCancelフラグで防止
      return;
    }
    let textarea = document.getElementById("comment-textarea");
    textarea.focus();
    if (this.isLoadingComments) {
      return;
    }
    if (this.postData.walletAddress === this.userData.walletAddress) {
      this.isOwnPost = true;
    } else {
      this.isOwnPost = false;
    }
    if (this.postData.likes && !this.didSetCachedLikeData) {
      this.didSetCachedLikeData = true;
      this.likesCount = this.postData.likes.length;
      this.didLike = this.postData.likes.includes(this.userData.walletAddress);
    }
  },
  props: {
    postData: {
      type: Object,
      required: true,
      default: () => ({ count: 0 }),
    },
    userData: {
      type: Object,
      default: () => ({ count: 0 }),
    },
    showOverlayComment: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    OverlayImage,
    CommentCell,
    LikerCell,
  },
  inject: {
    API_BASE_URL: {
      from: "API_BASE_URL",
    },
    API_ENDPOINT_COMMENTS: {
      from: "API_ENDPOINT_COMMENTS",
    },
    API_ENDPOINT_LIKES: {
      from: "API_ENDPOINT_LIKES",
    },
    API_ENDPOINT_USER: {
      from: "API_ENDPOINT_USER",
    },
    API_ENDPOINT_POST: {
      from: "API_ENDPOINT_POST",
    },
    API_ENDPOINT_CREATE_LIKE: {
      from: "API_ENDPOINT_CREATE_LIKE",
    },
    API_ENDPOINT_DELETE_LIKE: {
      from: "API_ENDPOINT_DELETE_LIKE",
    },
    API_ENDPOINT_CREATE_COMMENT: {
      from: "API_ENDPOINT_CREATE_COMMENT",
    },
    API_ENDPOINT_CREATE_LIKE_COMMENT: {
      from: "API_ENDPOINT_CREATE_LIKE_COMMENT",
    },
    API_ENDPOINT_DELETE_COMMENT: {
      from: "API_ENDPOINT_DELETE_COMMENT",
    },
    API_ENDPOINT_DELETE_LIKE_COMMENT: {
      from: "API_ENDPOINT_DELETE_LIKE_COMMENT",
    },
    API_ENDPOINT_OGP: {
      from: "API_ENDPOINT_OGP",
    },
    LOCAL_STORAGE_KEY_MEMBERSHIP: {
      from: "LOCAL_STORAGE_KEY_MEMBERSHIP",
    },
    LOCAL_STORAGE_KEY_LIKES: {
      from: "LOCAL_STORAGE_KEY_LIKES",
    },
    MAX_POST_CHARACTERS: {
      from: "MAX_POST_CHARACTERS",
    },
    API_ENDPOINT_DELETE_POST: {
      from: "API_ENDPOINT_DELETE_POST",
    },
  },
  methods: {
    showActions: function (event) {
      event.stopPropagation();
      let div = document.getElementById(`post-overlay`);
      div.classList.toggle("show");
    },
    clickContainer: function () {
      this.dismiss();
    },
    clickActiveArea: function (event) {
      event.stopPropagation();
    },
    report: function (event) {
      event.stopPropagation();
      let values = {
        membershipId: this.membershipId,
        postId: this.postData.postId,
        reportedWalletAddress: this.postData.walletAddress,
        reporterWalletAddress: this.userData.walletAddress,
        reportedMessage: this.postData.userMessage,
      };
      this.$emit("click-report-post-event", values);
    },
    delete_: function (event) {
      event.stopPropagation();
      // Delete post
      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_DELETE_POST;
      axios
        .delete(apiUrl, {
          data: {
            walletAddress: this.userData.walletAddress,
            membershipId: this.membershipId,
            postId: this.postData.postId,
          },
        })
        .then(() => {
          // Timelineにイベントをemitして投稿をリロード
          this.$emit("success-delete-post-event", this.postData.postId);
          this.dismiss();
        })
        .catch((err) => {
          console.error(err);
          this.$emit("fail-delete-post-event", err);
        });
    },
    dismiss: function () {
      console.log("dismissing..");
      let _this = this;
      function leave() {
        _this.doShowLikers = false;
        _this.didLoadLikers = false;
        _this.didCommentLoad = false;
        _this.commentText = "";
        _this.loadingCount = 0;
        _this.cachedCommentIds = [];
        _this.cachedLikes = [];
        _this.didSetCachedLikeData = false;
        // Remove liker cells
        let container_ = document.getElementById("likers-container");
        _this.removeAllLikers(container_);
        _this.$emit("close-overlay-comment", {
          postId: _this.postData.postId,
          likes: _this.likesCount,
        });
      }

      // コメント書いてる場合
      if (!this.commentText || this.commentText.length === 0) {
        leave();
        return;
      }

      // コメント書いてない場合
      this.showAlert = shared.showDraftLossAlert.bind(this);
      this.showAlert(() => {
        leave();
      });
    },
    removeAllLikers: function (parent) {
      while (parent.lastElementChild) {
        parent.removeChild(parent.lastElementChild);
      }
    },
    comment: function () {
      if (!this.commentText || this.commentText == "") {
        // TODO: Show error saying you need to write something
        return;
      }
      // Show loader on button
      this.showLoaderOnButton();
      // Post comment
      let _this = this;
      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_CREATE_COMMENT;
      let walletAddress = this.userData.walletAddress;
      let postId = this.postData.postId;

      axios
        .post(apiUrl, {
          walletAddress: walletAddress,
          membershipId: this.membershipId,
          postId: postId,
          text: this.commentText,
        })
        .then((result) => {
          _this.hideLoaderOnButton();
          // Reload comments
          if (result.data.message === "Success") {
            if (!result.data.comment_id) {
              // TODO: Handle error
              return;
            }
            let commentId = result.data.comment_id;
            // 追加されたコメントIDをキャッシュしたコメントID配列に反映
            if (_this.loadingCount === 0) {
              _this.cachedCommentIds.push(commentId);
            } else {
              _this.cachedCommentIds.push(commentId);
            }
            _this.loadingCount++;
            this.resetUI();
          }
        })
        .catch((err) => {
          console.error(err);
          _this.hideLoaderOnButton();
        });
    },
    showLoaderOnButton: function () {
      this.isCommenting = true;
      this.buttonTitle = "Commenting..";
    },
    hideLoaderOnButton: function () {
      this.isCommenting = false;
      this.buttonTitle = "Comment";
    },
    resetUI: function () {
      this.commentText = "";
      this.postTextLeftCount = this.MAX_POST_CHARACTERS;
    },
    likePost: function () {
      //
      this.didLike = !this.didLike;
      let _this = this;
      if (this.didLike) {
        //
        this.likesCount += 1;
        const listUser = [...this.cachedLikes, this.userData.walletAddress];
        this.cachedLikes = [...listUser];
        //
        // call api /createLike
        //
        let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_CREATE_LIKE;
        axios
          .post(apiUrl, {
            walletAddress: this.userData.walletAddress,
            membershipId: this.membershipId,
            postId: this.postData.postId,
          })
          .then(() => {
            let values = {
              post_id: _this.postData.postId,
              like_count: _this.likesCount,
              did_like: _this.didLike,
            };
            _this.$emit("did-like-post", values);
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        this.likesCount -= 1;
        const listUser = this.cachedLikes.filter(
          (item) => item !== this.userData.walletAddress
        );
        this.cachedLikes = [...listUser];
        let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_DELETE_LIKE;
        axios
          .delete(apiUrl, {
            data: {
              walletAddress: this.userData.walletAddress,
              membershipId: this.membershipId,
              postId: this.postData.postId,
            },
          })
          .then(() => {
            // Emit event to parent to sync like status
            let values = {
              post_id: this.postData.postId,
              like_count: this.likesCount,
              did_like: this.didLike,
            };
            this.$emit("did-delete-like-post", values);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    showLikers: function (e) {
      e.stopPropagation();
      if (this.likesCount > 0) {
        // Show likers
        this.doShowLikers = !this.doShowLikers;
      }
    },
    onClickLikerCell: function (values) {
      let _this = this;
      _this.$emit("click-profile-event", values.wallet_address);
    },
    openLink: function (event) {
      event.stopPropagation();
      if (event.target.innerText) {
        window.open(event.target.innerText, "_blank").focus();
      }
    },
    openOgpUrl: function () {
      if (this.ogpData.url) {
        window.open(this.ogpData.url, "_blank").focus();
      }
    },
    onClickProfileImage: function () {
      this.$emit("click-profile-event", this.postData.walletAddress);
      event.stopPropagation();
    },
    showPostImage: function (event) {
      event.stopPropagation();
      this.zoomPostImage = true;
    },
    dismissPostImage: function () {
      event.stopPropagation();
      this.zoomPostImage = false;
    },
    onChangePostText: function (event) {
      this.commentText = event.target.value;
      this.postTextLeftCount =
        this.MAX_POST_CHARACTERS - this.commentText.length;
      if (this.commentText.length > 0) {
        this.isCommentValid = true;
      } else {
        this.isCommentValid = false;
      }
    },
    onSuccessDeleteComment: function (commentId) {
      this.cachedCommentIds = this.cachedCommentIds.filter((id) => {
        return id !== commentId;
      });
    },
  },
  watch: {
    postData: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!value || !value?.comments) return;

        console.log("new post set");

        // postDataがアップデートされたらコメントをロードする
        this.didCommentLoad = false;
        this.cachedCommentIds = value.comments;
        // REVIEW: ここで毎回postDataを取得し、Likes数を同期しているが効率良いか
        let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_POST;
        axios
          .get(apiUrl, {
            params: {
              membershipId: this.membershipId,
              postId: value.postId,
            },
          })
          .then((result) => {
            let dataObj = JSON.parse(JSON.stringify(result.data.post));
            if (dataObj.likes) {
              this.didLike = dataObj.likes.includes(
                this.userData.walletAddress
              );
              this.likesCount = dataObj.likes.length;
              this.cachedLikes = dataObj.likes;
            }
          })
          .catch((err) => {
            console.error(err);
          });

        //
        // 通知から来た場合は、postDataにリンクがあるのにogpDataがセットされていないことがある。その場合はogpDataを取得し表示する
        //
        this.ogpData = {};
        let formattedText = `${value.userMessage}`.replaceAll("\\n", "\n");
        if (formattedText) {
          let matches = formattedText.match(/\bhttps?:\/\/\S+/gi);
          if (matches && matches.length > 0) {
            let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_OGP;

            this.$axios
              .get(apiUrl, {
                params: {
                  url: matches[0],
                },
              })
              .then((result) => {
                let dataObj = JSON.parse(JSON.stringify(result.data));
                this.ogpData.title = dataObj.title;
                this.ogpData.description = dataObj.description;
                this.ogpData.imageUrl = dataObj.url;
                this.ogpData.url = matches[0];
              })
              .catch((err) => {
                console.error(err, matches[0]);
              });
          }
        }
      },
    },
    showOverlayComment: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!value) {
          this.didLike = false;
          this.likesCount = 0;
        }
      },
    },
  },
});
</script>
