<template>
  <!-- Enlish -->
  <div v-if="$store.getters.curentLang" class="n-container">
    <h1>Privacy Policy</h1>
    <p>Next1 Create Inc. (hereinafter referred to as “our company”) has the following privacy policy ( hereinafter
      referred to as “this policy”).</p>

    <section class="text-left">
      <h3>Article 1 (Personal Information)</h3>
      <ol>
        <p>“Personal information” refers to “personal information” as defined in the Personal Information Protection
          Law, and is information about a living individual, including name, date of birth, address, telephone number,
          contact information, etc. It refers to information that can identify a specific individual from the
          description, etc., data related to facial appearance, fingerprints, voiceprints, and information that can
          identify a specific individual from the information alone such as the insurer number of the health insurance
          card (personal identification information).</p>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 2 (Method of Collecting Personal Information)</h3>
      <ol>
        <p>We may ask for personal information such as name, date of birth, address, telephone number, e-mail address,
          bank account number, credit card number, driver's license number, etc. when the user registers for use. In
          addition, transaction records and information related to payments, including the user's personal information,
          made between users and business partners, etc., will be shared with our business partners (including
          information providers, advertisers, ad distribution destinations, etc.). (hereinafter referred to as
          "Partners"), etc</p>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 3 (Purpose of collecting and using personal information)</h3>
      <ol>
        <p>The purposes for which the Company collects and uses personal information are as follows.</p>
        <li>For the provision and operation of our services.</li>
        <li>To respond to inquiries from users (including identity verification).</li>
        <li>To send e-mails about new functions, updates, campaigns, etc. of the services currently being used by users
          and information about other services provided by the Company.</li>
        <li>To contact you as necessary for maintenance, important notices, etc.</li>
        <li> To identify users who have violated the Terms of Service or who attempt to use the service for illegal or
          unjust purposes, and to refuse their use.</li>
        <li>For users to view, change, or delete their own registered information, and view usage status.</li>
        <li>To bill users for usage fees in paid services.</li>
        <li>Purposes incidental to the above purposes of use.</li>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 4 (Change of purpose of use)</h3>
      <ol>
        <li>We will change the purpose of use of personal information only if it is reasonably recognized that the
          purpose of use is relevant to the purpose before the change.</li>
        <li>If the purpose of use is changed, the changed purpose shall be notified to the user or announced on this
          website by the method prescribed by the Company.</li>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 5 (Provision of Personal Information to Third Parties)</h3>
      <ol>
        <li>We will not provide personal information to a third party without obtaining the prior consent of the user,
          except in the following cases. However, this excludes cases permitted by the Personal Information Protection
          Act and other laws and regulations.</li>
        <ol>
          <li>When it is necessary to protect a person's life, body or property, and it is difficult to obtain the
            person's consent</li>
          <li>When it is particularly necessary to improve public health or promote the sound development of children,
            and it is difficult to obtain the consent of the individual.</li>
          <li> When it is necessary to cooperate with a national agency, a local government, or a person entrusted by
            them in carrying out the affairs stipulated by laws and regulations, and obtaining the consent of the person
            concerned When there is a risk of hindrance.</li>
          <li>When the following matters have been announced or announced in advance and the Company has notified the
            Personal Information Protection Commission.</li>
          <ol>
            <li>Including provision to third parties in the purpose of use.</li>
            <li>Items of data provided to third parties.</li>
            <li>Means or method of provision to third parties.</li>
            <li>Stop providing personal information to third parties at the request of the person.</li>
            <li>How to accept your request.</li>
          </ol>
        </ol>
        <li>Notwithstanding the provisions of the preceding paragraph, in the following cases, the recipient of the
          information shall not be a third party.</li>
        <ol>
          <li>When we outsource all or part of the handling of personal information within the scope necessary to
            achieve the purpose of use.</li>
          <li>When personal information is provided along with business succession due to merger or other reasons.</li>
          <li>When personal information is jointly used with a specific person, that fact, the items of personal
            information to be jointly used, the scope of joint users, and the use by the users When the person is
            notified in advance of the purpose and the name of the person responsible for the management of the personal
            information, or when the person is placed in a state where the person can easily know it.</li>
        </ol>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 6 (Disclosure of Personal Information)</h3>
      <ol>
        <li>When the person requests disclosure of personal information, we will disclose it to the person without
          delay. However, if the disclosure falls under any of the following, we may not disclose all or part of it, and
          if we decide not to disclose it, we will notify you without delay. A fee of 1,000 yen will be charged for each
          disclosure of personal information.</li>
        <ol>
          <li>When there is a risk of harming the life, body, property or other rights and interests of the person or a
            third party.</li>
          <li>When there is a risk of significant hindrance to the proper implementation of our business.</li>
          <li>Other cases that violate laws and ordinances.</li>
        </ol>
        <li>Notwithstanding the provisions of the preceding paragraph, in principle, information other than personal
          information such as history information and characteristic information will not be disclosed.</li>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 7 (Correction and Deletion of Personal Information)</h3>
      <ol>
        <li>If the user's own personal information held by the company is incorrect information, the user shall correct,
          add or delete personal information (hereinafter referred to as "correction, etc.") to the company according to
          the procedures specified by the company. ) can be claimed.</li>
        <li>If the Company receives a request from the user as described in the preceding paragraph and determines that
          it is necessary to respond to the request, the Company shall correct the personal information concerned
          without delay.</li>
        <li>If the Company makes corrections, etc. based on the provisions of the preceding paragraph, or if it decides
          not to make corrections, etc., it will notify the user without delay.</li>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 8 (Suspension of use of personal information, etc.)</h3>
      <ol>
        <li>The Company may suspend or delete the use of personal information (hereinafter referred to as (hereinafter
          referred to as “suspension of use, etc.”), we will conduct the necessary investigation without delay.</li>
        <li> If it is determined that it is necessary to respond to the request based on the results of the
          investigation in the preceding paragraph, we will stop using the personal information without delay.</li>
        <li>If the Company suspends use, etc. based on the provisions of the preceding paragraph, or if it decides not
          to suspend use, etc., it will notify the user without delay.</li>
        <li>Notwithstanding the preceding two paragraphs, in cases where suspension of use, etc. requires a large amount
          of money or other cases in which suspension of use, etc. is difficult, necessary alternative measures to
          protect the rights and interests of the user If it is possible to take this alternative measure, it shall be
          taken.</li>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 9 (Changes to Privacy Policy)</h3>
      <ol>
        <li>The contents of this policy may be changed without notifying the user, except for laws and regulations and
          other matters stipulated otherwise in this policy.</li>
        <li>Unless otherwise specified by the Company, the changed privacy policy shall take effect from the time it is
          posted on this website.</li>
      </ol>
    </section>

    <section class="text-left">
      <h3>Article 10 (Contact point for inquiries)</h3>
      <ul>
        <p>For inquiries regarding this policy, please contact the following contact point.</p>
        <ul>
          <li>・Address: Samoncho 13 Isobe Bldg. 6F, Shinjuku, Tokyo, Japan</li>
          <li>・Company name: Next1 Create Inc.</li>
          <li>・Department in charge: Digital</li>
          <li>・Email address: contact-digital@next1create.com </li>
        </ul>
      </ul>
    </section>
  </div>
  <!-- japanese -->

  <div v-else class="n-container">
    <h1>プライバシーポリシー</h1>
    <p>Next1 Create株式会社（以下、当社とする）は、以下のプライバシーポリシー（以下、本ポリシーとする）を掲示しております。</p>

    <section class="text-left">
      <h3>第1条（個人情報）</h3>
      <ol>
        <p>
          「個人情報」とは、個人情報保護法に定めるところの「個人情報」を指し、氏名、生年月日、住所、電話番号、連絡先等、生存する個人に関する情報であって、記述、等により特定の個人を識別できる情報、顔の出処や指紋、音声紋等により特定の個人を識別できる情報及び保険証の保険者番号などの個人識別情報を指します。
        </p>
      </ol>
    </section>

    <section class="text-left">
      <h3>第2条（個人情報の収集方法）</h3>
      <ol>
        <p>
          利用者のご利用登録の際に、氏名、生年月日、住所、電話番号、メールアドレス、銀行口座番号、クレジットカード番号、運転免許証番号等、個人情報をお尋ねすることがあります。また、利用者とビジネスパートナー等との間で行われた取引や支払いに関する記録・情報（利用者に関する個人情報を含みます）は、当社のビジネスパートナー（情報提供元、広告配信先等を含みます）等と共有される場合があります。
        </p>
      </ol>
    </section>

    <section class="text-left">
      <h3>第3条（個人情報の利用目的）</h3>
      <ol>
        <p>当社が個人情報を収集し利用する目的は、以下のとおりです。</p>
        <li>当社のサービスの提供・運営のため。</li>
        <li>利用者からのお問い合わせに対応するため（本人確認を含む）。</li>
        <li>利用者が現在利用しているサービスの新機能、更新、キャンペーン等に関する電子メールを送信するため、及び当社が提供する他のサービスに関する情報を送信するため。</li>
        <li>メンテナンス、重要なお知らせ等のため、必要に応じて連絡するため。</li>
        <li>利用規約に違反した利用者や不正・不当な目的での利用を試みる利用者の識別をし、その利用をお断りするため。</li>
        <li>利用者が自己の登録情報を閲覧・変更・削除すること、利用状況を閲覧することができるようにするため。</li>
        <li>有料サービスの利用料金等の請求のため。</li>
        <li>上記利用目的に付随する目的。</li>
      </ol>
    </section>
    <section class="text-left">
      <h3>第4条（利用目的の変更）</h3>
      <ol>
        <li>利用目的が変更前の目的と関連性があり、合理的に認識される場合にのみ、個人情報の利用目的を変更します。</li>
        <li>利用目的が変更された場合は、変更後の目的をユーザーに通知するか、会社の定める方法によりこのウェブサイトで公表します。</li>
      </ol>
    </section>
    <section class="text-left">
      <h3>第5条（第三者提供）</h3>
      <ol>
        <li>ユーザーの事前の同意を得ずに、個人情報を第三者に提供しません。ただし、個人情報保護法およびその他の法令で許可されている場合を除きます。</li>
        <ol>
          <li>人の生命、身体または財産を保護するために必要であり、本人の同意を得ることが困難な場合</li>
          <li>公衆衛生の向上または児童の健全な育成を特に必要とし、本人の同意を得ることが困難な場合</li>
          <li>法令で定められた事務を実施するために、国の機関、地方自治体またはそれらに委託された者との協力が必要であり、本人の同意を得ることにより妨げが生じるおそれがある場合</li>
          <li>以下の事項が事前に公表され、会社が個人情報保護委員会に通知した場合</li>
          <ol>
            <li>利用目的における第三者への提供を含めること</li>
            <li>第三者に提供するデータの項目</li>
            <li>第三者への提供の手段または方法</li>
            <li>本人の要請により第三者への個人情報の提供を停止する方法</li>
            <li>要求を受け付ける方法</li>
          </ol>
        </ol>
        <li>前項の規定にかかわらず、次の場合には情報の受領者は第三者とはみなされません。</li>
        <ol>
          <li>利用目的を達成するために必要な範囲内で、個人情報の取り扱いの全部または一部を第三者に委託する場合</li>
          <li>合併その他の理由による事業の承継に伴って個人情報が提供される場合</li>
          <li>
            特定の者と共同で個人情報を利用する場合であり、その事実、共同利用される個人情報の項目、共同利用者の範囲、利用者による利用の目的、個人情報の管理者の氏名が、本人に事前に通知されるか容易に知ることができる状態に置かれる場合
          </li>
        </ol>
      </ol>
    </section>
    <section class="text-left">
      <h3>第6条（個人情報の開示）</h3>
      <ol>
        <li>
          本人が個人情報の開示を求めた場合、速やかに開示します。ただし、開示が以下のいずれかに該当する場合、全部または一部を開示しないことがあり、開示しない場合は速やかに通知します。個人情報の開示には1,000円の手数料がかかります。
        </li>
        <ol>
          <li>本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>
          <li>当社の業務の適切な実施に重大な支障を及ぼすおそれがある場合</li>
          <li>法令に違反する場合</li>
        </ol>
        <li>前項の規定にかかわらず、原則として、履歴情報および特性情報などの個人情報以外の情報は開示しません。</li>
      </ol>
    </section>
    <section class="text-left">
      <h3>第7条（個人情報の訂正および削除）</h3>
      <ol>
        <li>当社が保有するユーザー本人の個人情報に誤りがある場合、ユーザーは当社が定める手続により当社に対して個人情報の訂正、追加または削除（以下「訂正等」という）を請求することができます。</li>
        <li>当社が前項に記載されるようなユーザーからの請求を受け、その対応が必要であると判断した場合、当社は速やかに該当する個人情報を訂正します。</li>
        <li>当社は前項の規定に基づき訂正等を行った場合、または訂正等を行わないことを決定した場合、速やかにユーザーに通知します。</li>
      </ol>
    </section>

    <section class="text-left">
      <h3>第8条（個人情報の利用停止等）</h3>
      <ol>
        <li>当社は、個人情報の利用を停止または消去（以下「利用停止等」といいます）する場合、速やかに必要な調査を行います。</li>
        <li>前項の調査の結果、請求に対応する必要があると判断した場合、当社は速やかに個人情報の利用を停止します。</li>
        <li>当社が前項の規定に基づき利用停止等を行った場合、または利用停止等を行わないことを決定した場合、速やかにユーザーに通知します。</li>
        <li>前二項にかかわらず、利用停止等に関して多額の費用を要する場合や利用停止等が困難な場合には、ユーザーの権利利益を保護するために必要な代替措置が講じられる場合があります。</li>
      </ol>
    </section>
    <section class="text-left">
      <h3>第9条（プライバシーポリシーの変更）</h3>
      <ol>
        <li>本ポリシーの内容は、法令その他本ポリシーに別段の定めがある場合を除いて、ユーザーへの通知なく変更することがあります。</li>
        <li>当社が別途定める場合を除き、変更後のプライバシーポリシーは、当ウェブサイトに掲示された時点から効力を生じるものとします。</li>
      </ol>
    </section>
    <section class="text-left">
      <h3>第10条（お問い合わせ窓口）</h3>
      <ul>
        <p>本ポリシーに関するお問い合わせは、以下の窓口までお願いいたします。</p>
        <ul>
          <li>・住所 : 東京都新宿区左門町13 磯部ビル6F</li>
          <li>・会社名：ネクストワンクリエイト株式会社</li>
          <li>・担当部署：デジタル部</li>
          <li>・メールアドレス：contact-digital@next1create.com</li>
        </ul>
      </ul>
    </section>
  </div>


</template>

<style scoped>
.text-left {
  text-align: left;
}

ol li {
  list-style-type: decimal;
  margin: 20px 0 20px 2em;
}
</style>
