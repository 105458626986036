<template>
    <div class="language-selector">
        <button class="dropdown-toggle-language" @click="toggleDropdown">
            <span class="mdi mdi-translate "></span>
            <span class="current-lang">{{ currentLangName }}</span>
            <ul v-show="showDropdown" class="dropdown-menu-language">
                <template v-for="(item, idx) in languages" :key="idx">
                    <li @click="changeLanguage(item.key)">{{ item.name }}</li>
                </template>
            </ul>
            <span class="mdi mdi-chevron-down"></span>
        </button>
    </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
    name: "ChangeLanguage",
    data() {
        return {
            showDropdown: false,
            languages: [
                {
                    key: 'ja',
                    name: 'Japanese'
                },
                {
                    key: 'en',
                    name: 'English'
                }
            ]
        };
    },
    mounted() {
    },
    computed: {
        currentLangName() {
            const currentLangKey = this.$store.getters.currentLang;
            return this.languages.find((x) => x.key === currentLangKey)?.name;
        }
    },
    props: {
    },
    methods: {
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },
        changeLanguage(key) {
            // Save to store
            this.$store
                .dispatch('changeLanguage', key);
            // Trigger i18n
            this.$i18n.locale = key;
        },
    },
});
</script>

<style scoped lang="scss">
.language-selector {
    display: flex;
    align-items: center;
}

.dropdown-toggle-language {
    position: relative;
    cursor: pointer;
    padding: 8px;
    border: none;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.dropdown-menu-language {
    width: 102%;
    position: absolute;
    top: 96%;
    left: -2px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    z-index: 10;

    & li {
        padding: 5px 10px;
        cursor: pointer;
        border-bottom: 1px solid #ccc;

        & .active {
            color: blue;
        }

        &:hover {
            background-color: #f0f0f0;
        }
    }
}


.dropdown-menu li {
    padding: 5px 10px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
}

.dropdown-menu li:hover {
    background-color: #f0f0f0;
}

.current-lang {
    padding: 0 10px;
}
</style>