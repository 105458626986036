<template>
  <MainHeader :title="title" :walletAddress="walletAddress" :chainName="chainName" :membershipId="membershipId" :isAdmin="isAdmin" />
  <div id="n-container" style="margin-top: 120px;">
    <div id="contents">
      <div class="tab_box">
        <div class="n-container">
          <div v-if="isActive === 'A'"> 
            <p v-if="notifications.length === 0">{{ $t('NOTIFICATION.NO_NOTIFICATION') }}</p>         
            <NotificationCell 
              v-for="(notification, index) in notifications"
              :key="index"
              :notification="notification"
              :membershipId="membershipId"
            />
            <div class="infinite-loop-loader" v-if="isLoadingNotifications"></div>
          </div>
          <div v-else-if="isActive === 'B'">
            <div id="news-container"></div>
          </div>
          <div class="loader-container" v-if="!didLoad">
            <div class="loader"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#notification-container, #news-container {
  width: 100%;
}
.loader-container {
  width:  100%;
  height:  100px;
}
.loader {
  width: 50px;
  height: 50px;
  margin: auto;
  background-image: url("@/assets/img/loader.gif");
  background-size: contain;
}
</style>

<script>
import { defineComponent, reactive, createApp, h } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import shared from '@/shared';
import swalContainer from  '@/utils/swalContainer.js'
import MainHeader from '@/components/MainHeader.vue';
import NotificationCell from '@/components/NotificationCell.vue';
import NewsCell from '@/components/NewsCell.vue';
import axios from "axios";

export default defineComponent({
  name: 'NotificationView',
  data() {
    return {
      isActive: 'A',
      wasActive: '',
      title: '- - -',
      walletAddress: '',
      membershipId: '',
      chainName: '',
      didLoad: false,
      backgroundColorNotificationTab: "transparent",
      backgroundColorNewsTab: "transparent",
      isAdmin: false,
      apiRequest: null,
      notificationProps: [],
      lastTimestamp: null,
      lastScrollTop: 0,
      isLoadingNotifications: false,
      notifications: []
    }
  },
  unmounted() {
    // 無限スクロールイベントの解除
    window.removeEventListener('scroll', this.onScroll);

    // Unbound shared functions
    this.initMoralis = null;
    shared.initMoralis.bind(null);
    this.connectWallet = null;
    shared.connectWallet.bind(null);
    this.logout = null;
    shared.logout.bind(null);    
    this.checkNFT = null;
    shared.checkNFT.bind(null);
    this.handleErrorAndGoTop = null;
    shared.handleErrorAndGoTop.bind(null);
    this.getChainParamFromChainId = null;
    shared.getChainParamFromChainId.bind(null);
  },
  mounted() {
    let _this = this;
    // 無限スクロールイベントの登録
    window.addEventListener('scroll', this.onScroll);

    this.apiRequest = axios.CancelToken.source();
    // ページを去る前に子セルのHTTP Requestを全てキャンセル
    // onBeforeRouteLeave((to, from) => {
    onBeforeRouteLeave(() => {
      for (var i = 0; i < _this.notificationProps.length; i++){
        _this.notificationProps[i].doCancelHttpRequest = true;
      }
      return;
    });

    /* Bind shared functions */
    this.initMoralis = shared.initMoralis.bind(this);
    this.connectWallet = shared.connectWallet.bind(this);
    this.logout = shared.logout.bind(this);
    this.checkNFT = shared.checkNFT.bind(this);
    this.handleErrorAndGoTop = shared.handleErrorAndGoTop.bind(this);
    this.getChainParamFromChainId = shared.getChainParamFromChainId.bind(this);

    /* Init Moralis */
    this.initMoralis(this.MORALIS_SERVER_URL, this.MORALIS_APP_ID, this.MORALIS_MASTER_KEY);

    /* Set header title from cached data */
    let membershipObj = JSON.parse(sessionStorage.getItem(this.LOCAL_STORAGE_KEY_MEMBERSHIP));
    if (!membershipObj) {
      // Local cacheが無い。異常事態なのでトップへ戻る
      this.logout(this.LOCAL_STORAGE_KEY_MEMBERSHIP); 
      this.handleErrorAndGoTop(this.$t('POPUP.TITLE_COMMON_ERROR'), this.$t('POPUP.PLEASE_LOGIN_AGAIN'), 3000);
      return;
    }
    let tokenAddress = membershipObj.tokenAddress;
    let tokenId = membershipObj.tokenId;
    if (membershipObj.name) {
      this.title = membershipObj.name;  
    }
    if (this.$route.query.membershipId) {
      this.membershipId = this.$route.query.membershipId;  
    } else {
      // something went wrong. go back to top
      this.handleErrorAndGoTop(this.$t('POPUP.TITLE_COMMON_ERROR'), this.$t('POPUP.PLEASE_LOGIN_AGAIN'), 3000);
    }
    
    /* Connect to wallet */
    this.connectWallet((err, event, walletData) => {
      if (err) {
        this.handleErrorAndGoTop(this.$t('POPUP.TITLE_COMMON_ERROR'), this.$t('POPUP.PLEASE_LOGIN_AGAIN'), 3000);
        return;
      }
      let walletObj = JSON.parse(walletData);
      if (!walletObj.walletAddress || !walletObj.chainId) {
        this.handleErrorAndGoTop(this.$t('POPUP.TITLE_WALLET_DISCONNECT'), this.$t('POPUP.PLEASE_LOGIN_AGAIN'), 3000);
        return;
      }
      _this.walletAddress = walletObj.walletAddress;
      switch (walletObj.chainId) {
        case 1:
          _this.chainName = "Ethereum";
          break;
        case 137:
          _this.chainName = "Polygon";
          break;
        default:
          swalContainer.warningDiffNetwork()
          break;
      }
      let chainParam = _this.getChainParamFromChainId(walletObj.chainId);

      /* Check NFT */
      _this.checkNFT(chainParam, _this.walletAddress, tokenAddress, tokenId, function(isValidTokenExist) {
        if(!isValidTokenExist) {
          // _this.handleErrorAndGoTop("Required NFT not found - code 103", "You have no memebership NFT for viewing this page", 3000);
          _this.handleErrorAndGoTop(_this.$t('POPUP.TITLE_NOT_FOUND_NFT'), _this.$t('POPUP.CONTENT_NOT_FOUND_NFT'), 3000);
          return;
        }
        /* GET user data to change tab color if necessary */
        let apiUrl = _this.API_BASE_URL + _this.API_ENDPOINT_USER;
        axios.get(apiUrl, {
          params: {
            walletAddress: _this.walletAddress,
            membershipId: _this.membershipId
          }
        })
        .then((result) => {
          let dataObj = JSON.parse(JSON.stringify(result.data));
          if (dataObj.has_new_notification) {
            _this.backgroundColorNotificationTab = "#FF9A9A";
          } else {
            _this.backgroundColorNotificationTab = "transparent";
          }
          if (dataObj.has_new_news) {
            _this.backgroundColorNewsTab = "#FF9A9A";
          } else {
            _this.backgroundColorNewsTab = "transparent";
          }
        })
        .catch((err) => {
          console.error(err);
        });

        /* Check if user is admin to show/hide admin button on header */
        let apiUrl_ = _this.API_BASE_URL + _this.API_ENDPOINT_MEMBERSHIP;
        axios.get(apiUrl_, {
          params: {
            membershipId: _this.membershipId,
            walletAddress: _this.walletAddress
          }
        })
        .then(result => {
          let dataObj = JSON.parse(JSON.stringify(result.data));
          _this.isAdmin = dataObj.is_admin;
        })
        .catch(err => {
          console.error(err);
        });
      });
    });
  },
  updated() {
    if (this.isActive != this.wasActive) {
      this.didLoad = false;
    } else {
      return;
    }
    if (this.didLoad) {
      return;
    }
    if (!this.walletAddress) {
      return;
    }
    if (this.isActive === 'A') {
      this.loadNotifications(true,function() {});
    } else {
      var newsContainer = document.getElementById("news-container");
      this.loadNews(newsContainer);
    }
    this.wasActive = this.isActive;
  },
  inject: {
    LOCAL_STORAGE_KEY_MEMBERSHIP: {
      from: 'LOCAL_STORAGE_KEY_MEMBERSHIP'
    },
    MORALIS_SERVER_URL: {
      from: 'MORALIS_SERVER_URL'
    },
    MORALIS_APP_ID: {
      from: 'MORALIS_APP_ID'
    },
    MORALIS_MASTER_KEY: {
      from: 'MORALIS_MASTER_KEY'
    },
    API_BASE_URL: {
      from: 'API_BASE_URL'
    },
    API_ENDPOINT_MEMBERSHIP: {
      from: 'API_ENDPOINT_MEMBERSHIP'
    },
    API_ENDPOINT_NOTIFICATIONS: {
      from: 'API_ENDPOINT_NOTIFICATIONS'
    },
    API_ENDPOINT_NEWS: {
      from: 'API_ENDPOINT_NEWS'
    },
    API_ENDPOINT_USER: {
      from: 'API_ENDPOINT_USER'
    },
    API_ENDPOINT_POST: {
      from: 'API_ENDPOINT_POST'
    },
    API_ENDPOINT_DEFLAG_NOTIFICATIONS: {
      from: 'API_ENDPOINT_DEFLAG_NOTIFICATIONS'
    },
    API_ENDPOINT_DEFLAG_NEWS: {
      from: 'API_ENDPOINT_DEFLAG_NEWS'
    },
    NOTIFICATION_TYPE_TIP: {
      from: 'NOTIFICATION_TYPE_TIP'
    }
  },
  components: {
    MainHeader,
    NotificationCell
  },
  methods: {
    change: function(num){
      this.isActive = num
    },
    onScroll: function() {
      let _this = this;
      // Notificationページでなれば無限スクロールの必要がない、Skip
      let lastUrlPath =  window.location.pathname.split("/").pop();
      if (lastUrlPath != "notification") {
        return;
      }
      var isScrollingDownward = false;
      var st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > this.lastScrollTop){
          isScrollingDownward = true;
      } else {
          isScrollingDownward = false;
      }
      this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        if (isScrollingDownward) {
          if (!_this.isLoadingNotifications && _this.isActive === 'A') {
            _this.isLoadingNotifications = true;
            _this.loadNotifications(false, function() {
              _this.isLoadingNotifications = false;
            });
          }
        }
      }
    },
    loadNotifications: function(doRefresh, callback) {
      // Load notifications
      let _this = this;
      if (!this.lastTimestamp) {
        this.lastTimestamp = Date.now();
      }
      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_NOTIFICATIONS;
      axios.get(apiUrl, {
        params: {
          walletAddress: this.walletAddress,
          membershipId: this.membershipId,
          lastTimestamp: this.lastTimestamp
        },
        cancelToken: _this.apiRequest.token
      })
      .then(result => {
        let dataObj = JSON.parse(JSON.stringify(result.data));
        if (dataObj.data && dataObj.data.length) {
          let childDataObj = JSON.parse(JSON.stringify(dataObj.data));
          childDataObj.sort((a, b) => parseFloat(b.created_at) - parseFloat(a.created_at));
          let lastObject = childDataObj.reduce(function(prev, curr) {
              return prev.created_at < curr.created_at ? prev : curr;
          });
          if (lastObject) {
            _this.lastTimestamp = lastObject.created_at;
          } else {          
            // TODO: Handle error
          }
          this.notifications = [...this.notifications, ...childDataObj];
        }
        /* Deflag user's new notification / is_read status */
        let apiUrl = _this.API_BASE_URL + _this.API_ENDPOINT_DEFLAG_NOTIFICATIONS;
        axios.post(apiUrl, {
          walletAddress: _this.walletAddress,
          membershipId: _this.membershipId
        })
        .then(() => {
        })
        .catch(err => {
          console.error(err);        
        });
        _this.didLoad = true;
        callback();
      })
      .catch(err => {
        console.error(err);
        _this.didLoad = true;
        callback();
      });
    },
    loadNews: function(parent) { 
      // Clear meberships
      while (parent.firstChild) {
        parent.removeChild(parent.lastChild);
      }
      // Get news
      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_NEWS;
      let membershipData = sessionStorage.getItem(this.LOCAL_STORAGE_KEY_MEMBERSHIP);
      let membershipObj = JSON.parse(membershipData);
      this.membershipId = membershipObj.membershipId;
      let _this = this;
      axios.get(apiUrl, {
        params: {
          walletAddress: this.walletAddress,
          membershipId: this.membershipId
        }
      })
      .then(result => {
        let dataObj = JSON.parse(JSON.stringify(result.data));
        if (dataObj.data) {
          let childDataObj = JSON.parse(JSON.stringify(dataObj.data));
          var newsArray = [];
          Object.keys(childDataObj).sort().forEach(function(key) {
            newsArray.push(childDataObj[key]);
          });
          newsArray.sort((a, b) => parseFloat(b.created_at) - parseFloat(a.created_at));
          for (var i=0; i<newsArray.length; i++) {
            let obj = newsArray[i];
            let timestamp = Number(obj.created_at);
            let isRead = obj.is_read;
            let text = obj.text;
            let externalUrl = obj.external_url;
            let iconImageUrl = obj.icon_image_url;
            const props = reactive({ iconImageUrl: iconImageUrl, externalUrl: externalUrl, isRead: isRead, text: text, timestamp: timestamp });
            const comp = h(NewsCell, {
              onClickNewsEvent: value => {            
                window.open(value, '_blank').focus();
              }
            });
            var Cell = createApp(comp, props);
            const wrapper = document.createElement("div");
            Cell.mount(wrapper);
            parent.append(wrapper);
          }
        }
        /* Deflag user's new news / is_read status */
        let apiUrl = _this.API_BASE_URL + _this.API_ENDPOINT_DEFLAG_NEWS;
        axios.post(apiUrl, {
          walletAddress: _this.walletAddress,
          membershipId: _this.membershipId
        })
        .then(() => {
        })
        .catch(err => {
          console.error(err);
        });
        _this.didLoad = true;
      })
      .catch(err => {
        console.error(err);
        _this.didLoad = true;
      });
    },
  },
});
</script>