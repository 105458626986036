<template>
  <MainHeader
    :title="title"
    :walletAddress="walletAddress"
    :chainName="chainName"
    :membershipId="membershipId"
    :isAdmin="isAdmin"
  />
  <div class="n-container container_">
    <!-- Tab -->
    <ul class="tab_list">
      <li
        v-on:click="change('A')"
        v-bind:class="{ active: isActive === 'A' }"
        v-bind:style="{ backgroundColor: backgroundColorNotificationTab }"
      >
       {{ $t('ADMIN_VIEW.TAB_BASIC_INFO') }}
      </li>
      <li
        v-on:click="change('B')"
        v-bind:class="{ active: isActive === 'B' }"
        v-bind:style="{ backgroundColor: backgroundColorNewsTab }"
      >
        {{ $t('ADMIN_VIEW.TAB_BULLETIN_BOARD') }}
      </li>
      <li
        v-on:click="change('C')"
        v-bind:class="{ active: isActive === 'C' }"
        v-bind:style="{ backgroundColor: backgroundColorNewsTab }"
      >
      {{ $t('ADMIN_VIEW.TAB_SLACK_INTEGRATION') }}
      </li>
      <li
        v-on:click="change('D')"
        v-bind:class="{ active: isActive === 'D' }"
        v-bind:style="{ backgroundColor: backgroundColorNewsTab }"
      >
      {{ $t('ADMIN_VIEW.TAB_COMMERCIAL_ACCOUNT') }}
      </li>
    </ul>

    <!-- Tab Content Basic Info -->
    <BasicInfo
      v-if="isActive === 'A'"
      :title="title"
      :onChangeTitle="onChangeTitle"
      :coverImageUrl="coverImageUrl"
      :selectCoverImage="selectCoverImage"
      :updateBasicInfo="updateBasicInfo"
      :cancel="cancel"
    />

    <!-- Tab Content Bulletin -->
    <ContentBulletin
      v-else-if="isActive === 'B'"
      :bulletinTitle="bulletinTitle"
      :onChangeBulletinTitle="onChangeBulletinTitle"
      :isInvalidBulletinTitle="isInvalidBulletinTitle"
      :bulletinDescription="bulletinDescription"
      :onChangeBulletinDescription="onChangeBulletinDescription"
      :isInvalidBulletinDescription="isInvalidBulletinDescription"
      :bulletinLink="bulletinLink"
      :onChangeBulletinLink="onChangeBulletinLink"
      :isInvalidBulletinLink="isInvalidBulletinLink"
      :bulletinImageUrl="bulletinImageUrl"
      :deleteBulletinImage="deleteBulletinImage"
      :isInvalidFileSizeBulletin="isInvalidFileSizeBulletin"
      :cancel="cancel"
      :removeNews="removeNews"
      :updateBulletinInfo="updateBulletinInfo"
      :selectBulletinImage="selectBulletinImage"
    />

    <!-- Tab Content Slack Integration -->
    <SlackIntegration
      v-else-if="isActive === 'C'"
      :accessToken="accessToken"
      :onChangeAccessToken="onChangeAccessToken"
      :isInvalidAccessToken="isInvalidAccessToken"
      :channelId="channelId"
      :onChangeChannelId="onChangeChannelId"
      :isInvalidChannelId="isInvalidChannelId"
      :cancel="cancel"
      :updateSlackIntegration="updateSlackIntegration"
    />

    <!-- Tab Content Commerce Account -->
    <CommerceAccount
      v-if="isActive === 'D'"
      :selectedCountry="selectedCountry"
      :commerceEmail="commerceEmail"
      :stripeCountries="stripeCountries"
      :isValidCountry="isValidCountry"
      :isStripeAccountCreated="isStripeAccountCreated"
      :isValidEmail="isValidEmail"
      :onChangeEmail="onChangeEmail"
      :onChangeSelect="onChangeSelect"
      :goStripeConnectDashboard="goStripeConnectDashboard"
      :stripeAccountId="stripeAccountId"
      :stripePublicKey="stripePublicKey"
      :createStripeConnectAccount="createStripeConnectAccount"
      :continueOnboard="continueOnboard"
      :showDeleteAlert="showDeleteAlert"
      :updatePublicKey="updatePublicKey"
      :payoutsEnabled="payoutsEnabled"
      :chargesEnabled="chargesEnabled"
      :is-stripe-public-key-set="isStripePublicKeySet"
    />

    <!-- Overlay spinner -->
    <OverlaySpinner v-show="showSpinner"></OverlaySpinner>
  </div>
</template>

<style scoped>
h3 {
  margin-bottom: 40px;
}
select {
  font-size: 15px;
}
.container_ {
  margin-top: 140px;
  background-color: #fff;
  border: 2px solid #000;
}
.edit-image {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 24px auto;
  border: 2px solid #000;
  background-color: #eee;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border-radius: 8px;
}
.edit-image-overlay {
  width: 200px;
  height: 200px;
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
  border-radius: 8px !important;
}
.edit-image-overlay p {
  position: absolute;
  width: inherit;
  top: 0;
  left: 0;
  margin: 0;
  line-height: 200px;
  color: #fff;
  font-weight: 700;
}
.button-edit-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  margin-left: 12px;
  margin-top: 12px;
  border: none;
  border-radius: 15px;
  background-image: url("@/assets/img/icon-edit.png");
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
}
.button-delete-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  margin-right: 12px;
  margin-top: 12px;
  border: none;
  border-radius: 15px;
  background-image: url("@/assets/img/icon-close.png");
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
}
.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 12px;
}
.buttons button {
  min-width: 200px;
  margin-top: 32px;
}
.tab-content-container {
  padding: 12px;
}
</style>

<script>
import { defineComponent, ref } from "vue";
import MainHeader from "@/components/MainHeader.vue";
import BasicInfo from "@/components/BasicInfo.vue";
import ContentBulletin from "@/components/ContentBulletin.vue";
import SlackIntegration from "@/components/SlackIntegration.vue";
import CommerceAccount from "@/components/CommerceAccount.vue";
import OverlaySpinner from "@/views/OverlaySpinner.vue";
import shared from "@/shared";
import axios from "axios";
import { initializeApp } from "firebase/app";
import { onBeforeRouteLeave } from "vue-router";
import {
  getStorage,
  uploadBytes,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";
// const stripe = require("stripe")(process.env.VUE_APP_STRIPE_KEY);
import stripeCountries from "@/assets/json/stripe-countries.json";

export default defineComponent({
  name: "AdminView",
  data() {
    return {
      isActive: "A",
      title: "- - -",
      walletAddress: "",
      membershipId: "",
      chainName: "",
      coverImageUrl: "",
      bulletinImageUrl: "",
      bulletinTitle: "",
      bulletinDescription: "",
      bulletinLink: "",
      webhookUrl: "",
      accessToken: "",
      channelId: "",
      isInvalidName: false,
      isInvalidBulletinTitle: false,
      isInvalidBulletinDescription: false,
      isInvalidBulletinLink: false,
      isInvalidWebhookUrl: false,
      isInvalidAccessToken: false,
      isInvalidChannelId: false,
      isInvalidFileSizeCover: false,
      isInvalidFileSizeBulletin: false,
      isValidEmail: null,
      isStripeAccountCreated: null,
      selectedCoverImageFile: null,
      selectedBulletinImageFile: null,
      showSpinner: false,
      commerceEmail: null,
      selectedCountry: "",
      isValidCountry: null,
      stripeCountries: stripeCountries,
      stripeAccountId: null,
      stripePublicKey: null,
      payoutsEnabled: false,
      chargesEnabled: false,
      isStripePublicKeySet: false
    };
  },
  setup: () => {
    // 編集中、セーブせずにページを離れようとしたらアラートを表示
    const didUpdateMembership = ref(false);
    const isAdmin = ref(false);
    // onBeforeRouteLeave((to, from) => {
    onBeforeRouteLeave(() => {
      if (didUpdateMembership.value || !isAdmin.value) {
        return;
      }
      const answer = window.confirm(
        "You did not save info. Are you sure leaving page?"
      );
      // cancel the navigation and stay on the same page
      if (!answer) return false;
    });
    return {
      isAdmin,
      didUpdateMembership,
    };
  },
  unmounted() {
    // Unbound shared functions
    this.connectWallet = null;
    shared.connectWallet.bind(null);
    this.checkNFT = null;
    shared.checkNFT.bind(null);
    this.handleError = null;
    shared.handleError.bind(null);
    this.handleErrorAndGoTop = null;
    shared.handleErrorAndGoTop.bind(null);
    this.handleSuccess = null;
    shared.handleSuccess.bind(null);
    this.getChainParamFromChainId = null;
    shared.getChainParamFromChainId.bind(null);
    this.validateEmail = null;
    shared.validateEmail.bind(null);
  },
  mounted() {

    // Init Moralis
    this.initMoralis = shared.initMoralis.bind(this);
    this.initMoralis(
      this.MORALIS_SERVER_URL,
      this.MORALIS_APP_ID,
      this.MORALIS_MASTER_KEY
    );

    // Init firebase
    initializeApp(shared.firebaseConfig());

    // Init shared functions
    let _this = this;
    this.connectWallet = shared.connectWallet.bind(this);
    this.getChainParamFromChainId = shared.getChainParamFromChainId.bind(this);
    this.handleSuccess = shared.handleSuccess.bind(this);
    this.handleError = shared.handleError.bind(this);
    this.handleErrorAndGoTop = shared.handleErrorAndGoTop.bind(this);
    this.checkNFT = shared.checkNFT.bind(this);
    this.validateEmail = shared.validateEmail.bind(this);

    // ページ読み込み時にURLのパラメータをチェック
    const urlParams = new URLSearchParams(window.location.search);
    const activeTab = urlParams.get('activeTab');
    if (activeTab) {
      this.isActive = activeTab;
    }

    // Get membership data from local cache
    let membershipData = sessionStorage.getItem(
      this.LOCAL_STORAGE_KEY_MEMBERSHIP
    );
    if (!membershipData) {
      // 上記の値にnullがある場合は、ローカルのキャッシュを全て削除しTopへ
      sessionStorage.clear();
      this.$router.push("/");
      return;
    }
    let membershipObj = JSON.parse(membershipData);
    let tokenAddress = membershipObj.tokenAddress;
    let tokenId = membershipObj.tokenId;
    if (this.$route.query.membershipId) {
      this.membershipId = this.$route.query.membershipId;
    } else {
      this.handleErrorAndGoTop(
        "Sorry, something went wrong",
        "Please login again",
        3000
      );
      return;
    }
    // ウォレットに接続し、アドレスとメンバーシップIDをサーバーサイドに送ってAdmin権限を検証
    this.connectWallet(async (err, event, walletData) => {
      if (err) {
        _this.handleUnAuthorizedError();
        return;
      }
      let walletObj = JSON.parse(walletData);
      if (!walletObj.walletAddress || !walletObj.chainId) {
        console.error("wallet is disconnected?", event, walletData);
        _this.handleErrorAndGoTop(
          "Wallet Disconnected",
          "Please login again",
          3000
        );
        return;
      }
      _this.walletAddress = walletObj.walletAddress;

      // Check if user is admin
      _this.showSpinner = true;
      let apiUrl = _this.API_BASE_URL + _this.API_ENDPOINT_IS_ADMIN;
      try {
        const result = await axios.get(apiUrl, {
          params: {
            membershipId: _this.membershipId,
            walletAddress: _this.walletAddress,
          },
        });
        if (!result.data?.is_admin) {
          _this.handleErrorAndGoTop(
            "Sorry, something went wrong",
            "Please login again",
            3000
          );
          return;
        }
        _this.showSpinner = false;
      } catch (err) {
        console.error(err);
        _this.showSpinner = false;
        _this.handleErrorAndGoTop(
          "Sorry, something went wrong",
          "Please login again",
          3000
        );
        return;
      }

      // Construct UI
      let chainParam = _this.getChainParamFromChainId(walletObj.chainId);
      if (!chainParam) {
        _this.handleErrorAndGoTop(
          "Sorry, something went wrong",
          "Please login again",
          3000
        );
        return;
      }
      _this.chainName = chainParam;
      _this.showSpinner = true;

      // Check NFT and if no NFT go to TOP
      _this.checkNFT(
        chainParam,
        _this.walletAddress,
        tokenAddress,
        tokenId,
        function (isValidTokenExist) {
          if (!isValidTokenExist) {
            _this.showSpinner = false;
            _this.handleErrorAndGoTop(
              "Required NFT not found - code 101",
              "You have no memebership NFT for viewing this page",
              3000
            );
            return;
          }
          // GET membership info
          let apiUrl = _this.API_BASE_URL + _this.API_ENDPOINT_MEMBERSHIP;
          axios
            .get(apiUrl, {
              params: {
                membershipId: _this.membershipId,
                walletAddress: _this.walletAddress,
              },
            })
            .then((result) => {
              _this.showSpinner = false;
              let dataObj = JSON.parse(JSON.stringify(result.data));
              if (dataObj.is_admin) {
                _this.isAdmin = dataObj.is_admin;
                if (_this.isAdmin) {
                  if (dataObj.name) {
                    _this.title = dataObj.name;
                  }
                  if (dataObj.slack_access_token) {
                    _this.accessToken = dataObj.slack_access_token;
                  }
                  if (dataObj.slack_channel_id) {
                    _this.channelId = dataObj.slack_channel_id;
                  }
                  _this.coverImageUrl = dataObj.cover_image_url;
                  _this.bulletinTitle = dataObj.bulletin.title;
                  _this.bulletinDescription = dataObj.bulletin.text;
                  _this.bulletinLink = dataObj.bulletin.external_url;
                  _this.bulletinImageUrl = dataObj.bulletin.image_url;

                  // Init commerce account status
                  _this.fetchStripeConnectAccount();
                } else {
                  this.$router.push("/timeline");
                }
              } else {
                this.$router.push("/timeline");
              }
            })
            .catch((err) => {
              console.error(err);
              _this.showSpinner = false;
              this.$router.push("/timeline");
            });
        }
      );
    });


  },
  inject: {
    LOCAL_STORAGE_KEY_MEMBERSHIP: {
      from: "LOCAL_STORAGE_KEY_MEMBERSHIP",
    },
    MORALIS_SERVER_URL: {
      from: "MORALIS_SERVER_URL",
    },
    MORALIS_APP_ID: {
      from: "MORALIS_APP_ID",
    },
    MORALIS_MASTER_KEY: {
      from: "MORALIS_MASTER_KEY",
    },
    API_BASE_URL: {
      from: "API_BASE_URL",
    },
    API_ENDPOINT_MEMBERSHIP: {
      from: "API_ENDPOINT_MEMBERSHIP",
    },
    API_ENDPOINT_UPDATE_MEMBERSHIP: {
      from: "API_ENDPOINT_UPDATE_MEMBERSHIP",
    },
    API_ENDPOINT_DELETE_MEMBERSHIP: {
      from: "API_ENDPOINT_DELETE_MEMBERSHIP",
    },
    API_ENDPOINT_CREATE_SLACK_WEBHOOK: {
      from: "API_ENDPOINT_CREATE_SLACK_WEBHOOK",
    },
    API_ENDPOINT_REGISTER_STRIPE_CONNECT_ACCOUNT: {
      from: "API_ENDPOINT_REGISTER_STRIPE_CONNECT_ACCOUNT",
    },
    API_ENDPOINT_GET_STRIPE_ACCOUNT: {
      from: "API_ENDPOINT_GET_STRIPE_ACCOUNT",
    },
    API_ENDPOINT_DELETE_STRIPE_ACCOUNT: {
      from: "API_ENDPOINT_DELETE_STRIPE_ACCOUNT"
    },
    API_ENDPOINT_STRIPE_PUBLIC_KEY: {
      from: "API_ENDPOINT_STRIPE_PUBLIC_KEY"
    },
    API_ENDPOINT_IS_ADMIN: {
      from: "API_ENDPOINT_IS_ADMIN"
    },
    API_STRIPE_ONBOARD: {
      from: "API_STRIPE_ONBOARD"
    },
    API_STRIPE_DASHBOARD: {
      from: "API_STRIPE_DASHBOARD"
    },
    DEFAULT_URL_TRANSPARENT_IMAGE: {
      from: "DEFAULT_URL_TRANSPARENT_IMAGE",
    },
  },
  components: {
    MainHeader,
    OverlaySpinner,
    BasicInfo,
    ContentBulletin,
    SlackIntegration,
    CommerceAccount,
  },
  methods: {
    change: function (num) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('activeTab', num);
      history.pushState({}, '', window.location.pathname + '?' + urlParams.toString());
      this.isActive = num;
    },
    reloadAndSwitchTab(num) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('activeTab', num);
      // history.pushState({}, '', window.location.pathname + '?' + urlParams.toString());
      window.location.href = window.location.href.split('?')[0] + `?activeTab=${num}`;
      window.location.reload();
    },
    removeNews: function () {
      this.$swal({
        title: "Are you sure?",
        text: "All the information of bulletin board will be removed",
        // type: 'warning',
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Remove",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_DELETE_MEMBERSHIP;
          axios
            .delete(apiUrl, {
              data: {
                walletAddress: this.walletAddress,
                membershipId: this.membershipId,
              },
            })
            .then(() => {
              (this.bulletinTitle = ""),
                (this.bulletinLink = ""),
                (this.bulletinImageUrl = null),
                (this.bulletinDescription = null),
                (this.didUpdateMembership = true);
              this.showSpinner = false;
              this.handleSuccess = shared.handleSuccess.bind(this);
              this.handleSuccess();
            })
            .catch((err) => {
              console.error(err);
              this.showSpinner = false;
              this.handleError = shared.handleError.bind(this);
              this.handleError();
            });
        }
      });
    },
    handleUnAuthorizedError: function () {
      let _this = this;
      this.$swal({
        title: "No access right to this page",
        showCancelButton: false,
        confirmButtonText: "OK",
        timer: 3000,
      }).then(() => {
        _this.$router.push("/");
      });
    },
    onChangeTitle: function (event) {
      this.title = event.target.value;
    },
    onChangeBulletinTitle: function (event) {
      this.bulletinTitle = event.target.value;
    },
    onChangeWebhookUrl: function (event) {
      this.webhookUrl = event.target.value;
    },
    onChangeAccessToken: function (event) {
      console.log("access token", event.target.value);
      this.accessToken = event.target.value;
    },
    onChangeChannelId: function (event) {
      console.log("channel id", event.target.value);
      this.channelId = event.target.value;
    },
    onChangeBulletinDescription: function (event) {
      this.bulletinDescription = event.target.value;
    },
    onChangeBulletinLink: function (event) {
      this.bulletinLink = event.target.value;
    },
    selectBulletinImage: function () {
      let _this = this;
      var input = document.createElement("input");
      input.type = "file";
      input.accept = "image/png, image/jpeg";
      input.onchange = (e) => {
        if (e.target.files.length > 0) {
          var file = e.target.files[0];
          let maxfilesize = 1024 * 1024 * 2; // 2 Mb
          if (file.size > maxfilesize) {
            _this.isInvalidFileSizeBulletin = true;
            return;
          } else {
            _this.isInvalidFileSizeBulletin = false;
          }
          _this.bulletinImageUrl = URL.createObjectURL(file);
          _this.selectedBulletinImageFile = file;
        }
      };
      input.click();
    },
    selectCoverImage: function () {
      let _this = this;
      var input = document.createElement("input");
      input.type = "file";
      input.accept = "image/png, image/jpeg";
      input.onchange = (e) => {
        if (e.target.files.length > 0) {
          var file = e.target.files[0];
          let maxfilesize = 1024 * 1024 * 2; // 2 Mb
          if (file.size > maxfilesize) {
            _this.isInvalidFileSizeCover = true;
            return;
          } else {
            _this.isInvalidFileSizeCover = false;
          }
          _this.coverImageUrl = URL.createObjectURL(file);
          _this.selectedCoverImageFile = file;
        }
      };
      input.click();
    },
    updateBasicInfo: function () {
      // 各インプットのnullチェックとか正規表現チェックとかもやる
      if (this.title && this.title.length > 0) {
        this.isInvalidName = false;
      } else {
        this.isInvalidName = true;
        return;
      }
      let _this = this;
      this.showSpinner = true;
      // 画像がセットされている場合と。そうでない場合でパターンを分ける
      if (this.selectedCoverImageFile) {
        const storage = getStorage();
        var imageExtension = "";
        switch (this.selectedCoverImageFile.type) {
          case "image/png":
            imageExtension = ".png";
            break;
          case "image/jpeg":
            imageExtension = ".jpeg";
            break;
          default:
            this.showSpinner = false;
            this.handleError();
            return;
        }
        // 1. Upload image to firebase storage
        let imagePath = `/cover_images/${this.membershipId}/bulletin${imageExtension}`;
        const storageRef_ = storageRef(storage, imagePath);
        uploadBytes(storageRef_, this.selectedCoverImageFile)
          .then((snapshot) => {
            return getDownloadURL(snapshot.ref);
          })
          .then((downloadURL) => {
            // 2. Set membership data in firestore
            let apiUrl =
              this.API_BASE_URL + this.API_ENDPOINT_UPDATE_MEMBERSHIP;
            axios
              .post(apiUrl, {
                walletAddress: this.walletAddress,
                membershipId: this.membershipId,
                data: {
                  cover_image_url: downloadURL,
                  name: this.title,
                },
              })
              .then(() => {
                _this.didUpdateMembership = true;
                _this.showSpinner = false;
                _this.handleSuccess();
              })
              .catch((err) => {
                console.error(err);
                _this.showSpinner = false;
                _this.handleError();
              });
          });
      } else {
        // Only set membership data in firestore
        let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_UPDATE_MEMBERSHIP;
        axios
          .post(apiUrl, {
            walletAddress: this.walletAddress,
            membershipId: this.membershipId,
            data: {
              name: this.title,
            },
          })
          .then(() => {
            _this.didUpdateMembership = true;
            _this.showSpinner = false;
            _this.handleSuccess();
          })
          .catch((err) => {
            console.error(err);
            _this.showSpinner = false;
            _this.handleError();
          });
      }
    },
    updateBulletinInfo: function () {
      // 各インプットのnullチェック、正規表現チェック
      // Bulletin title validation
      if (!this.bulletinTitle) {
        this.isInvalidBulletinTitle = true;
      } else {
        if (this.bulletinTitle.length > 0) {
          this.isInvalidBulletinTitle = false;
        } else {
          this.isInvalidBulletinTitle = true;
        }
      }
      // Bulletin description validation
      if (!this.bulletinDescription) {
        this.isInvalidBulletinDescription = true;
      } else {
        if (this.bulletinDescription.length > 0) {
          this.isInvalidBulletinDescription = false;
        } else {
          this.isInvalidBulletinDescription = true;
        }
      }
      // Bulletin link validation
      if (!this.bulletinLink) {
        // do nothing because link is optional
      } else {
        if (this.bulletinLink.length > 0) {
          // Validate bulletin link url format
          this.validateUrl = shared.validateUrl.bind(this);
          this.isInvalidBulletinLink = !this.validateUrl(this.bulletinLink);
        } else {
          this.isInvalidBulletinLink = false;
        }
      }
      var isInvalidParams = false;
      if (
        this.isInvalidBulletinTitle ||
        this.isInvalidBulletinDescription ||
        this.isInvalidBulletinLink
      ) {
        isInvalidParams = true;
      }
      if (isInvalidParams) {
        return;
      }
      let _this = this;
      this.showSpinner = true;
      // 画像がセットされている場合と。そうでない場合でパターンを分ける
      if (this.selectedBulletinImageFile) {
        // Upload cover image to firebase storage if exists
        const storage = getStorage();
        var imageExtension = "";
        switch (this.selectedBulletinImageFile.type) {
          case "image/png":
            imageExtension = ".png";
            break;
          case "image/jpeg":
            imageExtension = ".jpeg";
            break;
          default:
            this.showSpinner = false;
            this.handleError();
            return;
        }
        let imagePath = `/bulletin_images/${this.membershipId}/bulletin${imageExtension}`;
        const storageRef_ = storageRef(storage, imagePath);
        uploadBytes(storageRef_, this.selectedBulletinImageFile)
          .then((snapshot) => {
            return getDownloadURL(snapshot.ref);
          })
          .then((downloadURL) => {
            // Upload bulletin image to firebase storage if exists
            let apiUrl =
              this.API_BASE_URL + this.API_ENDPOINT_UPDATE_MEMBERSHIP;
            axios
              .post(apiUrl, {
                walletAddress: this.walletAddress,
                membershipId: this.membershipId,
                data: {
                  bulletin_external_url: this.bulletinLink,
                  bulletin_image_url: downloadURL,
                  bulletin_text: this.bulletinDescription,
                  bulletin_title: this.bulletinTitle,
                },
              })
              .then(() => {
                _this.didUpdateMembership = true;
                _this.showSpinner = false;
                _this.handleSuccess();
              })
              .catch((err) => {
                console.error(err);
                _this.showSpinner = false;
                _this.handleError();
              });
          });
      } else {
        // Upload bulletin image to firebase storage if exists
        let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_UPDATE_MEMBERSHIP;
        axios
          .post(apiUrl, {
            walletAddress: this.walletAddress,
            membershipId: this.membershipId,
            data: {
              bulletin_external_url: this.bulletinLink,
              bulletin_text: this.bulletinDescription,
              bulletin_title: this.bulletinTitle,
            },
          })
          .then(() => {
            _this.didUpdateMembership = true;
            _this.showSpinner = false;
            _this.handleSuccess();
          })
          .catch((err) => {
            console.error(err);
            _this.showSpinner = false;
            _this.handleError();
          });
      }
    },
    updateSlackIntegration: function () {
      if (!this.accessToken) {
        this.isInvalidAccessToken = true;
      } else {
        this.isInvalidAccessToken = false;
      }

      if (!this.channelId) {
        this.isInvalidChannelId = true;
      } else {
        this.isInvalidChannelId = false;
      }

      if (this.isInvalidAccessToken || this.isInvalidChannelId) {
        return;
      }

      this.showSpinner = true;

      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_CREATE_SLACK_WEBHOOK;
      axios
        .post(apiUrl, {
          membershipId: this.membershipId,
          accessToken: this.accessToken,
          channelId: this.channelId,
        })
        .then((result) => {
          console.log("result", result.data);
          this.showSpinner = false;
          this.handleSuccess();
        })
        .catch((err) => {
          console.error("api err", err);
          this.showSpinner = false;
          this.handleError();
        });
    },
    deleteBulletinImage: function (event) {
      let _this = this;
      let imageUrl = this.DEFAULT_URL_TRANSPARENT_IMAGE;
      this.bulletinImageUrl = imageUrl;
      this.showSpinner = false;
      // OR,FirestoreのcoverImageUrlを透明なものに更新
      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_UPDATE_MEMBERSHIP;
      axios
        .post(apiUrl, {
          walletAddress: this.walletAddress,
          membershipId: this.membershipId,
          data: {
            bulletin_image_url: imageUrl,
          },
        })
        .then(() => {
          _this.showSpinner = false;
        })
        .catch((err) => {
          console.error(err);
          _this.showSpinner = false;
        });
      event.stopPropagation();
    },
    onChangeEmail: function () {
      this.commerceEmail = event.target.value;
    },
    onChangeSelect: function () {
      this.selectedCountry = event.target.value;
    },
    fetchStripeConnectAccount: async function () {
      /*
      NOTE: 
      ・Stripeは1つのメールアドレスにて複数のStandard子アカウントを作成することが可能
      ・このメソッドにより、Membership内で既にStripe Accountが作られているかを確認し、作られている場合は確認再作成できないようにする
      */

      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_GET_STRIPE_ACCOUNT;
      axios
        .get(apiUrl, {
          params: {
            membershipId: this.membershipId,
            walletAddress: this.walletAddress,
          },
        })
        .then((result) => {
          if (result.status === 200) {
            if (result?.data?.is_membership_exist) {
              this.isStripeAccountCreated = result?.data?.is_membership_exist;
            } else {
              this.isStripeAccountCreated = true;
              this.commerceEmail = result?.data?.email;
              this.selectedCountry = result?.data?.country;
              this.stripeAccountId = result?.data?.account_id;
              this.stripePublicKey = result?.data?.stripe_public_key;
              this.payoutsEnabled = result?.data?.payouts_enabled;
              this.chargesEnabled = result?.data?.charges_enabled;
              this.isStripePublicKeySet = Boolean(this.stripePublicKey);            
            }
          } else {
            this.handleErrorAndGoTop();
          }
        })
        .catch((err) => {
          console.log("err", err);
          if (err.response && err.response.status === 404) {
            this.isStripeAccountCreated = false;
          } else {
            this.handleErrorAndGoTop();
          }
        });
    },
    createStripeConnectAccount: async function () {
      if (!this.selectedCountry) {
        this.isValidCountry = false;
      } else {
        this.isValidCountry = true;
      }
      this.isValidEmail = this.validateEmail(this.commerceEmail);
      if (!this.isValidEmail || !this.isValidCountry) return;

      this.showSpinner = true;
      try {
        let apiUrl =
          this.API_BASE_URL + this.API_ENDPOINT_REGISTER_STRIPE_CONNECT_ACCOUNT;
        axios
          .post(apiUrl, {
            walletAddress: this.walletAddress,
            membershipId: this.membershipId,
            email: this.commerceEmail,
            country: this.selectedCountry,
          })
          .then(() => {
            this.showSpinner = false;
            this.handleSuccess();

            // ここでリロードし、CreateAccountボタンを押せないようにする。
            this.reloadAndSwitchTab('D');

          })
          .catch((err) => {
            console.error(err);
            this.showSpinner = false;
            this.handleError();
          });
      } catch (error) {
        console.error("Error creating account", error);
        this.showSpinner = false;
        this.handleError();
      }
    },
    showDeleteAlert() {
      this.$swal({
        html:
        '<h3>Do you want to delete your commerce account?</h3>' +
        '<ul style="list-style-type: disc; padding-left: 20px; text-align: left;">' +
        '<li>・All products will also be deleted.</li>' +
        '<li style="margin-top: 12px;">・DeepBeach is integrated with Stripe. After the deletion process is completed on the DeepBeach side, ' +
        'you might also want to delete the linked Stripe account as well.' +
        '<br/>The deletion of a Stripe account can be carried out by contacting ' + 
        '<a href="https://support.stripe.com/" target="_blank">Stripe Support</a>.</li>' + 
        '<li style="color: red; margin-top: 12px;">!! This operation cannot be reverted.</li></ul>',
        showCancelButton: true,
        confirmButtonText: "Delete Commerce Account",

      }).then((result) => {
        if (result.value) {
          let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_DELETE_STRIPE_ACCOUNT;
          this.showSpinner = true;
          const payload = {
            membershipId: this.membershipId,
            walletAddress: this.walletAddress
          }
          axios.delete(apiUrl, {
            data: payload
          })
          .then(() => {
            this.showSpinner = false;
            this.$swal({
              html:
              '<h3>Your commerce account is deleted</h3>' +
              '<ul style="list-style-type: none; text-align: left; padding-left: 0;">' +
              '<li>・Your commerce account has been deleted.</li>' +
              '<li style="margin-top: 12px;">・DeepBeach is integrated with Stripe. You will need to delete the linked Stripe account as well. ' +
              'The deletion of a Stripe account can be carried out by contacting ' +
              '<a href="https://support.stripe.com/" target="_blank">Stripe Support</a>.</li></ul>',
              showCancelButton: false,
              confirmButtonText: "Contact Stripe Support",
            }).then((result) => {
              if (result.value) {
                window.open("https://support.stripe.com/contact/email?topic=other", '_blank'); 
              }
              // Reload page to refresh
              this.$router.go({path: this.$router.currentRoute.path, force: true})
            }); 

          })
          .catch((err) => {
            console.error("Error deleting stripe account", err);
            this.showSpinner = false;
            this.handleError();
          });
        }
      });
    },
    updatePublicKey(apiKey) {

      /**
       * 1. Firebase DB側で、/stripe_connect_accounts コレクション内の、該当するmembershipIdに、
       * "public_key"ドキュメントを作成する。
       * 
       * 2. API側でPayment Intentを作成する際には、上記のPublic Keyを使用する
       * 
       */

      console.log("updatePublicKey", apiKey);

      this.showSpinner = true;

      let apiUrl = this.API_BASE_URL + this.API_ENDPOINT_STRIPE_PUBLIC_KEY;
      axios
        .post(apiUrl, {
          membershipId: this.membershipId,
          accountId: this.stripeAccountId,
          publicKey: apiKey,
        })
        .then(() => {
          this.showSpinner = false;
          this.handleSuccess();

          // ここでリロードし、Public Keyを反映
          this.reloadAndSwitchTab('D');

        })
        .catch((err) => {
          console.error(err);
          this.showSpinner = false;
          this.handleError();
        });
    },
    goStripeConnectDashboard() {
      // Standard accountの場合
      let url = "https://dashboard.stripe.com/" + this.stripeAccountId;
      window.open(url, "_blank");

      // Express accountの場合
      /*
      let apiUrl = this.API_BASE_URL + this.API_STRIPE_DASHBOARD;
      this.showSpinner = true;
      axios
      .get(apiUrl, {
        params: {
          membershipId: this.membershipId,
          walletAddress: this.walletAddress,
          accountId: this.stripeAccountId
        },
      })
      .then((result) => {
        this.showSpinner = false;
        if (!result.data.url) {
          this.$swal({
            title: "Sorry, something went wrong.",
            text: "Please try again",
            showCancelButton: false,
            confirmButtonText: "OK",
            timer: 3000,
          });
          return;
        }
        console.log("account link?", result.data.url);
        window.open(result.data.url, '_blank');
      })
      .catch((err) => {
        console.error("failed to go dashboard", err);
        this.showSpinner = false;
      });
      */
    },
    continueOnboard() {
      let apiUrl = this.API_BASE_URL + this.API_STRIPE_ONBOARD;
      this.showSpinner = true;
      axios
      .get(apiUrl, {
        params: {
          membershipId: this.membershipId,
          walletAddress: this.walletAddress,
          accountId: this.stripeAccountId
        },
      })
      .then((result) => {
        this.showSpinner = false;
        if (!result.data.url) {
          this.$swal({
            title: "Sorry, something went wrong.",
            text: "Please try again",
            showCancelButton: false,
            confirmButtonText: "OK",
            timer: 3000,
          });
          return;
        }
        console.log("account link?", result.data.url);
        window.open(result.data.url, '_blank');
      })
      .catch((err) => {
        console.error(err);
        this.showSpinner = false;

      });
    },
    cancel: function () {
      this.$router.push({
        name: "timeline",
        query: {
          membershipId: this.membershipId,
        },
      });
    },
  },
  watch: {
    selectedCountry(newCountry, oldCountry) {
      console.log(newCountry, oldCountry);
    },
  },
});
</script>
